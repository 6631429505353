import { makeStyles } from "../Theme/Theme";

const useStyles = makeStyles((theme) => ({
  dialog_root: {
    "& div.MuiDialog-container": {
      "& div.MuiPaper-root.MuiDialog-paper": {
        maxWidth: theme.spacing(100),
        marginTop: theme.spacing(15),
        [theme.breakpoints.down(760)]: {
          marginTop: theme.spacing(11),
        },
      },
    },
  },
}));

export { useStyles };
