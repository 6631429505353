import { alpha } from "@material-ui/core";
import { makeStyles } from "../../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      [theme.breakpoints.down(768)]: {
      display: "flex",
      justifyContent: "center",
      },
    },
    card_container: {
      width: "48%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
        marginRight: "0px !important",
        marginBottom: theme.spacing(2),
      },
    },
    content_header: {
      "& h3": {
        lineHeight: "28px",
        fontWeight: 600,
      },
      "& span": {
        fontSize: "13px",
        fontWeight: 700,
        lineHeight: "16px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    obituary_header: {
      width: "100%",
    },
    content_description: {
      fontWeight: 400,
    },
    content_card: {
      marginBottom: 0,
      height: "100%",
    },
    two_cards_layout: {
      height: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignContent: "space-between",
    },
    align_content_to_end: {
      alignContent: "flex-end",
    },
    image: {
      maxHeight: theme.spacing(32.5),
      [theme.breakpoints.down(768)]: {
        maxHeight: theme.spacing(31.25),
      },
    },
    media_container: {
      backgroundColor: alpha(theme.palette.miscellaneous.backgroundImageGray,0.3),
      height: theme.spacing(32.5),
      maxHeight: theme.spacing(32.5),
      [theme.breakpoints.down(768)]: {
        height: theme.spacing(31.25),
        maxHeight: theme.spacing(31.25),
      },
    },
    padding_16: {
      padding: theme.spacing(2),
    },
    actions_main_container: {
      padding: `${theme.spacing(1)}px 0px`,
      "& [class*='actions_container']": {
        justifyContent: "center",
      },
    },
  };
});

export { useStyles };
