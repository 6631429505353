import React, { useState } from "react";
import PropTypes from "prop-types";

const BusinessDirectoryListing = ({
  className,
  onHoverColor,
  offHoverColor,
  inactiveColor,
  disabled,
  fillColor,
}) => {
  const [iconColor, setIconColor] = useState(
    fillColor ? fillColor : !disabled ? offHoverColor : inactiveColor
  );
  return (
    <svg
      className={className}
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={
        !disabled
          ? () => setIconColor(onHoverColor)
          : () => setIconColor(inactiveColor)
      }
      onMouseLeave={
        !disabled
          ? () => setIconColor(offHoverColor)
          : () => setIconColor(inactiveColor)
      }
    >
      <circle cx="37.5" cy="37.5" r="32" stroke={iconColor} strokeWidth="3" />
      <circle cx="37.5" cy="37.5" r="36" stroke={iconColor} strokeWidth="3" />
      <rect
        x="20"
        y="23.3738"
        width="2.00935"
        height="2.73832"
        stroke={iconColor}
        strokeWidth="2"
      />
      <rect
        x="20"
        y="31.757"
        width="2.00935"
        height="2.73832"
        stroke={iconColor}
        strokeWidth="2"
      />
      <rect
        x="20"
        y="40.1402"
        width="2.00935"
        height="2.73832"
        stroke={iconColor}
        strokeWidth="2"
      />
      <rect
        x="20"
        y="48.5233"
        width="2.00935"
        height="2.73832"
        stroke={iconColor}
        strokeWidth="2"
      />
      <rect
        x="22.1873"
        y="19"
        width="30.4393"
        height="37"
        rx="4"
        stroke={iconColor}
        strokeWidth="2"
      />
      <line
        x1="29.4769"
        y1="18"
        x2="29.4769"
        y2="57"
        stroke={iconColor}
        strokeWidth="2"
      />
      <path
        d="M48.059 39.5268C48.059 39.5485 48.0497 39.6991 47.5639 39.8782C47.1198 40.0419 46.4683 40.1498 45.6914 40.2148C44.4706 40.317 43.0534 40.3061 41.8604 40.297C41.5409 40.2945 41.2374 40.2922 40.9582 40.2922C40.6789 40.2922 40.3755 40.2945 40.056 40.297C38.8629 40.3061 37.4457 40.317 36.2249 40.2148C35.448 40.1498 34.7966 40.0419 34.3524 39.8782C33.8667 39.6991 33.8573 39.5485 33.8573 39.5268C33.8573 38.1888 34.763 36.3001 36.1738 34.7167C37.5846 33.1332 39.3596 32.0126 40.9582 32.0126C42.5568 32.0126 44.3318 33.1332 45.7426 34.7167C47.1533 36.3001 48.059 38.1888 48.059 39.5268Z"
        fill={iconColor}
        stroke={iconColor}
      />
      <path
        d="M42.9918 25.4802C42.9918 26.3452 42.7805 27.3524 42.3906 28.1267C41.9906 28.921 41.4875 29.3236 40.9582 29.3236C40.4289 29.3236 39.9258 28.921 39.5258 28.1267C39.1359 27.3524 38.9246 26.3452 38.9246 25.4802C38.9246 24.6183 39.1326 24.1881 39.4056 23.9528C39.6958 23.7028 40.1768 23.5672 40.9582 23.5672C41.7396 23.5672 42.2206 23.7028 42.5108 23.9528C42.7838 24.1881 42.9918 24.6183 42.9918 25.4802Z"
        fill={iconColor}
        stroke={iconColor}
      />
      <rect
        x="32.0905"
        y="42.914"
        width="17.7354"
        height="0.844541"
        rx="0.42227"
        fill="white"
      />
      <rect
        x="32.0905"
        y="42.914"
        width="17.7354"
        height="0.844541"
        rx="0.42227"
        fill="url(#paint0_linear_2769:1086)"
      />
      <rect
        x="32.0905"
        y="42.914"
        width="17.7354"
        height="0.844541"
        rx="0.42227"
        stroke={iconColor}
        strokeWidth="0.844541"
      />
      <rect
        x="35.2575"
        y="45.2364"
        width="11.4013"
        height="0.42227"
        rx="0.211135"
        fill="white"
      />
      <rect
        x="35.2575"
        y="45.2364"
        width="11.4013"
        height="0.42227"
        rx="0.211135"
        fill="url(#paint1_linear_2769:1086)"
      />
      <rect
        x="35.2575"
        y="45.2364"
        width="11.4013"
        height="0.42227"
        rx="0.211135"
        stroke={iconColor}
        strokeWidth="0.42227"
      />
      <rect
        x="36.9466"
        y="46.9255"
        width="8.02314"
        height="0.42227"
        rx="0.211135"
        fill="white"
      />
      <rect
        x="36.9466"
        y="46.9255"
        width="8.02314"
        height="0.42227"
        rx="0.211135"
        fill="url(#paint2_linear_2769:1086)"
      />
      <rect
        x="36.9466"
        y="46.9255"
        width="8.02314"
        height="0.42227"
        rx="0.211135"
        stroke={iconColor}
        strokeWidth="0.42227"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2769:1086"
          x1="40.9582"
          y1="42.4917"
          x2="40.9582"
          y2="44.1808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2769:1086"
          x1="40.9582"
          y1="45.0253"
          x2="40.9582"
          y2="45.8698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2769:1086"
          x1="40.9582"
          y1="46.7144"
          x2="40.9582"
          y2="47.5589"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

BusinessDirectoryListing.propTypes = {
  className: PropTypes.string,
  onHoverColor: PropTypes.string,
  offHoverColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  disabled: PropTypes.bool,
};

BusinessDirectoryListing.defaultProps = {
  disabled: false,
  onHoverColor: "#f98c10",
  offHoverColor: "#7fc035",
  inactiveColor: "#e3e3e3",
};

export default BusinessDirectoryListing;
