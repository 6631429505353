import React from "react";
export const Flurries = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="32"
    viewBox="0 0 48 32"
    fill="none"
  >
    <path
      d="M43.0266 13.9442C43.0266 13.7521 43.0266 13.56 43.0266 13.368C43.1226 9.5263 40.1453 6.26089 36.3037 5.97277C34.671 5.87673 33.1343 6.26089 31.8857 7.02922C31.2135 2.89944 27.3718 -0.269932 22.8579 0.0181926C18.632 0.210276 15.2706 3.37964 14.6943 7.31734C13.926 7.02922 13.1577 6.93318 12.2933 6.93318C8.25955 6.93318 4.99414 10.1986 4.99414 14.2323C4.99414 14.3284 4.99414 14.4244 4.99414 14.5205C2.97727 15.0967 0 16.2492 0 20.475C0 23.4523 2.40103 26.0454 5.66644 26.3336C5.95456 26.3336 6.14666 26.2375 6.33874 26.0454L7.01101 25.085C7.10706 24.989 7.01103 24.7969 6.81895 24.7969H6.53081C3.84165 24.7969 1.72873 22.8761 1.72873 20.475C1.72873 17.6898 3.2654 16.7294 6.14665 16.0571L6.81895 15.769V14.7125C6.81895 14.5205 6.7229 14.4244 6.7229 14.2323C6.7229 11.159 9.21997 8.66192 12.2933 8.66192C13.2537 8.66192 14.1181 8.95005 14.9825 9.43026L16.231 10.1986V8.75796C16.135 5.10839 19.1122 2.03506 22.8579 1.84298H23.242C26.9876 1.84298 29.9649 4.62818 30.157 8.27776C30.157 8.3738 30.157 8.56589 30.157 8.85401L30.061 11.063L31.5976 9.5263C32.7501 8.3738 34.1907 7.79755 35.7274 7.8936C38.5126 7.98964 40.9137 10.1986 41.1057 12.9838C41.2018 13.56 41.1057 14.0402 41.0097 14.6165L40.8176 15.4809L41.682 15.673C43.8909 16.0571 45.4276 17.7859 45.5236 19.8027C45.6197 21.8196 44.2751 24.0286 42.2582 24.7969C41.778 24.8929 41.2018 25.085 40.7216 25.085H40.4335C40.2414 25.085 40.1453 25.2771 40.2414 25.3731L40.9137 26.3336C41.1057 26.5256 41.2978 26.7177 41.5859 26.6217C41.9701 26.6217 42.4503 26.4296 42.8345 26.3336C45.4276 25.3731 47.3484 22.5879 47.2524 19.7067C47.3484 16.9215 45.5236 14.7125 43.0266 13.9442Z"
      fill="white"
    />
    <path
      d="M14.0221 18.4582C14.0221 19.4186 13.2537 20.1869 12.2933 20.1869C11.3329 20.1869 10.5646 19.4186 10.5646 18.4582C10.5646 17.4978 11.3329 16.7294 12.2933 16.7294C13.2537 16.7294 14.0221 17.4978 14.0221 18.4582Z"
      fill="white"
    />
    <path
      d="M24.2984 22.78C24.2984 24.1246 23.2419 25.1811 21.8974 25.1811C20.5528 25.1811 19.4963 24.1246 19.4963 22.78C19.4963 21.4354 20.5528 20.379 21.8974 20.379C23.2419 20.379 24.2984 21.4354 24.2984 22.78Z"
      fill="white"
    />
    <path
      d="M31.6936 20.5711C31.6936 21.5315 30.9253 22.2038 30.0609 22.2038C29.1005 22.2038 28.4282 21.4355 28.4282 20.5711C28.4282 19.6107 29.1966 18.9384 30.0609 18.9384C30.9253 18.8423 31.6936 19.6107 31.6936 20.5711Z"
      fill="white"
    />
    <path
      d="M23.6262 30.3673C23.6262 31.3277 22.8579 32 21.9935 32C21.0331 32 20.3608 31.2317 20.3608 30.3673C20.3608 29.4069 21.1291 28.7346 21.9935 28.7346C22.8579 28.7346 23.6262 29.4069 23.6262 30.3673Z"
      fill="white"
    />
    <path
      d="M15.7507 26.8138C15.7507 27.7742 14.9824 28.4465 14.118 28.4465C13.1576 28.4465 12.4853 27.6781 12.4853 26.8138C12.4853 25.8533 13.2536 25.1811 14.118 25.1811C14.9824 25.085 15.7507 25.8533 15.7507 26.8138Z"
      fill="white"
    />
    <path
      d="M31.4055 30.3673C31.4055 31.1356 30.7332 31.8079 29.9649 31.8079C29.1965 31.8079 28.5242 31.1356 28.5242 30.3673C28.5242 29.599 29.1965 28.9267 29.9649 28.9267C30.7332 28.9267 31.4055 29.599 31.4055 30.3673Z"
      fill="white"
    />
    <path
      d="M36.976 23.4523C36.976 24.2207 36.3037 24.8929 35.5354 24.8929C34.767 24.8929 34.0947 24.2207 34.0947 23.4523C34.0947 22.684 34.767 22.0117 35.5354 22.0117C36.3037 22.0117 36.976 22.684 36.976 23.4523Z"
      fill="white"
    />
  </svg>
);
