import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    title_container: {
      width: "100%",
      justifyContent: "space-between",
      alignItems: "baseline",
      display: "flex",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      paddingInline: theme.spacing(4),
      [theme.breakpoints.down(960)]: {
        paddingInline: theme.spacing(3),
        flexWrap: "wrap",
        alignItems: "flex-start",
      },
    },
    title_container_no_sponsor: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBlock: theme.spacing(3),
      paddingInline: theme.spacing(4),
      [theme.breakpoints.down(960)]: {
        paddingInline: theme.spacing(3),
        flexWrap: "wrap",
        alignItems: "flex-start",
      },
    },
    title_one_card_no_sponsor: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingInline: "0px !important",
      paddingBlock: "0px !important",
      [theme.breakpoints.down(960)]: {
        paddingInline: theme.spacing(3),
        flexWrap: "wrap",
        alignItems: "flex-start",
      },
    },
    sponsor_container: {
      ["& > div"]: {
        display: "flex",
        gap: theme.spacing(3),
        paddingBottom: "0px !important",
      },
      [theme.breakpoints.down(960)]: {
        width: "100%",
        marginTop: theme.spacing(1),
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "calc(100vw - 50px)",
        overflow: "hidden",
      },
    },
    sponsor_label: {
      textTransform: "uppercase",
      [theme.breakpoints.down(960)]: {
        textTransform: "lowercase",
      },
    },
  };
});

export { useStyles };
