import React from "react";
import clsx from "clsx";
import { useStyles } from "./Editor.style";
import { isValidString } from "../../utils/helper";
import Link from "../../common/Link/Link";
import Typography from "../../common/Typography/Typography";
import { number, oneOfType, string } from "prop-types";
import labels from "../../config/labels";
import { useTownTheme } from "../../context/TownThemeContext";

const Editor = ({ editor }) => {
  const classes = useStyles();
  const { themeColors } = useTownTheme();

  const printAuthorLabel = () => {
    if (isValidString(editor?.full_name)) {
      return editor.full_name;
    }
    if (isValidString(editor?.email)) {
      return editor.email;
    }
    return "";
  };

  return (
    <div
      className={clsx(
        classes.container,
        editor?.img_url === null && classes.fit_content
      )}
    >
      {editor?.img_url && (
        <div className={classes.image_container}>
          <img
            src={editor.img_url}
            className={classes.image}
            alt="editor-thumbnail"
          />
        </div>
      )}
      <div
        className={clsx(
          classes.info_container,
          editor?.img_url === null && classes.fit_content
        )}
      >
        <div className={classes.name_container}>
          <Typography
            style={{ color: themeColors?.masthead_colors?.contrast }}
            level="t1_text_1"
            className={classes.label}
          >
            {labels.TOWN_EDITOR}
          </Typography>

          {editor?.profile_url ? (
            <Link
              style={{
                color: themeColors?.masthead_colors?.contrast,
                lineHeight: "17px",
              }}
              url={editor.profile_url}
              children={printAuthorLabel()}
              level="paragraph_1"
              color="white"
              target="_blank"
              rel="noopener noreferrer"
            />
          ) : (
            <Typography
              style={{
                color: themeColors?.masthead_colors?.contrast,
                lineHeight: "17px",
              }}
              level="paragraph_1"
              color="white"
            >
              {printAuthorLabel()}
            </Typography>
          )}
        </div>

        <Link
          url={editor?.profile_url}
          children={labels.CONTACT}
          underlined
          color="tapintoOrange"
          level="t4_text_4"
          target="_blank"
          className={classes.contact_link}
        />
      </div>
    </div>
  );
};

Editor.propTypes = {
  townId: oneOfType([string, number]),
  sectionId: oneOfType([string, number]),
  className: string,
};

export default Editor;
