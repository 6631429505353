import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { spacing } = theme;
  return {
    town_picker_button: {
      borderRadius: `${spacing(0.5)}px !important`,
      padding: `${spacing(0.5)}px ${spacing(2)}px !important`,
      textTransform: "none !important",
      width: "100%",
      backgroundColor: "white !important",
      justifyContent: "space-between !important",
      marginBottom: `${spacing(2)}px !important`,
    },
    town_picker_button_label: {
      fontSize: "14px",
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "300 !important",
      color: "black !important",
      "& svg": {
        width: spacing(2),
        height: spacing(2),
      },
    },
    icon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginRight: theme.spacing(0.5),
    },
    button_icon: {
      padding: "0px !important",
    },
    town_picker: {
      zIndex: "94037 !important",
    },
    town_picker_popper: {
      zIndex: "94040 !important",
    },
  };
});

export { useStyles };
