import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  login_admin_button: {
    width: "35%",
    height: `${theme.spacing(5)}px !important`,
    "&.MuiButtonBase-root": {
      marginTop: theme.spacing(0.5),
    },
    "&.MuiButton-text": {
      fontSize: "15px",
      fontWeight: 700,
      fontFamily: "Montserrat",
    },
    [theme.breakpoints.down(1400)]: {
      "&.MuiButton-text": {
        fontSize: "12px",
        lineHeight: "12px",
      },
    },
    [`@media print`]: {
      height: theme.spacing(3),
      "&.MuiButton-text": {
        fontSize: "8px",
        lineHeight: "10px",
        fontWeight: 700,
        fontFamily: "Montserrat",
      },
    },
  },
  login_popup: {
    zIndex: "94038 !important",
    marginTop: "0px !important",
  },
  login_dialog_title: {
    "&.MuiDialogTitle-root": {
      height: 0,
      padding: 0,
    },
  },
  login_close_button: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    cursor: "pointer",
  },
}));

export { useStyles };
