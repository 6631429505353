import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    already_subscribed_container: {
      fontSize: 14,
    },
    already_subscribed_link: {
      "& span": {
        fontSize: "14px !important",
        textDecoration: "underline !important",
      },
    },
  };
});

export { useStyles };
