import React from "react"

export const Showers = ({ className }) => {
 return (
  <svg xmlns="http://www.w3.org/2000/svg" width="41" height="32" viewBox="0 0 41 32" fill="none" className={className}>
    <path d="M36.6948 11.9312C36.6948 11.7633 36.6948 11.5953 36.6948 11.3434C36.6948 7.90063 33.9238 5.12963 30.4811 5.12963C29.3055 5.12963 28.2139 5.46551 27.2062 6.0533C26.6185 2.52656 23.3436 -0.160479 19.481 0.00746119C15.8703 0.175401 12.9314 2.86244 12.5115 6.22124C11.8398 5.96933 11.168 5.88535 10.4962 5.88535C7.05347 5.88535 4.28246 8.65636 4.28246 12.0991C4.28246 12.1831 4.28246 12.2671 4.28246 12.351C2.51909 12.8549 0 13.8625 0 17.3892C0 20.0763 2.18321 22.3435 5.20613 22.4274L6.12979 20.9999C6.04582 20.9999 5.87789 20.9999 5.79392 20.9999H5.54201C3.27482 20.9999 1.42749 19.3205 1.42749 17.3053C1.42749 14.8701 2.771 14.1144 5.20613 13.5266L5.79392 13.3587V12.435C5.79392 12.2671 5.79392 12.1831 5.79392 12.0152C5.79392 9.32812 7.97713 7.22888 10.5802 7.22888C11.4199 7.22888 12.1756 7.48078 12.9314 7.90063L14.023 8.48843V7.22888C13.939 4.12199 16.4581 1.51892 19.7329 1.35098H20.0688C23.2597 1.35098 25.8627 3.78611 26.0307 6.80903C26.0307 6.893 26.0307 7.06094 26.0307 7.31285L25.9467 9.16018L27.2902 7.81667C28.2139 6.893 29.3895 6.38918 30.733 6.38918C33.42 6.38918 35.5193 8.57239 35.5193 11.1755C35.5193 11.5113 35.5193 11.8472 35.4353 12.0991L35.2674 12.8549L36.0231 13.0228C37.8704 13.3587 39.2979 14.7862 39.3819 16.5495C39.4659 18.3129 38.2903 20.3282 36.4429 20.916L36.9468 22.2595C39.2979 21.5038 40.9773 19.0686 40.8933 16.4656C40.3895 14.4503 38.878 12.603 36.6948 11.9312Z" fill="white"/>
    <path d="M14.5267 28.2214C12.0916 24.9465 12.2595 19.4045 12.1756 21.1679C11.8397 25.7862 10.0763 28.2214 10.0763 28.2214C9.90838 28.4733 9.82441 28.8091 9.82441 29.145C9.82441 30.4046 10.916 31.4122 12.3435 31.4122C13.687 31.4122 14.8626 30.4046 14.8626 29.145C14.7786 28.8091 14.6947 28.4733 14.5267 28.2214Z" fill="white"/>
    <path d="M22.252 23.8549C20.1527 20.9999 20.3207 16.1297 20.1527 17.7251C19.9008 21.7557 18.3054 23.8549 18.3054 23.8549C18.1375 24.1068 18.0535 24.3587 18.0535 24.6946C18.0535 25.7862 19.0611 26.6259 20.2367 26.6259C21.4123 26.6259 22.4199 25.7862 22.4199 24.6946C22.4199 24.3587 22.336 24.1068 22.252 23.8549Z" fill="white"/>
    <path d="M28.1299 29.4809C26.1986 26.8778 26.3665 22.4274 26.1986 23.8549C25.9467 27.5496 24.5192 29.4809 24.5192 29.4809C24.4352 29.7328 24.3512 29.9847 24.3512 30.2366C24.3512 31.2443 25.2749 32 26.3665 32C27.4581 32 28.3818 31.1603 28.3818 30.2366C28.3818 29.9847 28.2978 29.7328 28.1299 29.4809Z" fill="white"/>
    <path d="M30.565 21.9236C29.3894 20.3282 29.4734 17.4732 29.3894 18.3969C29.2215 20.748 28.2978 21.9236 28.2978 21.9236C28.2138 22.0915 28.2138 22.2595 28.2138 22.4274C28.2138 23.0152 28.8016 23.519 29.4734 23.519C30.1451 23.519 30.7329 23.0152 30.7329 22.4274C30.649 22.1755 30.649 22.0076 30.565 21.9236Z" fill="white"/>
  </svg>
 )
}