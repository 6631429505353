import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  saved_drafts_dialog: {
    zIndex: "94037 !important",
  },
  draft_card: {
    width: "100%",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down(960)]: {
      width: "90%",
      flexWrap: "wrap",
    },
  },
  title: {
    "&.MuiDialogTitle-root": {
      margin: 0,
      background: theme.palette.miscellaneous.black,
      height: theme.spacing(9),
      maxHeight: theme.spacing(9),
      textAlign: "center",
    },
  },
  title_typography: {
    fontSize: "26px",
    paddingTop: theme.spacing(1),
  },
  close_button: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    position: "absolute",
    right: theme.spacing(2),
    cursor: "pointer",
    "&.MuiIconButton-root": {
      height: theme.spacing(2),
      width: theme.spacing(2),
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  },
  drafts_modal_content: {
    marginTop: theme.spacing(5),
    minHeight: theme.spacing(50),
    width: theme.spacing(75),
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignContent: "flex-start",
    [theme.breakpoints.down(761)]: {
      width: theme.spacing(62.5),
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down(601)]: {
      width: theme.spacing(50),
    },
    [theme.breakpoints.down(461)]: {
      width: theme.spacing(43.75),
    },
    [theme.breakpoints.down(401)]: {
      width: theme.spacing(35.5),
    },
  },
}));

export { useStyles };
