import { useState, useEffect } from "react";
import { getTopStories } from "../services/homePage";
import { AD_PLACEHOLDER } from "../utils/constants/homepageConstants";

const useTopStories = ({ town, topStoriesTemplate }) => {
  const [localNews, setLocalNews] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadLocalNews = async (topStory) => {
      setLoading(true);
      const response = await getTopStories({
        town_id: town.id,
        limit: topStory,
      });

      if (
        response &&
        response?.status === 200 &&
        response?.data?.top_stories?.length > 0
      ) {
        const topStoriesCount = response.data.top_stories.length;
        const placeholderForAd = { title: AD_PLACEHOLDER, id: 0 };
        const mainStory = response.data.top_stories[0];
        setLocalNews({
          mainStory: mainStory,
          secondaryStories:
            topStoriesCount === 1
              ? [placeholderForAd]
              : response.data.top_stories.slice(1, topStoriesCount),
        });
      }
      setLoading(false);
    };

    if (topStoriesTemplate && town && localNews === undefined) {
      if (["default", "layout_1", "layout_2"].includes(topStoriesTemplate)) {
        loadLocalNews(4);
      }
      if (["layout_3", "layout_4"].includes(topStoriesTemplate)) {
        loadLocalNews(5);
      }
      if (topStoriesTemplate === "layout_5") {
        loadLocalNews(7);
      }
    }
  }, [town, topStoriesTemplate]);

  return {
    localNews,
    loading,
  };
};

export default useTopStories;
