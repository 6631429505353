import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  ads_container: {
    display: "flex",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(768)]: {
      display: "block",
    },
    [`@media print`]: {
      display: "none !important",
    },
  },
  ad: {
    flexBasis: "auto",
    flexGrow: 1,
    [theme.breakpoints.down(768)]: {
      marginTop: theme.spacing(2),
    },
  },
  ad_for_bullseye: {
    "& > div > iframe": {
      border: "1px black solid !important",
      width: 320,
      height: 270,
      padding: theme.spacing(1),
    },
  },
  ad_for_standard: {
    "& > div > iframe": {
      border: "1px black solid !important",
      padding: theme.spacing(1),
      width: 320,
      height: 120,
    },
  },
  ad_for_desktop_leaderboard: {
    "& > div > iframe": {
      border: "1px black solid !important",
      padding: theme.spacing(1),
      width: 748,
      height: 110,
    },
  },
  ad_for_mobile_leaderboard: {
    "& > div > iframe": {
      border: "1px black solid !important",
      padding: theme.spacing(1),
      width: 340,
      height: 118,
    },
  },
}));

export { useStyles };
