import { makeStyles } from "../Theme/Theme";

const useStyles = makeStyles((theme) => ({
  close_button: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    "&.MuiIconButton-root": {
      height: theme.spacing(3),
      width: theme.spacing(3),
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(0.7),
    },
    "&.MuiIconButton-root .MuiIconButton-label": {
      display: "block",
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
  },
  icon_button_container: {
    display: "flex",
  },
}));

export { useStyles };
