import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const TagAutocomplete = ({
  className,
  items,
  disabled,
  setTagsArray,
  placeholder,
  value,
  shouldRenderLabelComponent,
  classes,
}) => {
  return (
    <Autocomplete
      multiple
      freeSolo
      id="tags-outlined"
      className={className}
      options={items}
      getOptionLabel={(option) => option.label}
      filterSelectedOptions
      renderInput={(props) => (
        <TextField {...props} placeholder={placeholder} variant="outlined" />
      )}
      renderOption={(option) => (
        <>{shouldRenderLabelComponent ? option.labelComponent : option.label}</>
      )}
      onChange={(event, tagsArray) => {
        event.stopPropagation();
        setTagsArray(tagsArray);
      }}
      disabled={disabled}
      value={value}
      classes={classes}
    />
  );
};

TagAutocomplete.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      labelComponent: PropTypes.object,
    })
  ),
  disabled: PropTypes.bool,
  setTagsArray: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      labelComponent: PropTypes.node,
    })
  ),
  shouldRenderLabelComponent: PropTypes.bool,
  classes: PropTypes.object,
};

TagAutocomplete.defaultProps = {
  disabled: false,
};

export default TagAutocomplete;
