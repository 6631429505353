import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values },
  } = theme;
  return {
    masterhead_desktop_container: {
      height: spacing(15),
      width: "100%",
      marginTop: 0,
      display: "flex",
      alignItems: "center",
      height: "fit-content",
      flexDirection: "row",
      flexGrow: 1,
      zIndex: 999,
      "&.MuiGrid-spacing-xs-1": {
        margin: 0,
        width: "100%",
      },
      [`@media print`]: {
        display: window.location.href.includes("legal_notices")
          ? "none"
          : "flex",
        position: "static",
        marginBottom: spacing(2),
      },
    },
    no_printing: {
      "@media print": {
        display: "none !important",
      },
    },
    margin_top_50: {
      marginTop: "50px",
    },
    admin_link: {
      display: "flex",
      justifyContent: "center",
    },
    grid_item: {
      "& .MuiGrid-item": {
        width: "100% !important",
      },
      [below(values.lg)]: {
        padding: "0px !important",
      },
    },
    logo_container: {
      display: "flex",
      justifyContent: "center",
      lineHeight: "2.6em",
    },
  };
});

export { useStyles };
