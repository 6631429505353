import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    backgroundColor: theme.palette.miscellaneous.white,
    marginBottom: theme.spacing(2),
    "& input": {
      opacity: "1 !important",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "none",
    },
    "& .MuiInputBase-input": {
      padding: `${theme.spacing(1)}px !important`,
    },
    "& .MuiInput-underline::after": {
      borderBottom: "none !important",
    },
  },
  autocomplete_options: {
    "& li": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12) !important",
    },
    "& li:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
    '& li.MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
    '& li.MuiAutocomplete-option[aria-selected="true"]:hover': {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  chip: {
    backgroundColor: `${theme.palette.themeColor.primary} !important`,
    margin: theme.spacing(1),
    maxWidth: 250,
    borderRadius: "50px !important",
    "& span": {
      color: `${theme.palette.dark.black} !important`,
    },
  },
}));

export { useStyles };
