import React from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

const LoginFilled = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 7.5L15.59 8.91L18.17 11.5H8V13.5H18.17L15.59 16.08L17 17.5L22 12.5L17 7.5ZM4 5.5H12V3.5H4C2.9 3.5 2 4.4 2 5.5V19.5C2 20.6 2.9 21.5 4 21.5H12V19.5H4V5.5Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default LoginFilled;
