import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values: values },
  } = theme;
  return {
    header_container: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: `${spacing(2)}px 8%`,
      [below(values.sm)]: {
        flexDirection: "column",
        padding: 0,
        paddingInline: spacing(2),
      },
    },
    news_container: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      columnGap: spacing(5),
      [below(1033)]: {
        flexWrap: "wrap",
        maxHeight: "fit-content",
      },
    },
    news_section: {
      display: "flex",
      justifyContent: "center",
      background: "white",
      width: "100%",
      padding: `${spacing(2)}px 8%`,
      [below(values.xs)]: {
        padding: 0,
        paddingInline: spacing(3),
        paddingBottom: spacing(3.25),
      },
    },
  };
});

export { useStyles };
