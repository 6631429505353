import React from "react";
import PropTypes from "prop-types";

const ChevronRightCircle = ({ className, fillColor }) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Icon-Set-Filled"
          transform="translate(-102.000000, -1089.000000)"
          fill={fillColor}
        >
          <path
            d="M122.536,1105.88 L114.879,1113.54 C114.488,1113.93 113.855,1113.93 113.464,1113.54 C113.074,1113.14 113.074,1112.51 113.464,1112.12 L120.586,1105 L113.464,1097.88 C113.074,1097.49 113.074,1096.86 113.464,1096.46 C113.855,1096.07 114.488,1096.07 114.879,1096.46 L122.536,1104.12 C122.775,1104.36 122.85,1104.69 122.795,1105 C122.85,1105.31 122.775,1105.64 122.536,1105.88 L122.536,1105.88 Z M118,1089 C109.164,1089 102,1096.16 102,1105 C102,1113.84 109.164,1121 118,1121 C126.836,1121 134,1113.84 134,1105 C134,1096.16 126.836,1089 118,1089 L118,1089 Z"
            id="chevron-right-circle"
          ></path>
        </g>
      </g>
    </svg>
  );
};

ChevronRightCircle.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

ChevronRightCircle.defaultProps = {
  fillColor: "black",
};

export default ChevronRightCircle;
