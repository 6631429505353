import { alpha } from "@material-ui/core";
import { makeStyles } from "../../../../../../common/Theme/Theme";
import { max } from "date-fns";

const useStyles = makeStyles((theme) => {
  return {
    content_header: {
      padding: 0,
      "& h3": {
        lineHeight: "28px",
        fontWeight: 600,
      },
    },
    content_card: {
      marginBottom: 0,
      width: "100%",
      [theme.breakpoints.down(768)]: {
        padding: 0,
        
      },
    },
    information_container_single_card: {
      width: "60%",
      display: "flex",
      alignContent: "center",
      alignSelf: "center",
      flexWrap: "wrap",
      gap: theme.spacing(3.25),
      padding: theme.spacing(4),
    },
    image: {
      maxHeight: theme.spacing(57.5),
      [theme.breakpoints.down(768)]: {
        maxHeight: theme.spacing(31.25),
      },
    },
    media_container: {
      backgroundColor: alpha(theme.palette.miscellaneous.backgroundImageGray,0.3),
      height: "80%",
      [theme.breakpoints.down(768)]: {
        height: theme.spacing(31.25),
        maxHeight: theme.spacing(31.25),
      },
    },
    no_rounded_border: {
      borderRadius: "0px",
    },
    padding_16: {
      padding: theme.spacing(2),
    },
    actions_main_container: {
      padding: `${theme.spacing(1)}px 0px`,
      "& [class*='actions_container']": {
        justifyContent: "center",
      },
    },
    root:{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      boxShadow: "none !important",
      [theme.breakpoints.down(768)]: {
        padding: 0,
      },
    },
    media_and_information_container: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      alignItems: "stretch",

    },
  };
});

export { useStyles };
