import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values },
  } = theme;
  return {
    main_container_layout_3: {
      height: "1340px",
      width: "100% !important",
      margin: "0px !important",
      padding: `${spacing(7)}px ${spacing(4)}px`,
      rowGap: spacing(4),
      backgroundColor: "white",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      [below(1025)]: {
        height: "fit-content",
        boxShadow: "none",
      },
      [below(values.md)]: {
        padding: 0,
        paddingBottom: spacing(3),
      },
    },
    ad_container_layout_3: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      backgroundColor: "#F7F7F7",
      paddingTop: spacing(2),
      "& [class*='ad_card_for_top_stories']": {
        margin: "0px",
        width: "fit-content !important",
        "& [class*='ads_container']": {
          width: "fit-content",
          height: "fit-content",
          margin: "0px !important",
          "& [class*='ad_for_bullseye']": {
            marginTop: "0px !important",
          },
        },
      },
      "& [class*='video_container']": {
        backgroundColor: "white",
      },
    },
  };
});

export { useStyles };
