import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    video_container: {
      border: "1px solid #333",
      padding: theme.spacing(1),
      margin: "0px auto 16px auto",
      maxWidth: "320px",
      "@media print": {
        display: "none",
      },
    },
    video_play_button: {
      width: "100px",
      height: "50px",
      display: "block",
      backgroundColor: "transparent",
      color: "white",

      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    video_play_show: {
      display: "block",
    },
    video_play_hide: {
      display: "none !important",
    },
    video_play_button_border_black: {
      border: "black solid 3px",
    },
    video_play_button_border_white: {
      border: "white solid 3px",
    },
    video_player: {
      width: "300px",
      height: "250px",
    },
    learn_more_link: {
      width: "300px",
      position: "absolute",
      textAlign: "right",
      color: "white !important",
      backgroundImage:
        "-webkit-linear-gradient(top,rgba(0,0,0,.8),rgba(0,0,0,.7) 40%,rgba(0,0,0,0) 99%)",
      height: "40px",
      padding: "8px",
      zIndex: "1",
    },
    information_title: {
      height: theme.spacing(6),
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      display: "block",
      "& span": {
        fontSize: "13px",
        fontWeight: 700,
        lineHeight: "16px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    secondary_story_top_section: {
      display: "flex",
      justifyContent: "space-between",
      height: "100%",
      flexDirection: "column",
    },
  };
});

export { useStyles };
