import React from "react";
import clsx from "clsx";
import { bool, object, shape } from "prop-types";
import Card from "../../../../common/Card/Card";
import { useStyles } from "./CategorySectionOrColumnCard.style";
import HorizontalLayout from "../../../../common/Card/Layouts/Horizontal/HorizontalLayout";
import VerticalLayout from "../../../../common/Card/Layouts/Vertical/VerticalLayout";
import ContentInfo from "../../../Town/Shared/ContentInfo/ContentInfo";
import ContentHeader from "../../../Town/Shared/ContentHeader/ContentHeader";
import CardActions from "../../../Town/Shared/CardActions/CardActions";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import labels from "../../../../config/labels";
import { VIDEO_PLAYER_OPTIONS } from "../../../../utils/constants/homepageConstants";

const CategorySectionOrColumnCard = ({
  content,
  blockClass,
  asSidebarItems,
  options,
}) => {
  const containsVideoAsMedia = content.media_content[0]?.isVideo;
  const classes = useStyles(containsVideoAsMedia);

  if (screenWidthIsLowerThan(960) || asSidebarItems) {
    return (
      <Card
        children={
          <VerticalLayout
            media={content.media_content[0]}
            information={
              <ContentInfo content={content} asContentHeader={true} />
            }
            options={{
              media: {
                clickable: true,
                townContentUrl: content.url_content,
                opacityOnFocus: true,
              },
              videoPlayer: {
                width: "90%",
                height: "200px",
                showPlayable: true,
              },
            }}
            customClasses={{ image: classes.image }}
          />
        }
        withShadow={options?.withShadow}
        classes={{
          root: classes.category_section_or_column_card_one_card_mobile,
        }}
      />
    );
  }

  return (
    <Card
      children={
        <HorizontalLayout
          header={
            <ContentHeader
              content={content}
              blockClass={blockClass}
              forSingleCard={true}
            />
          }
          media={content.media_content[0]}
          information={
            <ContentInfo
              content={content}
              blockClass={blockClass}
              forSingleCard={true}
            />
          }
          actions={
            <CardActions
              contentUrl={content.url_content}
              actionLabel={labels.READ_MORE}
              withSocialMediaIcons={false}
              withLink={true}
            />
          }
          options={{
            actions: {
              placement: "below information",
            },
            media: {
              clickable: true,
              townContentUrl: content.url_content,
              opacityOnFocus: true,
            },
            videoPlayer: VIDEO_PLAYER_OPTIONS,
          }}
          customClasses={{
            image: clsx(classes.image, classes.no_rounded_border),
          }}
        />
      }
      withShadow={true}
      classes={{
        root: classes.category_section_or_column_card,
      }}
    />
  );
};

CategorySectionOrColumnCard.propTypes = {
  content: object.isRequired,
  asSidebarItems: bool,
  options: shape({
    withShadow: bool,
  }),
};

export default CategorySectionOrColumnCard;
