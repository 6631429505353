import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { down: below, values: values },
  } = theme;
  return {
    header_container: {
      width: "100%",
      [below(values.md)]: {
        paddingLeft: spacing(1),
      },
      marginBottom: spacing(1),
      alignItems: "center",
      display: "flex",
    },
    header_text: {
      marginBottom: 0,
    },
    capitalized_text: {
      textTransform: "uppercase",
    },
    card_title: {
      "& span": {
        color: `${palette.themeColor.primary} !important`,
        textUnderlineOffset: `${spacing(1)}px`,
      },
    },
  };
});

export { useStyles };
