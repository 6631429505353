import React from "react";
import { Grid as MaterialGrid } from "@material-ui/core";
import PropTypes from "prop-types";

const Grid = ({
  children,
  className,
  classes,
  spacing,
  container,
  xs,
  sm,
  md,
  lg,
  xl,
  item,
  justifyContent,
  style,
  ...props
}) => {
  const getProps = () => {
    let properties = {};
    if (item) {
      properties["item"] = item;
    }
    if (xs) {
      properties["xs"] = xs;
    }
    if (sm) {
      properties["sm"] = sm;
    }
    if (md) {
      properties["md"] = md;
    }
    if (lg) {
      properties["lg"] = lg;
    }
    if (xl) {
      properties["xl"] = xl;
    }

    if (container) {
      properties["container"] = true;
    }

    if (spacing) {
      properties["spacing"] = spacing;
    }

    if (justifyContent) {
      properties["justifyContent"] = justifyContent;
    }

    if (style) {
      properties["style"] = style;
    }

    return properties;
  };

  return (
    <MaterialGrid
      className={className}
      classes={classes}
      {...getProps()}
      {...props}
    >
      {children}
    </MaterialGrid>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  spacing: PropTypes.number,
  className: PropTypes.string,
  container: PropTypes.bool,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  item: PropTypes.bool,
  classes: PropTypes.object,
};

export default Grid;
