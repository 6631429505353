import { makeStyles } from "../Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    link: {
      "&:hover": {
        textDecoration: "none !important",
        color: theme.palette.miscellaneous.blueLink,
      },
    },
  };
});
export { useStyles };
