import Typography from "../../common/Typography/Typography";
import { useWeather } from "../../hooks/useWeather";
import React, { useEffect, useMemo, useState } from "react";
import {
  Clear,
  Cloudy,
  Cold,
  Flurries,
  Fog,
  HazyMoonlight,
  HazySunshine,
  Hot,
  Ice,
  MostlyCloudy,
  MostlyCloudyFlurries,
  MostlyCloudyFlurriesNight,
  MostlyCloudyShowers,
  MostlyCloudySnow,
  MostlyCloudySnowNight,
  MostlyCloudyTStorms,
  MostlySunny,
  PartlyCloudy,
  PartlyCloudySnow,
  PartlyCloudyTStorms,
  Rain,
  Showers,
  Sleet,
  Snow,
  Sunny,
  TStorms,
  Windy,
} from "../../common/Icons/Weather";
import useStyles from "./Weather.style";
import { ClickAwayListener, Button, Box } from "@material-ui/core";
import WeatherTooltip from "./WeatherTooltip";
import { WeatherTooltipTitleContent } from "./WeatherTooltipTitleContent";
import { screenWidthIsLowerThan } from "../../hooks/useMediaQuery";
import values from "../../common/Theme/themes/breakpoints/values";
import withConfig from "../../utils/withConfig";
import { sendMessageToParent } from "../../utils/helper";
import { urlToUseOnIframe } from "../../utils/halstonMediaHelper";
import PopUpDialog from "../../common/Dialog/PopUpDialog";
import ButtonIcon from "../../common/Button/ButtonIcon";
import { useMasthead } from "../../hooks/useMasthead";

export const ICONS = {
  1: Sunny,
  2: MostlySunny,
  3: MostlySunny,
  4: MostlySunny,
  5: HazySunshine,
  6: Cloudy,
  7: Cloudy,
  8: Cloudy,
  11: Fog,
  12: Showers,
  13: MostlyCloudyShowers,
  14: MostlyCloudyShowers,
  15: TStorms,
  16: MostlyCloudyTStorms,
  17: MostlyCloudyTStorms,
  18: Rain,
  19: Flurries,
  20: MostlyCloudyFlurries,
  21: MostlyCloudyFlurries,
  22: Snow,
  23: MostlyCloudySnow,
  24: Ice,
  25: Sleet,
  26: Sleet,
  29: Sleet,
  30: Hot,
  31: Cold,
  32: Windy,
  33: Clear,
  34: Clear,
  35: PartlyCloudy,
  36: PartlyCloudy,
  37: HazyMoonlight,
  38: MostlyCloudy,
  39: PartlyCloudySnow,
  40: PartlyCloudySnow,
  41: PartlyCloudyTStorms,
  42: PartlyCloudyTStorms,
  43: MostlyCloudyFlurriesNight,
  44: MostlyCloudySnowNight,
};

export const FARENTHEIT_UNIT = "F";
export const CELSIUS_UNIT = "C";
export const IMPERIAL_WEATHER_SYSTEM = "Imperial";
export const METRIC_WEATHER_SYSTEM = "Metric";

export const Weather = React.memo(
  ({
    townSlug,
    sectionSlug = null,
    arrow = true,
    clickable = true,
    reduced = false,
    forStickyMasthead = false,
  }) => {
    const { weather, isLoading } = useWeather({ townSlug, sectionSlug });
    const { sticky: stickyMasthead } = useMasthead();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const isXsMobile = screenWidthIsLowerThan(values.sm);
    const isMobile = screenWidthIsLowerThan(values.md);
    const arrowPlacement = useMemo(
      () => (isMobile ? "bottom-end" : "bottom"),
      [isMobile]
    );
    const messageToParent = useMemo(() => {
      if (isMobile) {
        return { opened: "opened", closed: "closed" };
      }
      return { opened: "opened-desktop", closed: "closed-desktop" };
    }, [isMobile]);

    const handleToolTipOpen = () => {
      clickable ? setOpen(!open) : setOpen(false);
    };

    const handleTooltipClose = () => {
      setOpen(false);
    };

    const CurrentWeatherIcon =
      ICONS[weather?.forecast?.current_conditions?.Icon];

    const [weatherSystem, setWeatherSystem] = useState(IMPERIAL_WEATHER_SYSTEM);

    const handleChangeWeatherUnit = () => {
      setWeatherSystem(
        weatherSystem === IMPERIAL_WEATHER_SYSTEM
          ? METRIC_WEATHER_SYSTEM
          : IMPERIAL_WEATHER_SYSTEM
      );
    };

    useEffect(() => {
      const message = open ? messageToParent.opened : messageToParent.closed;
      sendMessageToParent(
        message,
        urlToUseOnIframe(townSlug, withConfig("HOME_MONGO"))
      );
    }, [open, messageToParent]);

    useEffect(() => {
      if (isXsMobile) {
        return;
      }

      if (forStickyMasthead && !stickyMasthead && open) {
        setOpen(false);
        return;
      }

      if (!forStickyMasthead && stickyMasthead && open) {
        setOpen(false);
        return;
      }
    }, [forStickyMasthead, stickyMasthead, open]);

    if (!weather?.forecast?.current_conditions) {
      return <></>;
    }

    if (isXsMobile) {
      return (
        <>
          <ButtonIcon
            children={
              <>
                {isLoading && "Loading weather..."}
                {!reduced &&
                  weather?.forecast?.current_conditions &&
                  (<CurrentWeatherIcon /> ||
                    weather?.forecast?.current_conditions?.Imperial?.Icon +
                      weather?.forecast?.current_conditions?.Text)}
                {weather?.forecast?.current_conditions && (
                  <Typography
                    level="t4_text_4"
                    color="white"
                    className={classes.temperature_container}
                  >
                    {weather.forecast.current_conditions.Imperial.Value}°{" "}
                    {weather.forecast.current_conditions.Imperial.Unit}
                  </Typography>
                )}
              </>
            }
            props={{
              onClick: () => handleToolTipOpen(),
              classes: { label: classes.button_icon_label },
            }}
            withDefaultStyles={false}
          />
          <PopUpDialog
            onClose={handleTooltipClose}
            openState={open}
            title=""
            content={
              <WeatherTooltipTitleContent
                handleChangeWeatherUnit={handleChangeWeatherUnit}
                weather={weather}
                weatherSystem={weatherSystem}
              />
            }
            disableCloseOnBackDropClick={false}
            titleClass={classes.pop_up_title}
            className={classes.pop_up}
            contentClass={classes.pop_up_content}
          />
        </>
      );
    }

    return (
      <ClickAwayListener onClickAway={() => handleTooltipClose()}>
        <div>
          <WeatherTooltip
            title={
              <WeatherTooltipTitleContent
                handleChangeWeatherUnit={handleChangeWeatherUnit}
                weather={weather}
                weatherSystem={weatherSystem}
              />
            }
            placement={arrowPlacement}
            arrow={arrow}
            open={open}
            contents={
              <Box
                component={Button}
                variant="text"
                level="button"
                className={classes.weather_container}
                onClick={handleToolTipOpen}
              >
                {isLoading && "Loading weather..."}
                {!reduced &&
                  weather?.forecast?.current_conditions &&
                  (<CurrentWeatherIcon /> ||
                    weather?.forecast?.current_conditions?.Imperial?.Icon +
                      weather?.forecast?.current_conditions?.Text)}
                {weather?.forecast?.current_conditions && (
                  <Typography
                    level="t4_text_4"
                    color="white"
                    className={classes.temperature_container}
                  >
                    {weather.forecast.current_conditions.Imperial.Value}°{" "}
                    {weather.forecast.current_conditions.Imperial.Unit}
                  </Typography>
                )}
              </Box>
            }
          ></WeatherTooltip>
        </div>
      </ClickAwayListener>
    );
  }
);
