import React from "react";
import clsx from "clsx";
import Grid from "../../../../common/Grid/Grid";
import { useStyles } from "./VerticalCard.style";
import ReactPlayer from "react-player";
import Link from "../../../../common/Link/Link";
import { bool, object, oneOf, shape, string } from "prop-types";
import Jumbotron from "../../../../common/Jumbotron/Jumbotron";

const ALLOWED_LAYOUTS = [
  "layout_1",
  "layout_2",
  "layout_3",
  "layout_4",
  "layout_5",
];

const VerticalCard = ({ contents, variant, classes, options }) => {
  if (variant === "mobile_layout_4") {
    variant = "layout_3";
  }

  const {
    name: header,
    section_for_front_end: section,
    media_content: media,
    url_content: contentUrl,
  } = contents || {};
  const defaultClasses = useStyles();
  const mediaItem = media?.length > 0 && media[0];
  const layoutClasses = {
    container: {
      root: clsx(
        defaultClasses[`container_${variant}`],
        classes?.container?.root
      ),
      ...classes?.container,
    },
    header_container: {
      root: options?.truncatedHeader
        ? defaultClasses[`header_container_truncated_${variant}`]
        : defaultClasses[`header_container_${variant}`],
      ...classes?.header_container,
    },
    media_container: {
      root: defaultClasses[`media_container_${variant}`],
      ...classes?.media_container,
    },
    header: clsx(defaultClasses[`header_${variant}`], classes?.header),
    image: clsx(defaultClasses[`image_${variant}`], classes?.image),
    image_link: clsx(
      defaultClasses[`image_link_${variant}`],
      classes?.image_link
    ),
    tag: defaultClasses[`tag_${variant}`],
  };

  if (!ALLOWED_LAYOUTS.includes(variant)) {
    return <></>;
  }

  if (variant === "layout_2") {
    return (
      <Jumbotron
        {...(mediaItem?.isVideo
          ? { videoUrl: mediaItem?.contentUrl }
          : { backgroundImageUrl: mediaItem?.contentUrl })}
        variant={mediaItem?.isVideo ? "video" : "image"}
        contents={
          <Grid container classes={{ root: defaultClasses.tag_container }}>
            <Grid item classes={{ root: layoutClasses?.tag }}>
              <Link
                color="white"
                level="paragraph_1"
                children={section?.name}
                url={section?.path}
              />
            </Grid>
            <Grid xs={12} item>
              <Link
                url={contentUrl}
                level="h1_header_1_mobile"
                className={layoutClasses?.header}
                children={header}
              />
            </Grid>
          </Grid>
        }
        classes={{
          container: { root: defaultClasses.jumbotron },
        }}
        options={{
          contentsType: "contained",
          redirectTo: contentUrl,
        }}
      />
    );
  }

  return (
    <Grid container classes={layoutClasses?.container}>
      <Grid item classes={layoutClasses?.media_container}>
        {mediaItem?.isVideo ? (
          <ReactPlayer
            url={mediaItem?.contentUrl}
            width="100%"
            height={variant === "layout_4" ? "95%" : "100%"}
            controls={true}
            {...options?.videoPlayer}
          />
        ) : (
          <>
            {["layout_3", "layout_4"].includes(variant) ? (
              <div
                style={{
                  backgroundImage: `url(${mediaItem?.contentUrl})`,
                }}
                className={layoutClasses?.image}
              />
            ) : (
              <a href={contentUrl} className={layoutClasses?.image_link}>
                <img
                  src={mediaItem?.contentUrl}
                  alt={mediaItem?.alt_text}
                  className={layoutClasses?.image}
                />
              </a>
            )}
          </>
        )}
        {variant === "layout_4" && section?.name && (
          <div className={layoutClasses?.tag}>
            <Link
              color="white"
              level="paragraph_1"
              children={section?.name}
              url={section?.path}
            />
          </div>
        )}
      </Grid>

      <Grid item classes={layoutClasses?.header_container}>
        <Link
          url={contentUrl}
          className={layoutClasses?.header}
          children={header}
        />
      </Grid>
    </Grid>
  );
};

VerticalCard.propTypes = {
  contents: shape({
    header: string,
    media: object,
    contentUrl: string,
    section_for_front_end: shape({
      name: string,
      path: string,
    }),
  }),
  variant: oneOf(ALLOWED_LAYOUTS),
  options: shape({
    videoPlayer: object,
    truncated: bool,
  }),
  classes: shape({
    header_container: string,
    media_container: string,
    image: string,
    image_link: string,
  }),
};

VerticalCard.defaultProps = {
  variant: "layout_1",
  options: {
    truncatedHeader: false,
  },
};

export default VerticalCard;
