import React, { Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Card from "../../../../../../common/Card/Card";
import HorizontalLayout from "../../../../../../common/Card/Layouts/Horizontal/HorizontalLayout";
import VerticalLayout from "../../../../../../common/Card/Layouts/Vertical/VerticalLayout";
import labels from "../../../../../../config/labels";
import CardActions from "../../../../Shared/CardActions/CardActions";
import { useStyles } from "./OneCard.style";
import ContentInfo from "../../../../Shared/ContentInfo/ContentInfo";
import ContentHeader from "../../../../Shared/ContentHeader/ContentHeader";
import { screenWidthIsLowerThan } from "../../../../../../hooks/useMediaQuery";
import {
  EVENTS_BLOCK,
  VIDEO_PLAYER_OPTIONS,
} from "../../../../../../utils/constants/homepageConstants";
import {
  isBlock,
  specialCaseCard,
} from "../../../../../../utils/helpers/homepageHelper";
import { useTown } from "../../../../../../context/TownContext";
import { parseEventMedia } from "../../../../../../utils/helpers/mediaHelper";

const OneCard = ({ contents, blockClass, block }) => {
  const classes = useStyles();
  const { town, sponsors } = useTown();

  return contents.map((content, index) => {
    const { eventMedia, eventUrl } = parseEventMedia({
      event: content,
      town: town,
    });

    return (
      <Fragment key={"single-card-" + index}>
        <Card
          withShadow={false}
          classes={{
            root: clsx(classes.content_card),
          }}
          children={
            screenWidthIsLowerThan(768) ? (
              <VerticalLayout
                title={blockClass.title}
                media={
                  isBlock({ blockClass: blockClass, type: EVENTS_BLOCK })
                    ? eventMedia
                    : content.media_content[0]
                }
                information={
                  <ContentInfo
                    content={content}
                    blockClass={blockClass}
                    asContentHeader={true}
                  />
                }
                options={{
                  caption: { visible: false },
                  credits: { visible: false },
                  media: {
                    clickable: true,
                    townContentUrl: content.url_content,
                    opacityOnFocus: true,
                  },
                  videoPlayer: VIDEO_PLAYER_OPTIONS,
                }}
                customClasses={{
                  image: classes.image,
                  media_container: classes.media_container,
                }}
              />
            ) : (
              <HorizontalLayout
                sponsors={sponsors}
                block={block}
                isOneCard={true}
                header={
                    <ContentHeader
                      content={content}
                      blockClass={blockClass}
                      forSingleCard
                    />
                }
                media={
                  isBlock({ blockClass: blockClass, type: EVENTS_BLOCK })
                    ? eventMedia
                    : content.media_content[0]
                }
                information={
                  <ContentInfo
                    content={content}
                    blockClass={blockClass}
                    forSingleCard
                    withTruncatedDescription={false}
                  />
                }
                actions={
                  <CardActions
                    contentUrl={
                      isBlock({ blockClass: blockClass, type: EVENTS_BLOCK })
                        ? eventUrl
                        : content.url_content
                    }
                    actionLabel={labels.READ_MORE}
                    withSocialMediaIcons={false}
                    withLink={false}
                  />
                }
                options={{
                  actions: {
                    placement: "below information",
                  },
                  media: {
                    clickable: true,
                    townContentUrl: isBlock({
                      blockClass: blockClass,
                      type: EVENTS_BLOCK,
                    })
                      ? eventUrl
                      : content.url_content,
                    opacityOnFocus: true,
                  },
                  videoPlayer: VIDEO_PLAYER_OPTIONS,
                }}
                customClasses={{
                  root: classes.root,
                  image: clsx(
                    classes.image,
                    specialCaseCard(blockClass) && classes.no_rounded_border
                  ),
                  information_container:
                    classes.information_container_single_card,
                  media_and_information_container: classes.media_and_information_container
                }}
              />
            )
          }
        />
      </Fragment>
    );
  });
};

OneCard.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.object).isRequired,
  blockClass: PropTypes.string.isRequired,
  block: PropTypes.object,
};

export default OneCard;
