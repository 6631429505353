import React from "react";
import PropTypes from "prop-types";

const RssFeed = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.93594 35.5555C7.66199 35.5555 9.87189 33.3456 9.87189 30.6196C9.87189 27.8936 7.66199 25.6837 4.93594 25.6837C2.2099 25.6837 0 27.8936 0 30.6196C0 33.3456 2.2099 35.5555 4.93594 35.5555Z"
        fill={fillColor}
      />
      <path
        d="M0.0836182 18.9072C9.20257 18.9072 16.6483 26.2693 16.6483 35.5555H23.4248C23.4248 22.5882 12.9673 12.1307 0.0836182 12.1307V18.9072Z"
        fill={fillColor}
      />
      <path
        d="M0.0836182 6.77647C15.8117 6.77647 28.6954 19.6601 28.6954 35.5555H35.4718C35.4718 15.9791 19.5764 0 0.0836182 0V6.77647Z"
        fill={fillColor}
      />
    </svg>
  );
};

RssFeed.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

RssFeed.defaultProps = {
  fillColor: "white",
};

export default RssFeed;
