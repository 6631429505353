import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    card: {
      marginBottom: theme.spacing(2),
    },
    no_shadow: {
      boxShadow: "none !important",
    },
    no_rounded: {
      borderRadius: "0 !important",
    },
  };
});

export { useStyles };
