import React from "react";
import { useTown } from "../../context/TownContext";
import { useStyles } from "./Town.style";
import LeftBlocks from "../../components/Town/LeftBlocks/LeftBlocks";
import RightBlocks from "../../components/RightBlocks/RightBlocks";
import Ad from "../../components/Ad/Ad";
import {
  screenWidthIsHigherThan,
  screenWidthIsLowerThan,
} from "../../hooks/useMediaQuery";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import withConfig from "../../utils/withConfig";
import values from "../../common/Theme/themes/breakpoints/values";
import useTopStories from "../../hooks/useTopStories";
import Layouts from "../../components/TopLocalNews/Layouts/Layouts";
const LEADERBOARD_AD_SIZE = [728, 90];
const LEADERBOARD_MOBILE_AD_SIZE = [320, 100];

const Town = () => {
  const classes = useStyles();
  const { town, videoAd, adTargets, topStoriesTemplate } = useTown();

  const { localNews } = useTopStories({
    town,
    topStoriesTemplate,
  });

  const shouldShowHeader = screenWidthIsLowerThan(values.xs);

  return (
    <>
      <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={withConfig("RECAPTCHA_KEY")}
        scriptProps={{ async: true, defer: true, appendTo: "body" }}
      >
        <div className={classes.main_container}>
          {screenWidthIsHigherThan(values.sm) ? (
            <div className={classes.top_leaderboard}>
              <Ad
                ad_unit={"tap_leaderboard_001"}
                size={LEADERBOARD_AD_SIZE}
                targets={adTargets}
              />
            </div>
          ) : (
            <div className={classes.top_leaderboard_mobile}>
              <Ad
                ad_unit={"leaderboard_mobile"}
                size={LEADERBOARD_MOBILE_AD_SIZE}
                targets={adTargets}
              />
            </div>
          )}
          <Layouts
            adTargets={adTargets}
            videoAd={videoAd}
            localNews={localNews}
            showHeader={shouldShowHeader}
          />
          {screenWidthIsHigherThan(values.sm) ? (
            <div className={classes.secondary_leaderboard}>
              <Ad
                ad_unit={"tap_leaderboard_002"}
                size={LEADERBOARD_AD_SIZE}
                targets={adTargets}
              />
            </div>
          ) : (
            <div className={classes.secondary_leaderboard_mobile}>
              <Ad
                ad_unit={"leaderboard_mobile_1"}
                size={LEADERBOARD_MOBILE_AD_SIZE}
                targets={adTargets}
              />
            </div>
          )}

          <section className={classes.display_blocks_section}>
            <div className={classes.display_blocks_container}>
              <div className={classes.left_block}>
                <LeftBlocks adTargets={adTargets} town={town} />
              </div>
              <div className={classes.right_block}>
                <RightBlocks adTargets={adTargets} town={town} section={null} />
              </div>
            </div>
          </section>
        </div>
      </GoogleReCaptchaProvider>
    </>
  );
};

export default Town;
