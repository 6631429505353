import React from "react";
export const Rain = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="32"
    viewBox="0 0 35 32"
    fill="none"
  >
    <path
      d="M30.932 10.0574C30.932 9.91585 30.932 9.77428 30.932 9.56193C30.932 6.65985 28.5961 4.32403 25.6941 4.32403C24.7031 4.32403 23.7829 4.60716 22.9335 5.10264C22.4381 2.12977 19.6775 -0.135276 16.4215 0.0062894C13.3779 0.147854 10.9005 2.4129 10.5466 5.2442C9.98034 5.03186 9.41408 4.96107 8.84782 4.96107C5.94573 4.96107 3.60991 7.2969 3.60991 10.199C3.60991 10.2698 3.60991 10.3405 3.60991 10.4113C2.12347 10.836 0 11.6854 0 14.6583C0 16.9233 1.84034 18.8345 4.38851 18.9052L5.16713 17.7019C5.09634 17.7019 4.95478 17.7019 4.884 17.7019H4.67165C2.76052 17.7019 1.2033 16.2863 1.2033 14.5875C1.2033 12.5348 2.33582 11.8978 4.38851 11.4023L4.884 11.2607V10.4821C4.884 10.3405 4.884 10.2698 4.884 10.1282C4.884 7.86315 6.72434 6.09359 8.9186 6.09359C9.62642 6.09359 10.2635 6.30594 10.9005 6.65985L11.8207 7.15533V6.09359C11.7499 3.47464 13.8734 1.28038 16.6339 1.13881H16.917C19.6068 1.13881 21.801 3.19151 21.9426 5.73968C21.9426 5.81046 21.9426 5.95203 21.9426 6.16438L21.8718 7.72159L23.0043 6.58907C23.7829 5.81046 24.7739 5.38577 25.9064 5.38577C28.1714 5.38577 29.941 7.22611 29.941 9.42037C29.941 9.7035 29.941 9.98663 29.8702 10.199L29.7287 10.836L30.3657 10.9776C31.9229 11.2607 33.1262 12.464 33.197 13.9505C33.2678 15.4369 32.2768 17.1357 30.7196 17.6311L31.1443 18.7637C33.1262 18.1266 34.5419 16.0739 34.4711 13.8797C34.1172 12.1809 32.7723 10.6237 30.932 10.0574Z"
      fill="white"
    />
    <path
      d="M8.84787 28.3901C8.56474 28.3901 8.28161 28.1777 8.28161 27.9654V13.455C8.28161 13.2426 8.49396 13.0303 8.84787 13.0303C9.131 13.0303 9.41413 13.2426 9.41413 13.455V27.9654C9.41413 28.1777 9.131 28.3901 8.84787 28.3901Z"
      fill="white"
    />
    <path
      d="M12.9533 30.5844C12.6701 30.5844 12.387 30.372 12.387 30.1597V15.6493C12.387 15.3661 12.5994 15.2246 12.9533 15.2246C13.2364 15.2246 13.5195 15.4369 13.5195 15.6493V30.1597C13.5195 30.372 13.3072 30.5844 12.9533 30.5844Z"
      fill="white"
    />
    <path
      d="M17.1293 27.3992C16.8462 27.3992 16.5631 27.1868 16.5631 26.9745V12.4641C16.5631 12.2517 16.7754 12.0394 17.1293 12.0394C17.4124 12.0394 17.6956 12.2517 17.6956 12.4641V26.9745C17.6248 27.2576 17.4124 27.3992 17.1293 27.3992Z"
      fill="white"
    />
    <path
      d="M21.2347 32C20.9516 32 20.6685 31.7877 20.6685 31.5753V17.0649C20.6685 16.8526 20.8808 16.6402 21.2347 16.6402C21.5179 16.6402 21.801 16.8526 21.801 17.0649V31.5753C21.801 31.7877 21.5179 32 21.2347 32Z"
      fill="white"
    />
    <path
      d="M25.3403 27.3992C25.0572 27.3992 24.7741 27.1868 24.7741 26.9745V12.4641C24.7741 12.2517 24.9864 12.0394 25.3403 12.0394C25.6235 12.0394 25.9066 12.2517 25.9066 12.4641V26.9745C25.9066 27.2576 25.6235 27.3992 25.3403 27.3992Z"
      fill="white"
    />
  </svg>
);
