import React, { useState } from "react";
import PropTypes from "prop-types";

const CandidateStatements = ({
  className,
  onHoverColor,
  offHoverColor,
  inactiveColor,
  disabled,
  fillColor,
}) => {
  const [iconColor, setIconColor] = useState(
    fillColor ? fillColor : !disabled ? offHoverColor : inactiveColor
  );
  return (
    <svg
      className={className}
      width="76"
      height="75"
      viewBox="0 0 76 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={
        !disabled
          ? () => setIconColor(onHoverColor)
          : () => setIconColor(inactiveColor)
      }
      onMouseLeave={
        !disabled
          ? () => setIconColor(offHoverColor)
          : () => setIconColor(inactiveColor)
      }
    >
      <circle cx="37.5" cy="37.5" r="32" stroke={iconColor} strokeWidth="3" />
      <path
        d="M74.5 37.5C74.5 57.3636 58.1772 73.5 38 73.5C17.8228 73.5 1.5 57.3636 1.5 37.5C1.5 17.6364 17.8228 1.5 38 1.5C58.1772 1.5 74.5 17.6364 74.5 37.5Z"
        stroke={iconColor}
        strokeWidth="3"
      />
      <path
        d="M54.9475 51.2634C54.9475 54.6421 43.6632 54.1595 37.8009 54.1595C31.9386 54.1595 20.6543 54.6421 20.6543 51.2634C20.6543 44.3324 29.6358 32.9215 37.8009 32.9215C45.9659 32.9215 54.9475 44.3324 54.9475 51.2634Z"
        fill={iconColor}
      />
      <rect
        x="20.6543"
        y="56.2409"
        width="34.2932"
        height="3.42932"
        rx="1"
        fill={iconColor}
      />
      <circle cx="43.2878" cy="42.5236" r="3.42932" fill="white" />
      <circle
        cx="43.2878"
        cy="42.5236"
        r="3.42932"
        fill="url(#paint0_linear_2769:1082)"
      />
      <path
        d="M41.6484 43.0703L42.6564 43.8655L45.0778 40.8835"
        stroke={iconColor}
        strokeLinecap="round"
      />
      <circle cx="38" cy="22" r="8" fill={iconColor} />
      <defs>
        <linearGradient
          id="paint0_linear_2769:1082"
          x1="43.2878"
          y1="39.0943"
          x2="43.2878"
          y2="45.9529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

CandidateStatements.propTypes = {
  className: PropTypes.string,
  onHoverColor: PropTypes.string,
  offHoverColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  disabled: PropTypes.bool,
};

CandidateStatements.defaultProps = {
  disabled: false,
  onHoverColor: "#f98c10",
  offHoverColor: "#7fc035",
  inactiveColor: "#e3e3e3",
};

export default CandidateStatements;
