/*eslint sort-keys: "error"*/

const userLabels = {
  RECENT_CONTRIBUTIONS: "RECENT CONTRIBUTIONS",
  NO_RECENT_CONTRIBUTIONS: "No recent contributions were found",
  NO_RECENT: "No recent ",
  WERE_FOUND: " were found",
  RESET_YOUR_PASSWORD: "Reset your password",
  NEW_PASSWORD: "New Password",
  PASSWORD_CONFIRMATION: "Password Confirmation",
  RESET_PASSWORD: "Reset Password",
  PASSWORD_TOO_SHORT_ERROR:
    "New password must contain a minimum of 8 characters",
  PASSWORD_CONFIRMATION_ERROR: "Password confirmation does not match password",
  YOUR_REQUEST_WAS_NOT_PROCESSED:
    "Your request could not be processed. Please try again later.",
};
export default userLabels;
