import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./NearbyTowns.style";
import HorizontalDivider from "../../../common/HorizontalDivider/HorizontalDivider";
import withConfig from "../../../utils/withConfig";

const NearbyTowns = ({ towns, containerClassName }) => {
  const classes = useStyles();

  return (
    <div {...(containerClassName && { className: containerClassName })}>
      {towns.map((town, id) => (
        <Fragment key={`nearby-towns-${id}`}>
          <a
            href={`${withConfig("HOME")}towns/${town.slug}`}
            className={classes.nearby_towns}
            target="_top"
          >
            {town.name}
          </a>
          <HorizontalDivider className={classes.nearby_towns_divider} />
        </Fragment>
      ))}
    </div>
  );
};

NearbyTowns.propTypes = {
  towns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  containerClassName: PropTypes.string,
};

export default NearbyTowns;
