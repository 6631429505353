import React from "react"
import PropTypes from "prop-types"
import MuiDivider from "@material-ui/core/Divider"

const Divider = ({ className, variant, orientation = 'horizontal', flexItem }) => {
  return <MuiDivider className={className} variant={variant} orientation={orientation} flexItem={flexItem} />
}

Divider.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["fullWidth", "inset", "middle"]),
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
}

export default Divider