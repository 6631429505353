import React from "react";

export const MostlyCloudyTStorms = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="32"
      viewBox="0 0 40 32"
      fill="none"
      className={className}
    >
      <path
        d="M21.9528 8.18875C21.7786 8.18875 21.6624 8.13067 21.5463 8.01452L18.3521 4.35572C18.1779 4.12341 18.1779 3.83303 18.4102 3.6588C18.5263 3.54265 18.6425 3.48457 18.7586 3.54265C18.8748 3.54265 18.9909 3.60073 19.1071 3.71688L22.3013 7.37568C22.4755 7.60799 22.4755 7.89837 22.2432 8.0726C22.1851 8.13067 22.069 8.18875 21.9528 8.18875Z"
        fill="white"
      />
      <path
        d="M27.1216 5.92378C26.8312 5.92378 26.5989 5.69147 26.5989 5.40109L26.5408 0.522686C26.5408 0.232305 26.7731 0 27.0635 0C27.3539 0 27.5862 0.232305 27.5862 0.522686L27.6443 5.40109C27.6443 5.51724 27.5862 5.69147 27.4701 5.74955C27.3539 5.8657 27.2377 5.92378 27.1216 5.92378Z"
        fill="white"
      />
      <path
        d="M37.9819 19.5136C37.9238 19.5136 37.8076 19.5136 37.7495 19.4555L33.4519 17.1906C33.3358 17.1325 33.2196 17.0163 33.2196 16.9002C33.1615 16.784 33.2196 16.6098 33.2777 16.4936C33.3358 16.3775 33.4519 16.2613 33.5681 16.2613C33.6842 16.2033 33.8584 16.2613 33.9746 16.3194L38.2722 18.5844C38.3884 18.6425 38.5045 18.7586 38.5045 18.8748C38.5626 18.9909 38.5045 19.1652 38.4465 19.2813C38.3303 19.4555 38.1561 19.5136 37.9819 19.5136Z"
        fill="white"
      />
      <path
        d="M34.3811 11.9637C34.265 11.9637 34.1488 11.9056 34.0907 11.8476C33.9746 11.7895 33.9165 11.6152 33.9165 11.4991C33.9165 11.3829 33.9165 11.2087 34.0327 11.1506C34.0907 11.0345 34.265 10.9764 34.3811 10.9764L39.2015 10.3956C39.3176 10.3956 39.4918 10.3956 39.608 10.5118C39.7241 10.5699 39.7822 10.7441 39.7822 10.8603C39.8403 11.1506 39.608 11.3829 39.3176 11.441L34.4973 12.0218C34.4392 11.9637 34.4392 11.9637 34.3811 11.9637Z"
        fill="white"
      />
      <path
        d="M30.8966 24.6824C30.6642 24.6824 30.49 24.5662 30.4319 24.3339L28.8058 19.7459C28.7477 19.6298 28.7477 19.4555 28.8058 19.3394C28.8639 19.2232 28.98 19.1071 29.0962 19.1071C29.2123 19.049 29.3866 19.049 29.5027 19.1071C29.6189 19.1652 29.735 19.2813 29.735 19.3975L31.3612 23.9855C31.4192 24.1016 31.4192 24.2759 31.3612 24.392C31.3031 24.5082 31.1869 24.6243 31.0708 24.6824C31.0127 24.6243 30.9546 24.6824 30.8966 24.6824Z"
        fill="white"
      />
      <path
        d="M31.7677 7.78221C31.6515 7.78221 31.5354 7.72414 31.4773 7.66606C31.245 7.49183 31.1869 7.14338 31.3612 6.96915L34.3811 3.13612C34.4392 3.01996 34.6134 2.96189 34.7296 2.96189C34.8457 2.96189 35.02 2.96189 35.078 3.07804C35.1942 3.13612 35.2523 3.31034 35.2523 3.4265C35.2523 3.54265 35.2523 3.71688 35.1361 3.83303L32.1161 7.66606C32.0581 7.72414 31.8838 7.78221 31.7677 7.78221Z"
        fill="white"
      />
      <path
        d="M27.0635 6.62069C23.8113 6.62069 21.0817 9.17604 20.9074 12.4283C21.0236 12.4283 21.1397 12.4283 21.1978 12.4283C21.2559 12.4283 21.3721 12.4283 21.4301 12.4283C21.6044 12.4283 21.7786 12.4864 21.9528 12.4864C22.127 9.81488 24.3339 7.72414 27.0635 7.72414C29.9093 7.72414 32.1742 9.98911 32.1742 12.8348C32.1742 15.6806 29.9093 17.9456 27.0635 17.9456H27.0054C27.3539 18.2359 27.6443 18.5844 27.8766 18.9909C30.9546 18.5844 33.2777 16.029 33.2777 12.8348C33.2777 9.40835 30.49 6.62069 27.0635 6.62069Z"
        fill="white"
      />
      <path
        d="M25.3793 17.1906C25.3793 17.0744 25.3793 16.9583 25.3793 16.784C25.3793 14.4029 23.4628 12.4864 21.0817 12.4864C20.2686 12.4864 19.5136 12.7187 18.8167 13.1252C18.4102 10.686 16.1452 8.82759 13.4737 8.94374C10.9764 9.05989 8.94374 10.9183 8.65336 13.2414C8.18875 13.0672 7.72414 13.0091 7.25953 13.0091C4.8784 13.0091 2.96189 14.9256 2.96189 17.3067C2.96189 17.3648 2.96189 17.4229 2.96189 17.4809C1.74229 17.8294 0 18.5263 0 21.0236C0 22.882 1.50998 24.4501 3.60073 24.5082L4.23956 23.5209C4.18149 23.5209 4.06534 23.5209 4.00726 23.5209H3.83303C2.26497 23.5209 0.987296 22.3593 0.987296 20.9655C0.987296 19.2813 1.91652 18.7586 3.60073 18.3521L4.00726 18.2359V17.5971C4.00726 17.4809 4.00726 17.4229 4.00726 17.3067C4.00726 15.4483 5.51724 13.9964 7.3176 13.9964C7.84029 13.9964 8.42105 14.1706 8.94374 14.461L9.69873 14.8675V13.9964C9.64065 11.8476 11.3829 10.0472 13.6479 9.93104H13.8802C16.0871 9.93104 17.8875 11.6152 18.0036 13.706C18.0036 13.7641 18.0036 13.8802 18.0036 14.0544L17.9456 15.3321L18.8748 14.4029C19.5136 13.7641 20.3267 13.4156 21.2559 13.4156C23.1143 13.4156 24.5662 14.9256 24.5662 16.726C24.5662 16.9583 24.5662 17.1906 24.5082 17.3648L24.392 17.8875L24.9147 18.0036C26.1924 18.2359 27.1797 19.2232 27.2377 20.4428C27.2958 21.6624 26.4828 23.0563 25.2051 23.4628L25.5535 24.392C27.1797 23.8693 28.3412 22.1851 28.2831 20.3848C27.9927 18.9329 26.8893 17.5971 25.3793 17.1906Z"
        fill="white"
      />
      <path
        d="M21.0236 24.7985C22.0109 23.4628 21.9528 21.1397 22.0109 21.8367C22.127 23.8113 22.882 24.7985 22.882 24.7985C22.9401 24.9147 22.9982 25.0309 22.9982 25.2051C22.9982 25.7278 22.5336 26.1343 21.9528 26.1343C21.372 26.1343 20.9074 25.7278 20.9074 25.2051C20.9074 25.0889 20.9655 24.9147 21.0236 24.7985Z"
        fill="white"
      />
      <path
        d="M17.5971 19.8621C18.4102 18.7586 18.3521 16.8421 18.4102 17.4229C18.5263 19.049 19.1652 19.8621 19.1652 19.8621C19.2232 19.9782 19.2232 20.0944 19.2232 20.2105C19.2232 20.6171 18.8167 20.9655 18.3521 20.9655C17.8875 20.9655 17.4809 20.6171 17.4809 20.2105C17.4809 20.0944 17.539 19.9782 17.5971 19.8621Z"
        fill="white"
      />
      <path
        d="M6.79492 24.7985C5.80762 23.4628 5.8657 21.1397 5.80762 21.8367C5.69147 23.8113 4.93648 24.7985 4.93648 24.7985C4.8784 24.9147 4.82033 25.0309 4.82033 25.2051C4.82033 25.7278 5.28494 26.1343 5.8657 26.1343C6.44646 26.1343 6.91107 25.7278 6.91107 25.2051C6.91107 25.0889 6.85299 24.9147 6.79492 24.7985Z"
        fill="white"
      />
      <path
        d="M10.2214 19.8621C9.40835 18.7586 9.46642 16.8421 9.40835 17.4229C9.2922 19.049 8.65336 19.8621 8.65336 19.8621C8.59528 19.9782 8.59528 20.0944 8.59528 20.2105C8.59528 20.6171 9.00181 20.9655 9.46642 20.9655C9.93103 20.9655 10.3376 20.6171 10.3376 20.2105C10.3376 20.0944 10.2795 19.9782 10.2214 19.8621Z"
        fill="white"
      />
      <path
        d="M9.81488 32L9.98911 31.7096L15.6225 22.9982H10.1633L14.8094 15.5644H15.274L12.1379 22.069H18.0036L9.81488 32Z"
        fill="white"
      />
    </svg>
  );
};
