import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { down: below, values: values },
    mastheadHeights,
  } = theme;
  return {
    main_container: {
      paddingTop: spacing(4),
      display: "flex",
      flexDirection: "column",
      rowGap: spacing(3),
      alignItems: "center",
      background: "#F7F7F7",
      [below(values.md)]: {
        paddingTop: spacing(3),
      },
    },
    display_blocks_container: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      maxWidth: spacing(154),
      [below(values.md)]: {
        flexWrap: "wrap",
      },
    },
    display_blocks_section: {
      display: "flex",
      justifyContent: "center",
      maxWidth: "100vw",
    },
    left_block: {
      width: "70%",
      [below(values.md)]: {
        width: "100%",
      },
    },
    right_block: {
      width: spacing(45),
      [below(values.md)]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: spacing(2),
      },
      top_leaderboard_mobile: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: `1px solid ${palette.grayVariation.grayForBorder}`,
        marginBottom: spacing(2),
      },
      secondary_leaderboard: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: spacing(3),
      },
      secondary_leaderboard_mobile: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .ads_container": {
          marginBottom: 0,
          "& #leaderboard_mobile_1": {
            marginTop: 0,
          },
        },
      },
    },
  };
});

export { useStyles };
