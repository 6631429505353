import React from "react";
import { any, bool, func, node, object, string } from "prop-types";
import ListItem from "@material-ui/core/ListItem";

const MenuListItem = ({
  className,
  primary,
  children,
  onClick,
  innerRef,
  onMouseEnter,
  title,
  classes,
  button,
  divider,
  disableGutters,
}) => {
  return (
    <div ref={innerRef} title={title}>
      <ListItem
        button={button}
        className={className}
        primary={primary}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        classes={classes}
        divider={divider}
        disableGutters={disableGutters}
      >
        {children}
      </ListItem>
    </div>
  );
};

MenuListItem.propTypes = {
  className: string,
  primary: string,
  children: node,
  onClick: func,
  innerRef: any,
  onMouseEnter: func,
  title: string,
  classes: object,
  disableGutters: bool,
};

MenuListItem.defaultProps = {
  divider: true,
  button: true,
  disableGutters: true,
};

export default MenuListItem;
