import React, { useMemo } from "react";
import Grid from "../../../../common/Grid/Grid";
import HorizontalCard from "../HorizontalCard/HorizontalCard";
import { useStyles } from "./SecondaryStoriesContainer.style";
import Header from "./Header/Header";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";

const SecondaryStoriesContainer = ({ stories, variant, classes, options }) => {
  const defaultClasses = useStyles();
  const isMediumScreen = screenWidthIsLowerThan(1025);
  const showHeader = useMemo(() => {
    if (variant === "layout_5" && isMediumScreen) {
      return false;
    }
    return true;
  }, [isMediumScreen]);
  const layoutClasses = {
    container: {
      root: defaultClasses[`container_${variant}`],
      ...classes?.container,
    },
  };

  return (
    <Grid container classes={layoutClasses?.container}>
      {showHeader && <Header variant={variant} classes={classes} />}

      {stories?.map((story, index) => (
        <HorizontalCard
          key={`story-${index}`}
          contents={story}
          variant={variant}
          options={options}
        />
      ))}
    </Grid>
  );
};

export default SecondaryStoriesContainer;
