import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Menu from "../../../common/Menu/Menu";
import MenuItem from "../../../common/Menu/MenuItem";
import { useStyles } from "./SubmenuForDesktop.style";

const SubmenuForDesktop = ({
  mainMenuRef,
  onClose,
  popoverClassName,
  submenuBlocks,
  onItemClick,
  menuItemClassName,
  anchorOrigin,
  forHamburgerMenu,
}) => {
  const classes = useStyles();

  const paperClasses = (hamburgerMenuClasses) => {
    if (hamburgerMenuClasses) {
      return clsx(
        classes.submenu_popover_paper,
        popoverClassName && popoverClassName
      );
    }

    return clsx(
      classes.submenu_popover_paper,
      popoverClassName && popoverClassName
    );
  };

  return (
    <Menu
      anchorEl={mainMenuRef}
      open={Boolean(mainMenuRef)}
      onClose={onClose}
      MenuListProps={{ onMouseLeave: onClose }}
      anchorOrigin={anchorOrigin}
      PaperProps={{
        className: paperClasses(forHamburgerMenu),
      }}
      PopoverClasses={{ root: classes.popover_root }}
    >
      {submenuBlocks?.map((submenuItems, index) => (
        <div className={classes.submenu_block} key={index}>
          {submenuItems.map((item, subIndex) => {
            return (
              <MenuItem
                title={item.name}
                onClick={() => onItemClick(item)}
                classes={{
                  root: menuItemClassName,
                }}
                key={subIndex}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </div>
      ))}
    </Menu>
  );
};

SubmenuForDesktop.propTypes = {
  mainMenuRef: PropTypes.any,
  onClose: PropTypes.func,
  popoverClassName: PropTypes.string,
  submenuBlocks: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        path: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    )
  ),
  onItemClick: PropTypes.func,
  menuItemClassName: PropTypes.string,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  forHamburgerMenu: PropTypes.bool,
};

SubmenuForDesktop.defaultProps = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  forHamburgerMenu: true,
};

export default SubmenuForDesktop;
