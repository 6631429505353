import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./Item.style";
import Typography from "../Typography/Typography";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { screenWidthIsLowerThan } from "../../hooks/useMediaQuery";
import labels from "../../config/labels";
import Download from "../Icons/Download";
import Button from "../Button/Button";

const WIDTH_LIMIT_FOR_DOCUMENTS = 660;
const WHITE = "#000000";
const BLACK = "#FFFFFF";

const Item = ({
  contentUrl,
  caption,
  credits,
  alt_text,
  title,
  isVideo,
  onImageClick,
  className,
  videoPlayerProps,
  documentUrl,
}) => {
  const classes = useStyles();

  const getVideoPlayerHeight = () => {
    if (videoPlayerProps.height) {
      return videoPlayerProps.height;
    } else {
      return screenWidthIsLowerThan(961) ? "210px" : "320px";
    }
  };

  const handleShowPdf = () => {
    window.open(documentUrl, "_blank");
  };

  const DocumentDownload = ({ fullSize }) => {
    return (
      <div
        className={clsx(
          fullSize ? classes.full_size : classes.show_pdf_container
        )}
      >
        <Button
          level={"simple_button"}
          onClick={handleShowPdf}
          color={WHITE}
          className={clsx(
            fullSize ? classes.full_pdf_button : classes.show_pdf_button
          )}
          variant={"contained"}
        >
          <Typography
            url={documentUrl}
            className={classes.download}
            level={"t4_text_4"}
            color={BLACK}
          >
            {labels.SHOW_PDF}
          </Typography>
          <div className={classes.download_icon}>
            <Download width={24} height={18} fillColor={BLACK} />
          </div>
        </Button>
      </div>
    );
  };

  return (
    <>
      <div
        className={clsx(
          classes.image_container,
          className && className,
          documentUrl ? classes.image_container_with_pdf : ""
        )}
      >
        {isVideo && (
          <VideoPlayer
            url={contentUrl}
            width={videoPlayerProps.width}
            showControls={true}
            height={getVideoPlayerHeight()}
          />
        )}
        {!isVideo && (
          <img
            src={contentUrl}
            title={title}
            alt={alt_text ? alt_text : ""}
            className={clsx(
              classes.image,
              onImageClick && classes.pointer_cursor
            )}
            {...(onImageClick && { onClick: onImageClick })}
          />
        )}
        {documentUrl && !screenWidthIsLowerThan(WIDTH_LIMIT_FOR_DOCUMENTS) && (
          <DocumentDownload fullSize={false} />
        )}
      </div>
      {(caption || credits) && (
        <div className={classes.caption_credits}>
          <Typography level="t1_text_1" color="black" bold>
            {caption}
          </Typography>
          <Typography level="t1_text_1" color="gray">
            {credits}
          </Typography>
        </div>
      )}
      {documentUrl && screenWidthIsLowerThan(WIDTH_LIMIT_FOR_DOCUMENTS) && (
        <DocumentDownload fullSize={true} />
      )}
    </>
  );
};

Item.propTypes = {
  contentUrl: PropTypes.string,
  caption: PropTypes.string,
  credits: PropTypes.string,
  alt_text: PropTypes.string,
  title: PropTypes.string,
  isVideo: PropTypes.bool,
  onImageClick: PropTypes.func,
  className: PropTypes.string,
  videoPlayerProps: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  documentUrl: PropTypes.string,
};

Item.defaultProps = {
  videoPlayerProps: {
    height: "320px",
    width: "100%",
  },
};

export default Item;
