import React, { useEffect, useState } from "react";
import Button from "../../../common/Button/Button";
import labels from "../../../config/labels";
import Location from "../../../common/Icons/Location";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import { getTownsAsItems } from "../../../services/townService";
import withConfig from "../../../utils/withConfig";
import theme from "../../../common/Theme/themes/default";
import { useStyles } from "./DialogPicker.style";
import { arrayOf, bool, object } from "prop-types";
import TownPicker from "../TownPicker";
import { useTownTheme } from "../../../context/TownThemeContext";
import ButtonIcon from "../../../common/Button/ButtonIcon";
import { sendMessageToParent } from "../../../utils/helper";
import { urlToUseOnIframe } from "../../../utils/halstonMediaHelper";

const DialogPicker = ({ nearbyTowns, townsWithClusters, iconButton }) => {
  const { town, themeColors } = useTownTheme();
  const classes = useStyles();
  const [openTownPicker, setOpenTownPicker] = useState(false);
  const {
    palette: {
      themeColor: { secondary },
    },
  } = theme;

  const townPickerTitle = () => {
    if (town?.under_license_contract) {
      return town?.options?.town_picker?.title;
    }

    return town?.name
      ? town?.options?.town_picker?.title
      : labels.TAPINTO_YOUR_LOCAL_NEWS;
  };

  useEffect(() => {
    const message = openTownPicker ? "opened" : "closed";

    sendMessageToParent(
      message,
      urlToUseOnIframe(town?.slug, withConfig("HOME_MONGO"))
    );
  }, [openTownPicker]);

  return (
    <>
      {iconButton ? (
        <ButtonIcon
          className={classes.button_icon}
          children={
            <Location
              className={classes.icon}
              fillColor={themeColors?.masthead_colors?.contrast}
            />
          }
          onClick={() => setOpenTownPicker(true)}
          ariaLabel="Town Picker"
        />
      ) : (
        <Button
          level="simple_button"
          color="primary"
          onClick={() => setOpenTownPicker(true)}
          children={
            <>
              {labels.CHOOSE_YOUR_TOWN}
              <Location fillColor={secondary} />
            </>
          }
          classes={{
            root: classes.town_picker_button,
            label: classes.town_picker_button_label,
          }}
          ariaLabel={labels.CHOOSE_YOUR_TOWN}
          dataCy={labels.CHOOSE_YOUR_TOWN}
        />
      )}
      <PopUpDialog
        onClose={() => setOpenTownPicker(false)}
        openState={openTownPicker}
        title={townPickerTitle()}
        content={
          <TownPicker
            townName={town?.name}
            nearbyTowns={nearbyTowns}
            isTownPickerMobile={true}
            townsAsItems={getTownsAsItems(
              townsWithClusters,
              withConfig("HOME")
            )}
            label={town?.options?.town_picker?.placeholder}
            comboBoxClasses={{ popper: classes.town_picker_popper }}
          />
        }
        className={classes.town_picker}
        titleClass={classes.mobile_town_picker_title}
      />
    </>
  );
};

DialogPicker.propTypes = {
  nearbyTowns: arrayOf(object),
  townsWithClusters: arrayOf(object),
  iconButton: bool,
};

DialogPicker.defaultProps = {
  iconButton: false,
};

export default DialogPicker;
