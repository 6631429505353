import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";

export const userSignIn = async (userEmail, password, rememberMe, townSlug) => {
  try {
    const signInUrl =
      townSlug === "halston-media"
        ? withConfig("HALSTON_MEDIA_SIGN_IN")
        : withConfig("SIGN_IN");

    const response = await restClient.post(signInUrl, {
      user: {
        email: userEmail.toLowerCase(),
        password: password,
        remember_me: rememberMe,
      },
      format: "json",
    });
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const sendResetPassword = async (userEmail) => {
  try {
    const response = await restClient.post(withConfig("RESET_PASSWORD_URL"), {
      email: userEmail.toLowerCase(),
      format: "json",
    });
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const findUser = async (userEmail, token) => {
  try {
    const response = await restClient.get(withConfig("FIND_USER_URL"), {
      email: userEmail.toLowerCase(),
      "g-recaptcha-response": token,
      format: "json",
    });
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const resendConfirmationEmail = async (userEmail) => {
  try {
    const response = await restClient.post(
      withConfig("SEND_CONFIRMATION_EMAIL_URL"),
      {
        email: userEmail.toLowerCase(),
        format: "json",
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const upgradeToContributor = async (
  userEmail,
  password,
  passwordConfirmation
) => {
  try {
    const response = await restClient.post(
      withConfig("UPGRADE_TO_CONTRIBUTOR_URL"),
      {
        email: userEmail.toLowerCase(),
        password: password,
        confirm_password: passwordConfirmation,
        format: "json",
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const createAccount = async (
  userEmail,
  password,
  passwordConfirmation,
  subscribedTownIds
) => {
  try {
    const response = await restClient.post(withConfig("CREATE_ACCOUNT_URL"), {
      email: userEmail.toLowerCase(),
      password: password,
      confirm_password: passwordConfirmation,
      subscribed_town_ids: subscribedTownIds,
      format: "json",
    });
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};
