export const snackbarReducer = (state, action) => {
  const newState = { ...state };
  if (action.message !== "" || action.message !== undefined) {
    newState.message = action.message;
    newState.open = action.open;
    newState.variant = action.variant;

    if (newState.icon && action.icon) {
      newState.icon = action.icon;
    }

    if (newState.autoHideDuration && action.autoHideDuration) {
      newState.autoHideDuration = action.autoHideDuration;
    }
  }
  return newState;
};

export const snackbarInitialState = {
  message: "",
  open: false,
  variant: "center_in_screen",
  icon: undefined,
};
