const draftLabels = {
  DRAFT_DELETED: "Draft was succesfully deleted.",
  ERROR_DELETING_DRAFT:
    "There was an error deleting your draft. Please try again later.",
  NO_DRAFTS_LEFT: "You have no drafts",
  DELETE_DRAFT_CONFIRMATION:
    "Are you sure you want to delete this DRAFT_TYPE draft? This action cannot be undone!",
};

export default draftLabels;
