import React from "react";
import PropTypes from "prop-types";

const VisibilityOffIcon = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="149"
      height="144"
      viewBox="0 0 149 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6669_19300)">
        <path
          d="M0 74.7578C0 78.9495 23.3096 127.182 74.5 127.182C125.69 127.182 149 78.9495 149 74.7578H0Z"
          fill={fillColor}
        />
        <path
          d="M0 74.7583C0 70.5667 23.3096 22.334 74.5 22.334C125.69 22.334 149 70.5667 149 74.7583H0Z"
          fill={fillColor}
        />
        <path
          d="M74.5 109.809C93.2786 109.809 108.502 94.1164 108.502 74.7582C108.502 55.4 93.2786 39.707 74.5 39.707C55.7214 39.707 40.4983 55.4 40.4983 74.7582C40.4983 94.1164 55.7214 109.809 74.5 109.809Z"
          fill="white"
        />
        <path
          d="M74.5 95.4749C85.5991 95.4749 94.5967 86.1996 94.5967 74.758C94.5967 63.3163 85.5991 54.041 74.5 54.041C63.4009 54.041 54.4033 63.3163 54.4033 74.758C54.4033 86.1996 63.4009 95.4749 74.5 95.4749Z"
          fill={fillColor}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.005 143.739L0 18.9719L9.3662 9.31445L130.371 134.082L121.005 143.739Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.79 134.684L8.78442 9.91718L18.1506 0.259766L139.156 125.027L129.79 134.684Z"
        fill="white"
      />
      <defs>
        <clipPath id="clip0_6669_19300">
          <rect
            width="149"
            height="104.852"
            fill="white"
            transform="translate(0 22.334)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

VisibilityOffIcon.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

VisibilityOffIcon.defaultProps = {
  fillColor: "black",
};

export default VisibilityOffIcon;
