import React from "react";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import Link from "../../../common/Link/Link";
import { useStyles } from "./DefaultBottomText.styles";
import clsx from "clsx";
import withConfig from "../../../utils/withConfig";

const DefaultBottomText = () => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.row, classes.singup_footer)}>
      <div className={classes.recaptcha_text}>
        <Typography className={classes.recaptcha_text_mobile} level="t2_text_2">
          {labels.RECAPTCHA_TEXT_BEGIN}

          <Link
            className={classes.recaptcha_text_mobile}
            level={"t2_text_2"}
            url={withConfig("SIGNUP_LINK_RECAPTCHA_PRIVACY")}
            color={"blueLink"}
          >
            {labels.PRIVACY_POLICY}
          </Link>

          {labels.AND}

          <Link
            className={classes.recaptcha_text_mobile}
            level={"t2_text_2"}
            url={withConfig("SIGNUP_LINK_TOS_RECAPTCHA")}
            color={"blueLink"}
          >
            {labels.TERMS_OF_SERVICE}
          </Link>
          {labels.APPLY}
        </Typography>
      </div>
    </div>
  );
};

export default DefaultBottomText;
