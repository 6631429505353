import React from "react";
import TextField from "../TextField/TextField";
import { bool, func, shape, string } from "prop-types";
import Search from "../Icons/Search";

const SearchField = ({
  placeholder,
  onChange,
  startIcon,
  startIconFill,
  onKeyPress,
  classes,
}) => {
  return (
    <TextField
      type="search"
      className={classes.text_field}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
      {...(startIcon && {
        InputProps: {
          startAdornment: (
            <Search fillColor={startIconFill} className={classes.start_icon} />
          ),
        },
      })}
      onKeyPress={(e) => onKeyPress(e.key)}
    />
  );
};

SearchField.propTypes = {
  placeholder: string,
  onChange: func,
  startIcon: bool,
  startIconFill: string,
  onKeyPress: func,
  classes: shape({ text_field: string, start_icon: string }),
};

SearchField.defaultProps = {
  placeholder: "Search",
  startIcon: false,
  startIconFill: "#9C9C9C",
  onKeyPress: () => {},
};

export default SearchField;
