import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    block_title_link: {
      textTransform: "uppercase",
      "& span": {
        color: `${theme.palette.themeColor.primary} !important`,
        textUnderlineOffset: `${theme.spacing(1)}px`,
        "&:hover": {
          textDecoration: "underline",
          textDecorationThickness: `2px`,
        },
      },
    },
    block_title: {
      textTransform: "uppercase",
    },
    icon: {
      fontSize: "14px !important",
    },
    primary_color_font: {
      textTransform: "uppercase",
      "& span": {
        fontWeight: 700,
        color: `${theme.palette.themeColor.primary} !important`,
      },
      "& :hover": {
        textDecoration: "underline",
      },
    },
    lowercase_font: {
      textTransform: "none",
    },
  };
});

export { useStyles };
