import React from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./ConfirmationEmailReSentContent.style";
import Link from "../../../common/Link/Link";
import { CONTACT_EMAIL } from "../logInConstants";
import { replaceInHtmlText } from "../../../utils/helper";

const ConfirmationEmailReSentContent = ({ email, customMessage }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Typography level="h4" color="tapintoOrange" bold>
        {labels.THANK_YOU}
      </Typography>
      {customMessage ? (
        <Typography
          level="t3_text_3"
          color="gray"
          className={classes.custom_message}
        >
          {replaceInHtmlText(customMessage, [
            {
              toReplace: ":email",
              toReplaceWith: `<a href=mailto:${email}>${email}</a>`,
            },
          ])}
        </Typography>
      ) : (
        <Typography level="t3_text_3" color="gray">
          {labels.CONFIRMATION_EMAIL_SENT_MESSAGE_1}
          <strong>{email}</strong>
          {labels.CONFIRMATION_EMAIL_SENT_MESSAGE_2}
          <Link
            children={CONTACT_EMAIL}
            level="t3_text_3"
            url={"mailto:" + CONTACT_EMAIL}
            color="blueLink"
          />
        </Typography>
      )}
    </div>
  );
};

ConfirmationEmailReSentContent.propTypes = {
  email: PropTypes.string,
  isLicense: PropTypes.bool,
  customMessage: PropTypes.string,
};

export default ConfirmationEmailReSentContent;
