import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  content: {
    width: theme.spacing(37.5),
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down(400)]: {
      width: theme.spacing(36.25),
    },
  },
  button: {
    width: "100%",
    height: theme.spacing(5),
    marginTop: `${theme.spacing(3)}px !important`,
    "& .MuiButton-label": {
      fontSize: "20px !important",
    },
  },
  divider: {
    width: "65%",
    border: `1px solid ${theme.palette.themeColor.primary}`,
    marginBottom: theme.spacing(1),
    marginTop: 0,
  },
  remember_me_checkbox: {
    marginTop: theme.spacing(1),
    "& .MuiTypography-root": {
      color: theme.palette.dark.gray,
      fontSize: "17px",
      fontFamily: "Montserrat !important",
      fontWeight: 500,
    },
  },
  login_loading_overlay: {
    background: "rgba(255, 255, 255, 0.6) !important",
  },
  forgot_password: {
    cursor: "pointer",
    marginTop: theme.spacing(1),
  },
  email_text: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export { useStyles };
