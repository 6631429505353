import { makeStyles } from "../Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    success: {
      "& .MuiSnackbarContent-root": {
        backgroundColor: `${theme.palette.themeColor.secondary} !important`,
        fontWeight: `${700} !important`,
      },
    },
    error: {
      "& .MuiSnackbarContent-root": {
        backgroundColor: `${theme.palette.miscellaneous.red} !important`,
        fontWeight: `${700} !important`,
      },
    },
    warning: {
      "& .MuiSnackbarContent-root": {
        backgroundColor: `${theme.palette.themeColor.primary} !important`,
        fontWeight: `${700} !important`,
      },
    },
    center_below_masthead: {
      top: `${theme.spacing(22.5)}px !important`,
      [theme.breakpoints.down(960)]: {
        top: `${theme.spacing(7.6)}px !important`,
      },
    },
    center_below_cms_header: {
      top: `${theme.spacing(10)}px !important`,
    },
    center_in_screen: {
      top: "45vh !important",
    },
    message_container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    action: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    action_icon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    icon_before_message: {
      "& svg": {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: theme.spacing(2),
      },
    },
    with_action: {
      "& .MuiSnackbarContent-message": {
        width: "90%",
      },
      "& .MuiSnackbarContent-action": {
        marginBottom: "auto",
      },
    },
  };
});

export { useStyles };
