import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  banner_container: {
    height: theme.spacing(6.25),
    width: "100%",
    backgroundColor: theme.palette.miscellaneous.defaultRed,
    color: theme.palette.miscellaneous.white,
    position: "fixed",
    zIndex: "99999",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    top: 0,
    [theme.breakpoints.down(960)]: {
      padding: theme.spacing(1),
      fontSize: "12px",
    },
  },
  bold_text: {
    fontWeight: "700",
    marginRight: theme.spacing(1),
  },
}));

export { useStyles };
