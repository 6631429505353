import React from "react";
import labels from "../../../../config/labels";
import ContentLinks from "../ContentLinks/ContentLinks";
import BubbledButton from "../../../../common/Button/BubbledButton";
import { redirectToPage } from "../../../../utils/helper";
import Link from "../../../../common/Link/Link";
import Typography from "../../../../common/Typography/Typography";
import ClickHere from "../../../../common/Icons/ClickHere";
import { useStyles } from "./ModalContents.style";
import { array, object, oneOfType } from "prop-types";

const ModalContents = ({
  actions,
  contributables,
  currentUserId,
  legalNoticePermissions,
  theme: { primaryColor, secondaryColor },
}) => {
  const classes = useStyles();
  return (
    <div className={classes.modal_contents}>
      {actions?.how_to_submit_content && (
        <BubbledButton
          level="button_orange"
          color="primary"
          className={classes.how_to_submit_content_button}
          onClick={() =>
            redirectToPage(actions?.how_to_submit_content, "_blank")
          }
        >
          {labels.HOW_TO_SUBMIT_CONTENT_ON_TAPINTO}
        </BubbledButton>
      )}

      <ContentLinks
        theme={{ primaryColor: primaryColor, secondaryColor: secondaryColor }}
        contributables={contributables}
        legalNoticePermissions={legalNoticePermissions}
        currentUserId={currentUserId}
      />

      {actions?.advertise_with_us && (
        <div className={classes.advertise_with_us}>
          <Typography level="paragraph_2">
            {labels.SUBMIT_CONTENT_FOOTER}
          </Typography>
          <Typography level="paragraph_2">{labels.TO_LEARN_MORE}</Typography>
          <Link
            url={actions.advertise_with_us}
            level="paragraph_2"
            children={
              <div className={classes.click_here}>
                {labels.CLICK_HERE}
                <ClickHere fillColor={secondaryColor} />
              </div>
            }
          />
        </div>
      )}
    </div>
  );
};

ModalContents.propTypes = {
  actions: oneOfType([object, array]),
  contributables: oneOfType([object, array]),
  theme: object,
};

export default ModalContents;
