import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import TextField from "../TextField";
import VisibilityIcon from "../../Icons/Visibility";
import VisibilityOffIcon from "../../Icons/VisibilityOff";
import { useStyles } from "./PasswordField.style";

const GRAY = "#3F5060";

const PasswordField = ({
  defaultValue,
  handlePasswordChange,
  className,
  label,
  labelLevel,
  boldLabel,
  error,
  required,
  placeholder,
  handleNextStepOnEnter,
  typeOfField,
  autoFocus,
  id,
}) => {
  const classes = useStyles();
  const [showPasswordAsText, setShowPasswordAsText] = useState(false);

  const getEndAdornmentForPasswordField = () => {
    if (showPasswordAsText) {
      return (
        <div
          onClick={() => setShowPasswordAsText(false)}
          className={classes.end_adornment}
        >
          <VisibilityOffIcon
            fillColor={GRAY}
            className={classes.end_adornment_svg}
          />
        </div>
      );
    } else {
      return (
        <div
          onClick={() => setShowPasswordAsText(true)}
          className={classes.end_adornment}
        >
          <VisibilityIcon
            fillColor={GRAY}
            className={classes.end_adornment_svg}
          />
        </div>
      );
    }
  };

  return (
    <TextField
      InputProps={{
        value: defaultValue,
        endAdornment: getEndAdornmentForPasswordField(),
      }}
      className={clsx(classes.textfield, className && className)}
      label={label}
      labelLevel={labelLevel}
      boldLabel={boldLabel}
      type={showPasswordAsText ? "text" : "password"}
      onChange={handlePasswordChange}
      error={error}
      required={required}
      placeholder={placeholder}
      {...(handleNextStepOnEnter && {
        onKeyPress: (e) => handleNextStepOnEnter(e.key, typeOfField),
      })}
      autoFocus={autoFocus}
      id={id}
      inputProps={{ "data-cy": "password" }}
    />
  );
};

PasswordField.propTypes = {
  defaultValue: PropTypes.string,
  handlePasswordChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  labelLevel: PropTypes.string,
  boldLabel: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  handleNextStepOnEnter: PropTypes.func,
  typeOfField: PropTypes.string,
  autoFocus: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PasswordField.defaultProps = {
  labelLevel: "paragraph_1",
  boldLabel: false,
};

export default PasswordField;
