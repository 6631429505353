import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(4)}px ${theme.spacing(
      4
    )}px ${theme.spacing(2.5)}px`,
    textAlign: "center",
    lineHeight: "22px",
    "& h4": {
      marginBottom: theme.spacing(2),
    },
  },
}));

export { useStyles };
