import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ClickableIcon.style";
import clsx from "clsx";
import Typography from "../../../common/Typography/Typography";

const ClickableIcon = ({
  icon,
  upperText,
  lowerText,
  textColor,
  textHoverColor,
  url,
  disabled,
  onClick,
  className,
  typographyLevel,
}) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  const iconText = (label, color, hoverColor) => {
    return (
      <Typography
        className={classes.icon_text_line_height}
        level={typographyLevel}
        color={isHovered ? hoverColor : color}
        bold
      >
        {label}
      </Typography>
    );
  };

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={clsx(classes.icon_container, className)}
    >
      <a
        {...(!disabled ? { href: url, target: "_parent" } : {})}
        className={classes.modal_link}
        onClick={onClick}
      >
        {upperText && iconText(upperText, textColor, textHoverColor)}
        {icon}
        <br />
        {lowerText && iconText(lowerText, textColor, textHoverColor)}
      </a>
    </div>
  );
};

ClickableIcon.propTypes = {
  icon: PropTypes.node,
  upperText: PropTypes.string,
  lowerText: PropTypes.string,
  textColor: PropTypes.string,
  textHoverColor: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  typographyLevel: PropTypes.string,
};

ClickableIcon.defaultProps = {
  typographyLevel: "h3_sub_header",
};

export default ClickableIcon;
