import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  games_divider: {
    marginBottom: `${theme.spacing(3)}px !important`,
  },
  section_container: {
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: `${theme.spacing(4)}px`,
    boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.20),0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down(1260)]: {
      paddingInline: `${theme.spacing(0)}px`,
      gap: theme.spacing(2),
    },
  },
  sidebar_container: {
    maxWidth: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  section_title_sidebar: {
    fontSize: theme.spacing(2.5),
    fontWeight: 700,
    color: theme.palette.themeColor.primary,
    marginBottom: theme.spacing(2),
  },
  section_title: {
    fontSize: theme.spacing(4),
    fontWeight: 700,
    paddingBottom: `${theme.spacing(5)}px`,
    textAlign: "center",
    marginBottom: 0,
    [theme.breakpoints.down(1260)]: {
      textAlign: "left",
      paddingBottom: `${theme.spacing(0)}px`,
    },
  },
  vertical_cards_container: {
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: `${theme.spacing(2)}px`,
    flexWrap: "wrap",
    [theme.breakpoints.down(1260)]: {
      flexDirection: "column",
      flexWrap: "nowrap",
    },
  },
  sidebar_cards_container: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: `${theme.spacing(2)}px`,
    "& > div": {
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  cards_container: {
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    gap: `${theme.spacing(2)}px`,
    [theme.breakpoints.down(1260)]: {
      flexDirection: "column",
    },
  },
  game_image: {
    maxWidth: "240px",
    height: "auto",
  },
  sidebar_game_image: {
    width: "100%",
    height: "auto",
  },
  vertical_image_container: {
    display: "flex",
    justifyContent: "center",
    maxWidth : "240px",
    [theme.breakpoints.down(1260)]: {
      maxWidth: "100%",
    },
  },
  sidebar_image_container: {
    maxWidth: "120px",
  },
  image_container: {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
  },
  sidebar_card_content: {
    display: "flex",
    gap: `${theme.spacing(2)}px`,
    alignItems: "center",
  },
  vertical_card_content: {
    display: "flex",
    gap: `${theme.spacing(7)}px`,
    alignItems: "center",
    [theme.breakpoints.down(1260)]: {
      gap: `${theme.spacing(2)}px`,
      flexDirection: "column",
    },
  },
  card_content: {
    display: "flex",
    flexDirection: "column",
    gap: `${theme.spacing(2)}px`,
    alignItems: "center",
  },
  vertical_card_title: {
    fontSize: theme.spacing(2.5),
    fontWeight: 700,
    textTransform: "capitalize",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  card_title:{
    fontSize: theme.spacing(2.5),
    fontWeight: 700,
    textAlign: "center",
    textTransform: "capitalize",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  sidebar_card_title: {
    fontSize: theme.spacing(2),
    fontWeight: 700,
    textTransform: "capitalize",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  card_description:{
    fontSize: theme.spacing(2.25),
    fontWeight: 400,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export { useStyles };
