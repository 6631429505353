import React from "react";
import PropTypes from "prop-types";

const MediaControl = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 24 24"
    >
      <path d="M2 24v-24l20 12-20 12z" fill={fillColor} />
    </svg>
  );
};

MediaControl.propTypes = {
  fillColor: PropTypes.string,
};

MediaControl.defaultProps = {
  fillColor: "white",
};

export default MediaControl;
