import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { down: below, values },
  } = theme;
  return {
    button: {
      borderRadius: "0px !important",
      padding: `${spacing(0.5)}px ${spacing(1)}px !important`,
      textTransform: "none !important",
      width: "48%",
      [below(values.lg)]: {
        width: "100%",
      },
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "12px",
        fontFamily: "Montserrat, sans-serif",
        color: "white",
      },
    },
    signup_button: {
      backgroundColor: `${palette.tapintoBranding.tapintoOrange} !important`,
      "& .MuiButton-label": {
        fontWeight: 700,
      },
    },
    submit_content_button: {
      border: `1px solid ${palette.tapintoBranding.tapintoOrange} !important`,
      "& .MuiButton-label": {
        fontWeight: 500,
      },
    },
    buttons_container: {
      width: spacing(40),
      display: "flex",
      flexWrap: "wrap",
      gap: spacing(1),
      justifyContent: "space-between",
      [below(values.md)]: {
        width: "100%",
        marginTop: spacing(4),
        paddingRight: spacing(4),
        gap: spacing(2),
      },
    },
  };
});

export { useStyles };
