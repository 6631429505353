import React from "react";
import PropTypes from "prop-types";
import { Tooltip as MaterialTooltip, styled } from "@material-ui/core";
import theme from "../../common/Theme/themes/default";

const CustomTooltip = styled(({ className, ...props }) => (
  <MaterialTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .MuiTooltip-arrow`]: {
    color: theme.palette.themeColor.primary,
  },
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "white",
    pointerEvents: "auto",
    padding: 0,
    [theme.breakpoints.down(760)]: {
      maxWidth: "100%",
    },
  },
}));

const WeatherTooltip = ({
  title,
  arrow,
  placement,
  disableHoverListener,
  disableFocusListener,
  contents,
  open,
  onClose,
  PopperProps,
}) => {
  return (
    <CustomTooltip
      title={title}
      arrow={arrow}
      placement={placement}
      disableHoverListener={disableHoverListener}
      disableFocusListener={disableFocusListener}
      disableTouchListener
      enterTouchDelay={50}
      leaveTouchDelay={5000}
      PopperProps={PopperProps}
      open={open}
      onClose={onClose}
    >
      {contents}
    </CustomTooltip>
  );
};

WeatherTooltip.propTypes = {
  title: PropTypes.node,
  arrow: PropTypes.bool,
  placement: PropTypes.oneOf([
    "bottom-end",
    "bottom-start",
    "bottom",
    "left-end",
    "left-start",
    "left",
    "right-end",
    "right-start",
    "right",
    "top-end",
    "top-start",
    "top",
  ]),
  disableHoverListener: PropTypes.bool,
  disableFocusListener: PropTypes.bool,
  contents: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  PopperProps: PropTypes.shape({
    disablePortal: PropTypes.bool,
  }),
};

WeatherTooltip.defaultProps = {
  disableFocusListener: false,
  disableHoverListener: false,
};

export default WeatherTooltip;
