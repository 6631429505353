import { useEffect, useState } from "react";
import { getWeatherConditions, getWeatherConditionsForSection } from "../services/weather";

export const useWeather = ({ townSlug, sectionSlug }) => {
  const [weather, setWeather] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getWeather = async () => {
      try {
        const weatherConditions = await getWeatherConditions({ townSlug });
        setWeather(weatherConditions);
      } catch (error) {
        console.log(error.message, "Oops! no weather for you");
      } finally {
        setIsLoading(false);
      }
    };

    const getWeatherForSection = async () => {
      try {
        const weatherConditions = await getWeatherConditionsForSection({ sectionSlug: sectionSlug });
        setWeather(weatherConditions);
      } catch (error) {
        console.log(error.message, "Oops! no weather for you");
      } finally {
        setIsLoading(false);
      }
    }

    if (townSlug && !sectionSlug) {
      getWeather();
    } else if (townSlug === "halston-media" && sectionSlug) {
      getWeatherForSection();
    }
  }, [townSlug, sectionSlug]);

  return {
    weather,
    isLoading,
  };
};
