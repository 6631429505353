import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useStyles } from "./CardActions.style";
import SocialNetworkShareButtons from "../../../SocialNetworkShareButtons/SocialNetworkShareButtons";
import Link from "../../../../common/Link/Link";
import {
  DIRECTORIES_BLOCK,
  REAL_ESTATE_BLOCK,
} from "../../../../utils/constants/homepageConstants";
import Typography from "../../../../common/Typography/Typography";
import { specialCaseCard } from "../../../../utils/helpers/homepageHelper";
import Button from "../../../../common/Button/Button";
import { redirectToPage } from "../../../../utils/helper";

const CardActions = ({
  contentUrl,
  actionLabel,
  withSocialMediaIcons,
  contentType,
  label,
  withLink,
}) => {
  const classes = useStyles(withSocialMediaIcons);

  switch (contentType) {
    case DIRECTORIES_BLOCK:
      return (
        <div className={classes.directories_actions_container}>
          <div className={classes.directories_label_container}>
            <Typography
              children={label}
              level="t2_text_2"
              color="tapintoOrange"
              bold
            />
          </div>

          <div className={classes.directories_button_container}>
            <Button
              level="simple_button"
              color="primary"
              bold
              classes={{ root: clsx(classes.button, classes.button_label) }}
              onClick={() => redirectToPage(contentUrl, "_top")}
            >
              {"Learn more"}
            </Button>
          </div>
        </div>
      );
    case REAL_ESTATE_BLOCK:
      return (
        <div className={classes.real_estates_actions_container}>
          <Typography
            children={label}
            level="t2_text_2"
            color="tapintoOrange"
            bold
          />
        </div>
      );
    default:
      return (
        <div
          className={clsx(
            classes.actions_container,
            withLink && withSocialMediaIcons && classes.space_between,
            specialCaseCard(contentType) && [classes.padding_16]
          )}
        >
          {withSocialMediaIcons && (
            <SocialNetworkShareButtons
              url={contentUrl}
              paddingWithinButtons={8}
              socialNetworks={["facebook", "twitter", "email"]}
              iconsSize={30}
              alignment={withLink ? "left" : "right"}
            />
          )}

          {withLink && (
            <Link
              children={actionLabel}
              url={contentUrl}
              className={classes.link}
              level="medium_11_px"
            />
          )}
        </div>
      );
  }
};

CardActions.propTypes = {
  contentUrl: PropTypes.string,
  actionLabel: PropTypes.string,
  withSocialMediaIcons: PropTypes.bool,
  withLink: PropTypes.bool,
  contentType: PropTypes.string,
  label: PropTypes.string,
};

CardActions.defaultProps = {
  withSocialMediaIcons: true,
  withLink: false,
};

export default CardActions;
