import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    information: {
      width: "100%",
      display: "block",
      fontWeight: 500,
      "&:hover": {
        textDecoration: "underline !important",
        textDecorationColor: `${theme.palette.dark.black} !important`,
      },
    },
    media_container: {
      width: (containsVideoAsMedia) => (containsVideoAsMedia ? "48%" : "90%"),
    },
    information_container: {
      width: (containsVideoAsMedia) => (containsVideoAsMedia ? "50%" : "70%"),
    },
    layout_container: {
      maxHeight: (containsVideoAsMedia) =>
        containsVideoAsMedia ? theme.spacing(25) : theme.spacing(20),
    },
    image: {
      maxHeight: theme.spacing(25),
      [theme.breakpoints.down(768)]: {
        maxHeight: theme.spacing(31.25),
      },
    },
    no_rounded_border: {
      borderRadius: "0px",
    },
    category_section_or_column_card: {
      marginBottom: theme.spacing(2),
    },
    actions_main_container: {
      padding: `${theme.spacing(1)}px 0px`,
      "& [class*='actions_container']": {
        justifyContent: "center",
      },
    },
  };
});

export { useStyles };
