import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./VerticalLayout.style";
import VideoPlayer from "../../../VideoPlayer/VideoPlayer";
import Typography from "../../../Typography/Typography";
import {
  mediaUrlForCardLayout,
  removeCaptionsAndCredits,
} from "../../../../utils/helpers/mediaHelper";
import Link from "../../../Link/Link";
import Carousel from "../../../Carousel/Carousel";

const VerticalLayout = ({
  media,
  header,
  information,
  actions,
  options,
  customClasses,
  title,
}) => {
  const mediaUrl = mediaUrlForCardLayout(options, media)
  const classes = useStyles({ fillBackgroundImage: mediaUrl })

  const Media = () => {
    return (
      <>
        <div
          className={clsx(
            options?.media?.withFillImageBackground ? classes.media_container_with_fill_background : classes.media_container,
            customClasses?.media_container && customClasses?.media_container
          )}
        >
          {media &&
            (media.isVideo && options?.videoPlayer?.showPlayable ? (
              <VideoPlayer
                url={mediaUrl}
                width={options?.videoPlayer?.width}
                showControls={true}
                height={options?.videoPlayer?.height}
              />
            ) : (
              <img
                src={mediaUrl}
                alt={media.alt_text}
                className={customClasses?.image ? customClasses.image : ""}
              />
            ))}
        </div>

        {(options?.caption?.visible && media?.caption) ||
          (options?.credits?.visible && media?.credits && (
            <div
              className={clsx(
                classes.caption_and_credits_container,
                customClasses?.caption_and_credits_container &&
                  customClasses?.caption_and_credits_container
              )}
            >
              {options?.caption?.visible && media?.caption && (
                <Typography
                  level={options?.caption?.typography?.level}
                  color={options?.caption?.typography?.color}
                  className={options?.caption?.typography?.className}
                >
                  {options?.caption?.withPrefix && <strong>Caption: </strong>}
                  {media.caption}
                </Typography>
              )}
              {options?.credits?.visible && media.credits && (
                <Typography
                  level={options?.credits?.typography?.level}
                  color={options?.credits?.typography?.color}
                  className={options?.credits?.typography?.className}
                >
                  {options?.credits?.withPrefix && <strong>Credits: </strong>}
                  {media.credits}
                </Typography>
              )}
            </div>
          ))}
      </>
    );
  };

  const shouldShowCarousel = () => {
    return options?.media?.showCarousel && Array.isArray(media);
  };

  const shouldShowClickableMedia = () => {
    return (
      options?.media?.clickable &&
      options?.media?.townContentUrl &&
      !media.isVideo
    );
  };

  return (
    <div
      className={clsx(
        classes.vertical_layout,
        customClasses?.root && customClasses?.root
      )}
    >
      <div
        className={customClasses?.top_section ? customClasses?.top_section : ""}
      >
        {title && title}
        {header && header}

        {shouldShowCarousel() ? (
          <div
            className={clsx(
              classes.carousel_container,
              classes.carousel_image,
              classes.carousel_swipers,
              classes.carousel_pagination,
              customClasses?.carousel?.image && customClasses?.carousel?.image,
              customClasses?.carousel?.container &&
                customClasses?.carousel?.container
            )}
          >
            <Carousel
              items={removeCaptionsAndCredits(media)}
              withPagination={true}
              showArrows={false}
              withAutoplay={false}
              videoPlayerProps={{
                height: "90%",
                width: "100%",
              }}
            />
          </div>
        ) : (
          <>
            {shouldShowClickableMedia() ? (
              <Link
                children={<Media />}
                url={options?.media?.townContentUrl}
                className={
                  options?.media?.opacityOnFocus && classes.opacity_on_focus
                }
              />
            ) : (
              <Media />
            )}
          </>
        )}
        {information && information}
      </div>

      {actions && actions}
    </div>
  );
};

VerticalLayout.propTypes = {
  media: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        contentUrl: PropTypes.string,
        contentThumbUrl: PropTypes.string,
        contentCarouselUrl: PropTypes.string,
        credits: PropTypes.string,
        caption: PropTypes.string,
        alt_text: PropTypes.string,
        title: PropTypes.string,
        isVideo: PropTypes.bool,
      })
    ),
    PropTypes.shape({
      contentUrl: PropTypes.string,
      contentThumbUrl: PropTypes.string,
      contentCarouselUrl: PropTypes.string,
      credits: PropTypes.string,
      caption: PropTypes.string,
      alt_text: PropTypes.string,
      title: PropTypes.string,
      isVideo: PropTypes.bool,
    }),
  ]),
  header: PropTypes.node,
  information: PropTypes.node.isRequired,
  actions: PropTypes.node,
  options: PropTypes.shape({
    credits: PropTypes.shape({
      typography: PropTypes.shape({
        level: PropTypes.string,
        color: PropTypes.string,
        className: PropTypes.string,
      }),
      visible: PropTypes.bool,
    }),
    caption: PropTypes.shape({
      typography: PropTypes.shape({
        level: PropTypes.string,
        color: PropTypes.string,
        className: PropTypes.string,
      }),
      visible: PropTypes.bool,
    }),
    videoPlayer: PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
    }),
    media: PropTypes.shape({
      version: PropTypes.oneOf(["carousel", "thumb"]),
      clickable: PropTypes.bool,
      townContentUrl: PropTypes.string,
      showCarousel: PropTypes.bool,
      opacityOnFocus: PropTypes.bool,
      withFillImageBackground: PropTypes.bool,
    }),
  }),
  customClasses: PropTypes.shape({
    root: PropTypes.string,
    media_container: PropTypes.string,
    caption_and_credits_container: PropTypes.string,
    image: PropTypes.string,
    top_section: PropTypes.string,
    carousel: PropTypes.shape({
      image: PropTypes.string,
      container: PropTypes.string,
    }),
  }),
};

VerticalLayout.defaultProps = {
  options: {
    credits: {
      typography: { level: "t0_text_0", color: "black", className: "" },
      visible: true,
      withPrefix: true,
    },
    caption: {
      typography: { level: "t0_text_0", color: "black", className: "" },
      visible: true,
      withPrefix: true,
    },
    videoPlayer: {
      width: "100%",
      height: "160px",
      showPlayable: true,
    },
    media: {
      clickable: false,
      showCarousel: false,
      opacityOnFocus: true,
      withFillImageBackground: false,
    },
  },
};

export default VerticalLayout;
