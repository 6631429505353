export const parseTownsWithClustersForSelect = (townOptions) => {
  let parsedOptions = [];

  townOptions.forEach((item) => {
    parsedOptions.push({
      name: item.name_with_zip_code,
      value: item.slug,
    });

    if (item.cluster_towns?.length) {
      item.cluster_towns.map((clusterTown) =>
        parsedOptions.push({
          name: clusterTown.name,
          value: item.slug,
        })
      );
    }
  });

  return parsedOptions;
};

export const extractTownSlugs = (towns) => {
  return towns?.map((town) => town.value);
};

export const verifyAndInsertCurrentTownInTownsArray = ({
  townsArray,
  currentTown,
  insertTownMethod,
}) => {
  const currentTownInTownsArray = townsArray?.find((item) =>
    item.value.includes(currentTown.slug)
  );

  if (currentTown && currentTownInTownsArray === undefined) {
    switch (insertTownMethod) {
      case "concat":
        townsArray.concat([
          {
            name: currentTown.name,
            value: currentTown.slug,
          },
        ]);

      default:
        townsArray.push({
          name: currentTown.name,
          value: currentTown.slug,
        });
    }
  }

  return townsArray;
};

export const insertCurrentTownToSubscripitonPayload = ({
  previousSubscriptions,
  town,
  formData,
}) => {
  let payload;

  if (formData.selectedTowns.length === 0) {
    previousSubscriptions = verifyAndInsertCurrentTownInTownsArray({
      townsArray: previousSubscriptions,
      currentTown: town,
    });

    payload = {
      ...formData,
      selectedTowns: previousSubscriptions,
      subscribed_town_slugs: extractTownSlugs(previousSubscriptions),
    };
  } else {
    const towns = verifyAndInsertCurrentTownInTownsArray({
      townsArray: formData?.selectedTowns,
      currentTown: town,
      insertTownMethod: "concat",
    });

    payload = {
      ...formData,
      selectedTowns: towns,
      subscribed_town_slugs: extractTownSlugs(towns),
    };
  }

  return payload;
};
