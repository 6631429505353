import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Card from "../../../common/Card/Card";
import VerticalLayout from "../../../common/Card/Layouts/Vertical/VerticalLayout";
import Link from "../../../common/Link/Link";
import { useStyles } from "./EventCard.style";
import Typography from "../../../common/Typography/Typography";
import { VIDEO_PLAYER_OPTIONS } from "../../../utils/constants/homepageConstants";
import { useTown } from "../../../context/TownContext";
import { useSection } from "../../../context/SectionContext";
import { parseEventMedia } from "../../../utils/helpers/mediaHelper";
import labels from "../../../config/labels";

const Event = ({ content, options, customClasses, useSectionContext }) => {
  const isFourCards = options?.isFourCards;
  const classes = useStyles(isFourCards);
  const { town } = useSectionContext ? useSection() : useTown();
  const { eventMedia, eventUrl } = parseEventMedia({
    event: content,
    town: town,
  });
  return (
    <Card
      withShadow={options?.withShadow}
      children={
        <VerticalLayout
          media={eventMedia}
          information={
            <Link
              children={
                <div className={classes.information_container}>
                  <Typography
                    children={content.title}
                    level="h3_sub_header"
                    className={classes.title}
                  />
                  <div>
                    <Typography
                      children={content.starts}
                      level="t5_text_5"
                      color={"tapintoOrange"}
                      className={clsx(classes.information, classes.starts)}
                    />
                    {content.all_day ? (
                      <Typography
                        children={labels.ALL_DAY_EVENT}
                        level="t2_text_2"
                        className={clsx(classes.information, classes.time)}
                      />
                    ) : (
                      <Typography
                        children={`${content.start_time} - ${content.end_time}`}
                        level="t2_text_2"
                        className={clsx(classes.information, classes.time)}
                      />
                    )}
                  </div>
                  <Typography
                    children={content.city}
                    level="paragraph_2"
                    color="gray"
                    className={classes.city}
                  />
                </div>
              }
              url={eventUrl}
            />
          }
          options={{
            media: {
              clickable: true,
              townContentUrl: eventUrl,
            },
            videoPlayer: VIDEO_PLAYER_OPTIONS,
          }}
          customClasses={{
            image: clsx(classes.image, customClasses?.image),
            media_container: classes.media_container,
          }}
        />
      }
      classes={{
        root: clsx(
          !options?.forHomepage && classes.event_card,
          customClasses?.card,
          options?.contentsLength == 4 && classes.event_card_length_4,
          options?.contentsLength == 3 && classes.event_card_length_3,
          options?.contentsLength == 2 && classes.event_card_length_2
        ),
      }}
    />
  );
};

Event.propTypes = {
  content: PropTypes.object.isRequired,
  options: PropTypes.shape({
    rightBlock: PropTypes.bool,
    forHomepage: PropTypes.bool,
    withShadow: PropTypes.bool,
    isFourCards: PropTypes.bool,
  }),
  customClasses: PropTypes.shape({
    card: PropTypes.string,
    image: PropTypes.string,
    carousel: {
      image: PropTypes.string,
      container: PropTypes.string,
    },
  }),
};

Event.defualtProps = {
  options: {
    forHomepage: false,
    rightBlock: false,
  },
  customClasses: {
    card: "",
    image: "",
    carousel: {
      image: "",
      container: "",
    },
  },
};

export default Event;
