import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";

export const fetchDraftsForUser = async (userId) => {
  try {
    const response = await restClient.get(withConfig("GET_USER_DRAFTS"), {
      user_id: userId,
    });
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const deleteDraft = async (draft) => {
  try {
    const response = await restClient.get(withConfig("DELETE_DRAFT"), {
      draft: draft,
    });
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};
