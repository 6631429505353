import React from "react";
import PropTypes from "prop-types";

const TapintoLogo = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.7998 21.3529H50.8547V37.5813H45.9451V21.3529H40V17.4613H56.7998V21.3529Z"
        fill="#81BF40"
      />
      <path
        d="M76.8221 37.5813H71.7463L70.4294 33.514H63.372L62.0551 37.5813H57.1072L64.1391 17.4613H69.7902L76.8221 37.5813ZM69.2404 29.8252L66.9007 22.6095L64.561 29.8252H69.2404Z"
        fill="#81BF40"
      />
      <path
        d="M94.7475 23.8121C94.7475 24.713 94.5983 25.5958 94.3 26.4606C94.0017 27.3164 93.5755 28.037 93.0215 28.6226C92.2629 29.4153 91.4148 30.0143 90.4772 30.4197C89.5482 30.8251 88.389 31.0278 86.9996 31.0278H83.944V37.5813H79.0344V17.4613H87.1147C88.325 17.4613 89.3436 17.5739 90.1704 17.7991C91.0057 18.0153 91.743 18.3441 92.3822 18.7855C93.1493 19.317 93.7332 19.9971 94.1338 20.8259C94.5429 21.6546 94.7475 22.6501 94.7475 23.8121ZM89.6717 23.9337C89.6717 23.3662 89.5269 22.8798 89.2371 22.4744C88.9473 22.06 88.6106 21.7718 88.227 21.6096C87.7156 21.3934 87.217 21.2763 86.7311 21.2583C86.2453 21.2313 85.5975 21.2177 84.7878 21.2177H83.944V27.2443H85.3503C86.1856 27.2443 86.8718 27.1902 87.4088 27.0821C87.9543 26.974 88.4103 26.7578 88.7768 26.4335C89.0922 26.1453 89.318 25.803 89.4544 25.4066C89.5993 25.0012 89.6717 24.5103 89.6717 23.9337Z"
        fill="#81BF40"
      />
      <path
        d="M100.233 19.9611H97.5224V17.3262H100.233V19.9611ZM100.079 37.5813H97.6758V22.4879H100.079V37.5813Z"
        fill="#F78C2A"
      />
      <path
        d="M116.253 37.5813H113.85V28.9874C113.85 28.2937 113.811 27.6452 113.735 27.0416C113.658 26.429 113.517 25.9516 113.313 25.6093C113.1 25.2309 112.793 24.9517 112.392 24.7715C111.992 24.5823 111.472 24.4878 110.832 24.4878C110.176 24.4878 109.49 24.6589 108.774 25.0012C108.058 25.3435 107.372 25.7804 106.716 26.3119V37.5813H104.312V22.4879H106.716V24.1635C107.466 23.5058 108.241 22.9924 109.042 22.623C109.844 22.2537 110.666 22.069 111.51 22.069C113.053 22.069 114.229 22.56 115.039 23.5419C115.848 24.5238 116.253 25.9381 116.253 27.7848V37.5813Z"
        fill="#F78C2A"
      />
      <path
        d="M127.773 37.4462C127.322 37.5723 126.827 37.6759 126.29 37.7569C125.762 37.838 125.289 37.8786 124.871 37.8786C123.414 37.8786 122.306 37.4642 121.547 36.6354C120.788 35.8067 120.409 34.4779 120.409 32.6493V24.6229H118.785V22.4879H120.409V18.1504H122.813V22.4879H127.773V24.6229H122.813V31.5007C122.813 32.2934 122.83 32.915 122.864 33.3654C122.898 33.8068 123.017 34.2212 123.222 34.6086C123.409 34.9689 123.665 35.2346 123.989 35.4058C124.321 35.5679 124.824 35.649 125.498 35.649C125.89 35.649 126.299 35.5905 126.725 35.4733C127.151 35.3472 127.458 35.2436 127.646 35.1626H127.773V37.4462Z"
        fill="#F78C2A"
      />
      <path
        d="M142.336 30.0414C142.336 32.5006 141.74 34.4419 140.546 35.8652C139.353 37.2885 137.755 38.0002 135.752 38.0002C133.732 38.0002 132.125 37.2885 130.932 35.8652C129.747 34.4419 129.155 32.5006 129.155 30.0414C129.155 27.5821 129.747 25.6408 130.932 24.2175C132.125 22.7852 133.732 22.069 135.752 22.069C137.755 22.069 139.353 22.7852 140.546 24.2175C141.74 25.6408 142.336 27.5821 142.336 30.0414ZM139.856 30.0414C139.856 28.0866 139.494 26.6362 138.769 25.6904C138.045 24.7355 137.039 24.258 135.752 24.258C134.448 24.258 133.433 24.7355 132.709 25.6904C131.993 26.6362 131.635 28.0866 131.635 30.0414C131.635 31.9331 131.997 33.3699 132.722 34.3518C133.446 35.3247 134.456 35.8112 135.752 35.8112C137.03 35.8112 138.032 35.3292 138.756 34.3653C139.489 33.3924 139.856 31.9511 139.856 30.0414Z"
        fill="#F78C2A"
      />
      <path
        d="M144.974 38.0002C144.533 38.0002 144.153 37.8513 143.833 37.5536C143.524 37.2449 143.37 36.8534 143.37 36.3793C143.37 35.9052 143.524 35.5248 143.833 35.2381C144.153 34.9404 144.533 34.7916 144.974 34.7916C145.426 34.7916 145.807 34.9404 146.115 35.2381C146.424 35.5248 146.579 35.9052 146.579 36.3793C146.579 36.8534 146.424 37.2449 146.115 37.5536C145.807 37.8513 145.426 38.0002 144.974 38.0002Z"
        fill="white"
      />
      <path
        d="M153.735 28.8375C154.441 28.8375 155.07 28.9808 155.621 29.2675C156.183 29.5431 156.624 29.9731 156.944 30.5575C157.264 31.1309 157.424 31.8696 157.424 32.7738V37.8679H154.844V33.1707C154.844 32.454 154.684 31.9248 154.364 31.583C154.055 31.2411 153.614 31.0702 153.041 31.0702C152.633 31.0702 152.263 31.1585 151.933 31.3349C151.613 31.5003 151.359 31.7594 151.172 32.1122C150.995 32.465 150.907 32.9171 150.907 33.4684V37.8679H148.327V28.9698H150.791V31.4341L150.328 30.6898C150.648 30.0944 151.106 29.6368 151.701 29.3171C152.296 28.9973 152.975 28.8375 153.735 28.8375Z"
        fill="white"
      />
      <path
        d="M164.243 38.0002C163.229 38.0002 162.335 37.8017 161.564 37.4048C160.803 37.0078 160.213 36.4675 159.794 35.7839C159.375 35.0893 159.165 34.3009 159.165 33.4188C159.165 32.5257 159.369 31.7373 159.777 31.0537C160.196 30.3591 160.764 29.8188 161.481 29.4329C162.198 29.0359 163.008 28.8375 163.912 28.8375C164.783 28.8375 165.566 29.0249 166.261 29.3998C166.966 29.7636 167.523 30.2929 167.931 30.9875C168.339 31.6712 168.543 32.4926 168.543 33.4519C168.543 33.5511 168.538 33.6669 168.527 33.7992C168.516 33.9205 168.505 34.0363 168.494 34.1465H161.266V32.6415H167.137L166.145 33.088C166.145 32.6249 166.051 32.2225 165.864 31.8807C165.676 31.5389 165.417 31.2742 165.086 31.0868C164.756 30.8883 164.37 30.7891 163.929 30.7891C163.488 30.7891 163.096 30.8883 162.754 31.0868C162.424 31.2742 162.165 31.5444 161.977 31.8972C161.79 32.239 161.696 32.647 161.696 33.1211V33.518C161.696 34.0032 161.801 34.4332 162.01 34.8081C162.231 35.172 162.534 35.4531 162.92 35.6516C163.317 35.839 163.78 35.9328 164.309 35.9328C164.783 35.9328 165.197 35.8611 165.55 35.7178C165.913 35.5744 166.244 35.3594 166.542 35.0727L167.915 36.5613C167.507 37.0244 166.994 37.3827 166.377 37.6363C165.759 37.8789 165.048 38.0002 164.243 38.0002Z"
        fill="white"
      />
      <path
        d="M173.9 38.0002C172.852 38.0002 172.036 37.7355 171.452 37.2063C170.867 36.666 170.575 35.8666 170.575 34.8081V27.0016H173.155V34.775C173.155 35.1499 173.254 35.4421 173.453 35.6516C173.651 35.8501 173.922 35.9493 174.263 35.9493C174.671 35.9493 175.019 35.839 175.305 35.6185L176 37.4378C175.735 37.6253 175.416 37.7686 175.041 37.8679C174.677 37.9561 174.296 38.0002 173.9 38.0002ZM169.202 31.1529V29.1682H175.372V31.1529H169.202Z"
        fill="white"
      />
    </svg>
  );
};

TapintoLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

TapintoLogo.defaultProps = {
  width: 216,
  height: 56,
};

export default TapintoLogo;
