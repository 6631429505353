import React from "react";
export const MostlyCloudyFlurriesNight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="33"
    viewBox="0 0 42 33"
    fill="none"
  >
    <path
      d="M14.5 29.8334C12.0833 26.5834 12.25 21.0834 12.1667 22.8334C11.8333 27.4167 10.0833 29.8334 10.0833 29.8334C9.91667 30.0834 9.83333 30.4167 9.83333 30.75C9.83333 32 10.9167 33 12.3333 33C13.6667 33 14.8333 32 14.8333 30.75C14.75 30.5 14.6667 30.1667 14.5 29.8334Z"
      fill="white"
    />
    <path
      d="M22.1667 25.5C20.0833 22.6667 20.25 17.8334 20.0833 19.4167C19.8333 23.4167 18.25 25.5 18.25 25.5C18.0833 25.75 18 26 18 26.3334C18 27.4167 19 28.25 20.1667 28.25C21.3333 28.25 22.3333 27.4167 22.3333 26.3334C22.3333 26 22.25 25.75 22.1667 25.5Z"
      fill="white"
    />
    <path
      d="M17.1667 18.3334C16 16.75 16.0833 13.9167 16 14.8334C15.8333 17.1667 14.9167 18.3334 14.9167 18.3334C14.8333 18.5 14.8333 18.6667 14.8333 18.8334C14.8333 19.4167 15.4167 19.9167 16.0833 19.9167C16.75 19.9167 17.3333 19.4167 17.3333 18.8334C17.25 18.6667 17.1667 18.5 17.1667 18.3334Z"
      fill="white"
    />
    <path
      d="M27.75 25C27.75 25.75 27.1667 26.25 26.5 26.25C25.75 26.25 25.25 25.6667 25.25 25C25.25 24.25 25.8333 23.75 26.5 23.75C27.1667 23.6667 27.75 24.25 27.75 25Z"
      fill="white"
    />
    <path
      d="M27.8333 19.5C27.8333 19.9167 27.5 20.25 27.0833 20.25C26.6667 20.25 26.3333 19.9167 26.3333 19.5C26.3333 19.0834 26.6667 18.8334 27.0833 18.8334C27.5 18.75 27.8333 19.0834 27.8333 19.5Z"
      fill="white"
    />
    <path
      d="M32.8333 23.6667C32.8333 24.3334 32.3333 24.8334 31.6667 24.8334C31 24.8334 30.5 24.3334 30.5 23.6667C30.5 23 31 22.5 31.6667 22.5C32.3333 22.5 32.8333 23 32.8333 23.6667Z"
      fill="white"
    />
    <path
      d="M31.25 29C31.25 29.4167 30.9167 29.75 30.5 29.75C30.0833 29.75 29.8333 29.4167 29.8333 29C29.8333 28.5834 30.1667 28.3334 30.5 28.3334C30.9167 28.25 31.25 28.5834 31.25 29Z"
      fill="white"
    />
    <path
      d="M41.5833 6.33336C40.4167 2.6667 36.6667 0.500031 32.9167 1.08336C34.4167 2.00003 35.5833 3.4167 36.0833 5.25003C36.75 7.25003 36.5 9.25003 35.6667 11C34.8333 8.6667 32.5833 7.00003 29.9167 7.00003C28.75 7.00003 27.6667 7.33336 26.6667 7.9167C26.0833 4.4167 22.8333 1.83336 19.0833 2.00003C15.5 2.1667 12.6667 4.83336 12.25 8.1667C11.5833 7.9167 10.9167 7.83336 10.25 7.83336C6.91667 7.75003 4.16667 10.5 4.16667 13.9167C4.16667 14 4.16667 14.0834 4.16667 14.1667C2.41667 14.6667 0 15.6667 0 19.1667C0 21.75 2.16667 24 5.16667 24.1667L6 22.6667C5.91667 22.6667 5.75 22.6667 5.66667 22.6667H5.41667C3.16667 22.6667 1.33333 21.0834 1.33333 19.0834C1.33333 16.75 2.58333 15.9167 5 15.3334L5.66667 15.1667V14.3334C5.66667 14.1667 5.66667 14.0834 5.66667 13.9167C5.66667 11.3334 7.75 9.1667 10.4167 9.1667C11.1667 9.1667 12 9.4167 12.6667 9.75003L13.75 10.3334V9.08336C13.6667 6.00003 16.1667 3.4167 19.3333 3.33336H19.6667C22.75 3.33336 25.3333 5.6667 25.5 8.75003C25.5 8.83336 25.5 9.00003 25.5 9.25003L25.4167 11.0834L26.6667 9.75003C27.5833 8.83336 28.75 8.33336 30 8.33336C32.4167 8.33336 34.4167 10.1667 34.6667 12.5834C33.75 13.6667 32.5833 14.5 31.1667 15C30.8333 15.0834 30.5 15.1667 30.0833 15.25C32 16.4167 34.3333 16.8334 36.6667 16.0834C36.8333 16 37.0833 15.9167 37.25 15.8334C38 16.5 38.4167 17.3334 38.5 18.3334C38.5833 20.0834 37.4167 22 35.5833 22.5834L36.0833 23.9167C38.3333 23.1667 40.0833 20.8334 39.9167 18.25C39.8333 17.0834 39.3333 16 38.5833 15.1667C41.3333 13.3334 42.75 9.75003 41.5833 6.33336Z"
      fill="white"
    />
  </svg>
);
