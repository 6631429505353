import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    text: {
      fontSize: "8px",
      fontWeight: 400,
      color: theme.palette.dark.gray,
      textAlign: "center",
      "& a": {
        color: theme.palette.miscellaneous.blueLink,
        fontSize: "8px",
        fontWeight: 400,
        textDecoration: "underline",
      },
    },
  };
});

export { useStyles };
