import { pathToUrl } from "../utils/helper";
import restClient from "../utils/restClient"
import withConfig from "../utils/withConfig";

export const getWeatherConditions = async ({ locationKey, townSlug }) => {
  
  const weatherConditionsResponse = await restClient.get(
    pathToUrl(withConfig("GET_WEATHER_CONDITIONS"), { town_slug: townSlug })
  );

  return weatherConditionsResponse.data;
}

export const getWeatherConditionsForSection = async ({ locationKey, sectionSlug }) => {
  const weatherConditionsForSectionResponse = await restClient.get(
    pathToUrl(withConfig("GET_WEATHER_CONDITIONS_FOR_SECTION"), { section_slug: sectionSlug })
  );

  return weatherConditionsForSectionResponse.data;
}