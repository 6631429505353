import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./VideoAd.style";
import { Link as MaterialLink } from "@material-ui/core";
import MediaControl from "../../common/Icons/MediaControl";
import ReactPlayer from "react-player";
import clsx from "clsx";
import labels from "../../config/labels";

const VideoAd = ({ videoAd }) => {
  const classes = useStyles();
  const [showVideo, setShowVideo] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const handleVideoPlayButton = () => {
    setShowVideo(true);
    setPlayVideo(true);
  };

  const handleVideoEnded = () => {
    setShowVideo(false);
    setPlayVideo(false);
  };

  const handleClick = () => {
    window.open(videoAd.video_url, "_blank", "noreferrer");
  };

  const coverStyle = {
    backgroundImage: `url(${videoAd.video_poster})`,
    backgroundRepeat: "no-repeat",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "300px",
    height: "250px",
  };

  return (
    <div className={classes.video_container} id={`video-ad-container`}>
      <div
        id={`video-ad-video-container`}
        className={
          showVideo ? classes.video_play_show : classes.video_play_hide
        }
      >
        <MaterialLink
          onPointerUp={handleClick}
          target="_blank"
          className={clsx(classes.learn_more_link)}
          id={`video-ad-lear-more`}
        >
          {labels.LEARN_MORE}
        </MaterialLink>

        <ReactPlayer
          url={videoAd.video_source}
          width={300}
          height={250}
          controls={true}
          className={clsx(classes.video_player)}
          onEnded={handleVideoEnded}
          playing={playVideo}
          playsinline={true}
          id={`video-ad-player`}
        />
      </div>
      {!showVideo && (
        <div style={coverStyle} id={`video-ad-link-container`}>
          <MaterialLink
            onClick={handleVideoPlayButton}
            target="_blank"
            className={clsx(
              classes.video_play_button,
              videoAd.dark_play_button
                ? classes.video_play_button_border_black
                : classes.video_play_button_border_white
            )}
            id={`video-ad-link`}
          >
            <MediaControl
              className={classes.media_control}
              fillColor={videoAd.dark_play_button ? "black" : "white"}
            />
          </MaterialLink>
        </div>
      )}
    </div>
  );
};

export default VideoAd;
