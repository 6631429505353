import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ButtonIcon.style";
import IconButton from "@material-ui/core/IconButton";

const ButtonIcon = ({
  className,
  children,
  onClick,
  ariaLabel,
  withDefaultStyles,
  props,
}) => {
  const classes = useStyles();
  return withDefaultStyles ? (
    <div className={classes.icon_button_container}>
      <IconButton
        className={className ? className : classes.close_button}
        onClick={onClick}
        aria-label={ariaLabel}
      >
        {children}
      </IconButton>
    </div>
  ) : (
    <IconButton {...props}>{children}</IconButton>
  );
};

ButtonIcon.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
  withDefaultStyles: PropTypes.bool,
  props: PropTypes.object,
};

ButtonIcon.defaultProps = {
  withDefaultStyles: true,
};

export default ButtonIcon;
