import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  nearby_towns: {
    color: theme.palette.light.gray,
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: theme.spacing(2),
    textDecoration: "none",
    marginLeft: theme.spacing(2),
    "&:hover": {
      color: theme.palette.themeColor.secondary,
    },
    "&:focus": {
      color: theme.palette.themeColor.secondary,
    },
  },
  nearby_towns_divider: {
    border: `1px solid ${theme.palette.themeColor.primary}`,
    borderRadius: theme.spacing(1),
    margin: "0px",
    marginTop: theme.spacing(0.8),
    marginBottom: theme.spacing(0.3),
    padding: "0px",
  },
}));

export { useStyles };
