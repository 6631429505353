import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./HorizontalLayout.style";
import VideoPlayer from "../../../VideoPlayer/VideoPlayer";
import { mediaUrlForCardLayout } from "../../../../utils/helpers/mediaHelper";
import Link from "../../../Link/Link";
import ContentTitle from "../../../../components/Town/LeftBlocks/Titles/ContentTile/ContentTitle";
import { getSponsorInfo } from "../../../../utils/helpers/homepageHelper";

const HorizontalLayout = ({
  media,
  header,
  information,
  actions,
  options,
  customClasses,
  isOneCard,
  block,
  sponsors,
}) => {
  const mediaPresent = media ? true : false;
  const actionsBelowInformation =
    options?.actions?.placement === "below information" ? true : false;
  const classes = useStyles();

  const Media = () => {
    return (
      <div
        className={clsx(
          classes.media_container,
          options?.media?.clickable &&
            !media.isVideo &&
            classes.media_container_clickable,
          customClasses?.media_container && customClasses?.media_container
        )}
      >
        {media &&
          (media.isVideo && options?.videoPlayer?.showPlayable ? (
            <VideoPlayer
              url={mediaUrlForCardLayout(options, media)}
              width={options?.videoPlayer?.width}
              showControls={true}
              height={options?.videoPlayer?.height}
            />
          ) : (
            <img
              src={mediaUrlForCardLayout(options, media)}
              alt={media.alt_text}
              className={clsx(
                classes.image,
                customClasses?.image && customClasses.image
              )}
            />
          ))}
      </div>
    );
  };

  return (
    <div
      className={clsx(
        !isOneCard
          ? classes.horizontal_layout
          : classes.horizontal_layout_single_card,
        customClasses?.root && customClasses?.root
      )}
    >
      <div
        className={clsx(
          !isOneCard ? classes.top_section : classes.top_section_single_card,
          !actionsBelowInformation && [
            classes.justify_content_to_start,
            classes.bottom_margin_16_px,
          ]
        )}
      >
        {header && !isOneCard && header}

        <div
          className={clsx(
            classes.media_and_information_container,
            customClasses?.media_and_information_container &&
              customClasses?.media_and_information_container
          )}
        >
          {media && options?.media?.clickable && !media.isVideo ? (
            <Link
              children={<Media />}
              url={options?.media?.townContentUrl}
              className={clsx(
                !isOneCard ? classes.media_link : classes.media_link_one_card,
                customClasses?.media_link && customClasses.media_link,
                options?.media?.opacityOnFocus && classes.opacity_on_focus
              )}
            />
          ) : (
            <Media />
          )}

          <div
            className={clsx(
              classes.information_container,
              !mediaPresent && classes.information_container_without_media,
              customClasses?.information_container &&
                customClasses?.information_container
            )}
          >
            {block?.title &&
              isOneCard &&
              !getSponsorInfo(sponsors, block.id) && (
                <>
                  <ContentTitle block={block} isOneCard={true} />
                </>
              )}
            {header && isOneCard && header}
            {information && information}

            {actions &&
              options?.actions?.placement === "below information" &&
              actions}
          </div>
        </div>
      </div>

      {actions && options?.actions?.placement === "bottom" && actions}
    </div>
  );
};

HorizontalLayout.propTypes = {
  media: PropTypes.shape({
    contentUrl: PropTypes.string,
    contentThumbUrl: PropTypes.string,
    contentCarouselUrl: PropTypes.string,
    credits: PropTypes.string,
    caption: PropTypes.string,
    alt_text: PropTypes.string,
    title: PropTypes.string,
    isVideo: PropTypes.bool,
  }),
  header: PropTypes.node,
  information: PropTypes.node.isRequired,
  actions: PropTypes.node,
  options: PropTypes.shape({
    videoPlayer: PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
    }),
    media: PropTypes.shape({
      version: PropTypes.oneOf(["carousel", "thumb"]),
      clickable: PropTypes.bool,
      townContentUrl: PropTypes.string,
    }),
    actions: PropTypes.shape({
      placement: PropTypes.oneOf(["below information", "bottom"]),
    }),
  }),
  customClasses: PropTypes.shape({
    root: PropTypes.string,
    media_container: PropTypes.string,
    media_and_information_container: PropTypes.string,
    information_container: PropTypes.string,
    media_link: PropTypes.string,
    image: PropTypes.string,
  }),
};

HorizontalLayout.defaultProps = {
  options: {
    videoPlayer: {
      width: "100%",
      height: "160px",
      showPlayable: true,
    },
    actions: {
      placement: "below information",
    },
    media: { clickable: false },
  },
};

export default HorizontalLayout;
