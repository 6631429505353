import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

const VideoPlayer = ({ url, width, height, showControls }) => {
  return (
    <>
      <ReactPlayer
        url={url}
        width={width}
        height={height}
        controls={showControls}
      />
    </>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  showControls: PropTypes.bool,
};

VideoPlayer.defaulProps = {
  width: "100%",
  showControls: true,
};

export default VideoPlayer;
