import React, { memo, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./Overall.style";
import { fetchOverallPageLinks } from "../../../services/masthead";
import { useCallback } from "react";
import Link from "../../../common/Link/Link";
import MasterheadLogo from "../MasterheadLogo";
import SubmitContentModal from "../../Modals/SubmitContentModal/SubmitContentModal";
import { handleModalActions } from "../../../utils/helper";
import HamburgerMenu from "../../HamburgerMenu/HamburgerMenu";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import OverallButtons from "../OverallButtons/OverallButtons";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import LogInPopUp from "../../LogInPopUp/LogInPopUp";
import { loadTownsWithClusters } from "../../../services/townService";
import MenuButton from "../../../common/MenuButton/MenuButton";
import theme from "../../../common/Theme/themes/default";
import values from "../../../common/Theme/themes/breakpoints/values";
import { useMasthead } from "../../../hooks/useMasthead";
import Grid from "../../../common/Grid/Grid";

const OverallMasterhead = ({ isPathUserSignIn, isIframe }) => {
  const classes = useStyles();
  const {
    palette: {
      themeColor: { primary },
    },
  } = theme;
  const [links, setLinks] = useState();
  const [townsWithClusters, setTownsWithClusters] = useState([]);
  const [openSubmitContentPopUp, setOpenSubmitContentPopUp] = useState(false);
  const [openLoginPopUp, setOpenLoginPopUp] = useState(false);
  const isMobile = screenWidthIsLowerThan(values.md);
  const { setIsOverall, sticky, openHamburgerMenu, handleOpenMenu } =
    useMasthead();

  useEffect(() => {
    setIsOverall(true);
    const intervalId = setTimeout(() => {
      setOpenLoginPopUp(isPathUserSignIn);
    }, 800);

    return () => {
      setOpenLoginPopUp(false);
      clearInterval(intervalId);
      setIsOverall(false);
    };
  }, []);

  const fetchData = useCallback(
    async (type) => {
      switch (type) {
        case "towns":
          const townsWithClustersResponse = await loadTownsWithClusters();
          if (townsWithClustersResponse?.status === 200) {
            setTownsWithClusters(
              townsWithClustersResponse?.data?.towns_with_clusters
            );
          }

          break;
        case "links":
          const overallPageLinksResponse = await fetchOverallPageLinks();
          if (overallPageLinksResponse?.status === 200) {
            setLinks({
              navLinks: overallPageLinksResponse?.data?.nav_links,
              signUpLink:
                overallPageLinksResponse?.data?.actions.newsletter_signup,
            });
          }
          break;
        default:
          break;
      }
    },
    [links, townsWithClusters]
  );

  useEffect(() => {
    if (links === undefined) {
      fetchData("links");
    }

    if (townsWithClusters.length === 0) {
      fetchData("towns");
    }
  }, [links, townsWithClusters]);

  const gridWidth = useMemo(() => links?.navLinks?.length / 6 || 1, [links]);

  const toggleMenu = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    handleOpenMenu(!openHamburgerMenu);
  };

  const handleOpenSubmitContenDialog = (state) => {
    setOpenSubmitContentPopUp(state);
  };

  const Login = () => {
    return (
      <PopUpDialog
        onClose={() => setOpenLoginPopUp(false)}
        openState={openLoginPopUp}
        title={""}
        content={
          <LogInPopUp
            townsWithClusters={townsWithClusters || []}
            isPathUserSignIn={isPathUserSignIn}
          />
        }
        closeButtonColor={primary}
        titleClass={classes.login_dialog_title}
        closeButtonClass={classes.login_close_button}
        className={classes.login_popup}
        disableCloseOnBackDropClick={true}
      />
    );
  };

  if (isMobile) {
    return (
      <div
        className={clsx(
          classes.masthead_container,
          isIframe && classes.masthead_container_iframe
        )}
      >
        <MenuButton toggleMenu={toggleMenu} />
        <HamburgerMenu
          openState={openHamburgerMenu}
          toggleMenu={toggleMenu}
          submitContentPaths={links?.submitContentLinks}
          openSubmitContentDialog={openSubmitContentPopUp}
          handleOpenSubmitContenDialog={handleOpenSubmitContenDialog}
          overallPage={true}
          isPathUserSignIn={isPathUserSignIn}
        />

        <MasterheadLogo
          forOverallPage={true}
          overallLogoClasses={{
            container: classes.logo_container,
            slogan: classes.logo_slogan,
          }}
        />

        <Login />
      </div>
    );
  }

  return (
    <Grid
      container
      justifyContent="space-evenly"
      className={clsx(
        classes.masthead_container,
        sticky && classes.reduced_height
      )}
    >
      <Grid item xs={2} className={classes.grid_item}>
        <MasterheadLogo
          forOverallPage={true}
          overallLogoClasses={{
            container: classes.logo_container,
            slogan: sticky ? classes.reduced_logo_slogan : classes.logo_slogan,
            tapinto: sticky
              ? classes.reduced_logo_tapinto
              : classes.logo_tapinto,
          }}
        />
      </Grid>

      {links?.navLinks?.map((link, index) => (
        <Grid item key={index} xs={gridWidth} className={classes.grid_item}>
          <Link
            color="white"
            level="t3_text_3"
            url={link.path}
            children={link.name}
            className={classes.link}
            target="_top"
          />
        </Grid>
      ))}

      <Grid item xs={2} className={classes.grid_item}>
        <OverallButtons
          signUpLink={links?.signUpLink}
          handleSubmitContentClick={() =>
            handleModalActions({
              type: "submitContent",
              action: "open",
              setOpenModal: setOpenSubmitContentPopUp,
            })
          }
        />
      </Grid>

      <SubmitContentModal
        handleClose={() =>
          handleModalActions({
            type: "submitContent",
            action: "close",
            setOpenModal: setOpenSubmitContentPopUp,
          })
        }
        openState={openSubmitContentPopUp}
        town={{ id: null }}
      />

      <Login />
    </Grid>
  );
};

OverallMasterhead.propTypes = {
  isPathUserSignIn: PropTypes.bool,
  isIframe: PropTypes.bool,
};

export default memo(OverallMasterhead);
