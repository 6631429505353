import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values },
  } = theme;
  return {
    main_container_layout_5: {
      width: "100% !important",
      margin: "0px !important",
      backgroundColor: "white",
      padding: spacing(5),
      height: "680px",
      [below(1025)]: {
        flexDirection: "column",
        padding: spacing(2),
        height: "fit-content",
      },
      [below(values.sm)]: {
        padding: 0,
        rowGap: spacing(4),
      },
    },
    top_stories_sidebar: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "column",
      [below(1025)]: {
        flexDirection: "row",
        paddingTop: spacing(3),
        justifyContent: "center",
      },
      [below(values.sm)]: {
        flexDirection: "column",
      },
    },
    subscription_box_container: {
      maxWidth: "320px",
    },
    main_story: {
      height: "100%",
    },
  };
});

export { useStyles };
