import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  content: {
    width: theme.spacing(37.5),
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    [theme.breakpoints.down(400)]: {
      width: theme.spacing(36.25),
    },
  },
  logo: {
    fontSize: "26px",
    lineHeight: "26px",
  },
  button: {
    width: "100%",
    height: theme.spacing(5),
    marginTop: `${theme.spacing(3)}px !important`,
    "& .MuiButton-label": {
      fontSize: "20px !important",
    },
  },
  textfield: {
    marginTop: `${theme.spacing(1)}px !important`,
    "& input.MuiInputBase-input": {
      textAlign: "center",
    },
  },
  back_to_homepage_header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  reduced_opacity: {
    opacity: "0.7",
  },
  divider: {
    width: "40%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: `1px solid ${theme.palette.light.lightGray}`,
  },
  back_to_homepage_button: {
    border: `1px solid ${theme.palette.light.gray} !important`,
    "&.MuiButtonBase-root": {
      textTransform: "none",
    },
  },
  login_loading_overlay: {
    background: "rgba(255, 255, 255, 0.6) !important",
  },
  primary_color: {
    color: theme.palette.themeColor.primary,
  },
}));

export { useStyles };
