import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      backgroundColor: `${theme.palette.themeColor.primary} !important`,
      color: "white !important",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, 50%)",
      objectFit: "fill",
      height: "4vh",
      width: "50vh",
    },

    simple_button: {
      color: "white !important",
      objectFit: "fill",
    },
    cms_button: {
      color: "white !important",
      objectFit: "fill",
    },
    legal_notice_submit_button: {
      color: "white !important",
      width: "4wh",
      backgroundColor: `${theme.palette.themeColor.primary} !important`,
      textTransform: "none !important",
    },
    common: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    mont_light: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 200,
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    mont_italic: {
      fontFamily: "Montserrat",
      fontStyle: "italic",
      fontWeight: 200,
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    mont_medium: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },

    mont_bold: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    h1: {
      fontWeight: 800,
      fontSize: "48px",
      lineHeight: "1.1",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "38px",
      lineHeight: "1.1",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "28px",
      lineHeight: "1.1",
    },
    h4: {
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "1.45",
    },
    body: {
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "1.45",
    },
    large: {
      fontWeight: "normal",
      fontSize: "60px",
      lineHeight: "1.45",
    },
    small: {
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "1.45",
    },
    tiny: {
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "1.6",
    },
    underlined: {
      textDecoration: "underline",
    },
    font_size_9_px: {
      fontSize: "9px",
    },
    font_size_10_px: {
      fontSize: "10px !important",
    },
    font_size_11_px: {
      fontSize: "11px",
    },
    font_size_12_px: {
      fontSize: "12px",
    },
    font_size_16_px: {
      fontSize: "16px",
    },
    font_size_15_px: {
      fontSize: "15px",
    },
    font_size_17_px: {
      fontSize: "17px",
    },
    font_size_26_px: {
      fontSize: "26px",
    },
    font_size_28_px: {
      fontSize: "28px",
    },
    font_size_35_px: {
      fontSize: "35px",
    },
  };
});

export { useStyles };
