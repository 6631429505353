import React from "react";
import PropTypes from "prop-types";

const Trashcan = ({ className, fillColor, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.04602 12.8398C1.04602 13.4094 1.50787 13.8558 2.0775 13.8558H9.02078C9.5904 13.8558 10.0523 13.4094 10.0523 12.8398L10.4525 4.46472H0.661133L1.04602 12.8398ZM7.38888 6.14281C7.38888 5.8503 7.55822 5.61937 7.85074 5.61937C8.14325 5.61937 8.31259 5.8503 8.31259 6.14281V11.9314C8.31259 12.2239 8.14325 12.4549 7.85074 12.4549C7.55822 12.4549 7.38888 12.2239 7.38888 11.9314V6.14281ZM5.07958 6.14281C5.07958 5.8503 5.24893 5.61937 5.54144 5.61937C5.83395 5.61937 6.0033 5.8503 6.0033 6.14281V11.9314C6.0033 12.2239 5.83395 12.4549 5.54144 12.4549C5.24893 12.4549 5.07958 12.2239 5.07958 11.9314V6.14281ZM2.77029 6.14281C2.77029 5.8503 2.93964 5.61937 3.23215 5.61937C3.52466 5.61937 3.69401 5.8503 3.69401 6.14281V11.9314C3.69401 12.2239 3.52466 12.4549 3.23215 12.4549C2.93964 12.4549 2.77029 12.2239 2.77029 11.9314V6.14281Z"
        fill={fillColor}
      />
      <path
        d="M1.04602 12.8398C1.04602 13.4094 1.50787 13.8558 2.0775 13.8558H9.02078C9.5904 13.8558 10.0523 13.4094 10.0523 12.8398L10.4525 4.46472H0.661133L1.04602 12.8398ZM7.38888 6.14281C7.38888 5.8503 7.55822 5.61937 7.85074 5.61937C8.14325 5.61937 8.31259 5.8503 8.31259 6.14281V11.9314C8.31259 12.2239 8.14325 12.4549 7.85074 12.4549C7.55822 12.4549 7.38888 12.2239 7.38888 11.9314V6.14281ZM5.07958 6.14281C5.07958 5.8503 5.24893 5.61937 5.54144 5.61937C5.83395 5.61937 6.0033 5.8503 6.0033 6.14281V11.9314C6.0033 12.2239 5.83395 12.4549 5.54144 12.4549C5.24893 12.4549 5.07958 12.2239 5.07958 11.9314V6.14281ZM2.77029 6.14281C2.77029 5.8503 2.93964 5.61937 3.23215 5.61937C3.52466 5.61937 3.69401 5.8503 3.69401 6.14281V11.9314C3.69401 12.2239 3.52466 12.4549 3.23215 12.4549C2.93964 12.4549 2.77029 12.2239 2.77029 11.9314V6.14281Z"
        fill={fillColor}
      />
      <path
        d="M10.6381 1.84744H7.8516V0.477254C7.8516 0.200139 7.71304 0 7.42053 0H3.69487C3.40236 0 3.23301 0.200139 3.23301 0.477254V1.84744H0.477254C0.184743 1.84744 0 2.06297 0 2.35548V2.9405C0 3.23301 0.184743 3.54092 0.477254 3.54092H10.6535C10.946 3.54092 11.1 3.21762 11.1 2.9405V2.35548C11.0846 2.06297 10.9307 1.84744 10.6381 1.84744ZM6.92788 1.84744H4.15673V0.923718H6.92788V1.84744Z"
        fill={fillColor}
      />
      <path
        d="M10.6381 1.84744H7.8516V0.477254C7.8516 0.200139 7.71304 0 7.42053 0H3.69487C3.40236 0 3.23301 0.200139 3.23301 0.477254V1.84744H0.477254C0.184743 1.84744 0 2.06297 0 2.35548V2.9405C0 3.23301 0.184743 3.54092 0.477254 3.54092H10.6535C10.946 3.54092 11.1 3.21762 11.1 2.9405V2.35548C11.0846 2.06297 10.9307 1.84744 10.6381 1.84744ZM6.92788 1.84744H4.15673V0.923718H6.92788V1.84744Z"
        fill="url(#paint1_linear_3860_2078)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3860_2078"
          x1="5.55684"
          y1="4.46472"
          x2="5.55684"
          y2="13.8558"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillColor} />
          <stop offset="1" stopColor={fillColor} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3860_2078"
          x1="5.55"
          y1="0"
          x2="5.55"
          y2="3.54092"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fillColor} />
          <stop offset="1" stopColor={fillColor} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

Trashcan.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Trashcan.defaultProps = {
  fillColor: "white",
  width: 12,
  height: 14,
};

export default Trashcan;
