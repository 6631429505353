import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    breakpoints: { down: below, values: values },
  } = theme;
  return {
    head_container: {
      [below(values.sm)]: {
        backgroundColor: "black",
        marginBottom: "0px !important",
      },
    },
    no_printing: {
      "@media print": {
        display: "none",
      },
    },
  };
});

export { useStyles };
