import React from "react";
import PropTypes from "prop-types";

const CheckCircle = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1543 31.5L27.1158 43.5577L53.4235 13.9615"
        stroke={fillColor}
        strokeWidth="6"
        strokeLinecap="round"
      />
      <path
        d="M45.75 6.81279C41.558 4.38787 36.6911 3 31.5 3C15.7599 3 3 15.7599 3 31.5C3 47.2401 15.7599 60 31.5 60C47.2401 60 60 47.2401 60 31.5C60 28.8416 59.636 26.2682 58.9552 23.8269"
        stroke={fillColor}
        strokeWidth="6"
        strokeLinecap="round"
      />
    </svg>
  );
};

CheckCircle.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

CheckCircle.defaultProps = {
  fillColor: "#7FC035",
};

export default CheckCircle;
