import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { down: below, values: values },
  } = theme;

  return {
    secondary_stories_container: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      rowGap: "32px",
      flexWrap: "wrap",
      columnGap: "16px",
      [below(768)]: {
        display: "flex",
        justifyContent: "center",
      },
    },
    mobile_card: {
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
    },
    information_container: {
      paddingBlock: spacing(1),
      height: spacing(21.25),
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
    },
    overall_town_link: {
      paddingBottom: spacing(1),
    },
    secondary_card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      margin: 0,
      [below(values.xs)]: {
        width: "100%",
        marginLeft: "0px !important",
      },
    },
    lazy_load_cards_container: {
      width: "50% !important",
      [below(values.xs)]: {
        display: "none",
      },
    },
    lazy_load_card_content: {
      backgroundColor: "#f1f1f1 !important",
      height: `${spacing(31.25)}px !important`,
    },
    lazy_load_cards: {
      width: "49% !important",
      boxShadow: "none !important",
    },
    mobile_divider: {
      border: `1px solid ${palette.miscellaneous.grayDivider}`,
      margin: `${spacing(2)}px 0px`,
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
      width: "auto",
      height: "auto",
      verticalAlign: "top",
      [below(values.xs)]: {
        maxHeight: spacing(25),
      },
    },
    name: {
      display: "block",
      alignSelf: "center",
      width: "100%",
      "& h5": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "5",
        WebkitBoxOrient: "vertical",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    caption_and_credits_container: {
      margin: "0 !important",
    },
    media_container: {
      backgroundColor: "rgba(206, 198, 194, 0.3)",
      height: spacing(28.62),
    },
    video_container_with_border: {
      border: `1px solid ${palette.dark.black}`,
      padding: spacing(1),
      marginLeft: spacing(3),
      [below(values.xs)]: {
        marginBottom: spacing(1),
        marginLeft: 0,
      },
    },
    video_ad_container: {
      width: spacing(37.5),
      height: spacing(31.25),
      [below(values.xs)]: {
        height: spacing(37.5),
        margin: "0 auto",
        "& #videoContainer #videoContent": {
          marginTop: spacing(2.5),
        },
      },
    },
    video_ad_card: {
      height: "fit-content",
      margin: "auto",
    },
    secondary_story_top_section: {
      display: "flex",
      justifyContent: "space-between",
      height: "100%",
      flexDirection: "column",
    },
    ad_in_stories: {
      marginTop: spacing(2),
      [below(values.md)]: {
        width: "50%",
        margin: "auto",
      },
    },
    card_subheader: {
      textTransform: "uppercase",
      "& h4": {
        fontWeight: 700 + " !important",
        color: theme.palette.themeColor.primary + " !important",
        marginBottom: spacing(0.5),
      },
      "& :hover": {
        textDecoration: "underline",
        textUnderlineOffset: `${spacing(0.625)}px`,
        textDecorationThickness: "2px",
      },
    },
  };
});

export { useStyles };
