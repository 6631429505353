import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      textTransform: "none !important",
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        color: theme.palette.miscellaneous.white,
        fontWeight: 600,
      },
    },
    add_more_towns_button: {
      paddingLeft: "0px !important",
      "& .MuiButton-label": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        color: theme.palette.themeColor.primary,
        fontWeight: 600,
      },
    },
    chip: {
      backgroundColor: `${theme.palette.themeColor.primary} !important`,
      margin: theme.spacing(1),
      maxWidth: 250,
      borderRadius: "50px !important",
      "& span": {
        color: `${theme.palette.dark.black} !important`,
      },
    },
    tooltip_button: {
      width: "fit-content",
    },
    tooltip_message: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "16px",
    },
    close_icon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    close_icon_button: {
      float: "right",
      minWidth: "auto !important",
      "& .MuiButton-label": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        color: theme.palette.themeColor.primary,
        fontWeight: 600,
      },
    },
  };
});

export { useStyles };
