import React from "react";
import PropTypes from "prop-types";

const Search = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.7461 49.0566H52.223L50.9743 47.8525C55.3448 42.7684 57.976 36.1681 57.976 28.988C57.976 12.9777 44.9983 0 28.988 0C12.9777 0 0 12.9777 0 28.988C0 44.9983 12.9777 57.976 28.988 57.976C36.1681 57.976 42.7684 55.3448 47.8525 50.9743L49.0566 52.223V55.7461L71.3551 78L78 71.3551L55.7461 49.0566ZM28.988 49.0566C17.8834 49.0566 8.91938 40.0926 8.91938 28.988C8.91938 17.8834 17.8834 8.91938 28.988 8.91938C40.0926 8.91938 49.0566 17.8834 49.0566 28.988C49.0566 40.0926 40.0926 49.0566 28.988 49.0566Z"
        fill={fillColor}
      />
    </svg>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

Search.defaultProps = {
  fillColor: "white",
};

export default Search;
