import ReactOnRails from "react-on-rails";
import { initializeGA } from "../src/utils/googleAnalytics";
import ExitIntentPopup from "../src/components/ExitIntentPopup/ExitIntentPopup";
import Ad from "../src/components/Ad/Ad";
import TownLayout from "../src/Layouts/Towns/TownLayout";
import Footer from "../src/components/Footer/Footer";

initializeGA();

ReactOnRails.register({
  Ad,
  ExitIntentPopup,
  TownLayout,
  Footer,
});
