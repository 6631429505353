import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./SocialNetworkShareButtons.style";
import { InlineShareButtons } from "sharethis-reactjs";
import { buildValidUrlShareContent } from "../../utils/helper";

const SocialNetworkShareButtons = ({
  alignment,
  socialNetworks,
  url,
  title,
  iconsSize,
  paddingWithinButtons,
  containerWidth,
  labelAction,
  disabled,
  radius,
  spacing,
  defaultContainerClass,
}) => {
  const classes = useStyles(containerWidth);
  const [showShareThis, setShowShareThis] = useState(false);
  const [shareThisConfig, setShareThisConfig] = useState({});

  const buildConfig = () => {
    return {
      alignment: alignment, // alignment of buttons (left, right)
      color: "social", // set the color of buttons (social, white)
      enabled: disabled ? false : true, // show/hide buttons (true, false)
      font_size: 16, // font size for the buttons
      hide_desktop: false, // hide buttons on desktop (true, false)
      labels: labelAction, // button labels (cta, counts, null)
      language: "en", // which language to use (see LANGUAGES)
      min_count: 0, // hide react counts less than min_count (INTEGER)
      networks: socialNetworks,
      padding: paddingWithinButtons, // padding within buttons (INTEGER)
      radius: radius, // the corner radius on each button (INTEGER)
      size: iconsSize, // the size of each button (INTEGER)
      show_total: false,
      url: buildValidUrlShareContent(url),
      title,
      spacing,
    };
  };

  useEffect(() => {
    setShareThisConfig(buildConfig());
    setShowShareThis(true);
  }, [alignment]);

  return (
    <div
      className={
        defaultContainerClass ? classes.social_network_icons_container : ""
      }
    >
      {showShareThis && <InlineShareButtons config={shareThisConfig} />}
    </div>
  );
};

SocialNetworkShareButtons.propTypes = {
  alignment: PropTypes.string,
  socialNetworks: PropTypes.arrayOf(PropTypes.string),
  iconsSize: PropTypes.number,
  paddingWithinButtons: PropTypes.number,
  containerWidth: PropTypes.number,
  labelAction: PropTypes.string,
  disabled: PropTypes.bool,
};

SocialNetworkShareButtons.defaultProps = {
  alignment: "left",
  socialNetworks: ["facebook", "twitter"],
  iconsSize: 40,
  paddingWithinButtons: 16,
  containerWidth: 120,
  labelAction: null,
  disabled: false,
  radius: 4,
  spacing: 8,
  defaultContainerClass: true,
};

export default SocialNetworkShareButtons;
