

const gamesLabels = {
    GAMES_TITLE: "GAMES",
    MOST_POPULAR_TITLE: "MOST POPULAR",
    SUDOKU_TITLE: "SUDOKU",
    MORE_GAMES_TITLE: "MORE GAMES",
    GAMES_PAGE_DESCRIPTION: "Test your skills and visit daily for fresh challenges.",
};

export default gamesLabels;