import React, { useEffect, useMemo, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import PropTypes from "prop-types";
import withConfig from "../../utils/withConfig";
import Typography from "../../common/Typography/Typography";
import { isValidEmail, redirectToPage } from "../../utils/helper";
import {
  AGREEMENT_CLAUSE,
  AGREEMENT_CLAUSE_ERROR,
  EMAIL,
  EMAIL_ERROR,
  NEW_PASSWORD,
  PASSWORD_CONFIRMATION,
  TOWNS,
  TOWNS_ERROR,
  PASSWORD_POPUP_NAME,
  NEW_ACCOUNT_POPUP_NAME,
  RESET_PASSWORD_POPUP_NAME,
  PASSWORD_RESET_INSTRUCTIONS_POPUP_NAME,
  MESSAGE_ERROR,
  AFTER_PASSWORD_CREATE_POPUP_NAME,
  STATUS_OK,
  PASSWORD_CONFIRMATION_ERROR,
  NEW_PASSWORD_ERROR,
  USER_CONFIRMATION_POPUP_NAME,
  CONFIRMATION_EMAIL_RE_SENT_POPUP_NAME,
  PASSWORD,
  PASSWORD_CONFIRMATION_FIELD_ID,
  SOMETHING_WENT_WRONG_ERROR,
  SOMETHING_WENT_WRONG_ERROR_MONGO_DB,
  RECAPTCHA_ERROR,
  PASSWORD_CONFIRMATION_CONTRIBUTOR,
} from "./logInConstants";
import AfterPasswordCreateContent from "./AfterPasswordCreateContent/AfterPasswordCreateContent";
import EmailContent from "./EmailContent/EmailContent";
import { useStyles } from "./LogInPopUp.style";
import NewAccountContent from "./NewAccountContent/NewAccountContent";
import PasswordContent from "./PasswordContent/PasswordContent";
import PasswordResetConfirmationContent from "./PasswordResetConfirmationContent/PasswordResetConfirmationContent";
import PasswordResetInstructionsContent from "./PasswordResetInstructionsContent/PasswordResetInstructionsContent";
import { validatePassword } from "../../services/passwordValidator";
import { getTownOption } from "../../services/townService";
import {
  sendResetPassword,
  findUser,
  upgradeToContributor,
  userSignIn,
  resendConfirmationEmail,
  createAccount,
} from "../../services/login";
import UserConfirmationContent from "./UserConfirmationContent/UserConfirmationContent";
import ConfirmationEmailReSentContent from "./ConfirmationEmailReSentContent/ConfirmationEmailReSentContent";

const LogInPopUp = ({
  townName,
  townsWithClusters,
  isPathUserSignIn,
  townSlug,
}) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [nextStepPopUpName, setNextStepPopUpName] = useState("");
  const [errors, setErrors] = useState([]);
  const [password, setPassword] = useState("");
  const [customAfterSignUpMessage, setCustomAfterSignUpMessage] = useState();
  const [customMessage, setCustomMessage] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isEnewsCheckboxChecked, setIsEnewsCheckboxChecked] = useState(true);
  const [isAgreementClauseChecked, setIsAgreementClauseChecked] =
    useState(false);
  const [userIsSubscriber, setUserIsSubscriber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTowns, setSelectedTowns] = useState([]);
  const preselectedTown = useMemo(() => {
    return townsWithClusters?.find((town) => town.name === townName);
  }, [townName]);
  const [passwordFocus, setPasswordFocus] = useState("password");

  useEffect(() => {
    if (preselectedTown) {
      setSelectedTowns([getTownOption(preselectedTown)]);
    }

    return () => {
      setSelectedTowns([]);
    };
  }, [preselectedTown]);

  const handleEmailInput = async (emailValue, token) => {
    if (isValidEmail(emailValue)) {
      if (errors.length > 0) {
        setErrors([]);
      }

      const findUserResponse = await findUser(emailValue, token);

      if (findUserResponse && findUserResponse?.data?.recaptcha_fail) {
        setErrors([{ key: "", error: RECAPTCHA_ERROR }]);
      } else {
        if (findUserResponse && findUserResponse?.status === STATUS_OK) {
          if (errors.length > 0) {
            setErrors([]);
          }
          if (findUserResponse?.data?.mongo_user === false) {
            setErrors([
              { key: "", error: SOMETHING_WENT_WRONG_ERROR_MONGO_DB },
            ]);
          } else if (findUserResponse?.data?.confirmed === false) {
            setNextStepPopUpName(USER_CONFIRMATION_POPUP_NAME);
            setCustomMessage(findUserResponse?.data?.message);
          } else if (
            findUserResponse?.data?.user_exists &&
            findUserResponse?.data?.can_login &&
            findUserResponse?.data?.confirmed
          ) {
            setNextStepPopUpName(PASSWORD_POPUP_NAME);
          } else if (
            findUserResponse?.data?.user_exists &&
            findUserResponse?.data?.confirmed &&
            findUserResponse?.data?.can_login === false
          ) {
            setUserIsSubscriber(true);
            setSelectedTowns([]);
            setNextStepPopUpName(NEW_ACCOUNT_POPUP_NAME);
          } else {
            setNextStepPopUpName(NEW_ACCOUNT_POPUP_NAME);
          }
        } else {
          setErrors([{ key: "", error: SOMETHING_WENT_WRONG_ERROR }]);
        }
      }
    } else {
      setErrors([{ key: EMAIL, error: EMAIL_ERROR }]);
    }
  };

  const handleSignUp = async () => {
    setIsLoading(true);
    if (validatePassword(password) && validatePassword(passwordConfirmation)) {
      if (password === passwordConfirmation) {
        if (errors.length > 0) {
          setErrors([]);
        }
        const errorsArray = [];
        if (isEnewsCheckboxChecked && selectedTowns.length === 0) {
          errorsArray.push({
            key: TOWNS,
            error: TOWNS_ERROR,
          });
        }
        if (isAgreementClauseChecked && errorsArray.length === 0) {
          const subscribedTownIds = selectedTowns.map((town) => town.id);
          const signUpResponse = await createAccount(
            email,
            password,
            passwordConfirmation,
            subscribedTownIds
          );
          if (
            signUpResponse.status === STATUS_OK &&
            signUpResponse?.data?.create_account
          ) {
            if (signUpResponse?.data?.after_sign_up_message) {
              setCustomAfterSignUpMessage(
                signUpResponse?.data?.after_sign_up_message
              );
            }
            setIsLoading(false);
            setNextStepPopUpName(AFTER_PASSWORD_CREATE_POPUP_NAME);
          } else {
            setIsLoading(false);
            setNextStepPopUpName(MESSAGE_ERROR);
          }
        } else {
          setIsLoading(false);
          errorsArray.push({
            key: AGREEMENT_CLAUSE,
            error: AGREEMENT_CLAUSE_ERROR,
          });
        }
        setErrors(errorsArray);
      } else {
        setIsLoading(false);
        setErrors([
          {
            key: PASSWORD_CONFIRMATION,
            error: PASSWORD_CONFIRMATION_ERROR,
          },
        ]);
      }
    } else {
      setIsLoading(false);
      setErrors([{ key: NEW_PASSWORD, error: NEW_PASSWORD_ERROR }]);
    }
  };

  const handleUpgradeToContributor = async () => {
    if (validatePassword(password) && validatePassword(passwordConfirmation)) {
      if (password === passwordConfirmation) {
        if (errors.length > 0) {
          setErrors([]);
        }
        setIsLoading(true);

        const upgradeToContributorResponse = await upgradeToContributor(
          email,
          password,
          passwordConfirmation
        );

        if (
          upgradeToContributorResponse &&
          upgradeToContributorResponse?.status === STATUS_OK
        ) {
          setIsLoading(false);

          if (upgradeToContributorResponse?.data?.upgraded_to_contributor) {
            if (upgradeToContributorResponse?.data?.after_upgrade_message) {
              setCustomAfterSignUpMessage(
                upgradeToContributorResponse?.data?.after_upgrade_message
              );
            }
            setNextStepPopUpName(AFTER_PASSWORD_CREATE_POPUP_NAME);
          } else {
            if (upgradeToContributorResponse?.data?.errors?.length > 0) {
              const allErrors = upgradeToContributorResponse?.data?.errors?.map(
                (e) => {
                  return {
                    key: e.includes("Password confirmation")
                      ? PASSWORD_CONFIRMATION
                      : NEW_PASSWORD,
                    error: e,
                  };
                }
              );
              setErrors(allErrors);
            } else {
              setErrors([{ key: "", error: SOMETHING_WENT_WRONG_ERROR }]);
            }
          }
        } else {
          setIsLoading(false);
          setErrors([{ key: "", error: SOMETHING_WENT_WRONG_ERROR }]);
        }
      } else {
        setIsLoading(false);
        setErrors([
          {
            key: PASSWORD_CONFIRMATION,
            error: PASSWORD_CONFIRMATION_ERROR,
          },
        ]);
      }
    } else {
      setErrors([{ key: NEW_PASSWORD, error: NEW_PASSWORD_ERROR }]);
    }
  };

  const handleLogIn = async () => {
    if (errors.length > 0) {
      setErrors([]);
    }
    setIsLoading(true);
    const userSignInResponse = await userSignIn(
      email,
      password,
      rememberMe,
      townSlug
    );
    if (userSignInResponse && userSignInResponse?.status === STATUS_OK) {
      if (
        userSignInResponse?.data?.logged_in &&
        userSignInResponse?.data?.redirect_to
      ) {
        redirectToPage(userSignInResponse.data.redirect_to, "_top");
      } else {
        setIsLoading(false);
        setErrors([
          { key: PASSWORD, error: userSignInResponse?.data?.messages },
        ]);
      }
    } else {
      setIsLoading(false);
      setErrors([{ key: "", error: SOMETHING_WENT_WRONG_ERROR }]);
    }
  };

  const showErrors = (errorsArray) => {
    return (
      <>
        {errorsArray.length > 0 && (
          <div className={classes.errors_container}>
            {errorsArray.map((error, index) => (
              <div key={index}>
                <Typography level="t1_text_1" color="red">
                  {error.error}
                </Typography>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  const shouldMarkError = (propName) => {
    let validation = false;
    if (errors.length > 0) {
      validation = errors.find((error) => error.key === propName)
        ? true
        : false;
    }
    return validation;
  };

  const handleOpenResetPasswordPopUp = () => {
    setNextStepPopUpName(RESET_PASSWORD_POPUP_NAME);
  };

  const handlePasswordChange = (e) => {
    setPasswordFocus("password");
    setPassword(e.target.value);
  };

  const handleGoBackToEnterPassword = () => {
    setNextStepPopUpName(PASSWORD_POPUP_NAME);
  };

  const handleResetPassword = async () => {
    setIsLoading(true);
    const sentEmailResponse = await sendResetPassword(email);
    if (
      sentEmailResponse.status === STATUS_OK &&
      sentEmailResponse?.data?.success
    ) {
      setIsLoading(false);
      setNextStepPopUpName(PASSWORD_RESET_INSTRUCTIONS_POPUP_NAME);
      setCustomMessage(sentEmailResponse?.data?.post_password_reset_message);
    } else {
      setIsLoading(false);
      setNextStepPopUpName(MESSAGE_ERROR);
    }
  };

  const handleAgreementClauseCheckboxChange = () => {
    setIsAgreementClauseChecked(!isAgreementClauseChecked);
  };

  const handleSubscriberTownsSelect = (towns) => {
    setSelectedTowns(towns);
  };

  const handleEnewsCheckboxChange = () => {
    setIsEnewsCheckboxChecked(!isEnewsCheckboxChecked);

    if (!isEnewsCheckboxChecked && townName === "") {
      setSelectedTowns([]);
    }

    if (townName) {
      if (isEnewsCheckboxChecked) {
        setSelectedTowns([]);
      } else {
        setSelectedTowns([getTownOption(preselectedTown)]);
      }
    }
  };

  const handlePasswordConfirmationChange = (e) => {
    setPasswordFocus("passwordConfirmation");
    setPasswordConfirmation(e.target.value);
  };

  const handleRememberMeCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleResendConfirmationEmail = async () => {
    if (errors.length > 0) {
      setErrors([]);
    }
    const resendconfirmationEmailResponse = await resendConfirmationEmail(
      email
    );
    if (
      resendconfirmationEmailResponse &&
      resendconfirmationEmailResponse?.status === STATUS_OK &&
      resendconfirmationEmailResponse?.data?.send_email
    ) {
      setNextStepPopUpName(CONFIRMATION_EMAIL_RE_SENT_POPUP_NAME);
      setCustomMessage(resendconfirmationEmailResponse?.data?.message);
    } else {
      setNextStepPopUpName(USER_CONFIRMATION_POPUP_NAME);
      setErrors([{ key: "", error: SOMETHING_WENT_WRONG_ERROR }]);
    }
  };

  const handleNextStepOnEnter = (key, type, recaptchaToken) => {
    if (key === "Enter" || key === "13") {
      switch (type) {
        case EMAIL:
          if (recaptchaToken && isValidEmail(email)) {
            handleEmailInput(email, recaptchaToken);
          }
          break;
        case PASSWORD:
          handleLogIn();
          break;
        case NEW_PASSWORD:
          document.getElementById(PASSWORD_CONFIRMATION_FIELD_ID).focus();
          break;
        case PASSWORD_CONFIRMATION:
          handleSignUp();
          break;
        case PASSWORD_CONFIRMATION_CONTRIBUTOR:
          handleUpgradeToContributor();
          break;
        default:
          break;
      }
    }
  };

  const PopUpContents = () => {
    switch (nextStepPopUpName) {
      case AFTER_PASSWORD_CREATE_POPUP_NAME:
        return (
          <AfterPasswordCreateContent
            customMessage={customAfterSignUpMessage}
          />
        );
      case NEW_ACCOUNT_POPUP_NAME:
        return (
          <NewAccountContent
            email={email}
            handlePasswordChange={handlePasswordChange}
            password={password}
            isEnewsCheckboxChecked={isEnewsCheckboxChecked}
            handleEnewsCheckboxChange={handleEnewsCheckboxChange}
            isAgreementClauseChecked={isAgreementClauseChecked}
            handleAgreementClauseCheckboxChange={
              handleAgreementClauseCheckboxChange
            }
            handleTownsSelection={handleSubscriberTownsSelect}
            passwordConfirmation={passwordConfirmation}
            handlePasswordConfirmationChange={handlePasswordConfirmationChange}
            handleSignUp={handleSignUp}
            showErrors={showErrors}
            errors={errors}
            shouldMarkError={shouldMarkError}
            townsWithClusters={townsWithClusters}
            selectedTowns={selectedTowns}
            userIsSubscriber={userIsSubscriber}
            handleUpgradeToContributor={handleUpgradeToContributor}
            isLoading={isLoading}
            handleNextStepOnEnter={handleNextStepOnEnter}
            activeFocus={passwordFocus}
          />
        );
      case PASSWORD_RESET_INSTRUCTIONS_POPUP_NAME:
        return (
          <PasswordResetInstructionsContent
            email={email}
            customMessage={customMessage}
          />
        );
      case MESSAGE_ERROR:
        return (
          <PasswordResetInstructionsContent
            email={email}
            error={true}
            customMessage={customMessage}
          />
        );
      case RESET_PASSWORD_POPUP_NAME:
        return (
          <PasswordResetConfirmationContent
            handleGoBackToEnterPassword={handleGoBackToEnterPassword}
            handleResetPassword={handleResetPassword}
            loadingPasswordResetRequest={isLoading}
          />
        );
      case PASSWORD_POPUP_NAME:
        return (
          <PasswordContent
            showErrors={showErrors}
            handleLogIn={handleLogIn}
            errors={errors}
            password={password}
            handlePasswordChange={handlePasswordChange}
            rememberMe={rememberMe}
            handleRememberMeCheckboxChange={handleRememberMeCheckboxChange}
            handleOpenResetPasswordPopUp={handleOpenResetPasswordPopUp}
            email={email}
            shouldMarkError={shouldMarkError}
            loadingUserSignIn={isLoading}
            handleNextStepOnEnter={handleNextStepOnEnter}
          />
        );
      case USER_CONFIRMATION_POPUP_NAME:
        return (
          <UserConfirmationContent
            handleResendConfirmationEmail={handleResendConfirmationEmail}
            errors={errors}
            customMessage={customMessage}
          />
        );
      case CONFIRMATION_EMAIL_RE_SENT_POPUP_NAME:
        return (
          <ConfirmationEmailReSentContent
            email={email}
            customMessage={customMessage}
          />
        );
      default:
        return (
          <GoogleReCaptchaProvider
            useRecaptchaNet
            reCaptchaKey={withConfig("RECAPTCHA_KEY")}
            scriptProps={{ async: true, defer: true, appendTo: "head" }}
          >
            <EmailContent
              shouldMarkError={shouldMarkError}
              setEmail={setEmail}
              showErrors={showErrors}
              handleEmailInput={handleEmailInput}
              errors={errors}
              defaultValue={email}
              handleNextStepOnEnter={handleNextStepOnEnter}
              isPathUserSignIn={isPathUserSignIn}
              townSlug={townSlug}
              redirectingToHomepage={setIsLoading}
              isRedirectingToHomepage={isLoading}
            />
          </GoogleReCaptchaProvider>
        );
    }
  };

  return <PopUpContents />;
};

LogInPopUp.propTypes = {
  townName: PropTypes.string,
  townsWithClusters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      name_with_zip_code: PropTypes.string,
      cluster_towns: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number,
          slug: PropTypes.string,
        })
      ),
    })
  ),
  isPathUserSignIn: PropTypes.bool,
  townSlug: PropTypes.string,
};

LogInPopUp.defaultProps = {
  townName: "",
};

export default LogInPopUp;
