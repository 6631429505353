export const adjustForUTCOffset = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const getClockTime = (date) => {
  if (!isValidDate(date)) {
    return date;
  }

  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();
  let ap = "AM";

  if (hour > 11) {
    ap = "PM";
  }
  if (hour > 12) {
    hour = hour - 12;
  }
  if (hour == 0) {
    hour = 12;
  }
  if (hour < 10) {
    hour = "0" + hour;
  }
  if (minute < 10) {
    minute = "0" + minute;
  }
  if (second < 10) {
    second = "0" + second;
  }
  const timeString = hour + ":" + minute + " " + ap;
  return timeString;
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const addMonths = (date, months) => {
  date.setMonth(date.getMonth() + months);

  return date;
};

export const formatDate = (date, format) => {
  if (!isValidDate(date)) {
    return date;
  }
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  return format
    .replace("MM", month < 10 ? `0${month}` : month)
    .replace("DD", day < 10 ? `0${day}` : day)
    .replace("YYYY", year);
}