
import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { down: below, values: values },
  } = theme;
  return {
    details_root: {
      justifyContent: "space-between",
      [below(values.md)]: {
        flexWrap: "wrap",
        padding: "0px !important",
      },
    },
    left_block_container: {
      marginRight: spacing(4),
      display: "flex",
      flexDirection: "column",
      gap: spacing(5),
      [below(values.md)]: {
        marginRight: 0,
        gap: spacing(4),
        justifyContent: "center",
      },
    },
    title_block_container: {
      borderBottom: `1px solid ${palette.grayVariation.grayForBorder}`,
      marginBottom: spacing(3),
    },
    title_ad_container: {
      marginBottom: spacing(3),
    },
    card_container: {
      boxShadow:
        "0px 1px 3px 0px rgba(0,0,0,0.12), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.2)",
      backgroundColor: theme.palette.miscellaneous.white,
      padding: `${spacing(6)}px ${spacing(4)}px`,
      paddingTop: `${spacing(2)}px !important`,
      [theme.breakpoints.down(768)]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    card_block: {
      padding: `${spacing(4)}px !important`,
      marginBlock: spacing(3),
      [below(values.xs)]: {
        padding: `${spacing(2)}px !important`,
      },
    },
    container:{
      boxShadow:
        "0px 1px 3px 0px rgba(0,0,0,0.12), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.2)",
      backgroundColor: theme.palette.miscellaneous.white,
    },
    title_container: {
      display: "none",
    },
  };
});

export { useStyles };
