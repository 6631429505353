export const HERE_REGEX = new RegExp(/\bhere\b/, "gi");

export const LETTERS_TO_EDITOR = "letters_to_editor";
export const CANDIDATE_STATEMENT = "candidate_statement";
export const COLUMN = "column";
export const POLICE_BLOTTER = "police_blotter";
export const PRESS_RELEASE = "press_release";

export const FRONT_END = "front_end";

export const POLICE_BLOTTER_REMOVAL_URL =
  "https://form.jotform.com/223385256474058";

export const TAPINTO_NET = "TAPinto.net";
export const CONTACT_EMAIL = "contact@tapinto.net";
export const TAPINTO_MARKETING = "TAPintoMarketing.net";
export const HERE = "here";
export const LINKS_TARGET = "_blank";
export const SELF_LINKS_TARGET = "_self";
