import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  dropdown_input: {
    "&.MuiAutocomplete-root": {
      backgroundColor: theme.palette.light.grey,
      borderRadius: theme.spacing(2),
      "& label.MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
        color: theme.palette.themeColor.primary,
      },
      "& label.MuiFormLabel-root.MuiInputLabel-root": {
        color: theme.palette.miscellaneous.white,
      },
      "& div.MuiInputBase-root": {
        "& input.MuiInputBase-input": {
          boxShadow: "none",
        },
        "& input.MuiAutocomplete-inputFocused": {
          border: "none",
          backgroundColor: theme.palette.light.grey,
        },
        "& fieldset.PrivateNotchedOutline-root-1.MuiOutlinedInput-notchedOutline":
          {
            border: "none",
          },
        "& fieldset.MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline":
          {
            border: "none",
          },
        "& fieldset.MuiOutlinedInput-root:hover.MuiOutlinedInput-notchedOutline":
          {
            border: "none",
          },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
    },
  },
  dropdown_towns_container: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0.5)}px ${theme.spacing(
      3
    )}px ${theme.spacing(0.5)}px`,
  },
  dropdown_town: {
    fontFamily: "Montserrat",
    fontSize: theme.spacing(2),
    fontWeight: "bold",
    backgroundColor: theme.palette.light.grey,
  },
  dropdown_cluster: {
    fontFamily: "Montserrat",
    fontSize: theme.spacing(2),
    fontWeight: "normal",
  },
  tapinto_nearby_container_closed: {
    height: 0,
    transition: "height .3s ease-out",
  },
  tapinto_nearby_container_open: {
    height: theme.spacing(50),
    padding: theme.spacing(1),
    transition: "height .3s ease-in",
  },
  nearby_towns: {
    color: theme.palette.light.gray,
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: theme.spacing(2),
    textDecoration: "none",
    marginLeft: theme.spacing(2),
    "&:hover": {
      color: theme.palette.light.gray,
    },
    "&:focus": {
      color: theme.palette.light.gray,
    },
  },
  nearby_towns_divider: {
    border: `1px solid ${theme.palette.themeColor.primary}`,
    borderRadius: theme.spacing(1),
    margin: "0px",
    marginTop: theme.spacing(0.8),
    marginBottom: theme.spacing(0.3),
    padding: "0px",
  },
  multiple_with_tags: {
    "&.MuiAutocomplete-root": {
      backgroundColor: theme.palette.miscellaneous.white,
      borderRadius: theme.spacing(2),
      width: "90%",
      margin: `auto auto ${theme.spacing(3)}px auto`,
      "& div.MuiInputBase-root": {
        paddingRight: "0px !important",
        "& div.MuiAutocomplete-endAdornment": {
          display: "none",
        },
        "& input.MuiInputBase-input": {
          boxShadow: "none",
        },
        "& input.MuiAutocomplete-inputFocused": {
          border: "none",
          borderRadius: theme.spacing(0.5),
          backgroundColor: theme.palette.miscellaneous.white,
        },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${theme.palette.light.grey}`,
        },
        "& fieldset.MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline":
          {
            border: `2px solid ${theme.palette.light.grey}`,
          },
        "& fieldset.MuiOutlinedInput-root:hover.MuiOutlinedInput-notchedOutline":
          {
            border: `2px solid ${theme.palette.light.grey}`,
          },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${theme.palette.light.grey}`,
        },
      },
    },
  },
  real_estate_towns_filter: {
    "&.MuiAutocomplete-root": {
      backgroundColor: theme.palette.miscellaneous.white,
      borderRadius: theme.spacing(2),
      "& div.MuiInputBase-root": {
        paddingRight: "0px !important",
        "& div.MuiAutocomplete-endAdornment": {
          display: "none",
        },
        "& input.MuiInputBase-input": {
          boxShadow: "none",
        },
        "& input.MuiAutocomplete-inputFocused": {
          border: "none",
          borderRadius: theme.spacing(0.5),
          backgroundColor: theme.palette.miscellaneous.white,
        },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${theme.palette.light.grey}`,
        },
        "& fieldset.MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline":
          {
            border: `2px solid ${theme.palette.light.grey}`,
          },
        "& fieldset.MuiOutlinedInput-root:hover.MuiOutlinedInput-notchedOutline":
          {
            border: `2px solid ${theme.palette.light.grey}`,
          },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          border: `2px solid ${theme.palette.light.grey}`,
        },
      },
    },
  },
  button: {
    textTransform: "none !important",
  },
  button_label: {
    fontSize: "16px",
    fontFamily: "Montserrat, sans-serif",
    color: theme.palette.themeColor.primary,
    fontWeight: 500,
  },
}));

export { useStyles };
