import React from "react";
import PropTypes from "prop-types";

const TriangleArrowForward = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="9"
      height="17"
      viewBox="0 0 9 17"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.666668 0L9 8.33333L0.666668 16.6667V0Z" fill={fillColor} />
    </svg>
  );
};
TriangleArrowForward.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

TriangleArrowForward.defaultProps = {
  fillColor: "#F98C10",
};

export default TriangleArrowForward;
