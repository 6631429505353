import React from "react";

export const MostlyCloudyShowers = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="32"
      viewBox="0 0 47 32"
      fill="none"
      className={className}
    >
      <path
        d="M25.5327 9.43158C25.4653 9.43158 25.4653 9.43158 25.5327 9.43158C25.3306 9.43158 25.1959 9.36421 25.0611 9.22948L21.3559 4.98527C21.1538 4.71579 21.1538 4.37895 21.4232 4.17684C21.558 4.04211 21.6927 4.04211 21.8274 4.04211C21.9622 4.04211 22.0969 4.10948 22.2317 4.24421L25.9369 8.48843C26.139 8.7579 26.139 9.09474 25.8695 9.29685C25.8022 9.36421 25.6674 9.43158 25.5327 9.43158Z"
        fill="white"
      />
      <path
        d="M31.4609 6.80421C31.1241 6.80421 30.8546 6.53474 30.8546 6.1979V0.606316C30.8546 0.269474 31.1241 0 31.4609 0C31.7978 0 32.0673 0.269474 32.0673 0.606316L32.1346 6.26526C32.1346 6.4 32.0672 6.60211 31.9325 6.66948C31.7978 6.73684 31.663 6.80421 31.4609 6.80421Z"
        fill="white"
      />
      <path
        d="M44.1263 22.5684C44.0589 22.5684 43.9242 22.5684 43.8568 22.5011L38.8715 19.8737C38.7368 19.8063 38.6021 19.6716 38.6021 19.5368C38.5347 19.4021 38.6021 19.2 38.6694 19.0653C38.7368 18.9305 38.8715 18.7958 39.0063 18.7958C39.141 18.7284 39.3431 18.7958 39.4779 18.8632L44.4631 21.4905C44.5979 21.5579 44.7326 21.6926 44.7326 21.8274C44.8 21.9621 44.7326 22.1642 44.6652 22.299C44.5305 22.5011 44.3284 22.5684 44.1263 22.5684Z"
        fill="white"
      />
      <path
        d="M39.9495 13.8105C39.8148 13.8105 39.68 13.7432 39.6127 13.6758C39.4779 13.6084 39.4106 13.4063 39.4106 13.2716C39.4106 13.1368 39.4106 12.9347 39.5453 12.8674C39.6127 12.7326 39.8148 12.6653 39.9495 12.6653L45.5411 11.9916C45.6758 11.9916 45.8779 11.9916 46.0127 12.1263C46.1474 12.1937 46.2148 12.3958 46.2148 12.5305C46.2821 12.8674 46.0127 13.1368 45.6758 13.2042L40.0842 13.8779C40.0169 13.8105 39.9495 13.8105 39.9495 13.8105Z"
        fill="white"
      />
      <path
        d="M35.9073 28.5642C35.6378 28.5642 35.4357 28.4295 35.3683 28.16L33.482 22.8379C33.4147 22.7032 33.4147 22.5011 33.482 22.3663C33.5494 22.2316 33.6841 22.0969 33.8189 22.0969C33.9536 22.0295 34.1557 22.0295 34.2904 22.0969C34.4252 22.1642 34.5599 22.299 34.5599 22.4337L36.4462 27.7558C36.5136 27.8905 36.5136 28.0926 36.4462 28.2274C36.3789 28.3621 36.2441 28.4969 36.1094 28.5642C35.9747 28.4969 35.9073 28.5642 35.9073 28.5642Z"
        fill="white"
      />
      <path
        d="M36.8505 8.96C36.7158 8.96 36.5811 8.89264 36.5137 8.82527C36.2442 8.62316 36.1768 8.21895 36.3789 8.01684L39.8821 3.57053C39.9495 3.43579 40.1516 3.36842 40.2863 3.36842C40.4211 3.36842 40.6231 3.36842 40.6905 3.50316C40.8252 3.57053 40.8926 3.77263 40.8926 3.90737C40.8926 4.04211 40.8926 4.24421 40.7579 4.37895L37.2547 8.82527C37.1874 8.89264 37.0526 8.96 36.8505 8.96Z"
        fill="white"
      />
      <path
        d="M31.4609 7.61265C27.6883 7.61265 24.522 10.5769 24.3199 14.3495C24.4546 14.3495 24.5894 14.3495 24.6567 14.3495C24.7241 14.3495 24.8588 14.3495 24.9262 14.3495C25.1283 14.3495 25.3304 14.4169 25.5325 14.4169C25.7346 11.3179 28.2946 8.89265 31.4609 8.89265C34.762 8.89265 37.3894 11.52 37.3894 14.8211C37.3894 18.1221 34.762 20.7495 31.4609 20.7495H31.3936C31.7978 21.0863 32.1346 21.4905 32.4041 21.9621C35.9746 21.4905 38.6693 18.5263 38.6693 14.8211C38.6693 10.8463 35.4357 7.61265 31.4609 7.61265Z"
        fill="white"
      />
      <path
        d="M30.1811 19.941C30.1811 19.8063 30.1811 19.6716 30.1811 19.5368C30.2484 16.8421 28.16 14.5516 25.4653 14.3495C24.32 14.2821 23.2421 14.5516 22.3663 15.0905C21.8948 12.1937 19.2 9.97052 16.0337 10.1726C13.0695 10.3074 10.7116 12.5305 10.3074 15.2926C9.76843 15.0905 9.22948 15.0231 8.62316 15.0231C5.79369 15.0231 3.50317 17.3137 3.50317 20.1432C3.50317 20.2105 3.50317 20.2779 3.50317 20.3453C2.08843 20.7495 0 21.5579 0 24.5221C0 26.6105 1.68422 28.4295 3.97474 28.6316C4.17685 28.6316 4.31158 28.5642 4.44632 28.4295L4.9179 27.7558C4.98527 27.6884 4.9179 27.5537 4.78316 27.5537H4.58106C2.69474 27.5537 1.21263 26.2063 1.21263 24.5221C1.21263 22.5684 2.29053 21.8947 4.31159 21.4232L4.78316 21.221V20.48C4.78316 20.3453 4.71579 20.2779 4.71579 20.1432C4.71579 17.9874 6.46737 16.2358 8.62316 16.2358C9.29685 16.2358 9.90317 16.4379 10.5095 16.7747L11.3853 17.3137V16.3032C11.3179 13.7431 13.4063 11.5874 16.0337 11.4526H16.3032C18.9305 11.4526 21.019 13.4063 21.1537 15.9663C21.1537 16.0337 21.1537 16.1684 21.1537 16.3705L21.0863 17.92L22.1642 16.8421C22.9726 16.0337 23.9832 15.6295 25.0611 15.6968C27.0148 15.7642 28.699 17.3137 28.8337 19.2674C28.9011 19.6716 28.8337 20.0084 28.7663 20.4126L28.6316 21.0189L29.2379 21.1537C30.7874 21.4232 31.8653 22.6358 31.9326 24.0505C32 25.4653 31.0569 27.0147 29.6421 27.5537C29.3053 27.621 28.9011 27.7558 28.5642 27.7558H28.3621C28.2274 27.7558 28.16 27.8905 28.2274 27.9579L28.699 28.6316C28.8337 28.7663 28.9684 28.901 29.1705 28.8337C29.44 28.8337 29.7769 28.6989 30.0463 28.6316C31.8653 27.9579 33.2127 26.0042 33.1453 23.9832C33.2127 21.9621 31.9327 20.48 30.1811 19.941Z"
        fill="white"
      />
      <path
        d="M12.059 28.9011C10.1053 26.2737 10.24 21.8274 10.1726 23.2421C9.90316 26.9474 8.48842 28.9011 8.48842 28.9011C8.35369 29.1032 8.28632 29.3726 8.28632 29.6421C8.28632 30.6526 9.16211 31.4611 10.3074 31.4611C11.3853 31.4611 12.3284 30.6526 12.3284 29.6421C12.2611 29.44 12.1937 29.1705 12.059 28.9011Z"
        fill="white"
      />
      <path
        d="M18.1894 25.3979C16.5052 23.1074 16.6399 19.2 16.5052 20.48C16.3031 23.7137 15.0231 25.3979 15.0231 25.3979C14.8883 25.6 14.821 25.8021 14.821 26.0716C14.821 26.9474 15.6294 27.621 16.5726 27.621C17.5157 27.621 18.3241 26.9474 18.3241 26.0716C18.3915 25.8021 18.3241 25.6 18.1894 25.3979Z"
        fill="white"
      />
      <path
        d="M22.9726 29.9789C21.4232 27.8905 21.5579 24.32 21.4232 25.4653C21.2211 28.4295 20.0758 29.9789 20.0758 29.9789C20.0084 30.1811 19.9411 30.3832 19.9411 30.5853C19.9411 31.3937 20.6821 32 21.5579 32C22.4337 32 23.1747 31.3263 23.1747 30.5853C23.1074 30.3158 23.04 30.1137 22.9726 29.9789Z"
        fill="white"
      />
      <path
        d="M24.859 23.8484C23.9158 22.5684 23.9832 20.2779 23.9158 21.0189C23.7811 22.9053 23.04 23.8484 23.04 23.8484C22.9727 23.9832 22.9727 24.1179 22.9727 24.2526C22.9727 24.7242 23.4442 25.1284 23.9832 25.1284C24.5221 25.1284 24.9937 24.7242 24.9937 24.2526C24.9937 24.1179 24.9263 23.9832 24.859 23.8484Z"
        fill="white"
      />
    </svg>
  );
};
