import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import labels from "../../../config/labels";
import ReusableLogo from "../../ReusableLogo/ReusableLogo";
import Typography from "../../../common/Typography/Typography";
import TextField from "../../../common/TextField/TextField";
import BubbledButton from "../../../common/Button/BubbledButton";
import { useStyles } from "./EmailContent.style";
import { EMAIL } from "../logInConstants";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import HorizontalDivider from "../../../common/HorizontalDivider/HorizontalDivider";
import Button from "../../../common/Button/Button";
import { redirectToHomepage } from "../../../utils/helper";
import Spinner from "../../../common/Spinner/Spinner";
import { useTownTheme } from "../../../context/TownThemeContext";

const ACTION = "login";

const EmailPopUpContent = ({
  shouldMarkError,
  setEmail,
  showErrors,
  handleEmailInput,
  errors,
  defaultValue,
  handleNextStepOnEnter,
  isPathUserSignIn,
  townSlug,
  redirectingToHomepage,
  isRedirectingToHomepage,
}) => {
  const { town } = useTownTheme();
  const classes = useStyles();
  const [token, setToken] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const recaptchaToken = await executeRecaptcha(ACTION);
    handleEmailInput(defaultValue, recaptchaToken);
    setToken(recaptchaToken);
  }, [executeRecaptcha, defaultValue]);

  const handleGoBackToHomepageClick = () => {
    redirectingToHomepage(true);
    redirectToHomepage(townSlug);
  };

  return (
    <>
      <div className={classes.content}>
        {town?.under_license_contract ? (
          <Typography
            level="paragraph_1"
            bold
            className={classes.primary_color}
          >
            {town?.name}
          </Typography>
        ) : (
          <ReusableLogo className={classes.logo} />
        )}
        <Typography level="h4" bold className={classes.primary_color}>
          {labels.WELCOME}
        </Typography>
        <TextField
          className={classes.textfield}
          label={labels.EMAIL_LABEL}
          labelLevel={"paragraph_1"}
          boldLabel={false}
          type="email"
          placeholder={labels.EMAIL_PLACEHOLDER}
          onChange={(e) => setEmail(e.target.value)}
          error={shouldMarkError(EMAIL)}
          defaultValue={defaultValue}
          autoFocus={true}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.key === "13") {
              handleReCaptchaVerify();
            }
            handleNextStepOnEnter(e.key, EMAIL, token, defaultValue);
          }}
          disabled={isRedirectingToHomepage}
          inputProps={{ "data-cy": "email" }}
        />
        {showErrors(errors)}
        <BubbledButton
          level="button_orange"
          color="primary"
          onClick={handleReCaptchaVerify}
          className={classes.button}
          disabled={isRedirectingToHomepage}
          dataCy="next"
        >
          {labels.NEXT}
        </BubbledButton>

        {isPathUserSignIn && (
          <>
            <div className={classes.back_to_homepage_header}>
              <HorizontalDivider className={classes.divider} />
              <Typography
                level="t0_text_0"
                color="gray"
                bold
                className={classes.reduced_opacity}
              >
                {labels.OR}
              </Typography>
              <HorizontalDivider className={classes.divider} />
            </div>
            <Button
              variant="outlined"
              level="simple_button"
              color="primary"
              bold
              className={clsx(
                classes.back_to_homepage_button,
                classes.reduced_opacity
              )}
              onClick={handleGoBackToHomepageClick}
              disabled={isRedirectingToHomepage}
            >
              <Typography level="t1_text_1" color="gray" bold>
                {labels.GO_BACK_TO_HOMEPAGE}
              </Typography>
            </Button>
          </>
        )}
      </div>
      {isRedirectingToHomepage && (
        <Spinner
          withLoadingOverlay={true}
          loadingOverlayClassName={classes.login_loading_overlay}
        />
      )}
    </>
  );
};

EmailPopUpContent.propTypes = {
  shouldMarkError: PropTypes.func,
  setEmail: PropTypes.func,
  showErrors: PropTypes.func,
  handleEmailInput: PropTypes.func,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      error: PropTypes.string,
    })
  ),
  defaultValue: PropTypes.string,
  handleNextStepOnEnter: PropTypes.func,
  isPathUserSignIn: PropTypes.bool,
  townSlug: PropTypes.string,
};

export default EmailPopUpContent;
