import { makeStyles } from "../Theme/Theme";

const useTextColorStyles = makeStyles((theme) => {
  return {
    black: {
      color: theme.palette.dark.black,
    },
    gray: {
      color: theme.palette.dark.gray,
    },
    tapintoOrange: {
      color:
        JSON.parse(localStorage.getItem("townTheme"))?.primary_color ??
        theme.palette.tapintoBranding.tapintoOrange,
    },
    tapintoGreen: {
      color:
        JSON.parse(localStorage.getItem("townTheme"))?.secondary_color ??
        theme.palette.tapintoBranding.tapintoGreen,
    },
    primary: {
      color:
        JSON.parse(localStorage.getItem("townTheme"))?.primary_color ??
        theme.palette.tapintoBranding.tapintoOrange,
    },
    secondary: {
      color:
        JSON.parse(localStorage.getItem("townTheme"))?.secondary_color ??
        theme.palette.tapintoBranding.tapintoGreen,
    },
    grayForCredits: {
      color: theme.palette.grayVariation.grayForCredits,
    },
    white: {
      color: theme.palette.miscellaneous.white,
    },
    blueLink: {
      color: theme.palette.miscellaneous.blueLink,
    },
    grayDivider: {
      color: theme.palette.miscellaneous.grayDivider,
    },
    grayForBorder: {
      color: theme.palette.grayVariation.grayForBorder,
    },
    red: {
      color: theme.palette.miscellaneous.red,
    },
    facebookBlue: {
      color: theme.palette.facebook.facebookBlue,
    },
    twitterBlue: {
      color: theme.palette.miscellaneous.twitterBlue,
    },
    instagramPink: {
      color: theme.palette.miscellaneous.instagramPink,
    },
    facebookLightBlue: {
      color: theme.palette.facebook.lightBlue,
    },
  };
});

const useBackgroundColor = makeStyles((theme) => {
  return {
    black: {
      color: theme.palette.dark.black,
    },
    gray: {
      color: theme.palette.dark.gray,
    },
    tapintoOrange: {
      color: theme.palette.tapintoBranding.tapintoOrange,
    },
    tapintoGreen: {
      color: theme.palette.tapintoBranding.tapintoGreen,
    },
    grayForCredits: {
      color: theme.palette.grayVariation.grayForCredits,
    },
    white: {
      color: theme.palette.miscellaneous.white,
    },
    blueLink: {
      color: theme.palette.miscellaneous.bluelink,
    },
    grayDivider: {
      color: theme.palette.miscellaneous.grayDivider,
    },
  };
});
export { useTextColorStyles, useBackgroundColor };
