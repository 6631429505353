import React, { useState } from "react";
import { useStyles } from "./SubscriptionBox.style";
import { replaceInHtmlText } from "../../utils/helper";
import labels from "../../config/labels";
import Spinner from "../../common/Spinner/Spinner";
import Form from "./Form/Form";
import Alert from "../../common/Alert/Alert";
import { loadThemeTexts } from "../../services/townService";

const SubscriptionBox = ({ town, emailAddress }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const town_to_use = town;

  const loadingContent = (value) => {
    setLoading(value);
  };

  const addError = (item) => {
    if (successMessage) {
      setSuccessMessage("");
    }

    setError(item);
  };

  const addContentName = (label) => {
    return label.replace("##contentName", town?.name);
  };

  const handleSuccess = async () => {
    if (error.message) {
      setError({});
    }
    const customizedTextResponse = await loadThemeTexts(town?.slug);

    if (
      customizedTextResponse?.status === 200 &&
      customizedTextResponse?.data?.post_subscription_message
    ) {
      setError("");
      setSuccessMessage(
        <div
          className={classes.custom_success_message}
          dangerouslySetInnerHTML={{
            __html: customizedTextResponse?.data?.post_subscription_message,
          }}
        />
      );
    } else {
      setSuccessMessage(addContentName(labels.YOU_HAVE_SUCCESSFULLY_SIGNED_UP));
    }
  };

  return (
    <div className={classes.main_container}>
      {loading && <Spinner withLoadingOverlay={true} />}
      {replaceInHtmlText(
        labels.SIGN_UP_BOX_HEADER,
        {
          toReplace: "TOWN",
          toReplaceWith: town_to_use
            ? `<strong>${town_to_use.name.toUpperCase()}</strong>`
            : "",
        },
        classes.header_text
      )}

      {error.message && (
        <div className={classes.alert_container}>
          <Alert severity="error" content={error.message} variant="filled" />
        </div>
      )}

      {successMessage ? (
        <div className={classes.alert_container}>
          <Alert severity="success" content={successMessage} variant="filled" />
        </div>
      ) : (
        <Form
          loadingContent={loadingContent}
          addError={addError}
          handleSuccess={handleSuccess}
          townToUse={town_to_use}
          emailAddress={emailAddress}
        />
      )}
    </div>
  );
};

export default SubscriptionBox;
