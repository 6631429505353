/*eslint sort-keys: "error"*/

const homepageLabels = {
  VIEW_EVENT: "View event",
  ADVERTISEMENT: "Advertisement",
  HIDE: "Hide",
  SHOW: "Show",
  ERROR_SUBSCRIPTIONS: "Your previous subscriptions could not be retrieved",
  SELECT_AT_LEAST_ONE_TOWN: "Select at least one town.",
  ORIGINAL_LOCAL_NEWS_YOU_CAN_TRUST: "Original Local News You Can Trust",
  FIND_YOUR_TOWN: "Find your Town",
  ENTER_YOUR_TOWNS_NAME_OR_ZIP_CODE: "Enter your town's name or zipcode:",
  YOUR_TOWN_OR_ZIP_CODE: "Your town or ZIP code",
  VIEW_LIST_OF_SITES: "View list of sites",
  LOCAL_NEWS_FROM_AROUND_THE_TAP_INTO_NETWORK:
    "Local News From Around the TAPinto Network",
  OVERALL_FRANCHISE:
    "Empower your community and yourself by owning/operating a local news and digital marketing platform in your community.",
  LEARN_MORE: "Learn More",
  WATCH_VIDEO: "Watch Video",
  ACCESS_ORIGINAL_LOCAL_NEWS:
    "Access original local news reporting about your community and much more!",
  MENU: "menu",
  LATEST_NEWS: "LATEST NEWS",
  TOP_LOCAL_NEWS: "TOP LOCAL NEWS",
};
export default homepageLabels;
