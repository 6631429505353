/*eslint sort-keys: "error"*/

const loginLabels = {
  TAP: "TAP",
  INTO: "into",
  WELCOME: "Welcome",
  NEXT: "Next",
  EMAIL_LABEL: "Email address",
  WELCOME_BACK: "Welcome Back",
  PASSWORD: "Password",
  SIGN_IN: "Sign In",
  REMEMEMBER_ME: "Remember Me",
  FORGOT_PASSWORD: "Forgot your password?",
  EMAIL_ERROR: "The email you have entered is not correct",
  PASSWORD_ERROR: "Wrong password. Try again or click 'Forgot your password?'",
  PASSWORD_RESET_POPUP_MESSAGE: "Are you sure you want to reset your password?",
  CONTINUE: "Continue",
  CANCEL: "Cancel",
  PASSWORD_RESET_INSTRUCTIONS_1: "You will be receiving an email shortly to ",
  PASSWORD_RESET_INSTRUCTIONS_2:
    " with a link to reset your password. Please check your inbox, Promotions folder (Gmail) or Spam folder. If you do not receive the email within 10 minutes, please email us at ",
  THANK_YOU: "Thank you",
  ERROR_MESSAGE: "An error has occurred. Please try again later.",
  LOG_IN: "Log In",
  CREATE_ACCOUNT: "Create Account",
  RECIEVE_NEWSLETTER: "Receive our Free Newsletter",
  SIGN_UP: "Sign Up",
  RECEIVE_ENEWS: "I want to receive FREE E-News from TAPinto",
  AGREE_TO: "I agree to TAPinto ",
  TERMS_OF_USE: "Terms of Use",
  AND: " and ",
  PRIVACY_POLICY: "Privacy Policy",
  CREATE_PASSWORD: "Create a password",
  RETYPE_PASSWORD: "Re-type your password",
  SIGNED_UP_MESSAGE_1:
    "You will soon receive an email from us. Click on the link in the email to confirm your registration.",
  SIGNED_UP_MESSAGE_2:
    "If you have not yet received an email from TAPinto.net, check your Promotions folder (Gmail) or Spam folder.",
  SIGNED_UP_MESSAGE_3: "For any further questions, please email us at ",
  PLEASE_INSERT_PASSWORD_AND_CONFIRMATION:
    "Please enter a valid password with no less than 6 characters",
  PASSWORD_CONFIRMATION_DOESNT_MATCH_PASSWORD:
    "The password confirmation does not match your password",
  PLEASE_AGREE_TO_TERMS:
    "Please agree to terms of use and privacy policy before continuing",
  PLEASE_CHOOSE_AT_LEAST_ONE_TOWN: "Choose at least one town to subscribe to",
  PLEASE_INSERT_A_VALID_PASSWORD:
    "Password length must be 6 characters or more",
  ENTER_PASSWORD: "Enter your password",
  CHOOSE_YOUR_TOWNS: "Choose your towns",
  YOU_ARE_A_SUBSCRIBER_CREATE_PASSWORD:
    "You are already a subscriber. Create a password to register as a contributor.",
  SOMETHING_WENT_WRONG_ERROR: "Something went wrong, please try again later.",
  SOMETHING_WENT_WRONG_ERROR_MONGO_DB:
    "Something went wrong with your account, please contact support.",
  UPGRADE: "UPGRADE",
  ACCOUNT_NOT_CONFIRMED: "Account Not Confirmed",
  ACCOUNT_NOT_CONFIRMED_MESSAGE_1:
    "You have not activated your account yet. Please check your inbox, Promotions folder (Gmail) or Spam folder. If you did not receive the confirmation email you can resend it by clicking ",
  ACCOUNT_NOT_CONFIRMED_MESSAGE_2: " here ",
  ACCOUNT_NOT_CONFIRMED_MESSAGE_3: " or contact us at ",
  CONFIRMATION_EMAIL_SENT_MESSAGE_1:
    "You will be receiving an email shortly to ",
  CONFIRMATION_EMAIL_SENT_MESSAGE_2:
    ". Please check your inbox, Promotions folder (Gmail) or Spam folder. If you do not receive the email within 10 minutes, contact us at ",
  RECAPTCHA_ERROR: "Recaptcha validation failed. Please try again later.",
  SUCCESSFUL_PASSWORD_RESET: "Your password has been successfully changed.",
  OR: "Or",
  GO_BACK_TO_HOMEPAGE: "Go Back to Homepage",
  IF_YOU_DID_NOT_RECEIVE_CONFIRMATION_EMAIL:
    "If you did not receive the confirmation email you can resend it by clicking",
};
export default loginLabels;
