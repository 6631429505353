import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import withConfig from "../../../utils/withConfig";
import FormContainer from "./FormContainer";

const SubscribeForm = () => {
  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={withConfig("RECAPTCHA_KEY")}
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
    >
      <FormContainer />
    </GoogleReCaptchaProvider>
  );
};

export default SubscribeForm;
