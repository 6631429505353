import React from "react";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import { FormControlLabel } from "@material-ui/core";
import { useStyles } from "./Checkbox.style";
import clsx from "clsx";

const Checkbox = ({
  checked,
  label,
  disabled,
  indeterminate,
  onChange,
  className,
  checkboxColor,
  labelPlacement,
  customClasses,
}) => {
  const styleProps = { checkboxColor };
  const classes = useStyles(styleProps);

  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      className={clsx(
        classes.checkbox_container,
        className,
        disabled && classes.disabled_label_container
      )}
      disabled={disabled}
      control={
        <MaterialCheckbox
          className={disabled ? classes.checkbox_disabled : classes.checkbox}
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
        />
      }
      classes={customClasses}
    />
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  checkboxColor: PropTypes.string,
  labelColor: PropTypes.string,
  labelPlacement: PropTypes.string,
  customClasses: PropTypes.object,
};

Checkbox.defaultProps = {
  checked: false,
  label: "",
  className: "",
  disabled: false,
  indeterminate: false,
  onChange: () => {},
  checkboxColor: "",
  labelColor: "",
  labelPlacement: "end",
};

export default Checkbox;
