import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Card from "../../../../../../common/Card/Card";
import { useStyles } from "./ThreeCards.style";
import VerticalLayout from "../../../../../../common/Card/Layouts/Vertical/VerticalLayout";
import ContentInfo from "../../../../Shared/ContentInfo/ContentInfo";
import SpecialCaseCard from "../../../../Shared/SpecialCaseCard/SpecialCaseCard";
import {
  isBlock,
  specialCaseCard,
} from "../../../../../../utils/helpers/homepageHelper";
import { GAMES_BLOCK } from "../../../../../../utils/constants/homepageConstants";
import withConfig from "../../../../../../utils/withConfig";
import { pathToUrl } from "../../../../../../utils/helper";
import { useTown } from "../../../../../../context/TownContext";

const ThreeCards = ({ contents, blockClass }) => {
  const classes = useStyles();
  const { town } = useTown();

  return contents.map((content, index) => {
    if (specialCaseCard(blockClass)) {
      return (
        <Fragment key={"special-case-card-" + index}>
          <SpecialCaseCard
            content={content}
            blockClass={blockClass}
            options={{ withShadow: false }}
          />
        </Fragment>
      );
    }

    return (
      <div className={classes.card_container} key={"three-cards-" + index}>
        <Card
          classes={{
            root: classes.content_card,
          }}
          withShadow={false}
          children={
            <VerticalLayout
              media={
                !isBlock({ blockClass, type: GAMES_BLOCK })
                  ? content?.media_content[0]
                  : { contentUrl: content?.game_image, alt_text: content?.game_name }
              }
              information={
                <ContentInfo
                  content={content}
                  blockClass={blockClass}
                  asContentHeader={true}
                />
              }
              options={{
                caption: { visible: false },
                credits: { visible: false },
                media: {
                  clickable: true,
                  townContentUrl: !isBlock({ blockClass, type: GAMES_BLOCK })
                    ? content?.url_content
                    : pathToUrl(withConfig("SHOW_GAME_URL"), {
                        town_slug: town.slug,
                        game_slug: content?.slug,
                      }),
                  opacityOnFocus: true,
                },
                videoPlayer: {
                  width: "100%",
                  height: "170px",
                  showPlayable: true,
                },
              }}
              customClasses={{
                image: classes.image,
                media_container: classes.media_container,
              }}
            />
          }
        />
      </div>
    );
  });
};

ThreeCards.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.object).isRequired,
  blockClass: PropTypes.string.isRequired,
};

export default ThreeCards;
