import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  content: {
    width: theme.spacing(37.5),
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down(400)]: {
      width: theme.spacing(36.25),
    },
  },
  button: {
    width: "100%",
    height: theme.spacing(5),
    marginTop: `${theme.spacing(3)}px !important`,
    "& .MuiButton-label": {
      fontSize: "20px !important",
    },
  },
  textfield: {
    marginTop: `${theme.spacing(1)}px !important`,
    "& input.MuiInputBase-input": {
      textAlign: "center",
    },
  },
  create_account_checkbox: {
    "& .MuiTypography-root": {
      color: theme.palette.dark.gray,
      fontSize: "14px",
      fontFamily: "Montserrat !important",
      fontWeight: 500,
      lineHeight: "16px",
    },
    "& a": {
      "& span": {
        fontWeight: 500,
      },
    },
  },
  create_account_checkbox_error: {
    "& .MuiTypography-root": {
      color: theme.palette.miscellaneous.red,
    },
  },
  email_text: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
  },
  town_selector: {
    width: "100% !important",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "& .MuiInputBase-input": {
      boxShadow: "none !important",
      border: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.dark.gray} !important`,
    },
  },
  town_selector_error: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.miscellaneous.red} !important`,
    },
  },
  password_field_container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  receive_newsletter_text: {
    marginTop: theme.spacing(1),
  },
  password_errors: {
    "& div[class*='errors_container']": {
      marginTop: `${theme.spacing(1.5)}px !important`,
      paddingLeft: "0px !important",
    },
  },
  login_loading_overlay: {
    background: "rgba(255, 255, 255, 0.6) !important",
  },
  subscriber_text: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  town_picker_popper: {
    zIndex: "94049 !important",
  },
}));

export { useStyles };
