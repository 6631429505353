import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    category: {
      fontSize: theme.spacing(2.25),
      fontWeight: 500,
      color: theme.palette.grayVariation.grayForSubTitle,
    },
    directory_address: {
      fontSize: theme.spacing(2.25),
      fontWeight: 500,
    },
    directory_phone:{
      fontSize: theme.spacing(2.25),
      fontWeight: 600,
      color: theme.palette.themeColor.primary,
    },
    real_estate_status: {
      fontSize: theme.spacing(2.25),
      fontWeight: 600,
      color: theme.palette.themeColor.primary,
    },
    real_estate_information: {

      fontSize: theme.spacing(2.25),
      fontWeight: 400,
      color: theme.palette.grayVariation.grayForSubTitle,
      marginBottom: "0px !important",
    },
    real_estate_information_container: {
      paddingTop: `${theme.spacing(2)}px !important`,
      paddingBottom: "0px !important",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    event_information_description: {
      fontWeight: 400,
      fontSize: theme.spacing(2),
      width: "100%",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: "5",
      WebkitBoxOrient: "vertical",
    },
    event_information_starts: {
      fontSize: theme.spacing(2.25),
      color: theme.palette.themeColor.primary,
      display: "block",
      fontWeight: 600,
    },
    event_information_time: {
      fontSize: theme.spacing(2.25),
      display: "block",
      paddingBottom: theme.spacing(1),
      fontWeight: 500,
    },
    content_header: {
      "& strong": {
        lineHeight: "28px",
        fontWeight: 700,
        fontSize: theme.spacing(2.25),
      },
    },
    directories_container: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
      paddingTop: `${theme.spacing(2)}px !important`,
    },
    obituary_information_dates: {
      fontSize: theme.spacing(2.25),
      display: "block",
      paddingBottom: theme.spacing(1),
      fontWeight: 500,
      color: `${theme.palette.grayVariation.grayForSubTitle} !important`,
    },
    content_description: {
      fontWeight: 400,
      fontSize: theme.spacing(2),
    },
    content_link: {
      "& span": {
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    event_city: {
      fontSize: theme.spacing(1.5),
      paddingBottom: theme.spacing(1),
      display: "block",
    },
    price_real_state: {
      fontSize: theme.spacing(4),
      fontWeight: 700,
      [theme.breakpoints.down(768)]: {
        fontSize: theme.spacing(2.25),
        fontWeight: 500,
      },
    },
    real_estate_address: {
      padding: "0px !important",
      marginBottom: "0 !important",
    },
    information_container: {
      padding: "0px 8px 8px 0px",
      width: "100%",
      ["& div"]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      },
      ["& div:last-child"]: {
        marginBottom: 0,
      },
      ["& div:nth-child(2)"]: {
        marginBottom: theme.spacing(1),
      },
    },
    game_name: {
      fontSize: theme.spacing(2.5),
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      paddingBlock: theme.spacing(2),
      "& strong": {
        textTransform: "capitalize",
      },
    },
    game_description: {
      fontSize: theme.spacing(2.25),
      fontWeight: 500,
      lineHeight: "normal",
    }
  };
});

export { useStyles };
