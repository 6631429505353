const HALSTON_MEDIA = "halston-media";

const buildAdTargetsForSponsor = (sponsorInfo) => {
  const targets = {};
  if (sponsorInfo?.town) {
    targets.towns = [sponsorInfo?.town];
  }

  if (sponsorInfo?.sections_slug?.length > 0) {
    targets.sections = sponsorInfo?.sections_slug;
  }

  if (sponsorInfo?.columns_slug?.length > 0) {
    targets.columns = sponsorInfo?.columns_slug;
  }

  if (sponsorInfo?.categories_slug?.length > 0) {
    targets.categories = sponsorInfo?.categories_slug;
  }

  if (sponsorInfo?.page_type) {
    targets.sponsor_display = [sponsorInfo?.page_type];
  }

  if (sponsorInfo?.sponsor_attr) {
    targets.sponsor_attr = sponsorInfo?.sponsor_attr;
  }
  return targets;
};

const buildAdTargets = (town, section) => {
  const targets = {};
  if (town) {
    if (town.slug_for_gam) {
      targets.towns = [town.slug_for_gam];
    }
    if (town.slug_for_gam === HALSTON_MEDIA && section.slug_for_gam) {
      targets.sections = [section.slug_for_gam];
    }
  } else {
    if (section) {
      targets.sections = [section.slug_for_gam];
      targets.towns = ["homepage"];
    }
  }

  return targets;
};

export { buildAdTargetsForSponsor, buildAdTargets };
