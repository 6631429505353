import React from "react";
import Typography from "../../../../common/Typography/Typography";
import Button from "../../../../common/Button/Button";
import labels from "../../../../config/labels";
import { buildUrlBasedOnHost, redirectToPage } from "../../../../utils/helper";
import { deleteDraft } from "../../../../services/drafts";
import Trashcan from "../../../../common/Icons/Trashcan";
import Edit from "../../../../common/Icons/Edit";
import { useStyles } from "./Draft.style";

const Draft = ({
  draft,
  setDataLoading,
  setSnackbarProps,
  fetchUserDrafts,
}) => {
  const classes = useStyles();

  const handleDeleteDraft = async (draft) => {
    if (
      confirm(
        labels.DELETE_DRAFT_CONFIRMATION.replace(
          "DRAFT_TYPE",
          draft.draft_content.type_label
        )
      )
    ) {
      try {
        setDataLoading(true);
        const response = await deleteDraft(draft);
        if (response && response?.status === 200) {
          if (response.data.was_draft_deleted) {
            setSnackbarProps({
              message: labels.DRAFT_DELETED,
              variant: "success center_in_screen",
              open: true,
            });
            fetchUserDrafts();
          } else {
            setSnackbarProps({
              message: labels.ERROR_DELETING_DRAFT,
              variant: "error center_in_screen",
              open: true,
            });
          }
        }
        setDataLoading(false);
      } catch (e) {
        setSnackbarProps({
          message: labels.ERROR_DELETING_DRAFT,
          variant: "error center_in_screen",
          open: true,
        });
      }
    }
  };

  const getRedirectUrl = (editPath) => {
    if (editPath) {
      return buildUrlBasedOnHost() + editPath;
    } else {
      return "";
    }
  };

  return (
    <>
      <Typography className={classes.draft_title} level="t1_text_1" underlined>
        {draft.draft_content.name}
      </Typography>
      <Typography
        className={classes.draft_type}
        level="t1_text_1"
        color="gray"
        bold
      >
        {draft.draft_content.type_label}
      </Typography>
      <div className={classes.actions_container}>
        <Button
          level="simple_button"
          color="primary"
          children={
            <>
              <Typography level="t1_text_1" color="gray">
                {labels.EDIT}
              </Typography>
              <Edit fillColor="#606060" className={classes.label_icon} />
            </>
          }
          classes={{ root: classes.button }}
          onClick={() =>
            redirectToPage(
              getRedirectUrl(draft.draft_content.edit_path),
              "_top"
            )
          }
        />
        <Button
          level="simple_button"
          color="primary"
          children={
            <>
              <Typography level="t1_text_1" color="gray">
                {labels.DELETE}
              </Typography>
              <Trashcan fillColor="#606060" className={classes.label_icon} />
            </>
          }
          classes={{ root: classes.button }}
          onClick={() => handleDeleteDraft(draft)}
        />
      </div>
    </>
  );
};

export default Draft;
