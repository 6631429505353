import cache from "../utils/cache";
import withConfig from "../utils/withConfig";

const AD_SLOTS = "AD_SLOTS";

const useGAMSlot = (adUnit, size, id, targets) => {
  const gamId = withConfig("GAM_ID");
  const path = `/${gamId}/${adUnit}`;

  let cachedElements = cache.get(AD_SLOTS);
  if (cachedElements === undefined) {
    cachedElements = [];
  }

  window.googletag = window.googletag || { cmd: [] };
  googletag.cmd.push(function () {
    const foundElementId = cachedElements.find((element) => element === id);
    if (foundElementId === undefined) {
      googletag.defineSlot(path, size, id).addService(googletag.pubads());
      if (targets?.towns) {
        googletag.pubads().setTargeting("town", targets?.towns);
      }
      if (targets?.sections) {
        googletag.pubads().setTargeting("section", targets?.sections);
      }

      if (targets?.columns) {
        googletag.pubads().setTargeting("column", targets?.columns);
      }

      if (targets?.categories) {
        googletag.pubads().setTargeting("category", targets?.categories);
      }

      if (targets?.sponsor_display) {
        googletag
          .pubads()
          .setTargeting("sponsor_display", targets?.sponsor_display);
      }
      if (targets?.sponsor_attr) {
        googletag.pubads().setTargeting("sponsor_attr", targets?.sponsor_attr);
      }
      cachedElements.push(id);
      cache.set(AD_SLOTS, cachedElements);
      googletag.cmd.push(function () {
        console.log(`Pushing ${id}`);
        googletag.display(id);
      });
    }

    googletag.pubads().enableSingleRequest();
    googletag.enableServices();
  });
};

export { useGAMSlot };
