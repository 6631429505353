import React from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./AfterPasswordCreateContent.style";
import Link from "../../../common/Link/Link";
import { CONTACT_EMAIL } from "../logInConstants";

const AfterPasswordCreateContent = ({ customMessage }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Typography level="h4" color="tapintoOrange" bold>
        {labels.THANK_YOU}
      </Typography>
      {customMessage ? (
        <div
          dangerouslySetInnerHTML={{
            __html: customMessage,
          }}
        />
      ) : (
        <>
          <Typography level="t3_text_3" color="gray">
            {labels.SIGNED_UP_MESSAGE_1}
            <br />
            <br />
            {labels.SIGNED_UP_MESSAGE_2}
            <br />
            <br />
            {labels.SIGNED_UP_MESSAGE_3}
            <Link
              children={CONTACT_EMAIL}
              level="t3_text_3"
              url={"mailto:" + CONTACT_EMAIL}
              color="blueLink"
            />
          </Typography>
        </>
      )}
    </div>
  );
};

AfterPasswordCreateContent.propTypes = {
  customMessage: PropTypes.string,
};

export default AfterPasswordCreateContent;
