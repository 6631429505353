import React, { useState, useCallback, useEffect } from "react";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { bool, func, number, shape, string } from "prop-types";
import Button from "../../common/Button/Button";
import TextInput from "../../common/TextInput/TextInput";
import Typography from "../../common/Typography/Typography";
import labels from "../../config/labels";
import { useStyles } from "./PopUpContent.style";
import { isValidEmail } from "../../utils/helper";
import { subscribeTo } from "../../services/subscriber";
import clsx from "clsx";
import SignUpBottomText from "../SignUpNewsletter/SignUpBottomText/SignUpBottomText";
import { loadThemeTexts } from "../../services/townService";
import AlreadySubscribedMessage from "../SignUpNewsletter/AlreadySubscribedMessage/AlreadySubscribedMessage";

const ACTION = "exitIntentPopUpSignUp";

const PopUpContent = ({
  subscribeToContent,
  placement,
  onSubscription,
  photoUrl,
  isLicense,
}) => {
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [exitIntentPopUpTitle, setExitIntentPopUpTitle] = useState(
    labels.BEFORE_YOU_GO
  );
  const textWithTown = () => {
    if (isLicense) {
      return labels.SIGNUP_FOR_FREE_MESSAGE.replace("TAPinto", "").replace(
        "##town",
        subscribeToContent.name
      );
    }
    return labels.SIGNUP_FOR_FREE_MESSAGE.replace(
      "##town",
      subscribeToContent.name
    );
  };

  const addContentName = (label) => {
    return label.replace("##contentName", subscribeToContent.name);
  };

  const handleCommitAction = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const result = executeRecaptcha(ACTION);

    setToken(result);

    //Input validation
    if (!subscriberEmail) {
      setIsEmpty(true);
    } else if (!isValidEmail(subscriberEmail)) {
      setInputError(true);
      setIsEmpty(false);
    } else if (subscriberEmail && isValidEmail(subscriberEmail)) {
      setIsEmpty(false);
      setButtonDisabled(true);

      const subscribeResponse = await subscribeTo(
        subscribeToContent.type,
        subscribeToContent.slug,
        subscriberEmail,
        token,
        placement
      );

      if (subscribeResponse?.status === 200) {
        onSubscription(true);
        handleSuccess();
      }

      // status 204 means that email is already subscribed to town
      if (subscribeResponse?.status === 204) {
        setSuccessMessage(
          <AlreadySubscribedMessage
            slug={subscribeToContent.slug}
            email={subscriberEmail}
          />
        );
      }
    }
  }, [executeRecaptcha, subscriberEmail]);

  const handleReCaptchaVerify = useCallback(
    (token) => {
      setToken(token);
    },
    [setToken]
  );

  const ErrorMessage = () => {
    return (
      <div className={classes.error_container}>
        <Typography className={classes.error_message} level="paragraph_2" bold>
          {inputError ? labels.INPUT_ERROR : labels.SIGNUP_ERROR}
        </Typography>
      </div>
    );
  };

  const handleSuccess = async () => {
    setExitIntentPopUpTitle(labels.THANK_YOU);

    const customizedTextResponse = await loadThemeTexts(
      subscribeToContent?.slug
    );

    if (
      customizedTextResponse?.status === 200 &&
      customizedTextResponse?.data?.post_subscription_message
    ) {
      setSuccessMessage(
        <div
          className={classes.message}
          dangerouslySetInnerHTML={{
            __html: customizedTextResponse?.data?.post_subscription_message,
          }}
        />
      );
    } else {
      setSuccessMessage(
        <div
          className={classes.message}
          dangerouslySetInnerHTML={{
            __html: addContentName(labels.YOU_HAVE_SUCCESSFULLY_SIGNED_UP),
          }}
        />
      );
    }
  };

  return (
    <>
      <div className={classes.body_container}>
        <div
          className={clsx(
            classes.left_container,
            exitIntentPopUpTitle === labels.THANK_YOU && classes.full_width
          )}
        >
          <div className={classes.title_container}>
            <Typography level="h1" className={classes.title} underlined>
              {exitIntentPopUpTitle}
            </Typography>
          </div>

          {successMessage ? (
            <>{successMessage}</>
          ) : (
            <>
              <div
                className={classes.message}
                dangerouslySetInnerHTML={{ __html: textWithTown() }}
              />
              <div
                className={clsx(
                  classes.email,
                  !isEmpty ? "" : classes.red_input_border
                )}
              >
                <TextInput
                  placeholder="your@email.com"
                  value={subscriberEmail}
                  onChange={(event) => setSubscriberEmail(event.target.value)}
                />
                {inputError && <ErrorMessage />}
              </div>
              <div
                className={clsx(
                  classes.signup_container,
                  inputError ? classes.signup_with_extra_margin : ""
                )}
              >
                <Button
                  level="simple_button"
                  color="primary"
                  variant="contained"
                  onClick={handleCommitAction}
                  disabled={buttonDisabled}
                  id="newsletter-subscription-exit-intent-popup-button"
                  classes={{ label: classes.button_label }}
                  children={labels.SIGN_UP}
                />
                <SignUpBottomText town={subscribeToContent} />
              </div>
            </>
          )}
        </div>
        <div
          className={clsx(
            classes.right_container,
            successMessage ? classes.hidden : ""
          )}
        >
          <img src={photoUrl} alt="newsletter signup" />
        </div>
      </div>
      <GoogleReCaptcha action={ACTION} onVerify={handleReCaptchaVerify} />
    </>
  );
};

PopUpContent.propTypes = {
  subscribeToContent: shape({
    type: string,
    name: string,
    slug: string,
    id: number,
  }),
  placement: string,
  onSubscription: func,
  underLicenseContract: bool,
  photoUrl: string,
};

export default PopUpContent;
