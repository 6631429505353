import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { values, down: below },
  } = theme;
  return {
    button_icon: {
      padding: "0px !important",
    },
    icon: {
      width: spacing(4),
      height: spacing(4),
    },
    search_text_field: {
      borderRadius: "4px",
      width: "50% !important",
      height: spacing(4),
      "& input.MuiOutlinedInput-input": {
        fontSize: "12px !important",
      },
    },
    textfield_search_icon: {
      width: spacing(1.5),
      height: spacing(1.5),
    },
    grid_item: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: spacing(1),
    },
    no_printing: {
      "@media print": {
        display: "none !important",
      },
    },
    top_nav_bar: {
      borderTop: `3px solid ${palette.themeColor.primary}`,
      backgroundColor: palette.dark.black,
      flexDirection: "row",
      "&.MuiGrid-spacing-xs-2": {
        margin: 0,
        width: "100%",
      },
      [below(values.md)]: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        padding: spacing(1),
        alignItems: "center",
        zIndex: 1300,
      },
    },
    no_padding: {
      padding: "8px 0px",
    },
    reduce_font_size: {
      [below(1032)]: {
        fontSize: "12px !important",
      },
    },
    signup: {
      width: "fit-content !important",
      margin: "0px !important",
      height: "fit-content !important",
      textTransform: "uppercase !important",
      padding: `${spacing(0.5)}px ${spacing(2)}px !important`,
      "& .MuiButton-label": {
        fontWeight: "500 !important",
        fontSize: "12px !important",
      },
    },
    icons_container: {
      display: "flex",
      alignItems: "center",
      gap: spacing(1),
    },
  };
});

export { useStyles };
