import withConfig from "../utils/withConfig";
import restClient from "../utils/restClient";

export const getUserContributions = async (userSlug) => {
  try {
    const response = await restClient.get(
      withConfig("GET_USER_CONTRIBUTIONS"),
      {
        user_slug: userSlug,
      }
    );
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const fetchSubscriptions = async (email) => {
  try {
    const response = await restClient.get(withConfig("FETCH_SUBSCRIPTIONS"), {
      email: email,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = async (formData) => {
  try {
    const response = await restClient.get(withConfig("RESET_USER_PASSWORD"), {
      ...formData,
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const fetchLegalNoticeEntities = async (userId) => {
  try {
    const response = await restClient.get(
      withConfig("FETCH_LEGAL_NOTICE_ENTITIES"),
      {
        user_id: userId,
      }
    );
    return response;
  } catch (error) {
    throw e;
  }
};
