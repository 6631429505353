import { makeStyles } from "../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values },
  } = theme;
  return {
    image_container: {
      height: "100%",
      backgroundColor: "#F1EEED",
      position: "relative",
      backgroundPosition: "top center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    container: {
      height: "40vh",
      position: "relative",
      backgroundColor: "#F1EEED",
    },
    contents_container_contained: {
      borderRadius: "3px",
      minHeight: "20%",
      width: "60%",
      backgroundColor: "rgba(255, 255, 255, 0.80)",
      position: "absolute",
      bottom: spacing(3),
      left: spacing(3),
      display: "flex",
      alignItems: "center",
      [below(values.l)]: {
        width: "calc(100% - 48px)",
      },
      [below(values.md)]: {
        width: "100%",
        bottom: 0,
        left: 0,
        borderRadius: "0px",
      },
    },
    contents_container_text: {
      borderRadius: "3px",
      minHeight: "20%",
      width: "calc(100% - 48px)",
      position: "absolute",
      bottom: spacing(3),
      left: spacing(3),
      display: "flex",
      alignItems: "center",
      [below(values.sm)]: {
        bottom: spacing(2),
      },
    },
    clickable: {
      cursor: "pointer",
    },
  };
});

export { useStyles };
