import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./HorizontalDivider.style";

const HorizontalDivider = ({ className }) => {
  const classes = useStyles();

  return (
    <hr className={clsx(classes.horizontal_divider, className && className)} />
  );
};

HorizontalDivider.propTypes = {
  className: PropTypes.string,
};

export default HorizontalDivider;
