import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Link from "../../../../common/Link/Link";
import { useStyles } from "./ContentHeader.style";
import { useTown } from "../../../../context/TownContext";
import {
  CLASSIFIED_BLOCK,
  EVENTS_BLOCK,
  MILESTONES_BLOCK,
  OBITUARIES_BLOCK,
} from "../../../../utils/constants/homepageConstants";
import { parseEventMedia } from "../../../../utils/helpers/mediaHelper";
import { getTextFromHtml } from "../../../../utils/helper";

const ContentHeader = ({ content, blockClass, forSingleCard, lineClamp }) => {
  const classes = useStyles({ lineClamp });
  const { town } = useTown();

  const ObituaryHeader = () => {
    const datesForObituary = () => {
      if (content.date_of_birth && content.date_of_death) {
        return content.date_of_birth + " - " + content.date_of_death;
      }

      if (content.date_of_birth) {
        return content.date_of_birth;
      }

      if (content.date_of_death) {
        return content.date_of_death;
      }

      return null;
    };

    return (
      <>
        <Link
          level={forSingleCard ? "h3_sub_header" : "h5_sub_header_2"}
          bold
          children={content.name}
          url={content.url_content}
          className={clsx(classes.obituary_header, classes.content_header)}
        />
        {datesForObituary() && (
          <Link
            level="paragraph_2"
            children={datesForObituary()}
            url={content.url_content}
            className={clsx(classes.content_header, classes.obituary_header)}
          />
        )}
      </>
    );
  };

  const EventHeader = () => {
    const { eventUrl } = parseEventMedia({
      event: content,
      town: town,
    });

    return (
      <Link
        level={forSingleCard ? "h3_sub_header" : "h5_sub_header_2"}
        children={content.title}
        url={eventUrl}
        className={clsx(classes.content_header, classes.event_header_weight)}
      />
    );
  };

  const ClassifiedOrMilestoneHeader = () => {
    return (
      <Link
        level={forSingleCard ? "h3_sub_header" : "h5_sub_header_2"}
        children={getTextFromHtml(content.name)}
        url={content.url_content}
        className={
          forSingleCard ? classes.content_header_single : classes.content_header
        }
      />
    );
  };

  switch (blockClass) {
    case OBITUARIES_BLOCK:
      return <ObituaryHeader />;
    case EVENTS_BLOCK:
      return <EventHeader />;
    case CLASSIFIED_BLOCK:
    case MILESTONES_BLOCK:
      return <ClassifiedOrMilestoneHeader />;
    default:
      return (
        <Link
          level={forSingleCard ? "h3_sub_header" : "h5_sub_header_2"}
          children={getTextFromHtml(content.name)}
          url={content.url_content}
          className={
            forSingleCard
              ? classes.content_header_single
              : classes.content_header
          }
        />
      );
  }
};

ContentHeader.propTypes = {
  content: PropTypes.object.isRequired,
  blockClass: PropTypes.string,
  forSingleCard: PropTypes.bool,
  lineClamp: PropTypes.number,
};

ContentHeader.defaultProps = {
  forSingleCard: false,
  lineClamp: 2,
};

export default ContentHeader;
