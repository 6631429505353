import React from "react";
export const HazyMoonlight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="34"
      viewBox="0 0 50 34"
      fill="none"
    >
      <path
        d="M34.4762 29C34.4762 29.4762 34.0952 29.8572 33.619 29.8572C31.4286 29.8572 29.3333 29.8572 27.1429 29.8572C23.2381 29.8572 19.4286 29.8572 15.5238 29.8572C14.8571 29.8572 13.9048 30.1429 13.9048 29C13.9048 27.9524 14.7619 28.0476 15.5238 28.0476C18.9524 28.0476 22.2857 28.0476 25.7143 28.0476C28.381 28.0476 30.9524 28.0476 33.619 28.0476C34.0952 28.1429 34.4762 28.5238 34.4762 29Z"
        fill="white"
      />
      <path
        d="M31.9048 19.381C30.1905 19.381 28.4762 19.381 26.7619 19.381C26.5714 19.381 26.381 19.381 26.1905 19.381C25.2381 19.381 24.2857 19.381 23.3333 19.381C22.6667 19.381 21.7143 19.1905 21.7143 20.3334C21.7143 21.4762 22.6667 21.1905 23.3333 21.1905C24.2857 21.1905 25.2381 21.1905 26.0952 21.1905C26.8571 21.1905 27.619 21.1905 28.381 21.1905C29.5238 21.1905 30.6667 21.1905 31.9048 21.1905C32.381 21.1905 32.7619 20.8095 32.7619 20.3334C32.7619 19.7619 32.381 19.381 31.9048 19.381Z"
        fill="white"
      />
      <path
        d="M28 31.1905C26.2857 31.1905 24.5714 31.1905 22.8571 31.1905C22.6667 31.1905 22.4762 31.1905 22.2857 31.1905C21.3333 31.1905 20.381 31.1905 19.4286 31.1905C18.7619 31.1905 17.8095 31 17.8095 32.1429C17.8095 33.2857 18.7619 33 19.4286 33C20.381 33 21.3333 33 22.1905 33C22.9524 33 23.7143 33 24.4762 33C25.619 33 26.7619 33 28 33C28.4762 33 28.8571 32.6191 28.8571 32.1429C28.8571 31.5715 28.4762 31.1905 28 31.1905Z"
        fill="white"
      />
      <path
        d="M37.3333 22.4286C33.619 22.4286 29.8095 22.4286 26.0952 22.4286C26 22.4286 26 22.4286 25.9048 22.4286C21.5238 22.4286 17.1429 22.4286 12.7619 22.5238H12.6667C11.8095 22.7143 11.8095 23.9524 12.6667 24.1429H12.7619C15.1429 24.2381 17.5238 24.1429 19.9048 24.1429C21.5238 24.1429 24.4762 24.1429 25.9048 24.1429C25.9048 24.1429 25.9048 24.1429 26 24.1429C27.8095 24.1429 29.5238 24.1429 31.3333 24.1429C32.9524 24.1429 35.9048 24.1429 37.3333 24.1429C37.8095 24.1429 38.1905 23.7619 38.1905 23.2857C38.1905 22.8095 37.8095 22.4286 37.3333 22.4286Z"
        fill="white"
      />
      <path
        d="M41.1429 26.1429C41.1429 26.7143 40.6667 27.0953 40.1905 27.0953C35.619 27.0953 31.0476 27.0953 26.381 27.0953C21.3333 27.0953 16.381 27.0953 11.3333 27.0953C11.1429 27.0953 10.9524 27.0953 10.8571 27.0953C10.2857 27.0953 9.71429 27.0953 9.33333 27.0953H9.2381C8.28571 26.9048 8.28571 25.4762 9.2381 25.2857C9.33333 25.2857 9.33333 25.2857 9.42857 25.2857C10 25.1905 10.4762 25.1905 11.0476 25.1905C20.8571 25.1905 30.5714 25.1905 40.381 25.1905C40.7619 25.1905 41.1429 25.6667 41.1429 26.1429Z"
        fill="white"
      />
      <path
        d="M49.1429 7.28574C47.7143 2.90479 43.3333 0.333356 38.8571 1.09526C40.5714 2.23812 42 3.85717 42.6667 5.9524C43.4286 8.23812 43.1429 10.7143 42.1905 12.7143C41.1429 9.9524 38.4762 7.9524 35.4286 7.9524C34.0952 7.9524 32.7619 8.33336 31.6191 9.00002C30.9524 4.90478 27.1429 1.76193 22.6667 2.04764C18.4762 2.23812 15.1429 5.38098 14.5714 9.28574C13.8095 9.00002 13.0476 8.90479 12.1905 8.90479C8.19048 8.90479 4.95238 12.1429 4.95238 16.1429C4.95238 16.2381 4.95238 16.3334 4.95238 16.4286C2.95238 17.0953 0 18.2381 0 22.4286C0 25.4762 2.57143 28.1429 6.09524 28.3334L7.14286 26.7143C7.04762 26.7143 6.85714 26.7143 6.76191 26.7143H6.47619C3.80952 26.7143 1.71429 24.8095 1.71429 22.4286C1.71429 19.6667 3.2381 18.7143 6.09524 18.0476L6.66667 17.7619V16.7143C6.66667 16.5238 6.66667 16.4286 6.66667 16.2381C6.66667 13.1905 9.14286 10.6191 12.2857 10.6191C13.2381 10.6191 14.0952 10.9048 14.9524 11.381L16.1905 12.1429V10.7143C16.0952 7.09526 19.0476 4.04764 22.7619 3.85717H23.1429C26.8571 3.85717 29.9048 6.61907 30 10.2381C30 10.3334 30 10.5238 30 10.8095L29.9048 13L31.4286 11.4762C32.4762 10.4286 33.9048 9.76193 35.4286 9.76193C38.2857 9.76193 40.6667 11.9524 40.9524 14.8095C39.9048 16.0476 38.4762 17.0953 36.7619 17.6667C36.381 17.7619 35.9048 17.8572 35.5238 17.9524C37.7143 19.381 40.5714 19.8572 43.2381 19C43.5238 18.9048 43.7143 18.8095 43.9048 18.7143C44.7619 19.4762 45.2381 20.5238 45.3333 21.6667C45.4286 23.6667 44 26.0476 41.9048 26.7143L42.4762 28.3334C45.1429 27.4762 47.1429 24.6191 47.0476 21.6667C46.9524 20.2381 46.381 19 45.5238 17.9524C48.8571 15.5715 50.4762 11.2857 49.1429 7.28574Z"
        fill="white"
      />
    </svg>
  );
};
