import React, { useState } from "react";
import Box from "../../../common/Box/Box";
import TextField from "../../../common/TextField/TextField";
import { useStyles } from "./index.style";
import PhotoUploader from "../../../common/Uploader/Uploader";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";

const EntityInputs = ({ onHandleChange, errors }) => {
  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const [statusMessage, setStatusMessage] = useState({
    error: "",
    success: "",
  });

  const handleAddFiles = (files) => {
    setStatusMessage({ error: "" });
    setFiles(files);
    onHandleChange({ target: { name: "logo_file", value: "", files: files } });
  };

  const handleErrorInputFile = (message) => {
    if (statusMessage?.success) {
      setStatusMessage({ success: "" });
    }

    setStatusMessage({ error: message });
  };

  return (
    <Box className={classes.inputs_container}>
      <TextField
        nativeLabel="Entity name"
        name="entityName"
        useDefaultClasses={false}
        useNativeError
        fullWidth
        customClasses={{ root: classes.text_field }}
        onChange={onHandleChange}
        helperText={errors.entityName}
        error={Boolean(errors.entityName)}
        type="text"
      />
      <TextField
        nativeLabel="Address"
        name="address"
        useDefaultClasses={false}
        useNativeError
        fullWidth
        customClasses={{ root: classes.text_field }}
        onChange={onHandleChange}
        helperText={errors.address}
        error={Boolean(errors.address)}
        type="text"
      />
      <TextField
        nativeLabel="Phone"
        name="phoneNumber"
        useDefaultClasses={false}
        useNativeError
        fullWidth
        customClasses={{ root: classes.text_field }}
        onChange={onHandleChange}
        helperText={errors.phoneNumber}
        error={Boolean(errors.phoneNumber)}
        type="text"
      />
      <TextField
        nativeLabel="Email"
        name="email"
        useDefaultClasses={false}
        useNativeError
        fullWidth
        customClasses={{ root: classes.text_field }}
        onChange={onHandleChange}
        helperText={errors.email}
        error={Boolean(errors.email)}
        type="email"
      />
      <PhotoUploader
        labelTitle={
          <Typography level="t4_text_4">{labels.LOGO_ENTITY_LABEL}</Typography>
        }
        options={{
          allowDeletePhoto: false,
          alerts: {
            variant: "filled",
          },
          labels: {
            instructions: labels.DRAG_AND_DROP_LOGO_ENTITY_INSTRUCTIONS,
            choosePhotosButton: labels.BROWSE_FILES,
          },
          facebookPreview: false,
          bottomActions: false,
        }}
        customClasses={{
          choose_button: classes.choose_button,
        }}
        showPreviewPlaceholder={false}
        statusMessage={statusMessage}
        handleError={handleErrorInputFile}
        files={files}
        addFiles={handleAddFiles}
      />
    </Box>
  );
};

export default EntityInputs;
