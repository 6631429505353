import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    sponsor_container: {
      width: "100%",
      paddingBottom: theme.spacing(2),
      alignItems: "center",
    },
    sponsor_container_sidebar: {
      display: "flex",
      width: "100%",
      maxHeight: `${theme.spacing(25)}px`,
      paddingBottom: theme.spacing(2),
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sponsored_by: {
      flexGrow: 1,
      fontWeight: "500",
      fontSize: theme.spacing(1.75),
    },
    container_for_weather: {
      paddingBottom: 0,
      width: "fit-content",
    },
  };
});

export { useStyles };
