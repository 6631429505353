import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./DialogTitle.style";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import ButtonIcon from "../../Button/ButtonIcon";
import Typography from "../../Typography/Typography";
import CloseCircle from "../../Icons/CloseCircle";
import FloatingLeftArrowBack from "../../Icons/FloatingLeftArrowBack";

const WHITE = "white";

const DialogTitle = ({
  children,
  onClose,
  onBack,
  className,
  titleColor,
  closeButtonColor,
  closeButtonClass,
  backButtonColor,
  backButtonClass,
  titleTypographyClass,
}) => {
  const classes = useStyles();
  return (
    <MuiDialogTitle
      disableTypography
      className={className ? className : classes.dialog_title}
    >
      <Typography
        className={titleTypographyClass ? titleTypographyClass : null}
        level="t4_text_4"
        color={titleColor ? titleColor : WHITE}
      >
        {children}
      </Typography>
      {onBack && (
        <ButtonIcon
          className={backButtonClass}
          onClick={onBack}
          ariaLabel="Back"
          children={
            <FloatingLeftArrowBack
              className={
                backButtonClass ? backButtonClass : classes.back_button
              }
              fillColor={backButtonColor ? backButtonColor : WHITE}
            />
          }
        />
      )}
      {onClose && (
        <CloseCircle
          onClick={onClose}
          className={closeButtonClass ? closeButtonClass : classes.close_button}
          fillColor={closeButtonColor ? closeButtonColor : WHITE}
        />
      )}
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.string,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  className: PropTypes.any,
  titleColor: PropTypes.string,
  closeButtonColor: PropTypes.string,
  closeButtonClass: PropTypes.any,
  backButtonColor: PropTypes.string,
  backButtonClass: PropTypes.any,
  titleTypographyClass: PropTypes.any,
};

export default DialogTitle;
