import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, up: above, values: values },
  } = theme;
  return {
    main_story_container: {
      width: "45%",
      [below(1033)]: {
        width: "100%",
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    vertical_layout: {
      height: "97%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [above(1033)]: {
        gap: spacing(3),
      },
    },
    main_card: {
      marginBottom: `${spacing(2)}px !important`,
      position: "relative",
      height: "max-content",
      [above(1033)]: {
        boxShadow: "none !important",
      },
      [below(1033)]: {
        height: "fit-content",
        padding: 0,
      },
    },
    card_title: {
      "& span": {
        lineHeight: "30px !important",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "3",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        alignSelf: "center",
      },
      "& :hover": {
        textDecoration: "underline",
      },
    },
    overall_card_title: {
      padding: 0,
      height: "auto",
    },
    card_title_container: {
      padding: `${spacing(2)}px 0px `,
      [below(1033)]: {
        height: "fit-content",
      },
    },
    overall_town_link: {
      paddingBottom: spacing(2),
      [below(1033)]: {
        paddingBottom: spacing(2),
      },
    },
    overall_card_info_container: {
      height: "auto",
      display: "flex",
      flexDirection: "column",
      padding: `${spacing(1.25)}px ${spacing(2)}px `,
    },
    card_description: {
      "& span": {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "20px",
        height: spacing(10),
        display: "flex",
        alignItems: "center",
        [below(1033)]: {
          height: "auto",
          padding: `${spacing(1)}px 0`,
        },
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    lazy_load_card_container: {
      width: "100% !important",
      height: "100% !important",
    },
    lazy_load_card_content: {
      width: "100% !important",
      height: "100% !important",
      backgroundColor: "#f1f1f1 !important",
      [below(1033)]: {
        minHeight: spacing(37.5),
      },
    },
    lazy_load_cards: {
      boxShadow: "none !important",
    },
    caption: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "20px",
    },
    caption_and_credits_container: {
      marginTop: `${spacing(2)}px !important`,
      marginBottom: spacing(2),
    },
    media_container: {
      backgroundColor: "rgba(206, 198, 194, 0.3)",
      width: spacing(75),
      height: spacing(50),
      maxWidth: "100%",
      [below(1033)]: {
        width: "100%",
        height: "auto",
      },
    },
    image: {
      maxWidth: "100% !important",
      maxHeight: spacing(50),
      height: "auto",
      [below(values.xs)]: {
        height: "auto",
        maxHeight: spacing(31.25),
      },
    },
    byline_container: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
    },
    byline: {
      marginBottom: spacing(1),
    },
    actions_main_container: {
      padding: `${spacing(1)}px 0px`,
      "& [class*='actions_container']": {
        justifyContent: "center",
      },
    },
    description: {
      "& h1": {
        fontStyle: "italic",
        fontSize: `${spacing(3)}px !important`,
        fontWeight: 400,
        "& :hover": {
          textDecoration: "underline",
        },
      },
    },
    card_subheader: {
      textTransform: "uppercase",
      "& h4": {
        fontWeight: 700 + " !important",
        color: theme.palette.themeColor.primary + " !important",
      },
      "& :hover": {
        textDecoration: "underline",
        textUnderlineOffset: `${spacing(0.625)}px`,
        textDecorationThickness: "2px",
      },
    },
    card_content_info: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: spacing(0.5),
      [below(values.xs)]: {
        gap: spacing(1.5),
        marginTop: 0,
      },
    },
  };
});

export { useStyles };
