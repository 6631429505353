import React from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { useStyles } from "./TextInput.style";
import clsx from "clsx";

const generateId = () => {
  return Math.random().toString(16).slice(2);
};

const TextInput = ({
  id,
  disabled,
  multiline,
  rows,
  className,
  placeholder,
  rowsMax,
  onChange,
  onFocus,
  ref,
  value,
  name,
  type,
  inputProps,
  onKeyPress,
}) => {
  const classes = useStyles();
  const classesInput = clsx(classes.input, className);

  return (
    <>
      <TextField
        inputRef={ref}
        onFocus={onFocus}
        placeholder={placeholder}
        value={value}
        multiline={multiline}
        minRows={rows}
        maxRows={rowsMax}
        onChange={onChange}
        disabled={disabled}
        id={id}
        className={classesInput}
        variant="outlined"
        name={name}
        type={type}
        InputProps={inputProps}
        onKeyPress={onKeyPress}
      />
    </>
  );
};

TextInput.propTypes = {
  error: PropTypes.bool,
  ref: PropTypes.object,
  type: PropTypes.oneOf(["text", "number", "phoneword", "email"]),
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  value: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  multiline: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  Inputprops: PropTypes.any,
  onKeyPress: PropTypes.func,
};

TextInput.defaultProps = {
  value: "",
  type: "text",
  placeholder: "",
  className: "",
  error: false,
  rows: 1,
  rowsMax: 1000,
  multiline: false,
  disabled: false,
  min: 0,
  max: Infinity,
  id: generateId(),
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onClick: () => {},
  isMandatory: false,
  autoComplete: "on",
  name: "",
};

export default TextInput;
