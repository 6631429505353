import { makeStyles } from "../Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    custom_chip: {
      borderRadius: `${theme.spacing(0.5)}px !important`,
      "& svg": {
        width: theme.spacing(2),
      },
    },
  };
});

export { useStyles };
