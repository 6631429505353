import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./BubbledButton.styles";
import clsx from "clsx";
import ButtonMaterial from "@material-ui/core/Button";
import { colorReference } from "../Theme/themes/default";
import { useTextColorStyles } from "../style/textColor";

const BubbledButton = ({
  level,
  children,
  color,
  className,
  bold,
  underlined,
  variant,
  onClick,
  onMouseEnter,
  disabled,
  startIcon,
  endIcon,
  dataCy,
  backgroundColor,
  labelColor,
}) => {
  const classes = useStyles({ backgroundColor, labelColor });
  const textColorClasses = useTextColorStyles();

  const getChildren = () => {
    return bold ? <strong>{children}</strong> : children;
  };

  const elements = {
    button_orange: (
      <ButtonMaterial
        className={clsx(
          classes.button,
          classes.mont_bold,
          classes.font_size_15_px,
          disabled ? classes.button_disabled : classes.orange_button,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          disabled && classes.button_disabled
        )}
        variant={variant}
        color={color}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        disabled={disabled}
        startIcon={startIcon}
        endIcon={endIcon}
        data-cy={dataCy}
      >
        {getChildren()}
      </ButtonMaterial>
    ),
    button_green: (
      <ButtonMaterial
        className={clsx(
          classes.button,
          classes.green_button,
          classes.mont_bold,
          classes.font_size_15_px,
          className,
          disabled ? classes.button_disabled : classes.green_button,
          textColorClasses[color],
          underlined && classes.underlined,
          disabled && classes.button_disabled
        )}
        variant={variant}
        color={color}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        disabled={disabled}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {getChildren()}
      </ButtonMaterial>
    ),
    custom_button: (
      <ButtonMaterial
        className={clsx(
          classes.button,
          classes.mont_bold,
          classes.font_size_15_px,
          className,
          disabled ? classes.button_disabled : classes.custom_button,
          textColorClasses[color],
          underlined && classes.underlined,
          disabled && classes.button_disabled
        )}
        variant={variant}
        color={color}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        disabled={disabled}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {getChildren()}
      </ButtonMaterial>
    ),
  };
  return children ? elements[level] : null;
};

BubbledButton.propTypes = {
  level: PropTypes.oneOf(["button_orange", "button_green", "custom_button"])
    .isRequired,
  color: PropTypes.oneOfType([
    PropTypes.oneOf(colorReference),
    PropTypes.string,
  ]),
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  underlined: PropTypes.bool,
  bold: PropTypes.bool,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  disabled: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  dataCy: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default BubbledButton;
