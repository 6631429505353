import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      marginTop: theme.spacing(3),
      color: theme.palette.themeColor.primary,
      fontWeight: 700,
      textTransform: "uppercase",
      paddingLeft: theme.spacing(1),
    },
    ads_container: {
      width: "100%",
      display: "flex",
      justifyContent: (props) => (props.singleAd ? "center" : "space-evenly"),
    },
    cards_container: {
      paddingInline: theme.spacing(4),
      width: "100%",
      display: "flex",
      [theme.breakpoints.up(768)]: {
        flexWrap: (props) => (props.isFourCards ? "wrap" : ""),
        alignItems: (props) => (props.isFourCards ? "center" : "flex-start"),
      },
      ["& > div > div > div"]: {
        [theme.breakpoints.up(768)]: {
          display: (props) => (props.isFourCards ? "flex" : "block"),
          alignItems: (props) => (props.isFourCards ? "center" : "flex-start"),
        },
      },
      justifyContent: "space-between",
      borderBottom: 0,
      [theme.breakpoints.down(768)]: {
        paddingInline: theme.spacing(3),
        display: "block",
        borderBottom: 0,
        marginBottom: theme.spacing(2),
      },
    },
    one_card_container: {
      width: "100%",
      display: "flex",
      gap: theme.spacing(3),
      alignContent: "center",
      borderBottom: 0,
      [theme.breakpoints.down(768)]: {
        display: "block",
        borderBottom: 0,
        marginBottom: theme.spacing(2),
        paddingInline: theme.spacing(3),
      },
      ["& div"]: {
        height: "100%",
        width: "100%",
      },
    },
    cards_container_mobile: {
      borderBottom: 0,
    },
    two_or_more_cards_container: {
      gap: theme.spacing(3),
      width: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up(768)]: {
        paddingInline: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      [theme.breakpoints.down(768)]: {
        paddingInline: theme.spacing(3),
      },
      backgroundColor: theme.palette.miscellaneous.white,
      "& > div": {
        width: "100%",
        display: "flex",
        gap: theme.spacing(3),
        [theme.breakpoints.down(768)]: {
          flexDirection: "column",
        },
      },
    },
    space_evenly: {
      justifyContent: "space-evenly",
    },
    double_real_estate_carousel_image: {
      [theme.breakpoints.down(768)]: {
        "& [class*='image_container']": {
          height: `${theme.spacing(20)}px !important`,
        },
      },
      "& [class*='image_container']": {
          height: `auto !important`,
        },
      "& img": {
        maxHeight: theme.spacing(22.5),
      },
    },
    double_real_estate_carousel_container: {},
    triple_real_estate_carousel_image: {
      [theme.breakpoints.down(768)]: {
        "& [class*='image_container']": {
          height: `${theme.spacing(20)}px !important`,
        },
      },
      "& img": {
        maxHeight: theme.spacing(25),
      },
    },

    real_estate_carousel_image_four_cards: {
        "& [class*='image_container']": {
          width: `${theme.spacing(15.25)}px`,
          height: `${theme.spacing(12.5)}px`,
        },
    },

    real_estate_image_container: {
      [theme.breakpoints.up(768)]: {
        maxWidth: `${theme.spacing(15)}px !important`,
      },
    },
    triple_real_estate_carousel_container: {},
    real_estate_image_container_four_cards: {
      height: `${theme.spacing(12.5)}px`,
      width: `${theme.spacing(15.25)}px`,
    },
    
  };
});

export { useStyles };
