import React from "react";
import PropTypes from "prop-types";
import labels from "../../config/labels";
import { useStyles } from "./ReusableLogo.style";

const ReusableLogo = ({ className, townName }) => {
  const classes = useStyles();

  return (
    <div className={className ? className : classes.logo_container}>
      <span className={classes.tap_town}>{labels.TAP}</span>
      <span className={classes.into}>{labels.INTO + " "}</span>
      {townName && <span className={classes.tap_town}>{" " + townName}</span>}
    </div>
  );
};

ReusableLogo.propTypes = {
  className: PropTypes.string,
  townName: PropTypes.string,
};

export default ReusableLogo;
