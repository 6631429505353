import React from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./UserConfirmationContent.style";
import Link from "../../../common/Link/Link";
import { CONTACT_EMAIL } from "../logInConstants";
import { replaceInHtmlText } from "../../../utils/helper";

const UserConfirmationContent = ({
  customMessage,
  handleResendConfirmationEmail,
  errors,
}) => {
  const classes = useStyles();

  const hasHereText = () => {
    return customMessage.includes("here.");
  };

  return (
    <>
      <div className={classes.content}>
        <Typography
          level="h4"
          color="tapintoOrange"
          bold
          className={classes.title_for_mobile}
        >
          {labels.ACCOUNT_NOT_CONFIRMED}
        </Typography>

        {customMessage ? (
          <div className={classes.custom_message}>
            {
              <Typography level="t3_text_3" color="gray">
                <div
                  dangerouslySetInnerHTML={{
                    __html: customMessage,
                  }}
                />
              </Typography>
            }
            <div>
              <Typography level="t3_text_3" color="gray">
                {labels.IF_YOU_DID_NOT_RECEIVE_CONFIRMATION_EMAIL}
              </Typography>
              <Link
                className={classes.custom_link}
                children={"here."}
                level="t3_text_3"
                onClick={() => handleResendConfirmationEmail()}
              />
            </div>
          </div>
        ) : (
          <>
            <Typography level="t3_text_3" color="gray">
              {labels.ACCOUNT_NOT_CONFIRMED_MESSAGE_1}
              <div
                className={classes.here_link}
                onClick={handleResendConfirmationEmail}
              >
                {labels.ACCOUNT_NOT_CONFIRMED_MESSAGE_2}
              </div>
              {labels.ACCOUNT_NOT_CONFIRMED_MESSAGE_3}
              <Link
                children={CONTACT_EMAIL}
                level="t3_text_3"
                url={"mailto:" + CONTACT_EMAIL}
                color="blueLink"
              />
            </Typography>

            {errors.length > 0 && (
              <Typography level="t3_text_3" color="red">
                {errors.map((error) => error.error)}
              </Typography>
            )}
          </>
        )}
      </div>
    </>
  );
};

UserConfirmationContent.propTypes = {
  email: PropTypes.string,
  error: PropTypes.bool,
};

export default UserConfirmationContent;
