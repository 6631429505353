import { makeStyles } from "../Theme/Theme";

const useStyles = makeStyles((theme) => ({
  swiper: {
    width: "100%",
    maxWidth: "100% !important",
    "& .swiper-button-next": {
      display: "block !important",
    },
    [theme.breakpoints.up(500)]: {
      maxWidth: "100% !important",
    },
    "& .swiper-pagination": {},
  },
  navigator_button: {
    width: theme.spacing(4),
    position: "absolute",
    cursor: "pointer",
    top: "40%",
    zIndex: 2,
    "& svg": {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    [theme.breakpoints.down(601)]: {
      top: `${theme.spacing(14)}px !important`,
    },
    [`@media print`]: {
      display: "none",
    },
  },
  previous: {
    left: theme.spacing(0.5),
    [theme.breakpoints.down(601)]: {
      left: 0,
    },
  },
  next: {
    right: theme.spacing(1),
    [theme.breakpoints.down(601)]: {
      right: 0,
    },
  },
}));

export { useStyles };
