import React, { Fragment } from "react";
import clsx from "clsx";
import { useStyles } from "./ContentLinks.style";
import Icon from "../Icon/Icon";
import ClickableIcon from "../../../../common/Icons/ClickableIcon/ClickableIcon";
import { array, object, oneOfType } from "prop-types";
import SubmitLegalNoticeRequestModal from "../../SubmitLegalNoticeRequestModal";
import LegalNoticeRequestThankyouModal from "../../LegalNoticeRequestThankyouModal";

const ContentLinks = ({
  theme: { primaryColor, secondaryColor },
  contributables,
  legalNoticePermissions,
  currentUserId,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [openThankYouModal, setOpenThankYouModal] = React.useState(false);
  
  const handleCloseThankyouModal = () => {
    setOpenThankYouModal(false);
  }

  const handleClose = () => {
    setIsOpen(false);
  }

  const iconProps = {
    fillColor: secondaryColor,
    offHoverColor: secondaryColor,
    onHoverColor: primaryColor,
  };

  return (
    <>
      <div className={classes.contributables}>
        {contributables?.paid_content?.map((paidContent) => {
          const isLegalNotice = paidContent?.code === "legal_notice";
          const disableContent = isLegalNotice && legalNoticePermissions && (legalNoticePermissions?.show_permission_form || legalNoticePermissions?.show_thankyou_message);
          const showLegalNoticePermissionForm = isLegalNotice && legalNoticePermissions && legalNoticePermissions?.show_permission_form;
          const showLegalNoticeThankYouMessage = isLegalNotice && legalNoticePermissions && legalNoticePermissions?.show_thankyou_message;
          return (
            <Fragment key={paidContent.position}>
              <ClickableIcon
                className={classes.clickable_icon_container}
                icon={<Icon iconProps={iconProps} type={paidContent.code} />}
                lowerText={paidContent?.name}
                typographyLevel="t1_text_1"
                textColor="black"
                url={paidContent?.url}
                disabled={disableContent}
                onClick={() => {
                  if (showLegalNoticePermissionForm) {
                    setIsOpen(true);
                    return
                  }
                  if (showLegalNoticeThankYouMessage) {
                    setOpenThankYouModal(true);
                    return
                  }
                }}
              />
            </Fragment>
          )
        })}

        <div
          className={clsx(
            classes.free_contributables,
            contributables?.free_content?.length > 0 &&
              contributables.paid_content?.length > 0 &&
              classes.top_border
          )}
        >
          {contributables?.free_content?.map((freeContent) => (
            <Fragment key={freeContent.position}>
              <ClickableIcon
                className={classes.clickable_icon_container}
                icon={<Icon iconProps={iconProps} type={freeContent.code} />}
                lowerText={freeContent.name}
                typographyLevel="t1_text_1"
                textColor="black"
                url={freeContent.url}
              />
            </Fragment>
          ))}
        </div>
      </div>
      <SubmitLegalNoticeRequestModal 
        isOpen={isOpen}
        onClose={handleClose}
        currentUserId={currentUserId}
      />
      <LegalNoticeRequestThankyouModal 
        isOpen={openThankYouModal}
        onClose={handleCloseThankyouModal}
      />
    </>
  );
};

ContentLinks.propTypes = {
  theme: object,
  contributables: oneOfType([object, array]),
};

export default ContentLinks;
