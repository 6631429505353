import {
  AD_BLOCK,
  AD_PLACEHOLDER,
  BUSINESS_LISTING_BLOCK,
  CATEGORY_BLOCK,
  CLASSIFIED_BLOCK,
  COLUMN_BLOCK,
  EVENTS_BLOCK,
  MILESTONES_BLOCK,
  OBITUARIES_BLOCK,
  REAL_ESTATE_BLOCK,
  SECTION_BLOCK,
  SUBSCRIPTION_BOX,
  LEGAL_NOTICES_BLOCK,
  GAMES_BLOCK,
} from "../constants/homepageConstants";

export const isBlock = ({ blockClass, type }) => {
  return blockClass === type;
};

export const getSponsorInfo = (sponsors, block_id) => {
  const render_sponsor = sponsors.find((sponsor) => {
    return sponsor.display_block_id === block_id;
  });
  return render_sponsor ? render_sponsor : null;
};

export const specialCaseCard = (blockClass) => {
  return (
    isBlock({ type: EVENTS_BLOCK, blockClass: blockClass }) ||
    isBlock({ type: OBITUARIES_BLOCK, blockClass: blockClass }) ||
    isBlock({ type: CLASSIFIED_BLOCK, blockClass: blockClass }) ||
    isBlock({ type: BUSINESS_LISTING_BLOCK, blockClass: blockClass }) ||
    isBlock({ type: MILESTONES_BLOCK, blockClass: blockClass })
  );
};

export const defineTypeOfContentForDisplayBlocks = ({ blockClass, slug }) => {
  switch (blockClass) {
    case BUSINESS_LISTING_BLOCK:
      return "directories";
    case CATEGORY_BLOCK:
      return `categories/${slug}`;
    case SECTION_BLOCK:
      return `sections/${slug}`;
    case COLUMN_BLOCK:
      return `columns/${slug}`;
    case REAL_ESTATE_BLOCK:
      return "real_estate_listings";
    case EVENTS_BLOCK:
      return "events";
    case OBITUARIES_BLOCK:
      return "obituaries";
    case MILESTONES_BLOCK:
      return "milestones";
    case CLASSIFIED_BLOCK:
      return "classifieds";
    case LEGAL_NOTICES_BLOCK:
      return "legal_notices";
    case GAMES_BLOCK:
      return "games";
    default:
      break;
  }
};

export const showBlockSection = (section, block) => {
  switch (section) {
    case "title":
      return block?.title;
    case "content":
      return block?.contents?.length > 0;
    case "ad":
      return isBlock({ blockClass: block?.class, type: AD_BLOCK });
    case "subscription":
      return isBlock({ blockClass: block?.class, type: SUBSCRIPTION_BOX });
    default:
      break;
  }
};

export const showEmptyBlock = (block, adUnit) => {
  return showBlockSection("ad", block) && !adUnit;
};

export const isAdPlaceholderBlock = (blockTitle) => {
  return blockTitle === AD_PLACEHOLDER;
};

export const groupBlocksByClassWithSameOrder = (blocks) => {
  let groupedBlocks = [];
  let currentAdGroup = [];
  let currentNonAdGroup = [];

  blocks.forEach((block) => {
    if (block.class === "AdBlock") {
      if (currentNonAdGroup.length > 0) {
        groupedBlocks.push(currentNonAdGroup);
        currentNonAdGroup = [];
      }
      currentAdGroup.push(block);
    } else {
      if (currentAdGroup.length > 0) {
        groupedBlocks.push(currentAdGroup);
        currentAdGroup = [];
      }
      currentNonAdGroup.push(block);
    }
  });

  // Push any remaining blocks in the groups
  if (currentAdGroup.length > 0) {
    groupedBlocks.push(currentAdGroup);
  }
  if (currentNonAdGroup.length > 0) {
    groupedBlocks.push(currentNonAdGroup);
  }

  return groupedBlocks
};
