import React from "react";
import { ICONS_SIZES } from ".";

export const Sunny = ({ className, size = 'lg' }) => {
  const iconSize = ICONS_SIZES[size];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      className={className}
      style={{ width: 'auto', height: iconSize }}
    >
      <path
        d="M17.0717 7.68932C16.6833 7.68932 16.3727 7.37865 16.3727 6.9903L16.295 0.699036C16.295 0.310684 16.6057 0 16.9163 0C17.3047 0 17.6154 0.310684 17.6154 0.699036L17.6931 6.9903C17.6931 7.14563 17.6154 7.30096 17.5377 7.45631C17.3824 7.61165 17.2271 7.68932 17.0717 7.68932Z"
        fill="white"
      />
      <path
        d="M9.84839 10.4078C9.92603 10.5631 10.0814 10.6408 10.3144 10.6408C10.4697 10.6408 10.6251 10.5631 10.7804 10.4078C11.0911 10.1748 11.0911 9.78641 10.8581 9.47572L6.66388 4.73787C6.50854 4.58252 6.35321 4.50485 6.19788 4.50485C6.04254 4.50485 5.88721 4.58252 5.73187 4.73787C5.4212 4.97087 5.4212 5.35922 5.65417 5.66991L9.84839 10.4078Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.91632 16.5437C8.91632 20.9709 12.5668 24.6213 16.994 24.6213C21.4988 24.6213 25.0717 21.0485 25.0717 16.5437C25.0717 12.1165 21.4212 8.466 16.994 8.466C12.4891 8.466 8.91632 12.1165 8.91632 16.5437ZM10.3144 16.6213C10.3144 12.8932 13.3435 9.94173 16.994 9.94173C20.7222 9.94173 23.6736 12.9709 23.6736 16.6213C23.6736 20.3495 20.6445 23.3009 16.994 23.3009C13.2658 23.3009 10.3144 20.2718 10.3144 16.6213Z"
        fill="white"
      />
      <path
        d="M1.07159 15.767L7.44055 16.3107C7.75122 16.3107 8.06189 16 8.06189 15.6893C8.06189 15.3786 7.82892 14.9903 7.44055 14.9903L1.14929 14.4466C0.760925 14.4466 0.450256 14.6796 0.450256 15.0679C0.450256 15.2233 0.527954 15.3786 0.605591 15.534C0.760925 15.6893 0.91626 15.767 1.07159 15.767Z"
        fill="white"
      />
      <path
        d="M3.40167 25.5534H3.24634C3.091 25.4757 2.93567 25.398 2.85803 25.2427C2.78033 25.0874 2.78033 24.932 2.78033 24.7767C2.85803 24.6214 2.93567 24.466 3.091 24.3883L8.45026 21.0485C8.60559 20.9709 8.76093 20.8932 8.91626 20.9709C9.07159 21.0485 9.22693 21.1262 9.30463 21.2815C9.38226 21.4369 9.45996 21.5922 9.38226 21.7476C9.30463 21.9029 9.22693 22.0582 9.07159 22.1359L3.7124 25.4757C3.7124 25.5534 3.55701 25.5534 3.40167 25.5534Z"
        fill="white"
      />
      <path
        d="M11.3241 31.9223C11.4018 32 11.4794 32 11.5571 32C11.7901 32 12.1008 31.8447 12.1785 31.534L14.4309 25.6311C14.5862 25.3204 14.3532 24.932 14.0425 24.7767C13.7319 24.6214 13.3435 24.8544 13.1882 25.165L10.9357 31.068C10.8581 31.301 10.8581 31.4563 10.9357 31.6116C11.0134 31.767 11.1688 31.8447 11.3241 31.9223Z"
        fill="white"
      />
      <path
        d="M31.2075 25.3204C31.1298 25.3204 30.9745 25.3204 30.8968 25.2427L25.3046 22.3689C25.1492 22.2913 24.9939 22.1359 24.9939 21.9806C24.9279 21.8484 24.9742 21.7162 25.0374 21.5361L25.0512 21.4966C25.0579 21.4773 25.0648 21.4574 25.0716 21.4369C25.1492 21.2816 25.3046 21.1262 25.4599 21.1262C25.6152 21.0485 25.8483 21.1262 26.0036 21.2039L31.5958 24.1553C31.7512 24.233 31.9065 24.3884 31.9065 24.5437C31.9842 24.699 31.9066 24.8544 31.8289 25.0097C31.6735 25.2427 31.4405 25.3204 31.2075 25.3204Z"
        fill="white"
      />
      <path
        d="M26.1591 15.3786C26.2368 15.4563 26.3921 15.534 26.5474 15.534L26.7028 15.6116L32.9941 14.9126C33.3047 14.8349 33.6154 14.5243 33.5377 14.1359C33.5377 13.9029 33.4601 13.7476 33.3047 13.6699C33.2271 13.5146 32.9941 13.5146 32.8387 13.5146L26.5474 14.2136C26.3145 14.2136 26.1591 14.2913 26.0814 14.4466C25.9261 14.5243 25.9261 14.7573 25.9261 14.9126C25.9261 15.1456 26.0038 15.301 26.1591 15.3786Z"
        fill="white"
      />
      <path
        d="M21.9647 32C21.6541 32 21.4211 31.8447 21.3434 31.534L19.2463 25.5534C19.1686 25.3981 19.1686 25.2427 19.2463 25.0097C19.324 24.8544 19.4793 24.699 19.6346 24.699C19.79 24.6214 19.9453 24.6214 20.1783 24.699C20.3337 24.7767 20.489 24.932 20.489 25.0874L22.5861 31.068C22.6638 31.2233 22.6638 31.3786 22.5861 31.6116C22.5084 31.767 22.3531 31.9223 22.1978 31.9223C22.1201 32 22.0424 32 21.9647 32Z"
        fill="white"
      />
      <path
        d="M22.6638 9.94174C22.7414 10.0194 22.8967 10.0971 23.0521 10.0971C23.2851 10.0971 23.4404 9.94174 23.5958 9.78639L27.4793 4.81552C27.6346 4.73785 27.6346 4.50485 27.6346 4.3495C27.6346 4.19417 27.4793 4.03883 27.4016 3.96115C27.2463 3.80582 27.0909 3.80582 26.9356 3.80582C26.7026 3.80582 26.5472 3.88348 26.4695 4.03882L22.5861 9.0097C22.3531 9.32037 22.3531 9.70872 22.6638 9.94174Z"
        fill="white"
      />
    </svg>
  );
};
