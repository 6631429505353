import React from "react";
import clsx from "clsx";
import Grid from "../../../../common/Grid/Grid";
import Typography from "../../../../common/Typography/Typography";
import { bool, node, object, oneOf, shape, string } from "prop-types";
import ReactPlayer from "react-player";
import Link from "../../../../common/Link/Link";
import { useStyles } from "./HorizontalCard.style";

const HorizontalCard = ({ contents, variant, options, classes }) => {
  const {
    name: header,
    media_content: media,
    url_content: contentUrl,
  } = contents || {};
  const mediaItem = media?.length > 0 && media[0];
  const defaultClasses = useStyles();

  const layoutClasses = {
    link: clsx(defaultClasses[`link_${variant}`], classes?.link),
    container: {
      root: defaultClasses[`container_${variant}`],
      ...classes?.container,
    },
    header_container: {
      root: options?.truncatedHeader
        ? defaultClasses[`header_container_truncated_${variant}`]
        : defaultClasses[`header_container_${variant}`],
      ...classes?.header_container,
    },
    media_container: {
      root: defaultClasses[`media_container_${variant}`],
      ...classes?.media_container,
    },
    header: clsx(defaultClasses[`header_${variant}`], classes?.header),
    image: clsx(defaultClasses[`image_${variant}`], classes?.image),
  };

  const LayoutWithMedia = () => {
    return (
      <a href={contentUrl} className={layoutClasses?.link}>
        <Grid container classes={layoutClasses?.container}>
          {mediaItem && (
            <Grid item classes={layoutClasses?.media_container}>
              {mediaItem?.isVideo ? (
                <ReactPlayer
                  url={mediaItem?.contentUrl}
                  width="100%"
                  height="100%"
                  controls={true}
                  {...options?.videoPlayer}
                />
              ) : (
                <div
                  style={{
                    backgroundImage: `url(${
                      variant == "layout_4"
                        ? mediaItem?.contentUrl
                        : mediaItem?.contentThumbUrl
                    })`,
                  }}
                  className={layoutClasses?.image}
                />
              )}
            </Grid>
          )}
          <Grid item classes={layoutClasses?.header_container}>
            <Typography level="paragraph_1" className={layoutClasses?.header}>
              {header}
            </Typography>
          </Grid>
        </Grid>
      </a>
    );
  };

  const HeaderLink = () => {
    return (
      <Grid container classes={layoutClasses?.container}>
        <Grid item xs={12} classes={layoutClasses?.header_container}>
          <Link
            url={contentUrl}
            children={header}
            level="h5_sub_header_2"
            className={layoutClasses?.header}
          />
        </Grid>
      </Grid>
    );
  };

  switch (variant) {
    case "layout_1":
    case "layout_2":
    case "layout_4":
      return <LayoutWithMedia />;
    case "layout_5":
      return <HeaderLink />;
    default:
      return <></>;
  }
};

HorizontalCard.propTypes = {
  contents: shape({
    header: string,
    media: object,
    contentUrl: string,
  }),
  variant: oneOf(["layout_1", "layout_2", "layout_4", "layout_5"]),
  options: shape({
    videoPlayer: object,
    selected: bool,
    truncatedHeader: bool,
  }),
  classes: shape({
    header_container: string,
    media_container: string,
    image: string,
    link: string,
    container: string,
    header: string,
    image: string,
  }),
};

HorizontalCard.defaultProps = {
  variant: "layout_1",
  options: {
    selected: false,
    truncatedHeader: false,
  },
};

export default HorizontalCard;
