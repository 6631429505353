import React, { Fragment } from "react";
import clsx from "clsx";
import Typography from "../../../common/Typography/Typography";
import MenuList from "../../../common/Menu/components/MenuList/MenuList";
import MenuListItem from "../../../common/Menu/components/MenuListItem/MenuListItem";
import HorizontalDivider from "../../../common/HorizontalDivider/HorizontalDivider";
import { useStyles } from "./SubmenuForMobile.style";
import { checkIfIsNotTheLastItem } from "../../../utils/helper";
import { array, func, object } from "prop-types";

const SubmenuForMobile = ({ subMenuItems, onItemClick, typographyProps }) => {
  const classes = useStyles();

  return (
    <MenuList disablePadding={true}>
      {subMenuItems?.map((submenuItem, submenuIndex) => (
        <Fragment key={`submenu-list-${submenuIndex}`}>
          <MenuListItem
            title={submenuItem.name}
            key={`submenu-list-item-${submenuIndex}`}
            disableGutters={false}
            classes={{
              root: classes.submenu_list_item,
              gutters: classes.submenu_item_gutters,
            }}
            onClick={() => onItemClick(submenuItem)}
          >
            <Typography
              level={typographyProps?.level || "paragraph_2"}
              color={typographyProps?.color || "white"}
              bold={typographyProps?.bold || false}
              underlined={typographyProps?.underlined || false}
            >
              {submenuItem.name}
            </Typography>

            {checkIfIsNotTheLastItem(submenuIndex, subMenuItems) && (
              <HorizontalDivider className={classes.submenu_divider} />
            )}
          </MenuListItem>
        </Fragment>
      ))}
      <HorizontalDivider
        className={clsx(classes.divider, classes.full_width_divider)}
      />
    </MenuList>
  );
};

SubmenuForMobile.propTypes = {
  subMenuItems: array,
  onItemClick: func,
  typographyProps: object,
};

export default SubmenuForMobile;
