import React, { useState } from "react";
import PropTypes from "prop-types";

const Classified = ({
  className,
  onHoverColor,
  offHoverColor,
  inactiveColor,
  disabled,
  fillColor,
}) => {
  const [iconColor, setIconColor] = useState(
    fillColor ? fillColor : !disabled ? offHoverColor : inactiveColor
  );
  return (
    <svg
      className={className}
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={
        !disabled
          ? () => setIconColor(onHoverColor)
          : () => setIconColor(inactiveColor)
      }
      onMouseLeave={
        !disabled
          ? () => setIconColor(offHoverColor)
          : () => setIconColor(inactiveColor)
      }
    >
      <circle cx="37.5" cy="37.5" r="32" stroke={iconColor} strokeWidth="3" />
      <circle cx="37.5" cy="37.5" r="36" stroke={iconColor} strokeWidth="3" />
      <path
        d="M31.302 49.8691H24C22.8954 49.8691 22 48.9736 22 47.8691V21.691C22 20.5865 22.8954 19.691 24 19.691H41.2618C42.3663 19.691 43.2618 20.5865 43.2618 21.691V31.7372"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect
        x="24.0576"
        y="22.4345"
        width="17.1466"
        height="4.11518"
        rx="2"
        fill={iconColor}
      />
      <rect
        x="24.0576"
        y="35.4659"
        width="12.3455"
        height="1.37173"
        rx="0.685864"
        fill={iconColor}
      />
      <rect
        x="24.0576"
        y="32.0366"
        width="16.4607"
        height="1.37173"
        rx="0.685864"
        fill={iconColor}
      />
      <rect
        x="24.0576"
        y="28.6073"
        width="17.1466"
        height="1.37173"
        rx="0.685864"
        fill={iconColor}
      />
      <rect
        x="24.0576"
        y="38.8952"
        width="10.288"
        height="1.37173"
        rx="0.685864"
        fill={iconColor}
      />
      <rect
        x="24.0576"
        y="42.3246"
        width="8.23037"
        height="1.37173"
        rx="0.685864"
        fill={iconColor}
      />
      <rect
        x="24.0576"
        y="45.7539"
        width="7.5445"
        height="1.37173"
        rx="0.685864"
        fill={iconColor}
      />
      <rect
        x="48.0575"
        y="48.2266"
        width="8.50747"
        height="1.57116"
        rx="0.785582"
        transform="rotate(37.2898 48.0575 48.2266)"
        fill={iconColor}
        stroke={iconColor}
        strokeWidth="1.57116"
      />
      <circle
        cx="41.8901"
        cy="44.3821"
        r="6.0445"
        stroke={iconColor}
        strokeWidth="3"
      />
    </svg>
  );
};

Classified.propTypes = {
  className: PropTypes.string,
  onHoverColor: PropTypes.string,
  offHoverColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  disabled: PropTypes.bool,
};

Classified.defaultProps = {
  disabled: false,
  onHoverColor: "#f98c10",
  offHoverColor: "#7fc035",
  inactiveColor: "#e3e3e3",
};

export default Classified;
