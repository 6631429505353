import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextAreaAutocomplete from "../TextAreaAutocomplete/TextAreaAutocomplete";
import useQueryParams from "../../hooks/useQueryParams";
import { useStyles } from "./MultipleAutocompleteWithChips.style";
import { buildChipDataForMultipleAutocomplete } from "../../utils/helper";

const MultipleAutocompleteWithChips = ({
  placeholder,
  label,
  chipColor,
  options,
  handleChange,
  queryParams,
  onKeyDown,
  preSelectedOptions,
  customClasses,
}) => {
  const classes = useStyles();
  const selectedOption = useQueryParams(queryParams);
  const [defaultOption, setDefaultOption] = useState([]);
  const [chipOptions, setChipOptions] = useState([]);

  useEffect(() => {
    if (selectedOption && options.length > 0) {
      const foundCategory = options.find(
        (category) => category.slug === selectedOption
      );
      if (foundCategory) {
        setDefaultOption([
          buildChipDataForMultipleAutocomplete(
            foundCategory.name,
            foundCategory.value,
            customClasses?.chip || classes.chip
          ),
        ]);
      }
    }
  }, [selectedOption, options]);

  useEffect(() => {
    const buildChipsForCategories = () => {
      const chipOptions = options.map((option) => {
        return buildChipDataForMultipleAutocomplete(
          option.name,
          option.value,
          customClasses?.chip || classes.chip
        );
      });
      setChipOptions(chipOptions);
    };

    if (chipOptions.length === 0) {
      buildChipsForCategories(options);
    }
  }, [options]);

  const getDefaultValues = () => {
    let defaultValues = defaultOption;
    if (preSelectedOptions?.length > 0) {
      defaultValues = preSelectedOptions;
    }
    return defaultValues;
  };

  return (
    <TextAreaAutocomplete
      options={chipOptions}
      isMultiple
      label={label}
      placeholder={placeholder}
      onChange={(selectedValues) => {
        handleChange(selectedValues);
      }}
      chipColor={chipColor}
      paperClass={clsx(
        classes.paper_container,
        customClasses?.paper && customClasses.paper
      )}
      className={clsx(
        classes.autocomplete,
        customClasses?.autocomplete && customClasses.autocomplete
      )}
      defaultValue={getDefaultValues()}
      withDefaultValues
      onKeyDown={onKeyDown}
    />
  );
};

MultipleAutocompleteWithChips.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.node,
  chipColor: PropTypes.string,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  queryParams: PropTypes.string,
  onKeyDown: PropTypes.func,
  customClasses: PropTypes.shape({
    paper: PropTypes.string,
    autocomplete: PropTypes.string,
    chip: PropTypes.string,
  }),
};

MultipleAutocompleteWithChips.defaultProps = {
  chipColor: "#606060",
};

export default MultipleAutocompleteWithChips;
