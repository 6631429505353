import withConfig from "../../utils/withConfig";
import labels from "../labels";
import errorLabels from "../error-labels.js/error-labels";
import disclaimerLabels from "../disclaimer-labels/disclaimer-labels";
import {
  CONTACT_EMAIL,
  TAPINTO_NET,
  TAPINTO_MARKETING,
  HERE,
  LINKS_TARGET,
  SELF_LINKS_TARGET,
  POLICE_BLOTTER_REMOVAL_URL,
} from "../../components/Article/Disclaimer/disclaimerConstants";

const htmlLabels = {
  HTML_LINK_CONTACT_INFO: `<a href='mailto:${withConfig(
    "EMAIL_CONTACT_TAPINTO"
  )}'> ${CONTACT_EMAIL}</a>`,

  HTML_LINK_TAPINTO_NET: `<a target=${SELF_LINKS_TARGET} href='${withConfig(
    "LINK_TAPINTO"
  )}'>${TAPINTO_NET}</a>`,

  HTML_LINK_TAPINTO_MARKETING_NET: `<a target=${LINKS_TARGET} href='${withConfig(
    "TAPINTO_MARKETING_URL"
  )}'>${TAPINTO_MARKETING}</a>`,

  HTML_LINK_WRITE_LETTERS_TO_EDITOR: `<a target=${LINKS_TARGET} href='${withConfig(
    "LINK_WRITE_LETTERS_TO_EDITOR"
  )}'>${disclaimerLabels.SUBMIT_LETTER_TO_EDITOR_HERE}</a>`,

  HTML_LINK_WRITE_TO_COLUMN: `<a target=${LINKS_TARGET} href='${withConfig(
    "LINK_WRITE_TO_COLUMN"
  )}'>${HERE}</a>`,

  HTML_LINK_POLICE_BLOTTER_REMOVAL: `<a target=${LINKS_TARGET} href='${POLICE_BLOTTER_REMOVAL_URL}'>${HERE}</a>`,

  HTML_DISCLAIMER_LETTERS_TO_THE_EDITOR_SUBMIT_DIY:
    "<br/><br/>Do you have an opinion on something happening in your town or about a story you read on TAPinto? Submit a letter to the editor here.",

  HTML_GO_BACK: `<a href='javascript:history.back()'>${errorLabels.GO_BACK}</a>`,

  HTML_BACK_TO_HOMEPAGE: `<a href='/'>${errorLabels.BACK_TO_HOMEPAGE}</a>`,

  HTML_INFO_AT_TAPINTO_NET: `<a href='mailto:${withConfig(
    "EMAIL_INFO_TAPINTO"
  )}'> info@tapinto.net </a>`,

  HTML_PHONE_CONTACT: `<a href='tel:908-279-0303'> 908-279-0303 </a>`,
};

export default htmlLabels;
