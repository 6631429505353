import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Card from "../../../common/Card/Card";
import VerticalLayout from "../../../common/Card/Layouts/Vertical/VerticalLayout";
import Link from "../../../common/Link/Link";
import { useStyles } from "./ObituaryCard.style";
import Typography from "../../../common/Typography/Typography";
import { VIDEO_PLAYER_OPTIONS } from "../../../utils/constants/homepageConstants";

const Obituary = ({ content, options, customClasses }) => {
  let isFourCardsObituary = options?.contentsLength === 4;
  const classes = useStyles(isFourCardsObituary);
  return (
    <Card
      withShadow={options?.withShadow}
      children={
        <VerticalLayout
          media={content.media_content[0]}
          information={
            <Link
              children={
                <div className={classes.information_container}>
                  <Typography
                    children={content.name}
                    level="t5_text_5"
                    className={clsx(classes.information, classes.name)}
                  />
                  <Typography
                    children={`${content.date_of_birth_for_front_end} - ${content.date_of_death_for_front_end}`}
                    level="t2_text_2"
                    color="gray"
                    className={clsx(classes.information, classes.time)}
                  />
                </div>
              }
              url={content.url_content}
            />
          }
          options={{
            media: {
              clickable: true,
              townContentUrl: content.url_content,
            },
            videoPlayer: VIDEO_PLAYER_OPTIONS,
            isFourCardsObituaries: isFourCardsObituary,
          }}
          customClasses={{
            image: clsx(classes.image, customClasses?.image),
            media_container: classes.media_container,
          }}
        />
      }
      classes={{
        root: clsx(
          !options?.forHomepage && classes.obituary_card,
          customClasses?.card,
          options?.contentsLength == 4 && classes.obituary_card_length_4,
          options?.contentsLength == 3 && classes.obituary_card_length_3,
          options?.contentsLength == 2 && classes.obituary_card_length_2
        ),
      }}
    />
  );
};

Obituary.propTypes = {
  content: PropTypes.object.isRequired,
  options: PropTypes.shape({
    forHomepage: PropTypes.bool,
    withShadow: PropTypes.bool,
  }),
  customClasses: PropTypes.shape({
    card: PropTypes.string,
    image: PropTypes.string,
    carousel: {
      image: PropTypes.string,
      container: PropTypes.string,
    },
  }),
};

Obituary.defualtProps = {
  options: {
    forHomepage: false,
  },
  customClasses: {
    card: "",
    image: "",
    carousel: {
      image: "",
      container: "",
    },
  },
};

export default Obituary;
