import React, { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import { useStyles } from "./MasterheadMobile.style";
import labels from "../../../config/labels";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import HamburgerMenu from "../../HamburgerMenu/HamburgerMenu";
import { redirectToHomepage } from "../../../utils/helper";
import PreviewBanner from "../PreviewBanner/PreviewBanner";
import withConfig from "../../../utils/withConfig";
import Search from "../../../common/Icons/Search";
import MobileSearch from "../../MobileSearch/MobileSearch";
import { sendMessageToParent, handleSearch } from "../../../utils/helper";
import ButtonIcon from "../../../common/Button/ButtonIcon";
import Home from "../../../common/Icons/Home";
import { urlToUseOnIframe } from "../../../utils/halstonMediaHelper";
import MenuButton from "../../../common/MenuButton/MenuButton";
import { useTownTheme } from "../../../context/TownThemeContext";
import Grid from "../../../common/Grid/Grid";
import MasterheadLogo from "../MasterheadLogo";
import { bool, object, string } from "prop-types";
import DialogPicker from "../../TownPicker/DialogPicker/DialogPicker";
import { useMastheadContext } from "../../../context/MastheadContext";
import { useTown } from "../../../context/TownContext";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import { useMasthead } from "../../../hooks/useMasthead";

const OPENED = "opened";
const CLOSED = "closed";

const MasterheadMobile = ({
  legalNoticePermissions,
  townSlug,
  isArticlePreview,
  currentUser,
  isPathUserSignIn,
  section,
  isIframe,
}) => {
  const classes = useStyles();
  const { town, themeColors } = useTownTheme();
  const { mastheadInfo, townsWithClusters, handleOpenMobileSearch } =
    useMastheadContext();
  const { nearbyTowns } = useTown();
  const { openHamburgerMenu, handleOpenMenu } = useMasthead();
  const xsScreen = screenWidthIsLowerThan(390);
  const logoContainerWidth = useMemo(() => (xsScreen ? 5 : 6), [xsScreen]);

  const toggleMenu = () => {
    handleOpenMenu();
  };

  useEffect(() => {
    const message = openHamburgerMenu ? OPENED : CLOSED;

    sendMessageToParent(
      message,
      urlToUseOnIframe(townSlug, withConfig("HOME_MONGO"))
    );
  }, [openHamburgerMenu]);

  useEffect(() => {
    if (isPathUserSignIn) {
      handleOpenMenu();
    }
  }, []);

  return (
    <>
      {isArticlePreview && <PreviewBanner />}

      <Grid
        style={{
          backgroundColor: themeColors?.masthead_colors?.background,
          marginTop: isArticlePreview ? "50px" : "0px",
        }}
        container
        justifyContent="space-evenly"
        className={classes.masterhead_container}
      >
        <Grid
          item
          className={classes.grid_item}
          xs={2}
          style={{ justifyContent: "flex-start" }}
        >
          <ButtonIcon
            className={classes.button_icon}
            children={
              <Home
                className={classes.icons}
                fillColor={themeColors?.masthead_colors?.contrast}
              />
            }
            onClick={() => redirectToHomepage(townSlug)}
            ariaLabel="Home"
          />
          <MenuButton toggleMenu={toggleMenu} />
        </Grid>

        <Grid item className={classes.grid_item} xs={logoContainerWidth}>
          <MasterheadLogo
            townName={town?.name}
            forMobile
            townSlug={townSlug}
            sectionSlug={section?.slug}
            logoUrl={mastheadInfo?.logo_image}
            customClasses={{ img: isIframe && classes.iframe_image_logo }}
          />
        </Grid>

        <Grid
          item
          className={classes.grid_item}
          xs={2}
          style={{ justifyContent: "flex-end" }}
        >
          <ButtonIcon
            className={classes.button_icon}
            children={
              <Search
                className={clsx(classes.icons, classes.search_icon)}
                fillColor={themeColors?.masthead_colors?.contrast}
              />
            }
            onClick={() => handleOpenMobileSearch()}
            ariaLabel="Search"
          />

          <DialogPicker
            town={town}
            nearbyTowns={nearbyTowns}
            townsWithClusters={townsWithClusters}
            iconButton
          />
        </Grid>
      </Grid>

      <HamburgerMenu
        openState={openHamburgerMenu}
        legalNoticePermissions={legalNoticePermissions}
        toggleMenu={toggleMenu}
        townSlug={townSlug}
        sectionSlug={section?.slug}
        currentUser={currentUser}
        isPathUserSignIn={isPathUserSignIn}
      />
    </>
  );
};

MasterheadMobile.propTypes = {
  legalNoticePermissions: object,
  townSlug: string,
  isArticlePreview: bool,
  currentUser: object,
  isPathUserSignIn: bool,
  section: object,
  isIframe: bool,
};

export default MasterheadMobile;
