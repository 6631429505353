import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: `${theme.spacing(2.5)}px ${theme.spacing(4)}px ${theme.spacing(
      2
    )}px ${theme.spacing(2)}px`,
    textAlign: "center",
    lineHeight: "18px",
  },
  buttons_container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(1.5),
  },
  continue_button: {
    height: theme.spacing(3.5),
    width: theme.spacing(11.5),
    backgroundColor: `${theme.palette.themeColor.primary} !important`,
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none",
    },
  },
  cancel_button: {
    height: theme.spacing(3.5),
    width: theme.spacing(11.5),
    border: `2px solid ${theme.palette.themeColor.primary} !important`,
    textAlign: "center",
    "& .MuiButton-label": {
      textTransform: "none",
    },
  },
  login_loading_overlay: {
    background: "rgba(255, 255, 255, 0.6) !important",
  },
}));

export { useStyles };
