import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      textTransform: "none !important",
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        color: theme.palette.miscellaneous.white,
        fontWeight: 600,
      },
    },
    sign_up_button: {
      marginTop: `${theme.spacing(2)}px !important`,
      marginBottom: `${theme.spacing(2)}px !important`,
      backgroundColor: `${theme.palette.themeColor.primary} !important`,
      width: "100% !important",
    },
    add_more_towns_button: {
      paddingLeft: "0px !important",
      "& .MuiButton-label": {
        fontSize: "12px",
        fontFamily: "Montserrat",
        color: theme.palette.themeColor.primary,
        fontWeight: 600,
      },
    },
    clause_text: {
      fontSize: "8px",
      fontWeight: 400,
      color: theme.palette.dark.gray,
      textAlign: "center",
      "& a": {
        color: theme.palette.miscellaneous.blueLink,
        fontSize: "8px",
        fontWeight: 400,
        textDecoration: "underline",
      },
    },
  };
});

export { useStyles };
