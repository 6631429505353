import React from "react";

const SquareInstagram = ({}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33333 0H26.6667C29.5897 0 32 2.41026 32 5.33333V26.6667C32 29.5897 29.5897 32 26.6667 32H5.33333C2.41026 32 0 29.5897 0 26.6667V5.33333C0 2.41026 2.41026 0 5.33333 0Z"
        fill="url(#paint0_linear_8559_2944)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4359 16C23.4359 11.8974 20.1026 8.5641 16 8.5641C11.8974 8.5641 8.56411 11.8974 8.56411 16C8.56411 20.1026 11.8974 23.4359 16 23.4359C20.1026 23.4359 23.4359 20.1026 23.4359 16ZM23.2821 7.53846C22.6154 7.53846 22.1026 8.10256 22.1026 8.71795C22.1026 9.38462 22.6154 9.89744 23.2821 9.89744C23.9487 9.89744 24.4615 9.38462 24.4615 8.71795C24.5128 8.10256 23.9487 7.53846 23.2821 7.53846ZM9.89744 5.79487H22.0513C24.3077 5.79487 26.1538 7.64102 26.1538 9.89744V22.0513C26.1538 24.3077 24.3077 26.1538 22.0513 26.1538H9.89744C7.64103 26.1538 5.79487 24.3077 5.79487 22.0513V9.89744C5.79487 7.64102 7.64103 5.79487 9.89744 5.79487ZM22.1026 4.46154H9.89744C6.8718 4.46154 4.46154 6.92308 4.46154 9.89744V22.0513C4.46154 25.0769 6.92308 27.4872 9.89744 27.4872H22.0513C25.0769 27.4872 27.4872 25.0256 27.4872 22.0513V9.89744C27.5385 6.92308 25.0769 4.46154 22.1026 4.46154ZM10.1026 16C10.1026 12.7179 12.7692 10.1026 16 10.1026C19.2308 10.1026 21.8974 12.7692 21.8974 16C21.8974 19.2821 19.2308 21.8974 16 21.8974C12.7692 21.8974 10.1026 19.2308 10.1026 16Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8559_2944"
          x1="26.7147"
          y1="25.7066"
          x2="4.82072"
          y2="5.8721"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEC053" />
          <stop offset="0.3273" stopColor="#F2203E" />
          <stop offset="0.6485" stopColor="#B729A8" />
          <stop offset="1" stopColor="#5342D6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SquareInstagram;
