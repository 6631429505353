import { alpha } from "@material-ui/core";
import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    mobile_card: {
      width: "100%",
    },
    mobile_divider: {
      border: `1px solid ${theme.palette.miscellaneous.grayDivider}`,
      margin: `${theme.spacing(2)}px 0px`,
    },
    image: {
      maxHeight: theme.spacing(31.25),
    },
    information: {
      width: "100%",
      display: "block",
    },
    information_title: {
      height: theme.spacing(6),
      display: "block",
      "& span": {
        fontSize: `${theme.spacing(2.25)}px`,
        fontWeight: 600,
        lineHeight: `${theme.spacing(3)}px`,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    secondary_story_top_section: {
      display: "flex",
      justifyContent: "space-between",
      height: "100%",
      flexDirection: "column",
    },
    card_subheader: {
      "& h4": {
        fontWeight: 700 + " !important",
        color: theme.palette.themeColor.primary + " !important",
      },
      "& :hover": {
        textDecoration: "underline",
      },
    },
    media_container: {
      backgroundColor: alpha(
        theme.palette.miscellaneous.backgroundImageGray,
        0.3
      ),
    },
    section_title: {
      textTransform: "uppercase",
      ["& h2"]: {
        color: theme.palette.themeColor.primary + " !important",
        marginBlock: theme.spacing(2),
        fontWeight: "700",
        fontSize: `${theme.spacing(2.5)}px !important`,
      },
      "& :hover": {
        textDecoration: "underline",
      },
    },
  };
});

export { useStyles };
