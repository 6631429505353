import { makeStyles } from "../../Theme/Theme";

const useStyles = makeStyles((theme) => ({
  dialog_content_root: {
    "&.MuiDialogContent-root": {
      padding: 0,
    },
    [theme.breakpoints.up(750)]: {
      "&.MuiDialogContent-root": {
        padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
      },
    },
  },
}));

export { useStyles };
