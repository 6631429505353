import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import CloseCircle from "../Icons/CloseCircle";
import Chip from "../Chip/Chip";
import { useStyles } from "./TextAreaAutocomplete.style";
import clsx from "clsx";
import { Paper } from "@material-ui/core";

const TextAreaAutocomplete = ({
  options,
  defaultValue,
  isMultiple,
  label,
  placeholder,
  variant,
  className,
  onChange,
  onRemove,
  chipColor,
  paperClass,
  withDefaultValues,
  onKeyDown,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event, valuesSelected) => {
    setValue(valuesSelected);
  };

  const handleRemove = (option) => {
    const selectedOptions = value.filter(
      (valueOption) => option.label !== valueOption.label
    );
    setValue(selectedOptions);
    onRemove(option.value);
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  useEffect(() => {
    if (withDefaultValues) {
      setValue(defaultValue);
    }
  }, [defaultValue, withDefaultValues]);

  return (
    <>
      {label}
      <Autocomplete
        multiple={isMultiple}
        freeSolo
        options={options}
        value={value}
        getOptionLabel={(option) => option.label}
        defaultValue={[...defaultValue]}
        className={clsx(className, classes.autocomplete_container)}
        PaperComponent={({ children }) => (
          <Paper className={paperClass}>{children}</Paper>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              label={option.labelComponent}
              key={`chip-element-${option.label}defaultValue-${index}`}
              className={option.chipClass}
              deleteIcon={<CloseCircle fillColor={chipColor} />}
              onDelete={() => handleRemove(option)}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} variant={variant} placeholder={placeholder} />
        )}
        onChange={handleChange}
        onKeyDown={onKeyDown}
      />
    </>
  );
};

TextAreaAutocomplete.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      labelComponent: PropTypes.node,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      chipClass: PropTypes.string,
    }).isRequired
  ).isRequired,
  defaultValue: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      labelComponent: PropTypes.node,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      chipClass: PropTypes.string,
    })
  ),
  chipColor: PropTypes.string,
  paperClass: PropTypes.string,
  withDefaultValues: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

TextAreaAutocomplete.defaultProps = {
  label: "",
  chipColor: "#ffffff",
  paperClass: "",
  defaultValue: [],
  onRemove: () => "",
  withDefaultValues: false,
};

export default TextAreaAutocomplete;
