import diyLabels from "./diy-labels/diyLabels";
import pdfUploaderLabels from "./diy-labels/pdfUploaderLabels";
import eventLabels from "./event-labels/eventLabels";
import loginLabels from "./login-labels/login-labels";
import obituaryLabels from "./obituary-labels/obituaryLabels";
import realEstateLabels from "./real-estate-labels/realEstateLabels";
import cmsLabels from "./cms-labels/cmsLabels";
import htmlLabels from "./html-labels/html-labels";
import disclaimerLabels from "./disclaimer-labels/disclaimer-labels";
import errorLabels from "./error-labels.js/error-labels";
import userLabels from "./user-labels/user-labels";
import columnLabels from "./column-labels/columnLabels";
import classifiedsLabels from "./classifieds-labels/classifiedsLabels";
import enewsSignupLabels from "./enews-labels/enewsLabels";
import draftLabels from "./draft-labels/draftLabels";
import homepageLabels from "./homepage-labels/homepageLabels";
import milestoneLabels from "./milestone-labels/milestone-labels";
import diretoriesLabels from "./directories-labels/directories-labels";
import legalNoticesLabels from "./legal-notices/labels";
import gamesLabels from "./games-labels/gamesLabels";
import ArticleLabels from "./article-labels/articleLabels";

export default {
  ...diyLabels,
  ...eventLabels,
  ...obituaryLabels,
  ...realEstateLabels,
  ...cmsLabels,
  ...loginLabels,
  ...htmlLabels,
  ...disclaimerLabels,
  ...errorLabels,
  ...userLabels,
  ...columnLabels,
  ...classifiedsLabels,
  ...enewsSignupLabels,
  ...draftLabels,
  ...homepageLabels,
  ...milestoneLabels,
  ...pdfUploaderLabels,
  ...diretoriesLabels,
  ...legalNoticesLabels,
  ...gamesLabels,
  ...ArticleLabels,
  ONE_SPACE: ` `,
  BULLET_POINT: `•`,
  BY: `By `,
  BYLINE_PUBLISHED: "Published",
  BYLINE_LAST_UPDATED: "Last Updated",
  LEVEL: "heading_section",
  SIGNUP_TITLE: "Sign Up for FREE ##contentName Newsletter",
  SIGNUP_SUBTITLE: "Get local news you can trust in your inbox.",
  EMAIL_PLACEHOLDER: "subscriber@example.com",
  RECAPTCHA_TEXT_BEGIN: "This site is protected by reCAPTCHA and the Google ",
  PRIVACY_POLICY: "Privacy Policy",
  AND: ` and `,
  TERMS_OF_SERVICE: "Terms of Service ",
  APPLY: ` apply.`,
  SUBSCRIBE: "Subscribe",

  GET_IN_TOUCH_WITH: "Get in touch with ",
  READ_MORE: "Read More ",
  READ_MORE_SECTION: "Read More",
  CLICK_TO: "Click to ",
  SUBMIT_CONTENT: "Submit Content",
  HERE: ` here `,
  TAGS: "TAGS",

  RECENT_ARTICLES_NEARBY: "Recent Articles Nearby",
  READ_MORE_2: "Read More »",
  READ_MORE_TOWN: "Read More TAPinto ",
  READ_MORE_LICENSE: "Read More ",
  NEWS: " News",

  TAPINTO_SUPPORT_MAIL: "support@tapinto.net",
  HALSTON_MEDIA: "Halston Media",
  HALSTON_MEDIA_EXTENDED_NAME:
    "(Mahopac, Katonah, Lewisboro, North Salem, Somers, Yorktown)",

  TAPINTO_NET: "TAPinto.net",
  YOU_HAVE_SUCCESSFULLY_SIGNED_UP:
    "You have successfully signed up for the TAPinto ##contentName Newsletter. If you are a new subscriber, you will soon receive an email from us. Please click on the link in the email to confirm your subscription.",
  YOU_HAVE_SUCCESSFULLY_SIGNED_UP_MULTIPLE_TOWNS:
    "You have successfully signed up for the TAPinto Newsletter(s). If you are a new subscriber, you will soon receive an email from us. Please click on the link in the email to confirm your subscription.",
  SELECT_AT_LEAST_ONE_TOWN: "You should select at least one town from the list",
  CHOOSE_TAPINTO_SITES: "TAPinto site(s) you would like to receive e-news from",
  ONE_TAPINTO_SITES: "Select ONE Site",
  CHOOSE_A_SITE: "Choose a site for publishing",
  SIGNUP_ERROR: "Your subscription didn't go through, please try again later.",
  INPUT_ERROR: "Invalid email format, please insert a valid email address.",
  SPONSORED_BY: "Sponsored By",
  TAP: "TAP",
  INTO: "into",
  SLOGAN: "Your Neighborhood News Online",
  LOGIN: "Login",
  NEWSLETTER_SIGNUP: "Newsletter Signup",
  ADMIN: "Admin",
  LOGOUT: "Logout",
  EDIT_PROFILE: "Edit Profile",
  MANAGE_CONTENT: "Manage Content",
  PHOTO_CREDIT: "Photo Credit:",
  TAPINTO_ANOTHER_TOWNS_NEWS: "TAPinto another town's news",
  TAPINTO_YOUR_LOCAL_NEWS: "TAPinto your local news",
  HOME: "Home",
  SECTIONS: "Sections",
  OPINION: "Opinion",
  ADVERTISE_WITH_US: "Advertise with us",
  FRANCHISE_TAPINTO: "Franchise TAPinto",
  TAPINTO_NEARBY: "TAPinto Nearby ...",
  CHOOSE_YOUR_TOWN: "Choose your town",
  EDITORS_NOTES: "Editor's Note: ",
  HOW_TO_SUBMIT_CONTENT_ON_TAPINTO: "How to Submit Content on TAPinto",
  PRESS_RELEASE_ANNOUNCEMENT: "Press release / Announcement",
  CALENDAR_EVENT_LISTING: "Calendar Event Listing",
  CANDIDATE_STATEMENT: "Candidate Statement",
  MILESTONE: "Milestone",
  REAL_ESTATE_LISTING: "Real Estate Listing",
  CLASSIFIED: "Classified",
  BUSINESS_DIRECTORY_LISTING: "Business Directory Listing",
  COLUMN: "Column",
  OBITUARY: "Obituary",
  EXEMPTION: "Apply for Exemption",
  LETTER_TO_THE_EDITOR: "Letter to the Editor",
  SUBMIT_CONTENT_FOOTER:
    "Content marketing can be included when you advertise in TAPinto.",
  TO_LEARN_MORE: "To learn more",
  CLICK_HERE: "Click here",
  EXEMPTION_CONFIRMATION_1:
    "For Non Profit / Government there is a nominal fee to have content published in TAPinto.",
  EXEMPTION_CONFIRMATION_2: "Would you like to request an exemption?",
  YES: "Yes",
  NO: "No",
  SUBMIT_REQUEST: "Submit Request",
  EXEMPTION_MAIN_TOWN:
    "Select the TAPinto site closest to where your nonprofit or government is based.",
  EXEMPTION_ADDITIONAL_TOWNS:
    "If the exemption is granted, would you like it applied to other TAPinto sites?",
  EXEMPTION_DISCLAIMER_1:
    "While your exemption request is being considered, any content that is submitted and not paid for will not be published.",
  EXEMPTION_DISCLAIMER_2:
    "Your exemption request will be evaluated within the next 72 business hours. You will be notified whether your exemption is approved or declined.",
  ERROR_MESSAGE_MULTIPLE_TOWNS_DISABLED:
    "Please select where your Non-Profit or Government is based before continuing to select additional towns.",
  SUCCESSFUL_REQUEST: "Your request has been submitted!",
  SUCCESSFUL_REQUEST_TEXT_1:
    " You will be notified whether your exemption is approved or declined within the next 72 business hours.",
  SUCCESSFUL_REQUEST_TEXT_2:
    "While your exemption request is being considered, any content that is submitted and not paid for will not be published.",
  UNSUCCESSFUL_REQUEST:
    "There was an error while processing your request. Please try again later.",
  UNSUCCESSFUL_REQUEST_1:
    "Your request could not be processed. Please try again later.",
  I_UNDERSTAND: "I Understand",
  SHOPPING_CART: "Shopping Cart",
  DOLLAR_SIGN: "$",
  PURCHASE_TOTAL: "Purchase Total",
  AGREEMENT_CLAUSE:
    "I agree to TAPinto.net's Terms of Use, Privacy Policy and Advertising Policy. I agree to license this submission for reuse by TAP IP LLC in its sole discretion.",
  NO_TOWNS_SELECTED: "You have not selected any sites yet",
  NO_PUBLICATIONS_SELECTED: "You have not selected any publications yet",
  PREVIEW_BANNER: "PREVIEW: ",
  PREVIEW_REMINDER:
    "To finish the checkout process and submit your content, return to the previous tab/window",
  CALENDAR_OF_EVENTS: "Calendar of Events",
  EVENT_CATEGORIES: "Event Categories",
  MY_DRAFTS: "My Drafts",
  SNACKBAR_SAVED_DRAFT_REDIRECTION:
    "You have been automatically redirected to your saved draft. Please finish it and continue to checkout before creating more content.",
  SEARCH_TAPINTO: "Search TAPinto",
  SUBMIT: "Submit",
  SEARCH: "Search",
  ADVERTISE: "Advertise",
  MARKETWITHTAP: "Market with TAPinto",
  REAL_ESTATE_LISTINGS: "Real Estate Listings",
  REAL_ESTATE: "Real Estate",
  NEARBY_TOWNS_TOOLTIP: "Click here to see nearby towns",
  STORY_CONTINUES_BELOW_NEWSLETTER_SIGN_UP:
    "Story continues below newsletter sign up",
  SPONSORED_CONTENT: "Sponsored Content",
  MESSAGE: "Message",
  READ_LESS: "Read less",
  RECAPTCHA_CLAUSE_TEXT:
    "This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.",
  FOLLOW_US: "Follow Us",
  COPY_RIGHT_DATE: "© Copyright ##year",
  COPY_RIGHT: "© Copyright ##year TAP IP LLC",
  RIGHTS_RESERVED: "All rights reserved.",
  DEVELOPED_BY: "Developed by",
  SENIROP: "Senirop",
  GET_LOCAL_NEWS_YOU_CAN_TRUST:
    "Get local news you can trust in your inbox for FREE",
  BY_SIGNING_UP: "By signing up, you agree to TAPinto's ",
  TERMS_OF_USE: "Terms of Use",
  SAVE_PHOTO: "Save photo",
  SAVE_FILES: "Save file(s)",
  CHOOSE_PHOTO: "Choose photo",
  BROWSE_FILES: "Browse Files",
  DRAG_AND_DROP_FILES_INSTRUCTIONS:
    "Drag and drop your file here, or click button to select a file from your computer",
  DRAG_AND_DROP_DOCUMENTS_INSTRUCTIONS:
    "Drag and drop your file(s) here, or click button to select file(s) from your computer.",
  PREVIEW_PHOTO_PLACEHOLDER: "Your preview will show here",
  SINGLE_PHOTO_VALIDATION_ERROR:
    "More than 1 file was selected. Please select a single file.",
  YOUR_PHOTO_WAS_SAVED: "Your photo has been succesfully saved",
  PHOTO_NOT_SAVED_ERROR: "There was an error uploading your image. ",
  ACCEPTED_PHOTO_FILES: "Make sure to use png, jpeg or webp files.",
  TRY_AGAIN_LATER: "Please try again later.",
  PHOTO_REMOVED: "Your photo was successfully removed",
  DONT_SEE_YOUR_TOWN_CLICK_HERE_FOR_MORE_INFO:
    "Don't see your town? Click here for more information",
  PHONE_ERROR: "Phone number is not valid, it must include an area code.",
  THIS_FIELD_IS_REQUIRED: "This is a required field",
  TOWN_EDITOR: "Editor",
  DOWNLOAD: "Download",
  DONE: "Done",
  LEARN_MORE: "Learn More",
  CHOOSE_YOUR_PUBLICATION: "Choose your publication",
  GO: "Go",
  SHOW_PDF: "Show PDF",
  GETTING_AUDIO_PLAYER_READY:
    "Getting your // Trinity Audio // player ready...",
  CLEAR_FILTERS: "Clear Filters",
  APPLY_BUTTON: "Apply",
};
