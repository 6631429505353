import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../common/Card/Card";
import VerticalLayout from "../../../../common/Card/Layouts/Vertical/VerticalLayout";
import { useStyles } from "./SpecialCaseCard.style";
import { useTown } from "../../../../context/TownContext";
import { useSection } from "../../../../context/SectionContext";
import ContentHeader from "../ContentHeader/ContentHeader";
import {
  EVENTS_BLOCK,
} from "../../../../utils/constants/homepageConstants";
import ContentInfo from "../ContentInfo/ContentInfo";
import { parseEventMedia } from "../../../../utils/helpers/mediaHelper";
import { isBlock } from "../../../../utils/helpers/homepageHelper";

const SpecialCaseCard = ({
  content,
  blockClass,
  useSectionContext,
  options,
}) => {
  const classes = useStyles();
  const { town } = useSectionContext ? useSection() : useTown();

  const { eventMedia, eventUrl } = parseEventMedia({
    event: content,
    town: town,
  });

  return (
    <Card
      withShadow={options?.withShadow}
      noRounded={true}
      children={
        <VerticalLayout
          media={
            isBlock({
              blockClass: blockClass,
              type: EVENTS_BLOCK,
            })
              ? eventMedia
              : content.media_content[0]
          }
          information={
            isBlock({ blockClass: blockClass, type: EVENTS_BLOCK }) ? (
              <div className={classes.event_info}>
                <ContentHeader content={content} blockClass={blockClass} />
                <ContentInfo content={content} blockClass={blockClass} />
              </div>
            ) : (
              <div className={classes.event_info}>
                <ContentInfo
                  content={content}
                  blockClass={blockClass}
                  asContentHeader={true}
                />
              </div>
            )
          }
          options={{
            media: {
              clickable: true,
              townContentUrl: isBlock({
                blockClass: blockClass,
                type: EVENTS_BLOCK,
              })
                ? eventUrl
                : content.url_content,
              opacityOnFocus: true,
            },
            videoPlayer: {
              width: "100%",
              height: "100%",
              showPlayable: true,
            },
            caption: { visible: false },
            credits: { visible: false },
          }}
          customClasses={{
            image: classes.image,
            media_container: classes.media_container,
          }}
        />
      }
      classes={{ root: classes.event_milestone_directory_obituary_card }}
    />
  );
};

SpecialCaseCard.propTypes = {
  content: PropTypes.object.isRequired,
  blockClass: PropTypes.string,
  options: PropTypes.shape({
    withShadow: PropTypes.bool,
  }),
};

export default SpecialCaseCard;
