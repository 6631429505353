import React, { useState } from "react";
import BubbledButton from "../../../../../common/Button/BubbledButton";
import SubmitContentModal from "../../../../Modals/SubmitContentModal/SubmitContentModal";
import { useTownTheme } from "../../../../../context/TownThemeContext";

const SubmitContentButton = ({ button, className }) => {
  const [openSubmitContentPopUp, setOpenSubmitContentPopUp] = useState(false);
  const { town } = useTownTheme();

  return (
    <>
      <SubmitContentModal
        town={{ id: town?.id }}
        handleClose={() => setOpenSubmitContentPopUp(false)}
        openState={openSubmitContentPopUp}
      />
      <BubbledButton
        level="custom_button"
        backgroundColor={button.background_color}
        labelColor={button.label_color}
        className={className}
        onClick={() => setOpenSubmitContentPopUp(true)}
      >
        {button.label}
      </BubbledButton>
    </>
  );
};

export default SubmitContentButton;
