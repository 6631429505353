import React from "react";
import Button from "../../../common/Button/Button";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import { useStyles } from "./index.style";
import Box from "../../../common/Box/Box";
import Link from "../../../common/Link/Link";

const LegalNoticeRequestThankyouModal = ({ isOpen, onClose }) => {
  const classes = useStyles();

  return (
    <PopUpDialog
      onClose={onClose}
      openState={isOpen}
      showTitle={false}
      className={classes.modal_container}
      content={
        <Box className={classes.content}>
          <Typography level="font_size_32_px">
            {labels.LEGAL_NOTICE_REQUEST_THANK_YOU}
          </Typography>
          <Typography level="medium_20_px" color="black">
            {labels.THANK_YOU_MESSAGE_FOR_CREATING_LEGAL_NOTICE_ENTITY}{" "}
            <Link
              url={`mailto:${labels.TAPINTO_SUPPORT_MAIL}.`}
              level="medium_20_px"
              color="blueLink"
            >
              {labels.TAPINTO_SUPPORT_MAIL}.
            </Link>
          </Typography>
          <Button
            level="cms_button"
            variant="contained"
            classes={{ root: classes.cancel_button }}
            onClick={onClose}
          >
            {labels.OK}
          </Button>
        </Box>
      }
    />
  );
};

export default LegalNoticeRequestThankyouModal;
