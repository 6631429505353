import React, { useState } from "react";
import PropTypes from "prop-types";

const RealEstateListing = ({
  className,
  onHoverColor,
  offHoverColor,
  inactiveColor,
  disabled,
  fillColor,
}) => {
  const [iconColor, setIconColor] = useState(
    fillColor ? fillColor : !disabled ? offHoverColor : inactiveColor
  );
  return (
    <svg
      className={className}
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={
        !disabled
          ? () => setIconColor(onHoverColor)
          : () => setIconColor(inactiveColor)
      }
      onMouseLeave={
        !disabled
          ? () => setIconColor(offHoverColor)
          : () => setIconColor(inactiveColor)
      }
    >
      <circle cx="37.5" cy="37.5" r="32" stroke={iconColor} strokeWidth="3" />
      <circle cx="37.5" cy="37.5" r="36" stroke={iconColor} strokeWidth="3" />
      <path
        d="M23.6367 53.8317V38.1601C23.6367 37.6297 23.8474 37.121 24.2225 36.7459L37.8867 23.0817L51.5509 36.7459C51.926 37.121 52.1367 37.6292 52.1367 38.1597V53.8334C52.1367 54.938 51.2413 55.8317 50.1367 55.8317H25.6367C24.5321 55.8317 23.6367 54.9362 23.6367 53.8317Z"
        fill={iconColor}
      />
      <path
        d="M38.1367 22.8317L37.8867 23.0817M37.8867 23.0817L24.2225 36.7459C23.8474 37.121 23.6367 37.6297 23.6367 38.1601V53.8317C23.6367 54.9362 24.5321 55.8317 25.6367 55.8317H50.1367C51.2413 55.8317 52.1367 54.938 52.1367 53.8334C52.1367 48.4633 52.1367 40.6882 52.1367 38.1597C52.1367 37.6292 51.926 37.121 51.5509 36.7459L37.8867 23.0817Z"
        stroke={iconColor}
      />
      <path
        d="M58.364 31.3688L58.3648 31.3695C59.3108 32.2262 59.3742 33.6798 58.5107 34.617L58.5105 34.6172C57.6455 35.5571 56.1776 35.6227 55.231 34.7615L55.2308 34.7613L38.1453 19.2317L37.809 18.9261L37.4727 19.2317L20.386 34.76L20.3853 34.7606C19.9407 35.1662 19.381 35.3663 18.8219 35.3663C18.2072 35.3663 17.6024 35.1291 17.147 34.6603L17.1063 34.616C16.2442 33.6793 16.309 32.2262 17.2526 31.3689L17.2527 31.3688L36.2424 14.1066L36.2428 14.1063C37.1302 13.2979 38.4878 13.2979 39.3752 14.1063L39.3756 14.1066L58.364 31.3688Z"
        fill={iconColor}
        stroke={iconColor}
      />
    </svg>
  );
};

RealEstateListing.propTypes = {
  className: PropTypes.string,
  onHoverColor: PropTypes.string,
  offHoverColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  disabled: PropTypes.bool,
};

RealEstateListing.defaultProps = {
  disabled: false,
  onHoverColor: "#f98c10",
  offHoverColor: "#7fc035",
  inactiveColor: "#e3e3e3",
};

export default RealEstateListing;
