import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ContentInfo.style";
import Typography from "../../../../common/Typography/Typography";
import ContentHeader from "../ContentHeader/ContentHeader";
import Link from "../../../../common/Link/Link";
import {
  BUSINESS_LISTING_BLOCK,
  EVENTS_BLOCK,
  GAMES_BLOCK,
  OBITUARIES_BLOCK,
  REAL_ESTATE_BLOCK,
} from "../../../../utils/constants/homepageConstants";
import { currencyFormatter, getTextFromHtml, pathToUrl } from "../../../../utils/helper";
import clsx from "clsx";
import labels from "../../../../config/labels";
import withConfig from "../../../../utils/withConfig";
import { useTown } from "../../../../context/TownContext";

const ContentInfo = ({
  content,
  blockClass,
  asContentHeader,
  forSingleCard,
  withTruncatedDescription = false,
  lineClamp,
}) => {
  const classes = useStyles();

  const {town} = useTown();

  switch (blockClass) {
    case EVENTS_BLOCK:
      return (
        <div className={classes.information_container}>
          <div>
            <Typography
              level="t4_text_4"
              className={classes.event_information_starts}
            >
              {content.starts}
            </Typography>
            {!content.all_day ? (
              <Typography
                level="t4_text_4"
                className={classes.event_information_time}
              >
                {content.start_time} - {content.end_time}
              </Typography>
            ) : (
              <Typography
                level="t4_text_4"
                className={classes.event_information_time}
              >
                {labels.ALL_DAY_EVENT}
              </Typography>
            )}
          </div>
          <Link
            children={
              <Typography
                level={forSingleCard ? "t4_text_4" : "t1_text_1"}
                color={"gray"}
                className={classes.event_city}
              >
                {content.city}
              </Typography>
            }
            url={content.event_url}
            className={classes.content_link}
          />
        </div>
      );
    case OBITUARIES_BLOCK:
      return (
        <div className={classes.information_container}>
          <div>
            <Typography
              level="t4_text_4"
              className={classes.obituary_information_dates}
            >
              {content.date_of_birth_for_front_end} -{" "}
              {content.date_of_death_for_front_end}
            </Typography>
          </div>
          <Link
            children={
              <Typography
                level={forSingleCard ? "t4_text_4" : "t1_text_1"}
                className={classes.event_information_description}
              >
                {getTextFromHtml(content.truncated_description)}
              </Typography>
            }
            url={content.url_content}
            className={classes.content_link}
          />
        </div>
      );
    case REAL_ESTATE_BLOCK:
      return (
        <div
          className={clsx(
            classes.real_estate_information_container,
            classes.information_container
          )}
        >
          <div>
            <Link
              children={
                <Typography
                  level={forSingleCard ? "t4_text_4" : "t1_text_1"}
                  className={classes.price_real_state}
                >
                  {currencyFormatter.format(content.price)}
                </Typography>
              }
              url={content.url_content}
              className={classes.content_link}
            />
          </div>
          <Link
            children={
              <Typography
                level="h5_sub_header_2"
                className={clsx(classes.real_estate_address)}
              >
                {`${content.address}, ${content.state} ${content.zip_code}`}
              </Typography>
            }
            url={content.url_content}
            className={classes.content_link}
          />
          <Link
            children={
              <Typography
                level={forSingleCard ? "t4_text_4" : "t1_text_1"}
                className={classes.real_estate_information}
              >
                {content.bathrooms} {labels.REAL_ESTATE_BED} |{" "}
                {content.bathrooms} {labels.REAL_ESTATE_BATH}
              </Typography>
            }
            url={content.url_content}
            className={classes.content_link}
          />
          <div>
            <Link
              children={
                <Typography
                  level={forSingleCard ? "t4_text_4" : "t1_text_1"}
                  className={classes.real_estate_status}
                >
                  {content.property_status}
                </Typography>
              }
              url={content.url_content}
              className={classes.content_link}
            />
          </div>
        </div>
      );
    case BUSINESS_LISTING_BLOCK:
      return (
        <div
          className={clsx(
            classes.information_container,
            !forSingleCard ? classes.directories_container : ""
          )}
        >
          {!forSingleCard && (
            <div>
              <Link
                children={
                  <Typography
                    level={forSingleCard ? "t4_text_4" : "t1_text_1"}
                    bold={true}
                    className={classes.content_header}
                  >
                    {content.name}
                  </Typography>
                }
                url={content.url_content}
                className={classes.content_link}
              />
            </div>
          )}
          <Link
            children={
              <Typography
                level={forSingleCard ? "t4_text_4" : "t1_text_1"}
                className={classes.directory_address}
              >
                {`${content.address}, ${content.state} ${content.zip_code}`}
              </Typography>
            }
            url={content.url_content}
            className={classes.content_link}
          />
          <div>
            <Link
              children={
                <Typography
                  level={forSingleCard ? "t4_text_4" : "t1_text_1"}
                  className={classes.directory_phone}
                >
                  {content.phone}
                </Typography>
              }
              url={content.url_content}
              className={classes.content_link}
            />
          </div>
          {content?.categories[0]?.name &&
            content?.categories[0]?.name.toLowerCase() !== "other" && (
              <div>
                <Link
                  children={
                    <Typography
                      level={forSingleCard ? "t4_text_4" : "t1_text_1"}
                      color={"gray"}
                      className={classes.category}
                    >
                      {content.categories[0].name}
                    </Typography>
                  }
                  url={content.url_content}
                  className={classes.content_link}
                />
              </div>
            )}
        </div>
      );
    case GAMES_BLOCK:
      return (
        <div>
          <Link
            children={
              <Typography
              level={forSingleCard ? "t4_text_4" : "t1_text_1"}
              bold
              className={classes.game_name}
              >
                {content.game_name}
              </Typography>
            }
            className={classes.content_link}
            url={pathToUrl(withConfig("SHOW_GAME_URL"), {town_slug: town.slug, game_slug: content.slug})}
          />
          <Link
            children={
              <Typography
              level={forSingleCard ? "t4_text_4" : "t1_text_1"}
              className={classes.game_description}
              >
                {content.game_description}
              </Typography>
            }
            className={classes.content_link}
            url={pathToUrl(withConfig("SHOW_GAME_URL"), {town_slug: town.slug, game_slug: content.slug})}
          />
        </div>
      );
    default:
      return asContentHeader ? (
        <ContentHeader
          content={content}
          blockClass={blockClass}
          lineClamp={lineClamp}
        />
      ) : (
        <>
          <Link
            children={
              <Typography
                level={forSingleCard ? "t4_text_4" : "t1_text_1"}
                className={clsx(
                  classes.content_description,
                  withTruncatedDescription && classes.information_container
                )}
              >
                {getTextFromHtml(
                  !forSingleCard
                    ? content.truncated_description
                    : content.truncated_description.slice(0, 200) + "..."
                )}
              </Typography>
            }
            url={content.url_content}
            className={classes.content_link}
          />
          {content?.categories && content.categories.length > 0 && (
            <Link
              children={
                <Typography
                  level={forSingleCard ? "t4_text_4" : "t1_text_1"}
                  className={classes.category}
                >
                  {content.categories[0].name}
                </Typography>
              }
              url={content.url_content}
              className={classes.content_link}
            />
          )}
        </>
      );
  }
};

ContentInfo.propTypes = {
  content: PropTypes.object.isRequired,
  blockClass: PropTypes.string,
  asContentHeader: PropTypes.bool,
  forSingleCard: PropTypes.bool,
  lineClamp: PropTypes.number,
  blockTitle: PropTypes.string,
};

ContentInfo.defaultProps = {
  asContentHeader: false,
  forSingleCard: false,
  lineClamp: 2,
};

export default ContentInfo;
