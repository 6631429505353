import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  errors_container: {
    width: "100%",
    textAlign: "left",
    paddingLeft: theme.spacing(2),
    lineHeight: "14px",
  },
  email_text: {
    marginTop: `${theme.spacing(2)}px !important`,
    marginBottom: `${theme.spacing(3)}px !important`,
  },
  remember_me_checkbox: {
    marginTop: theme.spacing(1),
    "& .MuiTypography-root": {
      color: theme.palette.dark.gray,
      fontSize: "17px",
      fontFamily: "Montserrat !important",
      fontWeight: 500,
    },
  },
  divider: {
    width: "65%",
    border: `1px solid ${theme.palette.themeColor.primary}`,
    marginBottom: theme.spacing(1),
    marginTop: 0,
  },
}));

export { useStyles };
