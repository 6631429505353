const directoriesLabels = {
  DISCOVER_LOCAL_BUSINESSES_AND_PROFESSIONALS:
    "Discover Local Businesses and Professionals",
  ALL_CATEGORIES: "All Categories",
  CITY: "City",
  BUSINESS_DIRECTORY: "Business Directory",
  POST_YOUR_LISTING: "Post Your Listing",
  SEE_MORE_BUSINESSES: "See More Businesses",
  BACK_TO_DIRECTORIES: "Back to Business Directory",
  SHARE_THIS_BUSINESS: "Share this Business",
  FOLLOW_THIS_BUSINESS_ON: "Follow this Business on",
  ABOUT_US: "About us",
  MAP: "Map",
  CONTACT: "Contact",
  CONTACT_BUSINESS: "CONTACT BUSINESS",
};
export default directoriesLabels;
