import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;

  return {
    how_to_submit_content_button: {
      "&.MuiButtonBase-root": {
        padding: spacing(2),
        width: "80%",
      },
      "& span.MuiButton-label": {
        fontSize: "12px",
        fontWeight: 700,
        fontFamily: "Montserrat, sans-serif",
      },
    },
    modal_contents: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      paddingTop: spacing(2),
    },
    advertise_with_us: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      paddingBottom: spacing(3),
    },
    click_here: {
      display: "flex",
      alignItems: "center",
      columnGap: "8px",
      color: palette.themeColor.secondary,
    },
  };
});

export { useStyles };
