import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  modal_link: {
    textDecoration: "none",
    color: theme.palette.miscellaneous.black,
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: 700,
    "&:hover": {
      color: theme.palette.miscellaneous.black,
    },
  },
  icon_text_active: {
    color: theme.palette.miscellaneous.black,
  },
  icon_text_inactive: {
    color: theme.palette.light.grey,
  },
  icon_container: {
    margin: `0 0 ${theme.spacing(1)}px 0`,
    display: "inline-flex",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  icon_text_line_height: {
    lineHeight: "normal",
  },
}));

export { useStyles };
