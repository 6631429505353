import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./DialogContent.style";
import MuiDialogContent from "@material-ui/core/DialogContent";

const DialogContent = ({ className, children }) => {
  const classes = useStyles();
  return (
    <MuiDialogContent
      className={className ? className : classes.dialog_content_root}
    >
      {children}
    </MuiDialogContent>
  );
};

DialogContent.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
};

export default DialogContent;
