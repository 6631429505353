import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;

  return {
    title: {
      "&.MuiDialogTitle-root": {
        margin: 0,
        background: palette.miscellaneous.black,
        height: spacing(9),
        maxHeight: spacing(9),
        textAlign: "center",
      },
    },
    title_typography: {
      fontSize: "26px",
      paddingTop: spacing(1),
    },
    close_button: {
      width: spacing(2),
      height: spacing(2),
      position: "absolute",
      right: spacing(2),
      cursor: "pointer",
      "&.MuiIconButton-root": {
        height: spacing(2),
        width: spacing(2),
        position: "absolute",
        right: spacing(1),
        top: spacing(1),
      },
    },
    submit_content_dialog: {
      zIndex: "94037 !important",
      maxWidth: theme.spacing(80) + " !important",
    },
  };
});

export { useStyles };
