import { Sunny } from "./1-sunny";
import { MostlySunny } from "./2.mostly-sunny";
import { Cloudy } from "./6-cloudy";
import { Showers} from "./12-showers";
import { HazySunshine } from "./5-hazy-sunshine";
import { Fog } from "./11-fog";
import { MostlyCloudyShowers } from "./13-mostly-cloudy-showers";
import { TStorms } from "./15-t-storms";
import { MostlyCloudyTStorms } from "./16-mostly-cloudy-t-storms";
import { Rain } from "./18-rain";
import { Flurries } from "./19-flurries";
import { MostlyCloudyFlurries } from "./20-mostly-cloudy-flurries";
import { Snow } from "./22-snow";
import { MostlyCloudySnow } from "./23-mostly-cloudy-snow";
import { Ice } from "./24-ice";
import { Sleet } from "./25-sleet";
import { Hot } from "./30-hot";
import { Cold } from "./31-cold";
import { Windy } from "./32-windy";
import { Clear } from "./33-clear";
import { PartlyCloudy } from "./35-partly-cloudy";
import { HazyMoonlight } from "./37-hazy-moonlight";
import { MostlyCloudy } from "./38-moustly-cloudy";
import { PartlyCloudySnow } from "./39-partly-cloudy-snow";
import { PartlyCloudyTStorms } from "./41-partly-cloudy-t-storm";
import { MostlyCloudyFlurriesNight } from "./43-mostly-clouudy-flurries-night";
import { MostlyCloudySnowNight } from "./44-mostly-cloudy-snow-night";

const ICONS_SIZES = {
  xs: 16,
  sm: 20,
  md: 24,
  lg: 32,
  xl: 40,
}

export {
  Sunny,
  MostlySunny,
  Cloudy,
  Showers,
  HazySunshine,
  Fog,
  MostlyCloudyShowers,
  TStorms,
  MostlyCloudyTStorms,
  Rain,
  Flurries,
  MostlyCloudyFlurries,
  Snow,
  MostlyCloudySnow,
  Ice,
  Sleet,
  Hot,
  Cold,
  Windy,
  Clear,
  PartlyCloudy,
  HazyMoonlight,
  MostlyCloudy,
  PartlyCloudySnow,
  PartlyCloudyTStorms,
  MostlyCloudyFlurriesNight,
  MostlyCloudySnowNight,
  ICONS_SIZES,
};