import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    information_container: {
      paddingTop: `${theme.spacing(1)}px `,
      [theme.breakpoints.up(768)]: {
        paddingLeft: (isFourCards) =>
          isFourCards ? theme.spacing(2) : theme.spacing(0),
      },
      ["& div"]: {
        [theme.breakpoints.up(768)]: {
          display: (isFourCards) => (isFourCards ? "block" : ""),
        },
        display: "flex",
        justifyContent: "space-between",
      },
      ["& h3"]: {
        marginBottom: "0 !important",
        lineHeight: `${theme.spacing(3.36)}px`,
      },
    },
    information: {
      display: "block",
      fontWeight: 500,
      paddingBottom: theme.spacing(2),
      fontSize: theme.spacing(2.25),
    },
    starts: {
      fontWeight: 600,
      fontSize: theme.spacing(1.75),
    },
    time: {
      fontWeight: 500,
      fontSize: theme.spacing(1.75),
    },
    city: {
      fontSize: theme.spacing(1.5),
      paddingBottom: theme.spacing(1),
      display: "block",
    },
    title: {
      fontSize: theme.spacing(2.25),
      display: "block",
      fontWeight: "700 !important",
      paddingBottom: theme.spacing(2),
      "&:hover": {
        textDecoration: "underline",
      },
    },
    image: {
      maxHeight: (isFourCards) => (!isFourCards ? "100%" : theme.spacing(30.75)),
      maxHeight: (isFourCards) => (!isFourCards ? "100%" : theme.spacing(30.75)),
      [theme.breakpoints.up(768)]: {
        height: (isFourCards) => (isFourCards ? theme.spacing(12.5) : "auto"),
        height: (isFourCards) => (isFourCards ? theme.spacing(12.5) : "auto"),
      },
      [theme.breakpoints.down(768)]: {
        maxHeight: theme.spacing(30),
      },
    },
    media_container: {
      height: theme.spacing(31.25),
      maxHeight: theme.spacing(31.25),
      [theme.breakpoints.up(768)]: {
        width: (isFourCards) => (isFourCards ? theme.spacing(15.25) : "100%"),
        height: (isFourCards) => (isFourCards ? theme.spacing(12.5) : theme.spacing(29.5)),
      },
      [theme.breakpoints.down(768)]: {
        height: theme.spacing(31.25),
        maxHeight: theme.spacing(31.25),
      },
    },

    event_card: {
      minWidth: "16vw",
      marginBottom: `${theme.spacing(3)}px !important`,
      [theme.breakpoints.down(1090)]: {
        minWidth: "25vw",
      },
      [theme.breakpoints.down(960)]: {
        minWidth: "100%",
      },
    },
    event_card_length_4: {
      width: "45%",
      [theme.breakpoints.up(768)]: {
        display: "flex",
        justifyContent: "space-between",
      },
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    event_card_length_3: {
      width: "32%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    event_card_length_2: {
      width: "48%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    title_right_block: {
      fontWeight: "600",
      fontSize: `${theme.spacing(2.25)}px !important`,
      "&:hover": {
        textDecoration: "underline",
      },
    },
  };
});

export { useStyles };
