import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./PopUpDialog.style";
import { Dialog } from "@material-ui/core";
import DialogTitle from "./DialogTitle/DialogTitle";
import DialogContent from "./DialogContent/DialogContent";

const PopUpDialog = ({
  onClose,
  onBack,
  openState,
  title,
  content,
  className,
  titleColor,
  titleClass,
  closeButtonColor,
  closeButtonClass,
  backButtonColor,
  backButtonClass,
  titleTypographyClass,
  contentClass,
  disableCloseOnBackDropClick,
  showTitle = true,
}) => {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      return onClose;
    } else {
      return;
    }
  };

  return (
    <Dialog
      onClose={disableCloseOnBackDropClick ? handleClose : onClose}
      open={openState}
      className={className ? className : classes.dialog_root}
    >
      {showTitle && (
        <DialogTitle
          onClose={onClose}
          onBack={onBack}
          children={title}
          className={titleClass}
          titleColor={titleColor}
          closeButtonColor={closeButtonColor}
          closeButtonClass={closeButtonClass}
          backButtonColor={backButtonColor}
          backButtonClass={backButtonClass}
          titleTypographyClass={titleTypographyClass}
        />
      )}
      <DialogContent className={contentClass} children={content} />
    </Dialog>
  );
};

PopUpDialog.propTypes = {
  onClose: PropTypes.func,
  openState: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.any,
  className: PropTypes.string,
  titleClass: PropTypes.any,
  titleColor: PropTypes.string,
  closeButtonColor: PropTypes.string,
  closeButtonClass: PropTypes.any,
  backButtonColor: PropTypes.string,
  backButtonClass: PropTypes.any,
  titleTypographyClass: PropTypes.any,
  contentClass: PropTypes.any,
  disableCloseOnBackDropClick: PropTypes.bool,
};

export default PopUpDialog;
