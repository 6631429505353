import React, { memo } from "react";
import PropTypes from "prop-types";
import MultipleAutocompleteWithChips from "../../../common/MultipleAutocompleteWithChips/MultipleAutocompleteWithChips";
import { useStyles } from "./MultipleWithChips.style";

const MultipleTownPickerWithChips = ({
  options,
  handleSelect,
  preselectedTowns,
}) => {
  const classes = useStyles();
  return (
    <MultipleAutocompleteWithChips
      options={options}
      handleChange={handleSelect}
      customClasses={{
        autocomplete: classes.autocomplete,
        chip: classes.chip,
        paper: classes.autocomplete_options,
      }}
      chipColor="black"
      placeholder="Select or remove towns"
      preSelectedOptions={preselectedTowns}
    />
  );
};

MultipleAutocompleteWithChips.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  handleSelect: PropTypes.func,
  preselectedTowns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      labelComponent: PropTypes.node,
      value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
      chipClass: PropTypes.string,
    })
  ),
};

export default memo(MultipleTownPickerWithChips);
