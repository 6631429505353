import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  textfield: {
    marginTop: `${theme.spacing(1)}px !important`,
    "& input.MuiInputBase-input": {
      textAlign: "center",
    },
  },
  end_adornment: {
    cursor: "pointer",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  end_adornment_svg: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export { useStyles };
