import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { down: below, values: values },
  } = theme;
  return {
    advertisement_text: {
      fontWeight: 400,
    },
    ad_card: {
      marginTop: `${spacing(2)}px !important`,
      marginBottom: `0px !important`,
      textAlign: "center",
      height: "fit-content",
      width: "fit-content",
      "& [class^='ads_container']": {
        margin: "0px !important",
      },
      [below(values.xs)]: {
        margin: `${spacing(2)}px 0px`,
        "& [class^='ads_container']": {
          "& [class^='ad']": {
            margin: "0px !important",
          },
        },
      },
    },
    ad_card_for_top_stories: {
      marginBottom: `${spacing(2)}px !important`,
      minWidth: spacing(40),
      display: "flex",
      justifyContent: "center",
      width: "48%",
      height: "fit-content",
      alignSelf: "center",
      "& [class^='ads_container']": {
        margin: "0px !important",
      },
      [below(1232)]: {
        width: "47% !important",
      },
      [below(768)]: {
        width: "100%",
        textAlign: "center",
        marginBottom: `${spacing(2)}px !important`,
        "& [class^='ads_container']": {
          marginBottom: `${spacing(2)}px !important`,
        },
        [below(695)]: {
          width: "100% !important",
        },
      },
    },
    divider: {
      border: `1px solid ${palette.miscellaneous.grayDivider}`,
      marginTop: spacing(3),
      marginBottom: spacing(4),
      width: "100%",
    },
    text_container: {
      marginBottom: spacing(2),
    },
  };
});

export { useStyles };
