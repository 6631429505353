import React from "react";
import PropTypes from "prop-types";

const DEFAULT_STYLES = {
  container: { display: "flex", flexDirection: "column" },
  slogan: { marginTop: "8px" },
};

const TapIntoLogo = ({ classes, sx }) => {
  return (
    <div
      className={classes?.container}
      style={{ ...DEFAULT_STYLES?.container, ...sx?.container }}
    >
      <svg
        className={classes?.tapinto}
        width="224"
        height="42"
        viewBox="0 0 224 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.7725 8.75068H23.7594V40.7203H13.0131V8.75068H0V1.08437H36.7725V8.75068Z"
          fill="#81BF40"
        />
        <path
          d="M80.5984 40.7203H69.4883L66.6058 32.7079H51.158L48.2755 40.7203H37.4453L52.8371 1.08437H65.2066L80.5984 40.7203ZM64.0032 25.4409L58.8819 11.2263L53.7606 25.4409H64.0032Z"
          fill="#81BF40"
        />
        <path
          d="M119.835 13.5954C119.835 15.37 119.508 17.1091 118.855 18.8127C118.202 20.4986 117.269 21.9183 116.057 23.0718C114.396 24.6334 112.54 25.8135 110.488 26.6121C108.454 27.4107 105.917 27.81 102.876 27.81H96.1873V40.7203H85.441V1.08437H103.128C105.777 1.08437 108.006 1.30619 109.816 1.74985C111.644 2.17575 113.258 2.82348 114.657 3.69304C116.337 4.74006 117.615 6.07989 118.491 7.71253C119.387 9.34517 119.835 11.3061 119.835 13.5954ZM108.725 13.8349C108.725 12.7169 108.407 11.7586 107.773 10.9601C107.139 10.1437 106.402 9.57587 105.562 9.25644C104.443 8.83053 103.351 8.59984 102.288 8.56434C101.225 8.51111 99.8067 8.48449 98.0343 8.48449H96.1873V20.3566H99.2656C101.094 20.3566 102.596 20.2501 103.771 20.0372C104.965 19.8242 105.963 19.3983 106.766 18.7595C107.456 18.1916 107.95 17.5172 108.249 16.7364C108.566 15.9378 108.725 14.9707 108.725 13.8349Z"
          fill="#81BF40"
        />
        <path
          d="M131.841 6.00891H125.909V0.818176H131.841V6.00891ZM131.506 40.7203H126.244V10.9867H131.506V40.7203Z"
          fill="#F78C2A"
        />
        <path
          d="M166.908 40.7203H161.647V23.7905C161.647 22.424 161.563 21.1463 161.395 19.9573C161.227 18.7506 160.919 17.8101 160.471 17.1357C160.005 16.3904 159.333 15.8402 158.457 15.4853C157.58 15.1126 156.442 14.9263 155.042 14.9263C153.606 14.9263 152.104 15.2635 150.537 15.9378C148.97 16.6122 147.468 17.4729 146.031 18.5199V40.7203H140.77V10.9867H146.031V14.2875C147.673 12.992 149.371 11.9805 151.124 11.2529C152.878 10.5253 154.679 10.1615 156.526 10.1615C159.902 10.1615 162.477 11.1287 164.249 13.063C166.022 14.9973 166.908 17.7834 166.908 21.4214V40.7203Z"
          fill="#F78C2A"
        />
        <path
          d="M192.124 40.4541C191.135 40.7025 190.053 40.9066 188.877 41.0663C187.721 41.226 186.685 41.3059 185.771 41.3059C182.581 41.3059 180.155 40.4896 178.495 38.8569C176.835 37.2243 176.004 34.6067 176.004 31.0043V15.1925H172.45V10.9867H176.004V2.44194H181.266V10.9867H192.124V15.1925H181.266V28.7416C181.266 30.3033 181.303 31.5278 181.377 32.4151C181.452 33.2846 181.713 34.101 182.161 34.864C182.572 35.5739 183.131 36.0974 183.84 36.4346C184.568 36.754 185.669 36.9137 187.142 36.9137C188.001 36.9137 188.896 36.7984 189.829 36.5677C190.762 36.3192 191.433 36.1151 191.844 35.9554H192.124V40.4541Z"
          fill="#F78C2A"
        />
        <path
          d="M224 25.8668C224 30.7115 222.694 34.5357 220.082 37.3396C217.47 40.1435 213.972 41.5454 209.588 41.5454C205.166 41.5454 201.649 40.1435 199.037 37.3396C196.444 34.5357 195.147 30.7115 195.147 25.8668C195.147 21.0221 196.444 17.1978 199.037 14.3939C201.649 11.5723 205.166 10.1615 209.588 10.1615C213.972 10.1615 217.47 11.5723 220.082 14.3939C222.694 17.1978 224 21.0221 224 25.8668ZM218.571 25.8668C218.571 22.0159 217.778 19.1588 216.192 17.2954C214.606 15.4143 212.405 14.4738 209.588 14.4738C206.733 14.4738 204.513 15.4143 202.927 17.2954C201.36 19.1588 200.576 22.0159 200.576 25.8668C200.576 29.5935 201.369 32.424 202.955 34.3583C204.541 36.2749 206.752 37.2331 209.588 37.2331C212.386 37.2331 214.578 36.2837 216.164 34.3849C217.769 32.4683 218.571 29.629 218.571 25.8668Z"
          fill="#F78C2A"
        />
      </svg>
      <svg
        className={classes?.slogan}
        style={{ ...DEFAULT_STYLES?.slogan, ...sx?.slogan }}
        width="224"
        height="13"
        viewBox="0 0 224 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Your Neighborhood News Online">
          <path
            d="M9.09708 1.23855H7.7221L4.59715 5.72529L1.48609 1.23855H0L3.86105 6.78315V9.74999H5.23603V6.80746L9.09708 1.23855Z"
            fill="white"
          />
          <path
            d="M12.3594 9.82295C14.5677 9.82295 16.1788 8.44896 16.1788 6.5278C16.1788 4.60665 14.5677 3.24482 12.3594 3.24482C10.1511 3.24482 8.52612 4.60665 8.52612 6.5278C8.52612 8.44896 10.1511 9.82295 12.3594 9.82295ZM12.3594 8.80157C10.9427 8.80157 9.87332 7.90179 9.87332 6.5278C9.87332 5.15381 10.9427 4.26619 12.3594 4.26619C13.776 4.26619 14.8316 5.15381 14.8316 6.5278C14.8316 7.90179 13.776 8.80157 12.3594 8.80157Z"
            fill="white"
          />
          <path
            d="M23.2726 3.30561V6.63724C23.2726 8.02339 22.3838 8.77726 20.9949 8.77726C19.731 8.77726 19.0088 8.14498 19.0088 6.88042V3.30561H17.6755V7.01417C17.6755 8.91101 18.9394 9.82295 20.8421 9.82295C21.8838 9.82295 22.8004 9.44601 23.3421 8.77726V9.74999H24.6059V3.30561H23.2726Z"
            fill="white"
          />
          <path
            d="M28.1442 4.38778V3.30561H26.8664V9.74999H28.1997V6.53996C28.1997 5.12949 29.0886 4.36346 30.5191 4.36346C30.6164 4.36346 30.7136 4.36346 30.8247 4.37562V3.24482C29.5331 3.24482 28.6164 3.63391 28.1442 4.38778Z"
            fill="white"
          />
          <path
            d="M42.9038 1.23855V7.58565L37.0706 1.23855H35.9317V9.74999H37.3206V3.40289L43.1538 9.74999H44.2927V1.23855H42.9038Z"
            fill="white"
          />
          <path
            d="M53.3831 6.56428C53.3831 4.58233 51.8693 3.24482 49.7443 3.24482C47.6194 3.24482 46.0499 4.61881 46.0499 6.5278C46.0499 8.44896 47.6332 9.82295 50.0082 9.82295C51.2304 9.82295 52.2443 9.45817 52.9109 8.77726L52.1748 8.02339C51.6332 8.54623 50.911 8.80157 50.0499 8.80157C48.5916 8.80157 47.5499 8.0477 47.3832 6.9169H53.3554C53.3693 6.80746 53.3831 6.66155 53.3831 6.56428ZM49.7443 4.22971C51.036 4.22971 51.9665 4.99574 52.1054 6.07791H47.3832C47.5221 4.98358 48.4666 4.22971 49.7443 4.22971Z"
            fill="white"
          />
          <path
            d="M55.6235 2.06537C56.1512 2.06537 56.5262 1.72491 56.5262 1.28718C56.5262 0.87377 56.1373 0.545471 55.6235 0.545471C55.1096 0.545471 54.7207 0.885929 54.7207 1.3115C54.7207 1.73707 55.1096 2.06537 55.6235 2.06537ZM54.9568 9.74999H56.2901V3.30561H54.9568V9.74999Z"
            fill="white"
          />
          <path
            d="M64.3358 3.30561V4.30267C63.7108 3.59743 62.7108 3.24482 61.6136 3.24482C59.4886 3.24482 57.8775 4.49721 57.8775 6.35757C57.8775 8.21793 59.4886 9.48249 61.6136 9.48249C62.6691 9.48249 63.6275 9.15419 64.2663 8.48544V8.99612C64.2663 10.4552 63.4747 11.1605 61.7386 11.1605C60.6553 11.1605 59.5858 10.8322 58.9053 10.3215L58.2664 11.2213C59.072 11.8535 60.4331 12.1818 61.7803 12.1818C64.2802 12.1818 65.5996 11.1483 65.5996 8.87453V3.30561H64.3358ZM61.7664 8.46112C60.2803 8.46112 59.2247 7.59781 59.2247 6.35757C59.2247 5.10517 60.2803 4.26619 61.7664 4.26619C63.2386 4.26619 64.2941 5.10517 64.2941 6.35757C64.2941 7.59781 63.2386 8.46112 61.7664 8.46112Z"
            fill="white"
          />
          <path
            d="M71.8 3.24482C70.675 3.24482 69.7583 3.59743 69.2028 4.21755V0.727859H67.8695V9.74999H69.2028V6.43053C69.2028 5.03222 70.1194 4.29051 71.5361 4.29051C72.7999 4.29051 73.5221 4.91063 73.5221 6.17519V9.74999H74.8555V6.04143C74.8555 4.1446 73.5916 3.24482 71.8 3.24482Z"
            fill="white"
          />
          <path
            d="M80.9479 3.24482C79.9063 3.24482 78.9896 3.58527 78.3785 4.27835V0.727859H77.0452V9.74999H78.323V8.72862C78.9202 9.45817 79.8646 9.82295 80.9479 9.82295C83.1007 9.82295 84.6701 8.49759 84.6701 6.5278C84.6701 4.55801 83.1007 3.24482 80.9479 3.24482ZM80.8368 8.80157C79.4341 8.80157 78.3646 7.90179 78.3646 6.5278C78.3646 5.15381 79.4341 4.26619 80.8368 4.26619C82.2535 4.26619 83.3229 5.15381 83.3229 6.5278C83.3229 7.90179 82.2535 8.80157 80.8368 8.80157Z"
            fill="white"
          />
          <path
            d="M89.3872 9.82295C91.5955 9.82295 93.2066 8.44896 93.2066 6.5278C93.2066 4.60665 91.5955 3.24482 89.3872 3.24482C87.1789 3.24482 85.554 4.60665 85.554 6.5278C85.554 8.44896 87.1789 9.82295 89.3872 9.82295ZM89.3872 8.80157C87.9706 8.80157 86.9012 7.90179 86.9012 6.5278C86.9012 5.15381 87.9706 4.26619 89.3872 4.26619C90.8039 4.26619 91.8594 5.15381 91.8594 6.5278C91.8594 7.90179 90.8039 8.80157 89.3872 8.80157Z"
            fill="white"
          />
          <path
            d="M96.0505 4.38778V3.30561H94.7728V9.74999H96.1061V6.53996C96.1061 5.12949 96.995 4.36346 98.4255 4.36346C98.5227 4.36346 98.6199 4.36346 98.731 4.37562V3.24482C97.4394 3.24482 96.5228 3.63391 96.0505 4.38778Z"
            fill="white"
          />
          <path
            d="M104.027 3.24482C102.902 3.24482 101.985 3.59743 101.43 4.21755V0.727859H100.097V9.74999H101.43V6.43053C101.43 5.03222 102.347 4.29051 103.763 4.29051C105.027 4.29051 105.749 4.91063 105.749 6.17519V9.74999H107.083V6.04143C107.083 4.1446 105.819 3.24482 104.027 3.24482Z"
            fill="white"
          />
          <path
            d="M112.466 9.82295C114.674 9.82295 116.285 8.44896 116.285 6.5278C116.285 4.60665 114.674 3.24482 112.466 3.24482C110.257 3.24482 108.632 4.60665 108.632 6.5278C108.632 8.44896 110.257 9.82295 112.466 9.82295ZM112.466 8.80157C111.049 8.80157 109.98 7.90179 109.98 6.5278C109.98 5.15381 111.049 4.26619 112.466 4.26619C113.882 4.26619 114.938 5.15381 114.938 6.5278C114.938 7.90179 113.882 8.80157 112.466 8.80157Z"
            fill="white"
          />
          <path
            d="M121.004 9.82295C123.212 9.82295 124.823 8.44896 124.823 6.5278C124.823 4.60665 123.212 3.24482 121.004 3.24482C118.796 3.24482 117.171 4.60665 117.171 6.5278C117.171 8.44896 118.796 9.82295 121.004 9.82295ZM121.004 8.80157C119.587 8.80157 118.518 7.90179 118.518 6.5278C118.518 5.15381 119.587 4.26619 121.004 4.26619C122.421 4.26619 123.476 5.15381 123.476 6.5278C123.476 7.90179 122.421 8.80157 121.004 8.80157Z"
            fill="white"
          />
          <path
            d="M132.001 0.727859V4.27835C131.39 3.58527 130.473 3.24482 129.431 3.24482C127.278 3.24482 125.709 4.55801 125.709 6.5278C125.709 8.49759 127.278 9.82295 129.431 9.82295C130.515 9.82295 131.459 9.45817 132.056 8.72862V9.74999H133.334V0.727859H132.001ZM129.542 8.80157C128.126 8.80157 127.056 7.90179 127.056 6.5278C127.056 5.15381 128.126 4.26619 129.542 4.26619C130.945 4.26619 132.014 5.15381 132.014 6.5278C132.014 7.90179 130.945 8.80157 129.542 8.80157Z"
            fill="white"
          />
          <path
            d="M146.198 1.23855V7.58565L140.364 1.23855H139.225V9.74999H140.614V3.40289L146.448 9.74999H147.586V1.23855H146.198Z"
            fill="white"
          />
          <path
            d="M156.677 6.56428C156.677 4.58233 155.163 3.24482 153.038 3.24482C150.913 3.24482 149.344 4.61881 149.344 6.5278C149.344 8.44896 150.927 9.82295 153.302 9.82295C154.524 9.82295 155.538 9.45817 156.205 8.77726L155.469 8.02339C154.927 8.54623 154.205 8.80157 153.344 8.80157C151.885 8.80157 150.844 8.0477 150.677 6.9169H156.649C156.663 6.80746 156.677 6.66155 156.677 6.56428ZM153.038 4.22971C154.33 4.22971 155.26 4.99574 155.399 6.07791H150.677C150.816 4.98358 151.76 4.22971 153.038 4.22971Z"
            fill="white"
          />
          <path
            d="M168.059 3.30561L165.865 8.46112L163.684 3.30561H162.559L160.337 8.4368L158.198 3.30561H156.934L159.67 9.74999H160.948L163.101 4.86199L165.226 9.74999H166.504L169.254 3.30561H168.059Z"
            fill="white"
          />
          <path
            d="M172.345 9.82295C174.303 9.82295 175.525 9.08124 175.525 7.91395C175.525 5.40915 170.859 6.64939 170.859 5.12949C170.859 4.61881 171.428 4.24187 172.595 4.24187C173.317 4.24187 174.053 4.37562 174.72 4.75256L175.289 3.82846C174.65 3.46368 173.567 3.24482 172.609 3.24482C170.72 3.24482 169.539 4.023 169.539 5.17813C169.539 7.74372 174.192 6.49132 174.192 7.93827C174.192 8.48543 173.678 8.81373 172.442 8.81373C171.484 8.81373 170.484 8.53407 169.859 8.14498L169.303 9.06908C169.914 9.49465 171.123 9.82295 172.345 9.82295Z"
            fill="white"
          />
          <path
            d="M184.949 9.84727C187.908 9.84727 190.102 7.99907 190.102 5.49427C190.102 2.98947 187.908 1.14127 184.949 1.14127C181.963 1.14127 179.769 3.00163 179.769 5.49427C179.769 7.98691 181.963 9.84727 184.949 9.84727ZM184.949 8.7651C182.769 8.7651 181.158 7.37895 181.158 5.49427C181.158 3.60959 182.769 2.22344 184.949 2.22344C187.102 2.22344 188.713 3.60959 188.713 5.49427C188.713 7.37895 187.102 8.7651 184.949 8.7651Z"
            fill="white"
          />
          <path
            d="M195.683 3.24482C194.516 3.24482 193.572 3.62175 193.03 4.27835V3.30561H191.753V9.74999H193.086V6.43053C193.086 5.03222 194.003 4.29051 195.419 4.29051C196.683 4.29051 197.405 4.91063 197.405 6.17519V9.74999H198.739V6.04143C198.739 4.1446 197.475 3.24482 195.683 3.24482Z"
            fill="white"
          />
          <path
            d="M200.928 9.74999H202.262V0.727859H200.928V9.74999Z"
            fill="white"
          />
          <path
            d="M205.196 2.06537C205.724 2.06537 206.099 1.72491 206.099 1.28718C206.099 0.87377 205.71 0.545471 205.196 0.545471C204.682 0.545471 204.293 0.885929 204.293 1.3115C204.293 1.73707 204.682 2.06537 205.196 2.06537ZM204.53 9.74999H205.863V3.30561H204.53V9.74999Z"
            fill="white"
          />
          <path
            d="M212.061 3.24482C210.895 3.24482 209.95 3.62175 209.409 4.27835V3.30561H208.131V9.74999H209.464V6.43053C209.464 5.03222 210.381 4.29051 211.798 4.29051C213.061 4.29051 213.784 4.91063 213.784 6.17519V9.74999H215.117V6.04143C215.117 4.1446 213.853 3.24482 212.061 3.24482Z"
            fill="white"
          />
          <path
            d="M224 6.56428C224 4.58233 222.486 3.24482 220.361 3.24482C218.236 3.24482 216.667 4.61881 216.667 6.5278C216.667 8.44896 218.25 9.82295 220.625 9.82295C221.847 9.82295 222.861 9.45817 223.528 8.77726L222.792 8.02339C222.25 8.54623 221.528 8.80157 220.667 8.80157C219.208 8.80157 218.167 8.0477 218 6.9169H223.972C223.986 6.80746 224 6.66155 224 6.56428ZM220.361 4.22971C221.653 4.22971 222.583 4.99574 222.722 6.07791H218C218.139 4.98358 219.083 4.22971 220.361 4.22971Z"
            fill="white"
          />
        </g>
      </svg>
    </div>
  );
};

TapIntoLogo.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    tapinto: PropTypes.string,
    slogan: PropTypes.string,
  }),
  sx: PropTypes.shape({
    container: PropTypes.object,
    slogan: PropTypes.object,
  }),
};

export default TapIntoLogo;
