import React from "react";

export const Snow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="32"
      viewBox="0 0 43 32"
      fill="none"
    >
      <path
        d="M38.4148 12.4496C38.4148 12.2781 38.4148 12.1066 38.4148 11.9351C38.5005 8.50523 35.8424 5.58982 32.4125 5.33258C30.9548 5.24683 29.5828 5.58982 28.4681 6.2758C27.8679 2.58866 24.438 -0.240999 20.4079 0.0162426C16.635 0.187737 13.6338 3.0174 13.1193 6.53304C12.4334 6.2758 11.7474 6.19005 10.9756 6.19005C7.37426 6.19005 4.45887 9.10546 4.45887 12.7069C4.45887 12.7926 4.45887 12.8783 4.45887 12.9641C2.65818 13.4786 0 14.5075 0 18.2804C0 20.9386 2.14368 23.2538 5.05909 23.511C5.31633 23.511 5.48783 23.4253 5.65933 23.2538L6.25954 22.3963C6.34529 22.3105 6.25956 22.1391 6.08807 22.1391H5.83081C3.42988 22.1391 1.54344 20.4241 1.54344 18.2804C1.54344 15.7938 2.9154 14.9363 5.48782 14.3361L6.08807 14.0788V13.1356C6.08807 12.9641 6.00231 12.8783 6.00231 12.7069C6.00231 9.96294 8.23173 7.7335 10.9756 7.7335C11.8331 7.7335 12.6048 7.99075 13.3766 8.41948L14.4913 9.10546V7.81925C14.4056 4.56085 17.0637 1.81694 20.4079 1.64544H20.7509C24.095 1.64544 26.7532 4.13211 26.9247 7.39051C26.9247 7.47626 26.9247 7.64776 26.9247 7.905L27.0104 9.79144L28.3823 8.41948C29.4113 7.39052 30.6976 6.87603 32.0695 6.96178C34.5562 7.04752 36.6999 9.01971 36.8714 11.5064C36.9571 12.0209 36.8714 12.4496 36.7856 12.9641L36.6141 13.7358L37.3858 13.9073C39.358 14.2503 40.73 15.7938 40.8157 17.5944C40.9015 19.3951 39.701 21.3673 37.9003 22.0533C37.4716 22.1391 36.9571 22.3105 36.5283 22.3105H36.2711C36.0996 22.3105 36.0139 22.482 36.0996 22.5678L36.6998 23.4253C36.8713 23.5968 37.0429 23.7683 37.3001 23.6825C37.6431 23.6825 38.0718 23.511 38.4148 23.4253C40.73 22.5678 42.445 20.0811 42.3592 17.5087C42.3592 15.1078 40.73 13.1356 38.4148 12.4496Z"
        fill="white"
      />
      <path
        d="M9.86087 25.6547H10.5468V24.9687H9.86087V25.6547ZM12.7763 26.5979L13.2908 27.1124L13.8052 26.5979L13.2908 26.0834L12.7763 26.5979ZM8.8319 21.7961L8.31743 22.3106L8.8319 22.825L9.34639 22.3106L8.8319 21.7961ZM15.8632 25.6547H16.5492V24.9687H15.8632V25.6547ZM10.5468 18.8807H9.86087V19.5666H10.5468V18.8807ZM16.6349 18.8807H15.9489V19.5666H16.6349V18.8807ZM13.7195 17.9374L13.205 17.423L12.6905 17.9374L13.205 18.4519L13.7195 17.9374ZM17.3209 23.4253L16.8922 22.3106L17.3209 21.2816L16.4634 22.1391H15.0914L15.606 21.3673L14.7485 21.1958L15.6917 20.1669H16.8922L15.7775 19.7381L15.3487 18.6234V19.8239L14.4055 20.8529L14.234 19.9096L13.4623 20.4241V19.0522L14.3197 18.1089L13.2908 18.6234L12.1761 18.1089L13.0335 18.9664V20.3384L12.1761 19.8239L12.0046 20.6814L11.0613 19.6524V18.4519L10.7183 19.5666L9.60364 19.9954H10.8041L11.7473 20.9386L10.7183 21.1958L11.3186 21.8818H9.94663L9.08915 21.0243L9.51789 22.1391L9.08915 23.2538L9.94663 22.3106H11.3186L10.7183 23.0823L11.7473 23.2538L10.8041 24.197H9.60364L10.7183 24.6257L11.0613 25.7405V24.54L12.0046 23.5968L12.1761 24.54L13.0335 23.9398V25.3117L12.1761 26.1692L13.205 25.7405L14.234 26.1692L13.3765 25.3117V23.9398L14.1482 24.4542L14.3197 23.511L15.2629 24.4542V25.6547L15.606 24.6257L16.7207 24.197H15.5202L14.577 23.2538L15.5202 22.9965L14.92 22.3106H16.2919L17.3209 23.4253ZM12.1761 21.5388L12.7763 22.0533H12.0046L12.1761 21.5388ZM12.2618 23.0823L12.0046 22.4821H12.7763L12.2618 23.0823ZM13.0335 23.5968L12.519 23.3395L13.0335 22.7393V23.5968ZM13.0335 21.7961L12.519 21.2816L13.0335 21.0243V21.7961ZM14.3197 21.5388L14.4913 22.0533H13.7195L14.3197 21.5388ZM13.4623 21.0243L14.0625 21.2816L13.4623 21.7961V21.0243ZM13.4623 23.5968V22.7393L14.0625 23.2538L13.4623 23.5968ZM14.3197 23.0823L13.7195 22.4821H14.577L14.3197 23.0823ZM17.5781 21.7961L17.0637 22.3106L17.5781 22.825L18.0926 22.3106L17.5781 21.7961Z"
        fill="white"
      />
      <path
        d="M29.9258 18.4519H30.2688V18.1089H29.9258V18.4519ZM31.2978 18.9664L31.555 19.2236L31.8122 18.9664L31.555 18.7091L31.2978 18.9664ZM29.4113 16.4797L29.1541 16.7369L29.4113 16.9942L29.6685 16.7369L29.4113 16.4797ZM32.927 18.4519H33.2699V18.1089H32.927V18.4519ZM30.2688 15.022H29.9258V15.365H30.2688V15.022ZM33.2699 15.022H32.927V15.365H33.2699V15.022ZM31.8122 14.5933L31.555 14.336L31.2978 14.5933L31.555 14.8505L31.8122 14.5933ZM33.6129 17.3372L33.3557 16.8227L33.6129 16.3082L33.1842 16.7369H32.4982L32.7555 16.3082L32.3267 16.2225L32.8412 15.708H33.4414L32.927 15.5365L32.7555 15.022V15.6222L32.241 16.1367L32.1552 15.6222L31.7265 15.8795V15.1935L32.1552 14.7648L31.6408 15.022L31.1263 14.7648L31.555 15.1935V15.8795L31.1263 15.6222L31.0405 16.051L30.526 15.5365V14.9362L30.3546 15.4507L29.7543 15.708H30.3546L30.869 16.2225L30.3546 16.3082L30.6975 16.6512H30.0115L29.5828 16.2225L29.7543 16.7369L29.5828 17.2514L30.0115 16.8227H30.6975L30.3546 17.2514L30.869 17.3372L30.3546 17.8517H29.7543L30.3546 18.0231L30.526 18.5376V17.9374L31.0405 17.4229L31.1263 17.9374L31.555 17.5944V18.2804L31.1263 18.7091L31.6408 18.4519L32.1552 18.7091L31.7265 18.2804V17.5944L32.1552 17.8517L32.241 17.3372L32.7555 17.8517V18.4519L32.927 17.9374L33.5272 17.7659H32.927L32.4125 17.2514L32.927 17.0799L32.6697 16.7369H33.3557L33.6129 17.3372ZM31.0405 16.3939L31.2978 16.6512H30.869L31.0405 16.3939ZM31.0405 17.1657L30.9547 16.8227H31.3835L31.0405 17.1657ZM31.4693 17.4229L31.212 17.3372L31.4693 17.0799V17.4229ZM31.4693 16.5654L31.212 16.3082L31.4693 16.2225V16.5654ZM32.0695 16.3939L32.1552 16.6512H31.7265L32.0695 16.3939ZM31.7265 16.1367L31.9837 16.2225L31.7265 16.4797V16.1367ZM31.7265 17.4229V16.9942L31.9837 17.2514L31.7265 17.4229ZM32.0695 17.1657L31.8122 16.8227H32.241L32.0695 17.1657ZM33.6987 16.4797L33.4414 16.7369L33.6987 16.9942L33.9559 16.7369L33.6987 16.4797Z"
        fill="white"
      />
      <path
        d="M20.0649 31.314H20.4078V30.971H20.0649V31.314ZM21.4368 31.7428L21.694 32L21.9513 31.7428L21.694 31.4855L21.4368 31.7428ZM19.5504 29.3418L19.2931 29.5991L19.5504 29.8563L19.8076 29.5991L19.5504 29.3418ZM23.066 31.314H23.409V30.971H23.066V31.314ZM20.4078 27.8841H20.0649V28.2271H20.4078V27.8841ZM23.409 27.8841H23.066V28.2271H23.409V27.8841ZM21.9513 27.3696L21.694 27.1124L21.4368 27.3696L21.694 27.6269L21.9513 27.3696ZM23.4948 29.5991L23.752 29.0846L23.3233 29.5133H22.6373L22.8945 29.0846L22.4658 28.9988L22.9803 28.4844H23.5805L23.066 28.3129L22.8945 27.7984V28.3986L22.38 28.9131L22.2943 28.3986L21.8656 28.6558V27.9699L22.2943 27.5411L21.7798 27.7984L21.2653 27.5411L21.694 27.9699V28.6558L21.2653 28.3986L21.1796 28.8273L20.6651 28.3129V27.7984L20.4936 28.3129L19.8934 28.5701H20.4936L21.0081 29.0846L20.4936 29.1703L20.8366 29.5133H20.1506L19.7219 29.0846L19.8934 29.5991L19.7219 30.1136L20.1506 29.6848H20.8366L20.4936 30.1136L21.0081 30.1993L20.4936 30.7138H19.8934L20.4936 30.8853L20.6651 31.3998V30.7995L21.1796 30.2851L21.2653 30.7995L21.694 30.4565V31.1425L21.2653 31.5713L21.7798 31.314L22.2943 31.5713L21.8656 31.1425V30.4565L22.2943 30.7138L22.38 30.1993L22.8945 30.7138V31.314L23.066 30.7995L23.6662 30.628H23.066L22.5515 30.1136L23.066 29.9421L22.8088 29.5991H23.4948ZM21.1796 29.2561L21.4368 29.5133H21.0081L21.1796 29.2561ZM21.1796 30.0278L21.0938 29.6848H21.5225L21.1796 30.0278ZM21.6083 30.1993L21.3511 30.1136L21.6083 29.8563V30.1993ZM21.6083 29.3418L21.3511 29.0846L21.6083 28.9988V29.3418ZM22.2086 29.2561L22.2943 29.5133H21.8656L22.2086 29.2561ZM21.8656 28.9988L22.1228 29.0846L21.8656 29.3418V28.9988ZM21.8656 30.2851V29.8563L22.1228 30.1136L21.8656 30.2851ZM22.2086 30.0278L21.9513 29.6848H22.38L22.2086 30.0278ZM23.8377 29.3418L23.5805 29.5991L23.8377 29.8563L24.095 29.5991L23.8377 29.3418Z"
        fill="white"
      />
      <path
        d="M20.1505 20.0811H20.665V19.5666H20.1505V20.0811ZM22.2942 20.7671L22.7229 21.1101L23.1517 20.7671L22.7229 20.3384L22.2942 20.7671ZM19.3788 17.1657L19.0358 17.5944L19.4645 17.9374L19.8075 17.5944L19.3788 17.1657ZM24.6094 20.0811H25.1238V19.5666H24.6094V20.0811ZM20.665 15.022H20.1505V15.5365H20.665V15.022ZM25.2096 15.022H24.6951V15.5365H25.2096V15.022ZM23.0659 14.2503L22.6372 13.9073L22.2085 14.2503L22.6372 14.5933L23.0659 14.2503ZM25.7241 18.3662L25.3811 17.5087L25.7241 16.737L25.1238 17.4229H24.0949L24.4379 16.8227L23.7519 16.737L24.5236 15.9652H25.4669L24.6094 15.6222L24.2664 14.7648V15.708L23.5804 16.4797L23.4947 15.7937L22.8944 16.2225V15.1935L23.5804 14.5075L22.8087 14.9363L21.9512 14.5933L22.6372 15.2793V16.3082L22.037 15.9652L21.9512 16.6512L21.1795 15.8795V14.9363L20.9223 15.7937L20.0648 16.1367H21.008L21.7797 16.8227L21.008 16.9942L21.4367 17.5087H20.4077L19.7218 16.8227L20.0648 17.6802L19.7218 18.4519L20.4077 17.7659H21.4367L21.008 18.3662L21.7797 18.4519L21.008 19.2236H20.0648L20.9223 19.4809L21.1795 20.3384V19.3951L21.8655 18.7092L22.037 19.4809L22.6372 19.0521V20.0811L21.9512 20.6813L22.7229 20.3384L23.4947 20.6813L22.8944 20.0811V19.0521L23.4947 19.4809L23.5804 18.7949L24.3521 19.5666V20.5098L24.6094 19.7381L25.4669 19.3951H24.5236L23.7519 18.7092L24.4379 18.5377L24.0091 18.0232H25.0381L25.7241 18.3662ZM21.8655 16.9942L22.2942 17.4229H21.694L21.8655 16.9942ZM21.9512 18.1089L21.7797 17.6802H22.3799L21.9512 18.1089ZM22.5514 18.5377L22.1227 18.3662L22.5514 17.9374V18.5377ZM22.5514 17.1657L22.1227 16.737L22.5514 16.5655V17.1657ZM23.4947 16.9942L23.6661 17.4229H23.0659L23.4947 16.9942ZM22.8087 16.5655L23.2374 16.737L22.8087 17.1657V16.5655ZM22.8087 18.5377V17.9374L23.2374 18.3662L22.8087 18.5377ZM23.4089 18.1089L22.9802 17.6802H23.5804L23.4089 18.1089ZM25.8956 17.1657L25.4669 17.5087L25.8956 17.8517L26.3243 17.5087L25.8956 17.1657Z"
        fill="white"
      />
      <path
        d="M28.1251 26.5979H28.6396V26.0834H28.1251V26.5979ZM30.2688 27.2839L30.6975 27.6269L31.1262 27.2839L30.6975 26.8551L30.2688 27.2839ZM27.3534 23.6825L27.0104 24.1112L27.4391 24.4542L27.7821 24.1112L27.3534 23.6825ZM32.584 26.5979H33.0984V26.0834H32.584V26.5979ZM28.6396 21.5388H28.1251V22.0533H28.6396V21.5388ZM33.1842 21.5388H32.6697V22.0533H33.1842V21.5388ZM31.0405 20.7671L30.6118 20.4241L30.183 20.7671L30.6118 21.1101L31.0405 20.7671ZM33.6987 24.883L33.3557 24.0255L33.6987 23.2538L33.0984 23.9397H32.0695L32.4124 23.3395L31.7265 23.2538L32.4982 22.482H33.4414L32.584 22.139L32.2409 21.2816V22.2248L31.555 22.9965L31.4692 22.3105L30.869 22.7393V21.7103L31.555 21.0243L30.7833 21.4531L29.9258 21.1101L30.6118 21.796V22.825L30.0115 22.482L29.9258 23.168L29.1541 22.3963V21.4531L28.8968 22.3105L28.0394 22.6535H28.9826L29.7543 23.3395L28.9826 23.511L29.4113 24.0255H28.3823L27.6963 23.3395L28.0394 24.197L27.6963 24.9687L28.3823 24.2827H29.4113L28.9826 24.883L29.7543 24.9687L28.9826 25.7404H27.8678L28.7253 25.9977L28.9826 26.8551V25.9119L29.6685 25.2259L29.84 25.9977L30.4403 25.5689V26.5979L29.7543 27.1981L30.526 26.8551L31.2977 27.1981L30.6975 26.5979V25.5689L31.2977 25.9977L31.3835 25.3117L32.1552 26.0834V27.0266L32.4124 26.2549L33.2699 25.9119H32.3267L31.555 25.2259L32.2409 25.0545L31.8122 24.54H32.8412L33.6987 24.883ZM29.84 23.511L30.2688 23.9397H29.6685L29.84 23.511ZM29.84 24.6257L29.6685 24.197H30.2688L29.84 24.6257ZM30.526 25.0545L30.0973 24.883L30.526 24.4542V25.0545ZM30.526 23.6825L30.0973 23.2538L30.526 23.0823V23.6825ZM31.3835 23.511L31.555 23.9397H30.9547L31.3835 23.511ZM30.7833 23.168L31.212 23.3395L30.7833 23.7682V23.168ZM30.7833 25.0545V24.4542L31.212 24.883L30.7833 25.0545ZM31.3835 24.6257L30.9547 24.197H31.555L31.3835 24.6257ZM33.8702 23.6825L33.4414 24.0255L33.8702 24.3685L34.2989 24.0255L33.8702 23.6825Z"
        fill="white"
      />
    </svg>
  );
};
