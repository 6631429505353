import React, { useState } from "react";
import PropTypes from "prop-types";

const CalendarEventsListing = ({
  className,
  onHoverColor,
  offHoverColor,
  inactiveColor,
  disabled,
  fillColor,
}) => {
  const [iconColor, setIconColor] = useState(
    fillColor ? fillColor : !disabled ? offHoverColor : inactiveColor
  );
  return (
    <svg
      className={className}
      width="76"
      height="75"
      viewBox="0 0 76 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={
        !disabled
          ? () => setIconColor(onHoverColor)
          : () => setIconColor(inactiveColor)
      }
      onMouseLeave={
        !disabled
          ? () => setIconColor(offHoverColor)
          : () => setIconColor(inactiveColor)
      }
    >
      <circle cx="37.5" cy="37.5" r="32" stroke={iconColor} strokeWidth="3" />
      <path
        d="M74.5 37.5C74.5 57.3636 58.1772 73.5 38 73.5C17.8228 73.5 1.5 57.3636 1.5 37.5C1.5 17.6364 17.8228 1.5 38 1.5C58.1772 1.5 74.5 17.6364 74.5 37.5Z"
        stroke={iconColor}
        strokeWidth="3"
      />
      <path
        d="M27.5181 21.2618H21.916C19.7069 21.2618 17.916 23.0526 17.916 25.2618V51.555C17.916 53.7641 19.7069 55.555 21.916 55.555H54.382C56.5911 55.555 58.382 53.7641 58.382 51.555V25.2618C58.382 23.0526 56.5911 21.2618 54.382 21.2618H51.1804M31.6333 21.2618H46.7223"
        stroke={iconColor}
        strokeWidth="2"
      />
      <path
        d="M28.204 18.5183V24.0052"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M47.4082 18.5183V24.0052"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M58.382 28.8063L17.916 28.8063"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="25.1177" cy="33.9503" r="1.71466" fill={iconColor} />
      <circle cx="34.0339" cy="33.9503" r="1.71466" fill={iconColor} />
      <circle cx="42.9501" cy="33.9503" r="1.71466" fill={iconColor} />
      <circle cx="51.8663" cy="33.9503" r="1.71466" fill={iconColor} />
      <circle cx="25.1176" cy="39.4372" r="1.71466" fill={iconColor} />
      <circle cx="25.1176" cy="44.9241" r="1.71466" fill={iconColor} />
      <circle cx="25.1176" cy="50.411" r="1.71466" fill={iconColor} />
      <circle cx="34.0339" cy="39.4372" r="1.71466" fill={iconColor} />
      <circle cx="34.0339" cy="44.9241" r="1.71466" fill={iconColor} />
      <circle cx="34.0339" cy="50.411" r="1.71466" fill={iconColor} />
      <circle cx="42.9501" cy="39.4372" r="1.71466" fill={iconColor} />
      <circle cx="42.9501" cy="44.9241" r="1.71466" fill={iconColor} />
      <circle cx="42.9501" cy="50.411" r="1.71466" fill={iconColor} />
      <circle cx="51.8663" cy="39.4372" r="1.71466" fill={iconColor} />
      <circle cx="51.8663" cy="44.9241" r="1.71466" fill={iconColor} />
      <circle cx="51.8663" cy="50.411" r="1.71466" fill={iconColor} />
    </svg>
  );
};

CalendarEventsListing.propTypes = {
  className: PropTypes.string,
  onHoverColor: PropTypes.string,
  offHoverColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  disabled: PropTypes.bool,
};

CalendarEventsListing.defaultProps = {
  disabled: false,
  onHoverColor: "#f98c10",
  offHoverColor: "#7fc035",
  inactiveColor: "#e3e3e3",
};

export default CalendarEventsListing;
