import React from "react";
import { replaceInHtmlText } from "../../../utils/helper";
import labels from "../../../config/labels";
import { useStyles } from "./Disclaimer.style";
import withConfig from "../../../utils/withConfig";

const Disclaimer = () => {
  const classes = useStyles();

  return replaceInHtmlText(
    labels.SIGN_UP_CLAUSE_TEXT + labels.RECAPTCHA_CLAUSE_TEXT,
    [
      {
        toReplace: "Privacy Policy",
        toReplaceWith: `<a href="${withConfig(
          "SIGNUP_LINK_RECAPTCHA_PRIVACY"
        )}" target="_blank">Privacy Policy</a>`,
      },
      {
        toReplace: "Terms of Service",
        toReplaceWith: `<a href="${withConfig(
          "SIGNUP_LINK_TOS_RECAPTCHA"
        )}" target="_blank">Terms of Service</a>`,
      },
      {
        toReplace: "TAPINTO_PRIVACY_POLICY",
        toReplaceWith: `<a href="${withConfig(
          "PRIVACY_POLICY"
        )}" target="_blank">Privacy Policy</a>`,
      },
      {
        toReplace: "TAPINTO_TERMS_OF_USE",
        toReplaceWith: `<a href="${withConfig(
          "TAPINTO_TERMS_OF_USE"
        )}" target="_blank">Terms of Service</a>`,
      },
    ],
    classes.text
  );
};

export default Disclaimer;
