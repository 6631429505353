import React from "react";
import { ICONS_SIZES } from "../Weather";
import PropTypes from "prop-types";

const SquareFacebook = ({ size = 'lg' }) => {
  const iconSize = ICONS_SIZES[size];
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: 'auto', height: iconSize }}
    >
      <rect width="32" height="32" rx="3" fill="#475993" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7214 11.2449C18.1393 11.2449 18.5893 11.2449 19.0071 11.2449C19.0714 11.2449 19.1036 11.2449 19.2 11.2449V8.90076C18.975 8.86727 18.7179 8.83378 18.4929 8.83378C18.0429 8.80029 17.5929 8.80029 17.175 8.80029C16.5 8.80029 15.8571 9.00122 15.3107 9.40308C14.6679 9.87192 14.3143 10.5417 14.1857 11.3454C14.1214 11.6803 14.1214 12.0152 14.1214 12.3501C14.1214 12.8859 14.1214 13.3882 14.1214 13.924V14.1249H12V16.7035H14.1214V23.2003H16.725V16.7035H18.8464C18.9429 15.8329 19.0714 14.9956 19.1679 14.0915C19.0071 14.0915 18.8464 14.0915 18.6857 14.0915C18.075 14.0915 16.6929 14.0915 16.6929 14.0915C16.6929 14.0915 16.6929 12.8189 16.725 12.2496C16.6929 11.4794 17.1429 11.2449 17.7214 11.2449Z"
        fill="white"
      />
    </svg>
  );
};

export default SquareFacebook;

SquareFacebook.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};