import React, { Fragment } from "react";
import {
  defineTypeOfContentForDisplayBlocks,
  getSponsorInfo,
  isBlock,
  showBlockSection,
  showEmptyBlock,
} from "../../utils/helpers/homepageHelper";
import BlockTitle from "../Town/Shared/BlockTitle/BlockTitle";
import Sponsor from "../Sponsor/Sponsor";
import ContentCard from "./ContentCard/ContentCard";
import AdCard from "../Homepage/AdCard/AdCard";
import SubscriptionBox from "../SubscriptionBox/SubscriptionBox";
import PropTypes from "prop-types";
import { randomString } from "../../utils/random";
import { GAMES_BLOCK } from "../../utils/constants/homepageConstants";
import Divider from "../../common/Divider/Divider";
const SubBlocks = ({
  classes,
  block,
  isMobile,
  town,
  adTargets,
  sponsors,
  useSectionContext,
}) => {
  let adUnit;

  if (showBlockSection("ad", block)) {
    adUnit = isMobile ? block?.mobile_ad_units[0] : block?.desktop_ad_units[0];
  }

  return showEmptyBlock(block, adUnit) ? (
    <Fragment key={"empty-block-" + randomString(3)}></Fragment>
  ) : (
    <div
      key={block.id}
      className={
        showBlockSection("ad", block)
          ? classes.space_evenly
          : classes.block_container
      }
    >
      {showBlockSection("title", block) && (
        <div className={classes.block_title_container}>
          <BlockTitle
            title={block.title}
            townSlug={town?.slug}
            contentType={defineTypeOfContentForDisplayBlocks({
              blockClass: block.class,
              slug: block.slug,
            })}
            options={{
              withEndIcon: false,
              forRightSidebar: true,
              isLowerCased: false,
              underlined: false,
            }}
          />
          {getSponsorInfo(sponsors, block.id) && (
            <div
              id={block?.class + "-" + block?.id}
              className={classes.sponsor_container}
            >
              <Sponsor
                isInRightBlock={true}
                sponsor_info={getSponsorInfo(sponsors, block.id)}
              />
            </div>
          )}
        </div>
      )}
      {showBlockSection("content", block) &&
        block.contents.map((content, index) => (
          <Fragment key={`${content?.id} - ${index}`}>
            <ContentCard
              content={content}
              blockClass={block?.class}
              useSectionContext={useSectionContext}
              asSidebarItems
            />
            {isBlock({blockClass: block?.class, type: GAMES_BLOCK}) && index < 2 && <Divider className={classes.games_divider}/>}
          </Fragment>
        ))}
      {showBlockSection("ad", block) && adUnit && (
        <AdCard
          adTargets={adTargets}
          adSize={block.size}
          adUnit={adUnit}
          options={{
            forTopStories: false,
          }}
        />
      )}
      {showBlockSection("subscription", block) && (
        <SubscriptionBox town={town} />
      )}
    </div>
  );
};

SubBlocks.propTypes = {
  classes: PropTypes.object,
  block: PropTypes.object,
  isMobile: PropTypes.bool,
  town: PropTypes.object,
  adTargets: PropTypes.object,
  sponsors: PropTypes.array,
  useSectionContext: PropTypes.bool,
};

export default SubBlocks;
