import React from "react";
export const MostlyCloudySnow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="32"
    viewBox="0 0 42 32"
    fill="none"
  >
    <path
      d="M23.101 8.59428C22.9181 8.59428 22.7962 8.53333 22.6743 8.41142L19.3219 4.57142C19.1391 4.32761 19.1391 4.02285 19.3829 3.83999C19.5048 3.71809 19.6267 3.71809 19.7486 3.71809C19.8705 3.71809 19.9924 3.77904 20.1143 3.90095L23.4667 7.74095C23.6495 7.98476 23.6496 8.28952 23.4057 8.47237C23.3448 8.53333 23.2229 8.59428 23.101 8.59428Z"
      fill="white"
    />
    <path
      d="M28.5257 6.21714C28.2209 6.21714 27.9771 5.97333 27.9771 5.66857L27.9162 0.548571C27.9162 0.24381 28.16 0 28.4647 0C28.7695 0 29.0133 0.24381 29.0133 0.548571L29.0743 5.66857C29.0743 5.79048 29.0133 5.97333 28.8914 6.03429C28.7695 6.15619 28.6476 6.21714 28.5257 6.21714Z"
      fill="white"
    />
    <path
      d="M39.9237 20.4799C39.8628 20.4799 39.7409 20.4799 39.6799 20.419L35.1694 18.0418C35.0475 17.9809 34.9256 17.859 34.9256 17.7371C34.8647 17.6152 34.9256 17.4323 34.9866 17.3104C35.0476 17.1885 35.1695 17.0666 35.2914 17.0666C35.4133 17.0057 35.5961 17.0666 35.718 17.1276L40.2285 19.5047C40.3504 19.5657 40.4723 19.6876 40.4723 19.8095C40.5333 19.9314 40.4723 20.1142 40.4113 20.2361C40.2894 20.419 40.1066 20.4799 39.9237 20.4799Z"
      fill="white"
    />
    <path
      d="M36.2057 12.5562C36.0837 12.5562 35.9618 12.4953 35.9009 12.4343C35.779 12.3734 35.718 12.1905 35.718 12.0686C35.718 11.9467 35.718 11.7638 35.8399 11.7029C35.9009 11.581 36.0837 11.52 36.2057 11.52L41.2647 10.9105C41.3866 10.9105 41.5695 10.9105 41.6914 11.0324C41.8133 11.0934 41.8742 11.2762 41.8742 11.3981C41.9352 11.7029 41.6914 11.9467 41.3866 12.0077L36.3275 12.6172C36.2056 12.5562 36.2057 12.5562 36.2057 12.5562Z"
      fill="white"
    />
    <path
      d="M32.4876 25.9047C32.2438 25.9047 32.0609 25.7828 32 25.539L30.2933 20.7238C30.2324 20.6019 30.2324 20.419 30.2933 20.2971C30.3543 20.1752 30.4762 20.0533 30.5981 20.0533C30.72 19.9924 30.9028 19.9924 31.0247 20.0533C31.1467 20.1143 31.2685 20.2362 31.2685 20.3581L32.9752 25.1733C33.0361 25.2952 33.0361 25.4781 32.9752 25.6C32.9142 25.7219 32.7923 25.8438 32.6704 25.9047C32.6095 25.8438 32.5485 25.9047 32.4876 25.9047Z"
      fill="white"
    />
    <path
      d="M33.4019 8.16761C33.28 8.16761 33.1581 8.10666 33.0972 8.0457C32.8534 7.86285 32.7924 7.49713 32.9753 7.31427L36.1448 3.29142C36.2057 3.16951 36.3886 3.10856 36.5105 3.10856C36.6324 3.10856 36.8153 3.10856 36.8762 3.23046C36.9981 3.29142 37.0591 3.47427 37.0591 3.59618C37.0591 3.71808 37.0591 3.90094 36.9372 4.02285L33.7677 8.0457C33.7067 8.10666 33.5238 8.16761 33.4019 8.16761Z"
      fill="white"
    />
    <path
      d="M28.5256 6.94857C25.1123 6.94857 22.2476 9.63048 22.0647 13.0438C22.1866 13.0438 22.3085 13.0438 22.3695 13.0438C22.4304 13.0438 22.5523 13.0438 22.6133 13.0438C22.7961 13.0438 22.979 13.1048 23.1618 13.1048C23.3447 10.301 25.6609 8.10667 28.5256 8.10667C31.5123 8.10667 33.8895 10.4838 33.8895 13.4705C33.8895 16.4571 31.5123 18.8343 28.5256 18.8343H28.4647C28.8304 19.1391 29.1352 19.5048 29.379 19.9314C32.6095 19.5048 35.0476 16.8229 35.0476 13.4705C34.9866 9.87429 32.0609 6.94857 28.5256 6.94857Z"
      fill="white"
    />
    <path
      d="M27.3067 18.1028C27.3067 17.9809 27.3067 17.859 27.3067 17.7371C27.3676 15.299 25.4781 13.2266 23.04 13.0438C22.0038 12.9828 21.0286 13.2266 20.2362 13.7142C19.8095 11.0933 17.3714 9.08187 14.5067 9.26472C11.8248 9.38663 9.69142 11.3981 9.32571 13.8971C8.83809 13.7142 8.35047 13.6533 7.8019 13.6533C5.2419 13.6533 3.16953 15.7257 3.16953 18.2857C3.16953 18.3466 3.16953 18.4076 3.16953 18.4685C1.88953 18.8342 0 19.5657 0 22.2476C0 24.1371 1.52381 25.7828 3.59619 25.9657C3.77904 25.9657 3.90096 25.9047 4.02286 25.7828L4.44952 25.1733C4.51047 25.1123 4.44953 24.9904 4.32762 24.9904H4.14475C2.43809 24.9904 1.09714 23.7714 1.09714 22.2476C1.09714 20.48 2.07238 19.8704 3.90095 19.4438L4.32762 19.2609V18.5904C4.32762 18.4685 4.26667 18.4076 4.26667 18.2857C4.26667 16.3352 5.85142 14.7504 7.8019 14.7504C8.41142 14.7504 8.96 14.9333 9.50858 15.2381L10.3009 15.7257V14.8114C10.24 12.4952 12.1295 10.5447 14.5067 10.4228H14.7505C17.1276 10.4228 19.0172 12.1904 19.1391 14.5066C19.1391 14.5676 19.1391 14.6895 19.1391 14.8723L19.0781 16.2742L20.0533 15.299C20.7848 14.5676 21.6991 14.2019 22.6743 14.2628C24.4419 14.3238 25.9657 15.7257 26.0876 17.4933C26.1486 17.859 26.0876 18.1638 26.0267 18.5295L25.9048 19.0781L26.4534 19.2C27.8553 19.4438 28.8305 20.5409 28.8914 21.8209C28.9524 23.1009 28.099 24.5028 26.819 24.9904C26.5143 25.0514 26.1486 25.1733 25.8438 25.1733H25.6609C25.539 25.1733 25.4781 25.2952 25.5391 25.3562L25.9657 25.9657C26.0876 26.0876 26.2095 26.2095 26.3924 26.1485C26.6362 26.1485 26.9409 26.0266 27.1847 25.9657C28.8305 25.3562 30.0495 23.5885 29.9886 21.76C30.1105 19.9923 28.9524 18.5904 27.3067 18.1028Z"
      fill="white"
    />
    <path
      d="M6.76578 27.4895H7.25339V27.0019H6.76578V27.4895ZM8.7772 28.16L9.14292 28.5257L9.50864 28.16L9.14292 27.7943L8.7772 28.16ZM6.03434 24.7466L5.66863 25.1124L6.03434 25.4781L6.40006 25.1124L6.03434 24.7466ZM11.0324 27.4895H11.5201V27.0019H11.0324V27.4895ZM7.25339 22.6743H6.76578V23.1619H7.25339V22.6743ZM11.581 22.6743H11.0934V23.1619H11.581V22.6743ZM9.50864 21.9428L9.14292 21.5771L8.7772 21.9428L9.14292 22.3086L9.50864 21.9428ZM12.0686 25.8438L11.7639 25.0514L12.0686 24.32L11.4591 24.9295H10.4839L10.8496 24.3809L10.2401 24.259L10.9105 23.5276H11.7639L10.9715 23.2228L10.6667 22.4305V23.3447L9.99625 24.0762L9.87436 23.4057L9.32577 23.7714V22.7962L9.93529 22.1257L9.20388 22.4914L8.41148 22.1257L9.02101 22.7352V23.7105L8.41148 23.3447L8.28959 23.9543L7.61911 23.2228V22.3695L7.3753 23.1619L6.58291 23.4667H7.43626L8.10672 24.1371L7.3753 24.32L7.80196 24.8076H6.82674L6.21721 24.1981L6.52197 24.9905L6.21721 25.7828L6.82674 25.1124H7.80196L7.3753 25.6609L8.10672 25.7828L7.43626 26.4533H6.58291L7.3753 26.7581L7.61911 27.5505V26.6971L8.28959 26.0267L8.41148 26.6971L9.02101 26.2705V27.2457L8.41148 27.8552L9.14292 27.5505L9.87436 27.8552L9.26483 27.2457V26.3924L9.8134 26.7581L9.93529 26.0876L10.6058 26.7581V27.6114L10.8496 26.88L11.642 26.5752H10.7886L10.1182 25.9047L10.7886 25.7219L10.362 25.2343H11.3372L12.0686 25.8438ZM8.41148 24.5638L8.83816 24.9295H8.28959L8.41148 24.5638ZM8.47244 25.6609L8.28959 25.2343H8.83816L8.47244 25.6609ZM9.02101 25.9657L8.65531 25.7828L9.02101 25.3562V25.9657ZM9.02101 24.7466L8.65531 24.3809L9.02101 24.1981V24.7466ZM9.93529 24.5638L10.0572 24.9295H9.50864L9.93529 24.5638ZM9.32577 24.1981L9.75244 24.3809L9.32577 24.7466V24.1981ZM9.32577 26.0267V25.4171L9.75244 25.7828L9.32577 26.0267ZM9.87436 25.6609L9.44768 25.2343H10.0572L9.87436 25.6609ZM12.2515 24.6857L11.8858 25.0514L12.2515 25.4171L12.6172 25.0514L12.2515 24.6857Z"
      fill="white"
    />
    <path
      d="M21.0288 22.3695H21.2726V22.1257H21.0288V22.3695ZM22.004 22.6743L22.1869 22.8571L22.3697 22.6743L22.1869 22.4914L22.004 22.6743ZM20.6631 20.9676L20.4802 21.1505L20.6631 21.3333L20.8459 21.1505L20.6631 20.9676ZM23.1012 22.3695H23.3449V22.1257H23.1012V22.3695ZM21.2726 19.9314H21.0288V20.1752H21.2726V19.9314ZM23.4059 19.9314H23.1621V20.1752H23.4059V19.9314ZM22.3697 19.5657L22.1869 19.3829L22.004 19.5657L22.1869 19.7486L22.3697 19.5657ZM23.4668 21.1505L23.6497 20.7848L23.3449 21.0895H22.8573L23.0402 20.7848L22.7354 20.7238L23.1012 20.3581H23.5278L23.1621 20.2362L23.0402 19.8705V20.2971L22.6745 20.6629L22.5526 20.2971L22.2478 20.48V19.9924L22.5526 19.6876L22.1869 19.8705L21.8211 19.6876L22.1259 19.9924V20.48L21.8211 20.2971L21.7602 20.6019L21.3945 20.2362V19.8095L21.2726 20.1752L20.8459 20.3581H21.2726L21.6383 20.7238L21.2726 20.7848L21.5164 21.0286H21.0288L20.724 20.7238L20.8459 21.0895L20.724 21.5162L21.0288 21.2114H21.5164L21.3335 21.5162L21.6992 21.5771L21.3335 21.9429H20.9068L21.3335 22.0648L21.4554 22.4305V22.0038L21.8211 21.6381L21.8821 22.0038L22.1869 21.76V22.2476L21.8821 22.5524L22.2478 22.3695L22.6135 22.5524L22.3087 22.2476V21.76L22.6135 21.9429L22.6745 21.5771L23.0402 21.9429V22.3695L23.1621 22.0038L23.5888 21.8819H23.1621L22.7964 21.5162L23.1621 21.3943L22.9792 21.1505H23.4668ZM21.8211 20.9067L22.004 21.0895H21.6992L21.8211 20.9067ZM21.8211 21.4552L21.7602 21.2114H22.065L21.8211 21.4552ZM22.1259 21.5771L21.943 21.5162L22.1259 21.3333V21.5771ZM22.1259 20.9676L21.943 20.7848L22.1259 20.7238V20.9676ZM22.5526 20.9067L22.6135 21.0895H22.3087L22.5526 20.9067ZM22.2478 20.7238L22.4307 20.7848L22.2478 20.9676V20.7238ZM22.2478 21.6381V21.3333L22.4307 21.5162L22.2478 21.6381ZM22.5526 21.4552L22.3697 21.2114H22.6745L22.5526 21.4552ZM23.7107 20.9676L23.5278 21.1505L23.7107 21.3333L23.8935 21.1505L23.7107 20.9676Z"
      fill="white"
    />
    <path
      d="M13.9581 31.4514H14.2019V31.2076H13.9581V31.4514ZM14.9943 31.8171L15.1772 32L15.3601 31.8171L15.1772 31.6343L14.9943 31.8171ZM13.5924 30.1105L13.4096 30.2933L13.5924 30.4762L13.7753 30.2933L13.5924 30.1105ZM16.0915 31.4514H16.3353V31.2076H16.0915V31.4514ZM14.2019 29.0743H13.9581V29.3181H14.2019V29.0743ZM16.3962 29.0743H16.1524V29.3181H16.3962V29.0743ZM15.3601 28.7086L15.1772 28.5257L14.9943 28.7086L15.1772 28.8914L15.3601 28.7086ZM16.64 30.659L16.4572 30.2933L16.64 29.9276L16.3353 30.2324H15.8477L16.0305 29.9276L15.7258 29.8667L16.0915 29.5009H16.5181L16.1524 29.379L16.0305 29.0133V29.44L15.6648 29.8057L15.6038 29.44L15.2991 29.6228V29.1352L15.6038 28.8305L15.2381 29.0133L14.8724 28.8305L15.1772 29.1352V29.6228L14.8724 29.44L14.8115 29.7447L14.4458 29.379V28.9524L14.3239 29.3181L13.8972 29.5009H14.3239L14.6896 29.8667L14.3239 29.9276L14.5677 30.1714H14.0191L13.7143 29.8667L13.8362 30.2324L13.7143 30.5981L14.0191 30.2933H14.5067L14.2629 30.5981L14.6286 30.659L14.2629 31.0247H13.8362L14.2629 31.1467L14.3848 31.5124V31.0857L14.7505 30.72L14.8115 31.0857L15.1162 30.8419V31.3295L14.8115 31.6343L15.1772 31.4514L15.5429 31.6343L15.2381 31.3295V30.8419L15.5429 31.0247L15.6038 30.659L15.9696 31.0247V31.4514L16.0915 31.0857L16.5181 30.9638H16.0915L15.7258 30.5981L16.0915 30.4762L15.9086 30.2324H16.3962L16.64 30.659ZM14.8115 29.9886L14.9943 30.1714H14.6896L14.8115 29.9886ZM14.8115 30.5371L14.7505 30.2933H15.0553L14.8115 30.5371ZM15.1162 30.72L14.9334 30.659L15.1162 30.4762V30.72ZM15.1162 30.1105L14.9334 29.9276L15.1162 29.8667V30.1105ZM15.5429 29.9886L15.6038 30.1714H15.2991L15.5429 29.9886ZM15.2381 29.8057L15.421 29.8667L15.2381 30.0495V29.8057ZM15.2381 30.72V30.4152L15.421 30.5981L15.2381 30.72ZM15.5429 30.5371L15.3601 30.2933H15.6648L15.5429 30.5371ZM16.701 30.1105L16.5181 30.2933L16.701 30.4762L16.8839 30.2933L16.701 30.1105Z"
      fill="white"
    />
    <path
      d="M14.08 23.5276H14.4458V23.1619H14.08V23.5276ZM15.6038 24.0152L15.9086 24.259L16.2134 24.0152L15.9086 23.7105L15.6038 24.0152ZM13.5315 21.4552L13.2876 21.76L13.5924 22.0038L13.8362 21.76L13.5315 21.4552ZM17.2495 23.5276H17.6153V23.1619H17.2495V23.5276ZM14.4458 19.8705H14.08V20.2971H14.4458V19.8705ZM17.6762 19.8705H17.3105V20.2971H17.6762V19.8705ZM16.1524 19.3829L15.8477 19.0781L15.5429 19.3219L15.8477 19.5657L16.1524 19.3829ZM18.0419 22.3086L17.7981 21.699L18.0419 21.1505L17.6153 21.6381H16.8838L17.1276 21.2114L16.64 21.1505L17.1886 20.6019H17.8591L17.2495 20.3581L17.0058 19.7486V20.419L16.5181 20.9676L16.4572 20.48L16.0305 20.7848V20.0533L16.5181 19.5657L15.9696 19.8705L15.36 19.6267L15.8477 20.1143V20.8457L15.421 20.6019L15.36 21.0895L14.8115 20.541V19.8705L14.6286 20.48L14.0191 20.7238H14.6896L15.2381 21.2114L14.6896 21.3333L14.9943 21.699H14.2629L13.7753 21.2114L14.0191 21.821L13.7753 22.3695L14.2629 21.8819H14.9943L14.6896 22.3086L15.2381 22.3695L14.6896 22.9181H14.0191L14.6286 23.101L14.8115 23.7105V23.04L15.2991 22.5524L15.421 23.101L15.8477 22.7962V23.5276L15.36 23.9543L15.9086 23.7105L16.4572 23.9543L16.0305 23.5276V22.7962L16.4572 23.101L16.5181 22.6133L17.0667 23.1619V23.8324L17.2495 23.2838L17.8591 23.04H17.1886L16.64 22.5524L17.1276 22.4305L16.8229 22.0648H17.5543L18.0419 22.3086ZM15.2991 21.3333L15.6038 21.6381H15.1772L15.2991 21.3333ZM15.2991 22.1257L15.1772 21.821H15.6038L15.2991 22.1257ZM15.7867 22.3695L15.482 22.2476L15.7867 21.9429V22.3695ZM15.7867 21.4552L15.482 21.1505L15.7867 21.0286V21.4552ZM16.3962 21.3333L16.5181 21.6381H16.0915L16.3962 21.3333ZM15.9696 21.0286L16.2743 21.1505L15.9696 21.4552V21.0286ZM15.9696 22.4305V22.0038L16.2743 22.3086L15.9696 22.4305ZM16.3962 22.1257L16.0915 21.821H16.5181L16.3962 22.1257ZM18.1638 21.4552L17.8591 21.699L18.1638 21.9429L18.4686 21.699L18.1638 21.4552Z"
      fill="white"
    />
    <path
      d="M19.7488 28.16H20.1145V27.7942H19.7488V28.16ZM21.2116 28.6476L21.5164 28.8914L21.8211 28.6476L21.5164 28.3428L21.2116 28.6476ZM19.2002 26.0876L18.9563 26.3923L19.2611 26.6362L19.5049 26.3923L19.2002 26.0876ZM22.9182 28.16H23.284V27.7942H22.9182V28.16ZM20.1145 24.5638H19.7488V24.9295H20.1145V24.5638ZM23.3449 24.5638H22.9792V24.9295H23.3449V24.5638ZM21.7602 24.0152L21.4554 23.7714L21.1507 24.0152L21.4554 24.259L21.7602 24.0152ZM23.7107 26.9409L23.4668 26.3314L23.7107 25.7828L23.284 26.2704H22.5525L22.7964 25.8438L22.3087 25.7828L22.8573 25.2342H23.5278L22.9182 24.9904L22.6745 24.3809V25.0514L22.1868 25.6L22.1259 25.1123L21.6992 25.4171V24.6857L22.1868 24.1981L21.6383 24.5028L21.0287 24.259L21.5164 24.7466V25.4781L21.0897 25.2342L21.0287 25.7219L20.4802 25.1733V24.5028L20.2973 25.1123L19.6878 25.3562H20.3583L20.9068 25.8438L20.3583 25.9657L20.663 26.3314H19.9316L19.444 25.8438L19.6878 26.4533L19.444 27.0019L19.9316 26.5142H20.663L20.3583 26.9409L20.9068 27.0019L20.3583 27.5504H19.6878L20.2973 27.7333L20.4802 28.3428V27.6723L20.9678 27.1847L21.0897 27.7333L21.5164 27.4285V28.16L21.0287 28.5866L21.5773 28.3428L22.1259 28.5866L21.6992 28.16V27.4285L22.1259 27.7333L22.1868 27.2457L22.7354 27.7942V28.4647L22.9182 27.9162L23.5278 27.6723H22.8573L22.3087 27.1847L22.7964 27.0628L22.4916 26.6971H23.223L23.7107 26.9409ZM20.9678 25.9657L21.2726 26.2704H20.8459L20.9678 25.9657ZM20.9678 26.7581L20.8459 26.4533H21.2726L20.9678 26.7581ZM21.3944 27.0019L21.0897 26.88L21.3944 26.5752V27.0019ZM21.3944 26.0876L21.0897 25.7828L21.3944 25.6609V26.0876ZM22.0649 25.9657L22.1868 26.2704H21.7602L22.0649 25.9657ZM21.6383 25.6609L21.943 25.7828L21.6383 26.0876V25.6609ZM21.6383 27.0628V26.6362L21.943 26.9409L21.6383 27.0628ZM22.0649 26.7581L21.7602 26.4533H22.1868L22.0649 26.7581ZM23.8325 26.0876L23.5278 26.3314L23.8325 26.5752L24.1373 26.3314L23.8325 26.0876Z"
      fill="white"
    />
  </svg>
);
