import { addMonths } from "./dateUtils";

const HUNDRED = 100;

const get = (cname) => {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].split("=");
    if (cookie[0] === cname) {
      return cookie[1];
    }
  }
  return "";
};

const save = (cname, cvalue) => {
  const date = new Date();
  const dateInfuture = addMonths(date, HUNDRED);
  document.cookie = `${cname}=${cvalue}; expires=${dateInfuture}; path=/;`;
};

export default { get, save };
