import React from "react";
import PropTypes from "prop-types";
import Typography from "../Typography/Typography";
import { Link as MaterialLink } from "@material-ui/core";
import { useStyles } from "./Link.style";
import clsx from "clsx";

const Link = ({
  bold,
  className,
  url,
  color,
  level,
  italic,
  children,
  target,
  rel,
  underlined,
  onClick,
  download,
  style,
}) => {
  const classes = useStyles();

  return (
    <>
      <MaterialLink
        className={clsx(className, classes.link)}
        href={url}
        target={target}
        rel={rel}
        onClick={onClick}
        download={download}
      >
        <Typography
          bold={bold}
          italic={italic}
          level={level}
          color={color}
          underlined={underlined}
          style={style}
        >
          {children}
        </Typography>
      </MaterialLink>
    </>
  );
};

Link.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  level: PropTypes.string,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  children: PropTypes.node,
  target: PropTypes.string,
  rel: PropTypes.string,
  underlined: PropTypes.bool,
  onClick: PropTypes.func,
  download: PropTypes.bool,
  style: PropTypes.object,
};

export default Link;
