import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./Typography.style";
import { useTextColorStyles } from "../style/textColor";
import { colorReference } from "../Theme/themes/default";
import clsx from "clsx";

const Typography = ({
  level,
  children,
  color,
  className,
  bold,
  italic,
  underlined,
  font_size,
  centered,
  style,
}) => {
  const classes = useStyles();
  const textColorClasses = useTextColorStyles();

  const getChildren = () => {
    return italic && bold ? (
      <strong>
        <i>{children}</i>
      </strong>
    ) : bold ? (
      <strong>{children}</strong>
    ) : italic ? (
      <i>{children}</i>
    ) : (
      children
    );
  };

  const elements = {
    h1_header_1: (
      <h1
        className={clsx(
          classes.h1_header_1,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h1>
    ),
    h1_header_1_mobile: (
      <h1
        style={style}
        className={clsx(
          classes.h1_header_1_mobile,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h1>
    ),
    h2_header_2: (
      <h2
        style={style}
        className={clsx(
          classes.h2_header_2,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h2>
    ),
    h2_header_2_mobile: (
      <h2
        style={style}
        className={clsx(
          classes.h2_header_2,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h2>
    ),
    h3_sub_header: (
      <h3
        style={style}
        className={clsx(
          classes.h3_sub_header,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h3>
    ),
    h3_sub_header_2: (
      <h3
        style={style}
        className={clsx(
          classes.h3_sub_header_2,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h3>
    ),
    h4_sub_header_4: (
      <h4
        style={style}
        className={clsx(
          classes.h4_sub_header_4,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h4>
    ),
    h5_sub_header_2: (
      <h5
        style={style}
        className={clsx(
          classes.h5_sub_header_2,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h5>
    ),
    paragraph_1: (
      <span
        style={style}
        className={clsx(
          classes.paragraph_1,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    paragraph_2: (
      <span
        style={style}
        className={clsx(
          classes.paragraph_2,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    t0_text_0: (
      <span
        style={style}
        className={clsx(
          classes.t0_text_0,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    t1_text_1: (
      <span
        style={style}
        className={clsx(
          classes.t1_text_1,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    t2_text_2: (
      <span
        style={style}
        className={clsx(
          classes.t2_text_2,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    t3_text_3: (
      <span
        style={style}
        className={clsx(
          classes.t3_text_3,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),

    t4_text_4: (
      <span
        style={style}
        className={clsx(
          classes.t4_text_4,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),

    t5_text_5: (
      <span
        style={style}
        className={clsx(
          classes.t5_text_5,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),

    medium_inherit_font: (
      <span
        style={style}
        className={clsx(
          classes.mont_medium,
          classes.inherit_font,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    heading_section: (
      <h2
        style={style}
        className={clsx(
          classes.common,
          classes.mont_bold,
          classes.font_size_26_px,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h2>
    ),
    heading: (
      <h1
        style={style}
        className={clsx(
          classes.common,
          classes.mont_bold,
          classes.font_size_35_px,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h1>
    ),
    subheading: (
      <h2
        style={style}
        className={clsx(
          classes.h1,
          classes.common,
          classes.mont_bold,
          classes.font_size_28_px,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h2>
    ),
    medium_9_px: (
      <span
        style={style}
        className={clsx(
          classes.mont_medium,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          classes.font_size_9_px,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    medium_10_px: (
      <span
        style={style}
        className={clsx(
          classes.mont_medium,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          classes.font_size_10_px,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    medium_11_px: (
      <span
        style={style}
        className={clsx(
          classes.mont_medium,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          classes.font_size_11_px,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    medium_12_px: (
      <span
        style={style}
        className={clsx(
          classes.font_size_12_px,
          classes.mont_medium,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),

    medium_13_px: (
      <span
        style={style}
        className={clsx(
          classes.mont_medium,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          classes.font_size_13_px,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    medium_15_px: (
      <span
        style={style}
        className={clsx(
          classes.mont_medium,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          classes.font_size_15_px,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    medium_16_px: (
      <span
        style={style}
        className={clsx(
          classes.mont_medium,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          classes.font_size_16_px,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    medium_17_px: (
      <span
        style={style}
        className={clsx(
          classes.mont_medium,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          classes.font_size_17_px,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    medium_18_px: (
      <span
        style={style}
        className={clsx(
          classes.mont_medium,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          classes.font_size_18_px,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    medium_20_px: (
      <span
        style={style}
        className={clsx(
          classes.mont_medium,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          classes.font_size_20_px,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),

    simple_link: (
      <span
        className={clsx(
          classes.mont_medium,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          centered ? classes.centered : ""
        )}
        style={{
          ...style,
          fontSize: font_size,
          color: color,
        }}
      >
        {getChildren()}
      </span>
    ),
    simple_div: (
      <div
        className={clsx(
          classes.mont_medium,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
        style={{
          ...style,
          fontSize: font_size,
          color: color,
        }}
      >
        {getChildren()}
      </div>
    ),

    simple_text: (
      <span
        className={clsx(
          classes.mont_medium,
          classes.font_size_13_px,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          centered ? classes.centered : ""
        )}
        style={{
          ...style,
          fontSize: font_size,
          color: color,
        }}
      >
        {getChildren()}
      </span>
    ),
    text_article: (
      <p
        style={style}
        className={clsx(
          classes.mont_medium,
          classes.font_size_17_px,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </p>
    ),
    text_article_mobile: (
      <p
        style={style}
        className={clsx(
          classes.mont_medium,
          classes.font_size_16_px,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </p>
    ),

    caption: (
      <p
        style={style}
        className={clsx(
          classes.mont_medium,
          classes.font_size_10_px,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </p>
    ),

    mont_italic_9_px: (
      <span
        style={style}
        className={clsx(
          classes.mont_italic,
          classes.font_size_9_px,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    credits: (
      <p
        style={style}
        className={clsx(
          classes.mont_light,
          classes.font_size_10_px,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </p>
    ),
    h1: (
      <h1
        style={style}
        className={clsx(
          classes.h1,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h1>
    ),
    h2: (
      <h2
        style={style}
        className={clsx(
          classes.h2,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h2>
    ),
    h3: (
      <h3
        style={style}
        className={clsx(
          classes.h3,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h3>
    ),
    h4: (
      <h4
        style={style}
        className={clsx(
          classes.h4,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </h4>
    ),
    body: (
      <p
        style={style}
        className={clsx(
          classes.body,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </p>
    ),
    large: (
      <p
        style={style}
        className={clsx(
          classes.large,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </p>
    ),
    small: (
      <p
        style={style}
        className={clsx(
          classes.small,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </p>
    ),
    tiny: (
      <p
        style={style}
        className={clsx(
          classes.tiny,
          classes.common,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </p>
    ),
    bold_text_13: (
      <div
        style={style}
        className={clsx(
          classes.mont_bold,
          classes.font_size_13_px,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </div>
    ),
    medium_text_12: (
      <span
        style={style}
        className={clsx(
          classes.mont_medium,
          classes.font_size_12_px,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    font_size_32_px: (
      <span
        className={clsx(
          classes.mont_medium,
          classes.font_size_32_px,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
    cms_form_label: (
      <span
        style={style}
        className={clsx(
          classes.cms_form_label,
          className,
          textColorClasses[color],
          underlined && classes.underlined,
          bold,
          italic,
          centered ? classes.centered : ""
        )}
      >
        {getChildren()}
      </span>
    ),
  };
  return children ? elements[level] : null;
};

Typography.propTypes = {
  level: PropTypes.oneOf([
    "h1_header_1",
    "h1_header_1_mobile",
    "h2_header_2",
    "h2_header_2_mobile",
    "h3_sub_header",
    "h3_sub_header_2",
    "h4_sub_header_4",
    "h5_sub_header_2",
    "paragraph_1",
    "paragraph_2",
    "t0_text_0",
    "t1_text_1",
    "t2_text_2",
    "t3_text_3",
    "t4_text_4",
    "t5_text_5",
    "medium_9_px",
    "medium_10_px",
    "medium_11_px",
    "medium_12_px",
    "medium_13_px",
    "medium_15_px",
    "medium_16_px",
    "medium_17_px",
    "medium_18_px",
    "medium_20_px",
    "heading_section",
    "simple_text",
    "simple_div",
    "simple_link",
    "heading",
    "subheading",
    "h1",
    "h2",
    "h3",
    "h4",
    "body",
    "large",
    "small",
    "tiny",
    "caption",
    "credits",
    "bold_text_13",
    "medium_text_12",
    "mont_italic_9_px",
    "text_article",
    "text_article_mobile",
    "font_size_32_px",
    "cms_form_label",
  ]),
  color: PropTypes.oneOf(colorReference),
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  underlined: PropTypes.bool,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  font_size: PropTypes.string,
  centered: PropTypes.bool,
  style: PropTypes.object,
};

Typography.defaultProps = {
  level: "simple_text",
  color: "black",
  bold: false,
  underlined: false,
  children: null,
  font_size: "12px",
  italic: false,
  centered: false,
};

export default Typography;
