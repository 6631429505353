import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    main_container: {
      backgroundColor: theme.palette.themeColor.sign_up_background_color,
      border: `5px solid ${theme.palette.themeColor.primary}`,
      borderRadius: theme.spacing(1),
      padding: `${theme.spacing(1)}px  ${theme.spacing(2)}px`,
      position: "relative",
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    header_text: {
      fontSize: "14px",
    },
    alert_container: {
      margin: `${theme.spacing(1)}px 0px`,
    },
  };
});

export { useStyles };
