import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: `${theme.palette.miscellaneous.white} !important`,
    textTransform: "none !important",
    borderRadius: "0px !important",
    textTransform: "lowercase !important",
  },
  label_icon: {
    marginLeft: theme.spacing(1.2),
    width: `${theme.spacing(1.5)}px !important`,
    height: `${theme.spacing(1.5)}px !important`,
  },
  draft_title: {
    width: "35%",
    [theme.breakpoints.down(761)]: {
      width: "100%",
      marginBottom: theme.spacing(1),
    },
  },
  draft_type: {
    color: theme.palette.grayVariation.grayForBorders,
    [theme.breakpoints.down(761)]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  actions_container: {
    width: "fit-content",
    [theme.breakpoints.down(761)]: {
      width: "100%",
      display: "inherit",
      justifyContent: "flex-end",
    },
  },
}));

export { useStyles };
