import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./NewAccountContent.style";
import Checkbox from "../../../common/Checkbox/Checkbox";
import BubbledButton from "../../../common/Button/BubbledButton";
import Link from "../../../common/Link/Link";
import withConfig from "../../../utils/withConfig";
import PasswordField from "../../../common/TextField/PasswordField/PasswordField";
import {
  AGREEMENT_CLAUSE,
  NEW_PASSWORD,
  PASSWORD_CONFIRMATION,
  PASSWORD_CONFIRMATION_CONTRIBUTOR,
  PASSWORD_CONFIRMATION_FIELD_ID,
  TAPINTO_GREEN,
  TAPINTO_GREEN_HEX,
  TOWNS,
} from "../logInConstants";
import TownPicker from "../../TownPicker/TownPicker";
import {
  filterSelectedTownsFromOptions,
  getTownsAsItems,
} from "../../../services/townService";
import Spinner from "../../../common/Spinner/Spinner";
import { useTownTheme } from "../../../context/TownThemeContext";

const NewAccountContent = ({
  email,
  isAgreementClauseChecked,
  isEnewsCheckboxChecked,
  handleEnewsCheckboxChange,
  handleAgreementClauseCheckboxChange,
  handleTownsSelection,
  password,
  handlePasswordChange,
  passwordConfirmation,
  handlePasswordConfirmationChange,
  handleSignUp,
  showErrors,
  errors,
  shouldMarkError,
  townsWithClusters,
  selectedTowns,
  userIsSubscriber,
  handleUpgradeToContributor,
  isLoading,
  handleNextStepOnEnter,
  activeFocus,
}) => {
  const classes = useStyles();
  const { town } = useTownTheme();

  const TermsAndPrivacyLabel = () => {
    let agreeToLabel = labels.AGREE_TO;

    if (town?.under_license_contract) {
      agreeToLabel = labels.AGREE_TO.replace("TAPinto", town?.name);
    }

    return (
      <>
        {agreeToLabel}
        <Link
          url={withConfig("TAPINTO_TERMS_OF_USE")}
          children={labels.TERMS_OF_USE}
          color={TAPINTO_GREEN}
          underlined={true}
          level="t4_text_4"
          target="_blank"
          rel="noopener noreferrer"
        />
        {labels.AND}
        <Link
          url={withConfig("PRIVACY_POLICY")}
          children={labels.PRIVACY_POLICY}
          color={TAPINTO_GREEN}
          underlined={true}
          level="t4_text_4"
          target="_blank"
          rel="noopener noreferrer"
        />
      </>
    );
  };

  const ReceiveEnewsLabel = () => {
    if (town?.under_license_contract) {
      return labels.RECEIVE_ENEWS.replace("TAPinto", town?.name);
    }
    return labels.RECEIVE_ENEWS;
  };

  const shouldShowTownPicker = () => {
    return isEnewsCheckboxChecked && !userIsSubscriber;
  };

  return (
    <>
      <div className={classes.content}>
        {userIsSubscriber ? (
          <Typography
            level="paragraph_1"
            color="tapintoOrange"
            bold
            className={classes.subscriber_text}
          >
            {labels.YOU_ARE_A_SUBSCRIBER_CREATE_PASSWORD}
          </Typography>
        ) : (
          <Typography level="h4" color="tapintoOrange" bold>
            {labels.CREATE_ACCOUNT}
          </Typography>
        )}

        <Typography level="paragraph_1" className={classes.email_text}>
          {email}
        </Typography>

        <div className={classes.password_field_container}>
          <PasswordField
            defaultValue={password}
            handlePasswordChange={handlePasswordChange}
            label={labels.CREATE_PASSWORD}
            error={shouldMarkError(NEW_PASSWORD)}
            required
            autoFocus={activeFocus === "password"}
            handleNextStepOnEnter={handleNextStepOnEnter}
            typeOfField={NEW_PASSWORD}
          />
        </div>

        <div className={classes.password_field_container}>
          <PasswordField
            defaultValue={passwordConfirmation}
            handlePasswordChange={handlePasswordConfirmationChange}
            label={labels.RETYPE_PASSWORD}
            error={
              shouldMarkError(NEW_PASSWORD)
                ? shouldMarkError(NEW_PASSWORD)
                : shouldMarkError(PASSWORD_CONFIRMATION)
            }
            required
            handleNextStepOnEnter={handleNextStepOnEnter}
            typeOfField={
              userIsSubscriber
                ? PASSWORD_CONFIRMATION_CONTRIBUTOR
                : PASSWORD_CONFIRMATION
            }
            id={PASSWORD_CONFIRMATION_FIELD_ID}
            autoFocus={activeFocus === "passwordConfirmation"}
          />
        </div>

        {!userIsSubscriber && (
          <>
            <Typography
              level="paragraph_1"
              color="tapintoOrange"
              className={classes.receive_newsletter_text}
            >
              {labels.RECIEVE_NEWSLETTER}
            </Typography>

            <Checkbox
              label={<ReceiveEnewsLabel />}
              checked={isEnewsCheckboxChecked}
              checkboxColor={TAPINTO_GREEN_HEX}
              className={clsx(
                classes.create_account_checkbox,
                shouldMarkError(TOWNS) && classes.create_account_checkbox_error
              )}
              onChange={handleEnewsCheckboxChange}
            />

            {shouldShowTownPicker() && (
              <TownPicker
                withNoTownOption={false}
                isMultipleWithTags={true}
                townsAsItems={getTownsAsItems(
                  filterSelectedTownsFromOptions(
                    townsWithClusters,
                    selectedTowns
                  ),
                  withConfig("HOME")
                )}
                setTagsArray={handleTownsSelection}
                isTownPickerMobile={false}
                multipleTownPickerClass={clsx(
                  classes.town_selector,
                  shouldMarkError(TOWNS) && classes.town_selector_error
                )}
                valueforMultiplePicker={selectedTowns}
                shouldRenderLabelComponentForMultiple={true}
                placeholder={labels.CHOOSE_YOUR_TOWNS}
                tagAutocompleteClasses={{ popper: classes.town_picker_popper }}
              />
            )}

            <Checkbox
              label={<TermsAndPrivacyLabel />}
              checked={isAgreementClauseChecked}
              checkboxColor={TAPINTO_GREEN_HEX}
              className={clsx(
                classes.create_account_checkbox,
                shouldMarkError(AGREEMENT_CLAUSE) &&
                  classes.create_account_checkbox_error
              )}
              onChange={handleAgreementClauseCheckboxChange}
            />
          </>
        )}

        <div className={classes.password_errors}>{showErrors(errors)}</div>

        <BubbledButton
          level="button_orange"
          color="primary"
          onClick={userIsSubscriber ? handleUpgradeToContributor : handleSignUp}
          className={classes.button}
          disabled={isLoading}
        >
          {userIsSubscriber ? labels.UPGRADE : labels.SIGN_UP}
        </BubbledButton>
      </div>
      {isLoading && (
        <Spinner
          withLoadingOverlay={true}
          loadingOverlayClassName={classes.login_loading_overlay}
        />
      )}
    </>
  );
};

NewAccountContent.propTypes = {
  email: PropTypes.string,
  isAgreementClauseChecked: PropTypes.bool,
  isEnewsCheckboxChecked: PropTypes.bool,
  handleEnewsCheckboxChange: PropTypes.func,
  handleAgreementClauseCheckboxChange: PropTypes.func,
  handleTownsSelection: PropTypes.func,
  password: PropTypes.string,
  handlePasswordChange: PropTypes.func,
  passwordConfirmation: PropTypes.string,
  handlePasswordConfirmationChange: PropTypes.func,
  handleSignUp: PropTypes.func,
  showErrors: PropTypes.func,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      error: PropTypes.string,
    })
  ),
  shouldMarkError: PropTypes.func,
  userIsSubscriber: PropTypes.bool,
  handleUpgradeToContributor: PropTypes.func,
  isLoading: PropTypes.bool,
  activeFocus: PropTypes.string,
};

export default NewAccountContent;
