import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "auto",
    display: "flex",
    height: theme.spacing(16),
    backgroundColor: theme.palette.miscellaneous.black,
    paddingLeft: theme.spacing(17),
    paddingRight: theme.spacing(21),
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(900)]: {
      height: theme.spacing(20),
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: "unset",
      paddingRight: "unset",
    },
  },
  copy_right: {
    display: "flex",
    flexDirection: "column",
    fontWeight: 300,
    order: 1,
    [theme.breakpoints.down(900)]: {
      order: 2,
      textAlign: "center",
    },
  },
  logo: {
    order: 2,
    paddingTop: theme.spacing(),
    [theme.breakpoints.down(900)]: {
      order: 1,
    },
  },
  senirop_info: {
    display: "flex",
    alignItems: "baseline",
    order: 3,
    [theme.breakpoints.down(900)]: {
      paddingTop: theme.spacing(1),
    },
  },
  senirop: {
    paddingLeft: theme.spacing(1),
  },
  license_logo: {
    maxHeight: `calc(100% - ${theme.spacing(4)}px)`,
    [theme.breakpoints.down(900)]: {
      maxHeight: `calc(100% - ${theme.spacing(10)}px)`,
    },
  }
}));

export { useStyles };
