import labels from "../../config/labels";

export const PASSWORD_POPUP_NAME = "password";
export const NEW_ACCOUNT_POPUP_NAME = "newAccount";
export const RESET_PASSWORD_POPUP_NAME = "resetPassword";
export const PASSWORD_RESET_INSTRUCTIONS_POPUP_NAME =
  "passwordResetInstructions";
export const MESSAGE_ERROR = "messageError";
export const AFTER_PASSWORD_CREATE_POPUP_NAME = "afterPasswordCreate";
export const USER_CONFIRMATION_POPUP_NAME = "userConfirmation";
export const CONFIRMATION_EMAIL_RE_SENT_POPUP_NAME = "confirmationEmailResent";
export const PASSWORD_CONFIRMATION_FIELD_ID = "passwordConfirmartionField";

export const EMAIL = "EMAIL";
export const NEW_PASSWORD = "NEW_PASSWORD";
export const PASSWORD_CONFIRMATION = "PASSWORD_CONFIRMATION";
export const AGREEMENT_CLAUSE = "AGREEMENT_CLAUSE";
export const TOWNS = "TOWNS";
export const PASSWORD = "PASSWORD";
export const PASSWORD_CONFIRMATION_CONTRIBUTOR =
  "PASSWORD_CONFIRMATION_CONTRIBUTOR";

export const PASSWORD_ERROR = labels.PLEASE_INSERT_A_VALID_PASSWORD;
export const INCORRECT_PASSWORD_ERROR = labels.PASSWORD_ERROR;
export const EMAIL_ERROR = labels.EMAIL_ERROR;
export const NEW_PASSWORD_ERROR =
  labels.PLEASE_INSERT_PASSWORD_AND_CONFIRMATION;
export const PASSWORD_CONFIRMATION_ERROR =
  labels.PASSWORD_CONFIRMATION_DOESNT_MATCH_PASSWORD;
export const AGREEMENT_CLAUSE_ERROR = labels.PLEASE_AGREE_TO_TERMS;
export const TOWNS_ERROR = labels.PLEASE_CHOOSE_AT_LEAST_ONE_TOWN;
export const SOMETHING_WENT_WRONG_ERROR = labels.SOMETHING_WENT_WRONG_ERROR;
export const SOMETHING_WENT_WRONG_ERROR_MONGO_DB =
  labels.SOMETHING_WENT_WRONG_ERROR_MONGO_DB;
export const RECAPTCHA_ERROR = labels.RECAPTCHA_ERROR;

export const TAPINTO_GREEN = "tapintoGreen";
export const TAPINTO_GREEN_HEX = "#7FC035";
export const GRAY_HEX = "#3F5060";

export const STATUS_OK = 200;

export const CONTACT_EMAIL = "contact@tapinto.net";
