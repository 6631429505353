import React from "react";
export const Clear = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="33"
      viewBox="0 0 25 33"
      fill="none"
    >
      <path
        d="M5.92977 1.20447C8.99686 3.14696 11.3483 6.11182 12.5751 9.79233C15.3355 18.1757 10.6326 27.2748 2.24926 29.9329C1.5336 30.1374 0.81795 30.3418 0.102295 30.4441C3.98728 32.8978 8.89463 33.7157 13.5975 32.1821C21.9809 29.4217 26.5815 20.4249 23.9234 12.0415C21.3675 4.3738 13.5975 -0.124605 5.92977 1.20447Z"
        fill="white"
      />
    </svg>
  );
};
