import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values },
  } = theme;
  return {
    buttons: {
      display: "flex",
      justifyContent: "stretch",
      alignItems: "center",
      columnGap: spacing(1),
      width: "100%",
      padding: "0px 4px",
    },
    button: {
      borderRadius: `${spacing(0.5)} !important`,
      padding: `${spacing(0.5)} !important`,
      textTransform: "none !important",
      flex: 1,
    },
    button_label: {
      fontSize: "10px",
      fontFamily: "Montserrat, sans-serif",
    },
  };
});

export { useStyles };
