import React from "react";
import PropTypes from "prop-types";
import Link from "../../../common/Link/Link";
import labels from "../../../config/labels";
import { useStyles } from "./AlreadySubscribedMessage.style";
const AlreadySubscribedMessage = ({ slug, email, linkColor }) => {
  const classes = useStyles();

  return (
    <div className={classes.already_subscribed_container}>
      {labels.ALREADY_SUBSCRIBED_CLICK_HERE}
      <Link
        className={classes.already_subscribed_link}
        url={`/towns/${slug}/subscriptions/new?email=${email}`}
        color={linkColor}
      >
        here.
      </Link>
    </div>
  );
};

AlreadySubscribedMessage.propTypes = {
  slug: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  linkColor: PropTypes.string,
};

export default AlreadySubscribedMessage;
