import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    content_header: {
      paddingBottom: theme.spacing(1),
      paddingBlock: `${theme.spacing(1.25)}px`,
      display: "block",
      "& h3": {
        padding: "0 !important",
      },
      "& h5": {
        fontWeight: 700,
        lineHeight: "28px",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: (props) => props?.lineClamp || 2,
        "&:hover": {
          textDecoration: "underline",
        },
      },
      "&:hover": {
        textDecoration: "underline !important",
        textDecorationColor: `${theme.palette.dark.black} !important`,
      },
    },
    content_header_single: {
      [theme.breakpoints.down(768)]: {
        paddingBottom: theme.spacing(2),
      },
      padding: 0,
      display: "block",
      "& h3": {
        padding: 0,
        fontWeight: 700,
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        lineHeight: 1.2,
        "&:hover": {
          textDecoration: "underline",
        },
      },
      "&:hover": {
        textDecoration: "underline !important",
        textDecorationColor: `${theme.palette.dark.black} !important`,
      },
    },
    event_header_weight: {
      paddingBlock: "0",
      "& h3": {
        paddingBlock: "0",
        fontWeight: "700",
        marginBottom: "0",
        lineHeight: "1",
      },
    },
    obituary_header: {
      width: "100%",
      display: "block",
      margin: "0px !important",
      ["& h3"]: {
        fontWeight: 700,
        marginBottom: "0",
        lineHeight: `${theme.spacing(3.5)}px`,
      },
    },
  };
});

export { useStyles };
