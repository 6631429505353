import React, { Fragment } from "react";
import { useStyles } from "./GamesCard.style";
import Card from "../../../common/Card/Card";
import Link from "../../../common/Link/Link";
import Typography from "../../../common/Typography/Typography";
import PropTypes from "prop-types";
import withConfig from "../../../utils/withConfig";
import { useTown } from "../../../context/TownContext";
import clsx from "clsx";
import { pathToUrl } from "../../../utils/helper";
import labels from "../../../config/labels";
import Divider from "../../../common/Divider/Divider";

const CARD_TYPE = {
  horizontal: "horizontal",
  vertical: "vertical",
  sidebar: "sidebar",
  sidebarSection: "sidebarSection",
};

const GamesCard = ({ content, sectionTitle, cardType, townSlug }) => {
  const classes = useStyles();

  const { town } = useTown();

  return (
    <div
      className={clsx(
        cardType === CARD_TYPE.horizontal || cardType === CARD_TYPE.vertical
          ? classes.section_container
          : classes.sidebar_container
      )}
    >
      {(cardType === CARD_TYPE.horizontal ||
        cardType === CARD_TYPE.vertical) && (
        <Typography level="h2_header_2" className={classes.section_title}>
          {sectionTitle}
        </Typography>
      )}
      {cardType === CARD_TYPE.sidebarSection && (
        <Link
          children={
            <Typography level="h2_header_2" className={classes.section_title_sidebar}>
              {labels.GAMES_TITLE}
            </Typography>
          }
          url={pathToUrl(withConfig("INDEX_GAMES_URL"), {
            town_slug: townSlug,
          })}
        />
      )}
      <div
        className={clsx(
          cardType === CARD_TYPE.horizontal
            ? classes.cards_container
            : cardType === CARD_TYPE.vertical
            ? classes.vertical_cards_container
            : classes.sidebar_cards_container
        )}
      >
        {content.map((game, index) => (
          <Fragment key={`game-index-${index}`}>
            <Card
              children={
                <Link
                  children={
                    <div
                      className={clsx(
                        cardType === CARD_TYPE.horizontal
                          ? classes.card_content
                          : cardType === CARD_TYPE.sidebar ||
                            cardType === CARD_TYPE.sidebarSection
                          ? classes.sidebar_card_content
                          : classes.vertical_card_content
                      )}
                    >
                      <div
                        className={clsx(
                          cardType === CARD_TYPE.horizontal
                            ? classes.image_container
                            : cardType === CARD_TYPE.sidebar ||
                              cardType === CARD_TYPE.sidebarSection
                            ? classes.sidebar_image_container
                            : classes.vertical_image_container
                        )}
                      >
                        <img
                          src={game?.game_image}
                          alt={game?.game_name}
                          className={clsx(
                            cardType === CARD_TYPE.horizontal ||
                              cardType === CARD_TYPE.vertical
                              ? classes.game_image
                              : classes.sidebar_game_image
                          )}
                        />
                      </div>
                      <Typography
                        level="paragraph_1"
                        className={clsx(
                          cardType === CARD_TYPE.horizontal
                            ? classes.card_title
                            : cardType === CARD_TYPE.sidebar ||
                              cardType === CARD_TYPE.sidebarSection
                            ? classes.sidebar_card_title
                            : classes.vertical_card_title
                        )}
                      >
                        {game?.dificulty && cardType === CARD_TYPE.horizontal
                          ? game?.dificulty
                          : game?.game_name}
                      </Typography>
                      {(cardType === CARD_TYPE.horizontal ||
                        cardType === CARD_TYPE.vertical) && (
                        <Typography
                          level="paragraph_1"
                          className={classes.card_description}
                        >
                          {game?.game_description}
                        </Typography>
                      )}
                    </div>
                  }
                  url={pathToUrl(withConfig("SHOW_GAME_URL"), {
                    town_slug: town?.slug ? town?.slug : townSlug,
                    game_slug: game?.slug,
                  })}
                />
              }
              withShadow={false}
              noRounded={true}
            />
            {cardType === CARD_TYPE.sidebarSection && (
              <Divider className={classes.games_divider}/>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

GamesCard.propTypes = {
  content: PropTypes.array,
  sectionTitle: PropTypes.string,
  cardType: PropTypes.oneOf(Object.values(CARD_TYPE)),
  townSlug: PropTypes.string,
};

GamesCard.defaultProps = {
  content: [],
  cardType: CARD_TYPE.horizontal,
  townSlug: "",
};

export default GamesCard;
