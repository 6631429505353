import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./LeftBlocks.style";
import { useTown } from "../../../context/TownContext";
import Contents from "./Contents/Contents";
import {
  AD_BLOCK,
  SUBSCRIPTION_BOX,
  TITLE_BLOCK,
} from "../../../utils/constants/homepageConstants";
import ContentTitle from "./Titles/ContentTile/ContentTitle";
import { isBlock, getSponsorInfo } from "../../../utils/helpers/homepageHelper";
import SubscriptionBox from "../../SubscriptionBox/SubscriptionBox";
import BlockTitle from "../Shared/BlockTitle/BlockTitle";
import Box from "../../../common/Box/Box";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";

const LeftBlocks = ({ adTargets, town }) => {
  const classes = useStyles();
  const { leftBlocks, sponsors } = useTown();

  const Title = ({ block }) => {
    let sponsorInfo = getSponsorInfo(sponsors, block.id);
    if (
      (block?.title && sponsorInfo) ||
      (block?.title && sponsorInfo && block?.contents?.length === 1) ||
      (block?.title && !sponsorInfo && block?.contents?.length === 1 && screenWidthIsLowerThan(768)) ||
      (block?.title && !sponsorInfo && block?.contents?.length !== 1)
    ) {
      return <ContentTitle sponsorInfo={sponsorInfo} block={block} />;
    }
    return <></>;
  };

  return (
    <div className={classes.left_block_container}>
      {leftBlocks?.length > 0 &&
        leftBlocks.map((block, index) => {
          if (isBlock({ blockClass: block?.class, type: SUBSCRIPTION_BOX })) {
            return (
              <Fragment key={"subscription-box-" + index}>
                <SubscriptionBox town={town} />
              </Fragment>
            );
          }

          if (isBlock({ blockClass: block?.class, type: TITLE_BLOCK })) {
            return block.title ? (
              <div
                className={classes.title_block_container}
                key={"title-block-" + index}
              >
                <BlockTitle title={block.title} />
              </div>
            ) : (
              <></>
            );
          }

          return (
            <Box
              key={"ad-box-" + index}
              className={
                isBlock({ blockClass: block?.class, type: AD_BLOCK })
                  ? ""
                  : classes.container
              }
            >
              <Title block={block} />
              <Contents
                contents={block?.contents}
                block={block}
                {...(isBlock({
                  blockClass: block?.class,
                  type: AD_BLOCK,
                }) && {
                  adProps: { ...block, ad_targets: adTargets },
                })}
              />
            </Box>
          );
        })}
    </div>
  );
};

LeftBlocks.propTypes = {
  adTargets: PropTypes.oneOfType([
    PropTypes.shape({
      towns: PropTypes.arrayOf(PropTypes.string),
      sections: PropTypes.arrayOf(PropTypes.string),
      sponsor_display: PropTypes.arrayOf(PropTypes.string),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        towns: PropTypes.arrayOf(PropTypes.string),
        sections: PropTypes.arrayOf(PropTypes.string),
        sponsor_display: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  ]),
};

export default LeftBlocks;
