import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    social_network_icons_container: {
      "& .st-inline-share-buttons": {
        width: (width) => width,
      },
    },
    no_display: {},
  };
});

export { useStyles };
