import React from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
import BubbledButton from "../../../common/Button/BubbledButton";
import Checkbox from "../../../common/Checkbox/Checkbox";
import { useStyles } from "./PasswordContent.style";
import HorizontalDivider from "../../../common/HorizontalDivider/HorizontalDivider";
import PasswordField from "../../../common/TextField/PasswordField/PasswordField";
import { PASSWORD, TAPINTO_GREEN_HEX } from "../logInConstants";
import Spinner from "../../../common/Spinner/Spinner";

const PasswordPopUpContent = ({
  showErrors,
  handleLogIn,
  errors,
  password,
  rememberMe,
  handleRememberMeCheckboxChange,
  handleOpenResetPasswordPopUp,
  email,
  handlePasswordChange,
  shouldMarkError,
  loadingUserSignIn,
  handleNextStepOnEnter,
}) => {
  const classes = useStyles();

  const shouldDisableSignInButton = () => {
    if (password === "" || loadingUserSignIn) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className={classes.content}>
        <Typography level="h4" color="tapintoOrange" bold>
          {labels.WELCOME_BACK}
        </Typography>
        <Typography level="paragraph_1" className={classes.email_text}>
          {email}
        </Typography>

        <PasswordField
          handlePasswordChange={handlePasswordChange}
          defaultValue={password}
          error={shouldMarkError(PASSWORD)}
          label={labels.ENTER_PASSWORD}
          handleNextStepOnEnter={handleNextStepOnEnter}
          typeOfField={PASSWORD}
          autoFocus={true}
        />

        {showErrors(errors)}
        <BubbledButton
          level="button_orange"
          color="primary"
          onClick={handleLogIn}
          className={classes.button}
          disabled={shouldDisableSignInButton()}
          dataCy="signin"
        >
          {labels.SIGN_IN}
        </BubbledButton>
        {/* Disabling remember me checkbox until finding a solution */}
        {/* <Checkbox
          label={labels.REMEMEMBER_ME}
          checked={rememberMe}
          checkboxColor={TAPINTO_GREEN_HEX}
          className={classes.remember_me_checkbox}
          onChange={handleRememberMeCheckboxChange}
          disabled={loadingUserSignIn}
        />
        <HorizontalDivider className={classes.divider} /> */}
        <div
          onClick={loadingUserSignIn ? null : handleOpenResetPasswordPopUp}
          className={classes.forgot_password}
        >
          <Typography color={"gray"} level="paragraph_1" underlined={true}>
            {labels.FORGOT_PASSWORD}
          </Typography>
        </div>
      </div>
      {loadingUserSignIn && (
        <Spinner
          withLoadingOverlay={true}
          loadingOverlayClassName={classes.login_loading_overlay}
        />
      )}
    </>
  );
};

PasswordPopUpContent.propTypes = {
  showErrors: PropTypes.func,
  handleLogIn: PropTypes.func,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      error: PropTypes.string,
    })
  ),
  password: PropTypes.string,
  rememberMe: PropTypes.bool,
  handleRememberMeCheckboxChange: PropTypes.func,
  handleOpenResetPasswordPopUp: PropTypes.func,
  email: PropTypes.string,
  shouldMarkError: PropTypes.func,
  loadingUserSignIn: PropTypes.bool,
  handleNextStepOnEnter: PropTypes.func,
};

export default PasswordPopUpContent;
