import { makeStyles } from "../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  return {
    input_field: {
      width: "100%",
      height: theme.spacing(4),
      backgroundColor: theme.palette.miscellaneous.white,
      padding: 0,
      "& div.MuiInputBase-root": {
        height: theme.spacing(4),

        "& input.MuiInputBase-input.MuiOutlinedInput-input": {
          height: theme.spacing(4),
          borderRadius: "8px",
          padding: `0px 0px 0px ${theme.spacing(1)}px`,
        },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.miscellaneous.grayDivider,
        },
      },
      "& div.MuiInputBase-root.Mui-focused": {
        height: theme.spacing(4),

        "& input.MuiInputBase-input.MuiOutlinedInput-input": {
          height: theme.spacing(4),
          border: "none",
          padding: `0px 0px 0px ${theme.spacing(1)}px`,
        },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.miscellaneous.grayDivider,
        },
      },
    },
    error_input_field: {
      width: "100%",
      height: theme.spacing(4),
      backgroundColor: theme.palette.miscellaneous.white,
      padding: 0,
      "& div.MuiInputBase-root": {
        height: theme.spacing(4),

        "& input.MuiInputBase-input.MuiOutlinedInput-input": {
          height: theme.spacing(4),
          borderRadius: "8px",
          padding: `0px 0px 0px ${theme.spacing(1)}px`,
        },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.miscellaneous.red,
        },
      },
    },
    text_area: {
      width: "100%",
      "& textarea": {
        boxShadow: "none !important",
      },
      "& :focus": {
        boxShadow: "none !important",
        backgroundColor: "transparent !important",
        border: "none !important",
      },
    },
    error_text_area: {
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.miscellaneous.red} !important`,
      },
    },
  };
});

export { useStyles };
