import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
  },
  singup_footer: {
    marginTop: theme.spacing(2),
    fontSize: 13,
    fontFamily: "Montserrat, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    [theme.breakpoints.down(601)]: {
      marginTop: theme.spacing(1),
      display: "block",
    },
    "& a": {
      color: theme.palette.themeColor.secondary,
    },
  },
  subtitle: {
    marginTop: theme.spacing(0.5),
  },
  recaptcha_text: {
    flexGrow: 1,
    flexBasis: "auto",
  },
  recaptcha_text_mobile: {
    [theme.breakpoints.down(600)]: {
      fontSize: "12px !important",
    },
  },
}));

export { useStyles };
