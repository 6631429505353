import ReactGA from "react-ga";
import withConfig from "./withConfig";

const initializeGA = () => {
  const ID = withConfig("GOOGLE_ANALYTICS_ID");
  ReactGA.initialize(ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
};

const registerGAEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export { initializeGA, registerGAEvent };
