import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    block_container: {
      borderBottom: 0,
      width: "100%",
    },
    block_title_container: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down(768)]: {
        marginTop: "0",
      },
    },
    sponsor_container: {
      width: "100%",
      display: "inline-flex",
      flexDirection: "row-reverse",
      "& [class*='sponsor_container']": {
        paddingBottom: theme.spacing(0),
        "& [class*='ads_container']": {
          marginBottom: theme.spacing(0),
        },
      },
    },
    space_evenly: {
      display: "flex",
      justifyContent: "space-evenly",
      paddingBottom: theme.spacing(1),
    },
    no_ad_blocks: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      paddingInline: theme.spacing(3),
      gap: theme.spacing(1.5),
      [theme.breakpoints.down(768)]: {
        paddingTop: theme.spacing(3),
        paddingInline: theme.spacing(2),
      },
    },
    ad_block_with_margin_top: {
      marginTop: theme.spacing(3),
    },
    block_divider: {
      width: "100%",
    },
    games_divider: {
      marginBottom: `${theme.spacing(3)}px !important`,
    },
    no_printing: {
      "@media print": {
        display: "none",
      },
    },
  };
});

export { useStyles };
