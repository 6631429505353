import React from "react";
import PropTypes from "prop-types";
import { Menu as MaterialMenu } from "@material-ui/core";

const Menu = ({
  id,
  className,
  elevation,
  getContentAnchorEl,
  anchorEl,
  anchorOrigin,
  transformOrigin,
  onClick,
  onMouseEnter,
  onMouseLeave,
  children,
  open,
  disableScrollLock,
  onClose,
  MenuListProps,
  keepMounted,
  PaperProps,
  PopoverClasses,
}) => {
  return (
    <MaterialMenu
      id={id}
      className={className}
      elevation={elevation}
      getContentAnchorEl={getContentAnchorEl}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      open={open}
      disableScrollLock={disableScrollLock}
      onClose={onClose}
      MenuListProps={MenuListProps}
      keepMounted={keepMounted}
      PaperProps={PaperProps}
      PopoverClasses={PopoverClasses}
    >
      {children}
    </MaterialMenu>
  );
};

Menu.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  elevation: PropTypes.any,
  getContentAnchorEl: PropTypes.any,
  anchorEl: PropTypes.any,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  open: PropTypes.bool,
  disableScrollLock: PropTypes.bool,
  onClose: PropTypes.func,
  MenuListProps: PropTypes.objectOf(PropTypes.any),
  keepMounted: PropTypes.bool,
  PaperProps: PropTypes.objectOf(PropTypes.any),
  PopoverClasses: PropTypes.object,
};

export default Menu;
