import React from "react";
import PropTypes from "prop-types";

const Location = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="50"
      height="70"
      viewBox="0 0 50 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 0C11.1786 0 0 10.955 0 24.5C0 42.875 25 70 25 70C25 70 50 42.875 50 24.5C50 10.955 38.8214 0 25 0ZM25 33.25C20.0714 33.25 16.0714 29.33 16.0714 24.5C16.0714 19.67 20.0714 15.75 25 15.75C29.9286 15.75 33.9286 19.67 33.9286 24.5C33.9286 29.33 29.9286 33.25 25 33.25Z"
        fill={fillColor}
      />
    </svg>
  );
};

Location.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

Location.defaultProps = {
  fillColor: "white",
};

export default Location;
