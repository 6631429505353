import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";

export const getTopStories = async (town_id, section_id) => {
  try {
    const response = await restClient.get(
      withConfig("GET_TOP_LOCAL_NEWS_BY_SECTION_AND_TOWN"),
      {
        town_id: town_id,
        section_id: section_id,
        top_stories_limit: 4,
      }
    );
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getDisplayBlocks = async (town_id, section_id) => {
  try {
    const response = await restClient.get(
      withConfig("GET_DISPLAY_BLOCKS_BY_SECTION_AND_TOWN"),
      {
        town_id: town_id,
        section_id: section_id,
      }
    );
    return response;
  } catch (e) {
    console.log(e);
    return false;
  }
};
