import { alpha } from "@material-ui/core";
import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;
  return {
    modal_container: {
      minWidth: theme.spacing(80) + " !important",
    },
    content: {
      padding: `${spacing(6)}px ${spacing(9)}px`,
      gap: spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cancel_button: {
      fontFamily: "Montserrat !important",
      backgroundColor: palette.themeColor.primary + " !important",
      textTransform: 'none !important',
      fontSize: '16px !important',
      color: palette.common.white + " !important",
      minWidth: '150px !important',
    },
  }
})

export { useStyles }