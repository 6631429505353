import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Snackbar as MuiSnackbar } from "@material-ui/core";
import { useStyles } from "./Snackbar.style";
import ClickableIcon from "../Icons/ClickableIcon/ClickableIcon";
import CloseCircle from "../Icons/CloseCircle";
import WarningIcon from "../Icons/WarningIcon";
import CheckCircle from "../Icons/CheckCircle";

const Snackbar = ({
  onClose,
  open,
  message,
  variant,
  autoHideDuration,
  anchorOrigin,
  classes,
  messageContainerClass,
  onActionClick,
  actionClassName,
  useAction,
  customAction,
  useIconBeforeMessage,
  customIconBeforeMessage,
}) => {
  const customClasses = useStyles();

  const getClasses = () => {
    let classes = {
      root: [],
      topCenter: [],
    };
    if (variant) {
      if (useAction) {
        classes.root.push(customClasses.with_action);
      }
      if (variant.includes("success")) {
        classes.root.push(customClasses.success);
      }
      if (variant.includes("error")) {
        classes.root.push(customClasses.error);
      }
      if (variant.includes("warning")) {
        classes.root.push(customClasses.warning);
      }
      if (variant.includes("center_top_below_masthead")) {
        classes.topCenter.push(customClasses.center_below_masthead);
      }
      if (variant.includes("center_top_below_cms_header")) {
        classes.topCenter.push(customClasses.center_below_cms_header);
      }
      if (variant.includes("center_in_screen")) {
        classes.topCenter.push(customClasses.center_in_screen);
      }
    }
    return classes;
  };

  const DefaultIconBeforeMessage = () => {
    if (variant.includes("error")) {
      return <CloseCircle fillColor="white" />;
    } else if (variant.includes("warning")) {
      return <WarningIcon />;
    } else if (variant.includes("success")) {
      return <CheckCircle fillColor="white" />;
    } else {
      return <></>;
    }
  };

  const CustomMessage = () => {
    return (
      <div
        className={clsx(
          customClasses.message_container,
          messageContainerClass && messageContainerClass
        )}
      >
        {useIconBeforeMessage && (
          <div className={customClasses.icon_before_message}>
            {customIconBeforeMessage ? (
              customIconBeforeMessage
            ) : (
              <DefaultIconBeforeMessage />
            )}
          </div>
        )}
        {message}
      </div>
    );
  };

  const Action = () => {
    return customAction ? (
      customAction
    ) : (
      <ClickableIcon
        icon={<CloseCircle className={customClasses.action_icon} />}
        {...(onActionClick && { onClick: onActionClick })}
        className={clsx(
          customClasses.action,
          actionClassName && actionClassName
        )}
      />
    );
  };

  return (
    <MuiSnackbar
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      message={<CustomMessage />}
      {...(useAction && { action: <Action /> })}
      anchorOrigin={anchorOrigin}
      classes={{
        root: clsx(getClasses()?.root),
        anchorOriginTopCenter: clsx(getClasses()?.topCenter),
        ...classes,
      }}
    />
  );
};

Snackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["top", "bottom"]),
    horizontal: PropTypes.oneOf(["center", "left", "right"]),
  }),
  classes: PropTypes.object,
  variant: PropTypes.oneOf([
    "success",
    "warning",
    "error",
    "center_top_below_masthead",
    "center_top_below_cms_header",
    "center_in_screen",
    "success center_top_below_masthead",
    "success center_top_below_cms_header",
    "success center_in_screen",
    "warning center_top_below_masthead",
    "warning center_top_below_cms_header",
    "warning center_in_screen",
    "error center_top_below_masthead",
    "error center_top_below_cms_header",
    "error center_in_screen",
  ]),
  messageContainerClass: PropTypes.string,
  onActionClick: PropTypes.func,
  actionClassName: PropTypes.string,
  useAction: PropTypes.bool,
  customAction: PropTypes.node,
  useIconBeforeMessage: PropTypes.bool,
  customIconBeforeMessage: PropTypes.node,
};

Snackbar.defaultProps = {
  autoHideDuration: 8000,
  anchorOrigin: { vertical: "top", horizontal: "center" },
  useIconBeforeMessage: true,
};

export default Snackbar;
