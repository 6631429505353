import React from 'react';
import Box from '../../common/Box/Box';
import { Button } from '@material-ui/core';
import { CELSIUS_UNIT, FARENTHEIT_UNIT, ICONS, IMPERIAL_WEATHER_SYSTEM } from './Weather';
import Typography from '../../common/Typography/Typography';
import Link from '../../common/Link/Link';
import useStyles from './Weather.style';

export const WeatherTooltipTitleContent = ({ weather, weatherSystem, handleChangeWeatherUnit }) => {
  
  const CurrentWeatherIcon = ICONS[weather.forecast.current_conditions?.Icon];
  const NightlyConditionsIcon = ICONS[weather.forecast.nightly_conditions?.Icon];
  const TomorrowConditionsIcon = ICONS[weather.forecast.tomorrow_conditions?.Icon];

  const classes = useStyles()

  return (
    <>
      <Box className={classes.weather_title_container}>
        <Box className={classes.tooltip_title_header}>
          <Button className={classes.weather_system_button} onClick={handleChangeWeatherUnit}>
            {weatherSystem === IMPERIAL_WEATHER_SYSTEM ? FARENTHEIT_UNIT : CELSIUS_UNIT} 
            <span className={classes.weather_system_divider}/> 
            <span className={classes.weather_system_opposite}>&nbsp;{weatherSystem === IMPERIAL_WEATHER_SYSTEM ? CELSIUS_UNIT : FARENTHEIT_UNIT}</span>
          </Button>
          <Link level="t0_text_0" url={weather.forecast.current_conditions_link} target="__blank">
            <Box className={classes.flex_container}>
              <CurrentWeatherIcon />
              <Typography level="t4_text_4" color="white">
              {weather.forecast.current_conditions?.Text}
              </Typography>
            </Box>
          </Link>
        </Box>
        <Link level="t0_text_0" url={weather.forecast.current_conditions_link} target="__blank">
          <Box className={classes.flex_container_column_center}>
              <Typography level="t4_text_4" color="white">
                current
              </Typography>
              <Box className={classes.flex_container}>
                <Typography level="h1" color="white" bold>
                  {weather.forecast.current_conditions?.[weatherSystem]?.Value}
                </Typography>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}>
                  <Typography level="font_size_32_px" color="white">
                    °
                  </Typography>
                  <Typography level="h4" color="white">
                    {weather.forecast.current_conditions?.[weatherSystem]?.Unit}
                  </Typography>
                </Box>
              </Box>
              <Typography level="t4_text_4" color="white">
                {weather.town_name}, {weather.forecast.country_id}
              </Typography>
          </Box>
          <Box className={classes.weather_title_content}>
            <Box className={classes.flex_container_column_center}>
              <NightlyConditionsIcon size='sm'/>
              <Box className={classes.flex_container}>
                <Typography level="font_size_32_px" color="white" bold>
                  {weather.forecast.nightly_conditions?.[weatherSystem]?.Value}
                </Typography>
                <Box className={classes.flex_container_column_center_no_gap}>
                  <Typography level="t0_text_0" color="white">
                    °
                  </Typography>
                  <Typography level="tiny" color="white">
                    {weather.forecast.nightly_conditions?.[weatherSystem]?.Unit}
                  </Typography>
                </Box>
              </Box>
              <Typography level="t0_text_0" color="white">
                Tonight
              </Typography>
            </Box>
            <Box className={classes.flex_container_column_center}>
              <TomorrowConditionsIcon size='sm'/>
              <Box className={classes.flex_container}>
                <Typography level="font_size_32_px" color="white" bold>
                  {weather.forecast.tomorrow_conditions?.[weatherSystem]?.Value}
                </Typography>
                <Box className={classes.flex_container_column_center_no_gap}>
                  <Typography level="t0_text_0" color="white">
                    °
                  </Typography>
                  <Typography level="tiny" color="white">
                    {weather.forecast.tomorrow_conditions?.[weatherSystem]?.Unit}
                  </Typography>
                </Box>
              </Box>
              <Typography level="t0_text_0" color="white">
              Tomorrow
              </Typography>
            </Box>
          </Box>
        </Link>
      </Box>
      <Box className={classes.weather_title_content_footer}>
        <Link level="t0_text_0" url={weather.forecast.current_conditions_link} target="__blank">
          See more weather info at AccuWeather.com
        </Link>
      </Box>
    </>
  )
}