const errorLabels = {
  ERROR_404_PAGE_NOT_FOUND: "The page you are looking for could not be found.",
  YOU_CAN_GO_BACK_OR_GO_BACK_TO_HOMEPAGE:
    "You can go back or return to homepage.",
  WE_APOLOGIZE_FOR_THE_INCONVINIENCE: "We apologize for the inconvinience.",
  GO_BACK: "back",
  BACK_TO_HOMEPAGE: "homepage",
  TECHNICAL_DIFFICULTIES:
    "TAPinto.net is temporarily experiencing technical difficulties.",
  WE_HAVE_BEEN_ALERTED:
    "We have been alerted to the issue you are experiencing and are working to resolve it. Should you have any questions or concerns, please email info@tapinto.net or call 908-279-0303.",
};

export default errorLabels;
