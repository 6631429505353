import React from "react";

export const HazySunshine = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="33"
    viewBox="0 0 36 33"
    fill="none"
    className={className}
  >
    <path
      d="M16.3774 23.8679C16.3774 24.1698 16.1509 24.3962 15.8491 24.3962C14.4906 24.3962 13.1321 24.3962 11.7736 24.3962C9.35849 24.3962 6.86792 24.3962 4.45283 24.3962C4.07547 24.3962 3.4717 24.5472 3.39623 23.8679C3.39623 23.1887 3.92453 23.2642 4.37736 23.2642C6.49057 23.2642 8.67924 23.2642 10.7925 23.2642C12.4528 23.2642 14.1132 23.2642 15.7736 23.2642C16.1509 23.3396 16.3774 23.566 16.3774 23.8679Z"
      fill="white"
    />
    <path
      d="M14.717 17.8302C13.6604 17.8302 12.5283 17.8302 11.4717 17.8302C11.3208 17.8302 11.2453 17.8302 11.0943 17.8302C10.4906 17.8302 9.88679 17.8302 9.28302 17.8302C8.83019 17.8302 8.30189 17.7547 8.30189 18.434C8.30189 19.1132 8.90566 18.9623 9.35849 18.9623C9.96226 18.9623 10.566 18.9623 11.0943 18.9623C11.5472 18.9623 12.0755 18.9623 12.5283 18.9623C13.283 18.9623 13.9623 18.9623 14.717 18.9623C15.0189 18.9623 15.2453 18.7359 15.2453 18.434C15.3208 18.0566 15.0189 17.8302 14.717 17.8302Z"
      fill="white"
    />
    <path
      d="M18.1887 19.7925C15.8491 19.7925 13.5094 19.7925 11.1698 19.7925C11.0943 19.7925 11.0943 19.7925 11.0189 19.7925C8.30189 19.7925 5.50943 19.7925 2.71698 19.7925H2.64151C2.11321 19.8679 2.11321 20.6981 2.64151 20.8491H2.71698C4.22641 20.9245 5.73585 20.8491 7.24528 20.8491C8.30189 20.8491 10.1132 20.8491 11.0189 20.8491C12.1509 20.8491 13.283 20.8491 14.3396 20.8491C15.3962 20.8491 17.2075 20.8491 18.1132 20.8491C18.4151 20.8491 18.6415 20.6226 18.6415 20.3208C18.7925 20.0189 18.4906 19.7925 18.1887 19.7925Z"
      fill="white"
    />
    <path
      d="M20.6038 22.1321C20.6038 22.434 20.3019 22.7358 20 22.7358C17.1321 22.7358 14.1887 22.7358 11.3208 22.7358C8.15094 22.7358 4.98113 22.7358 1.81132 22.7358C1.73585 22.7358 1.58491 22.7358 1.50943 22.7358C1.20755 22.7358 0.830189 22.7358 0.528302 22.7358H0.45283C-0.150943 22.5849 -0.150943 21.7547 0.45283 21.6038H0.528302C0.90566 21.5283 1.20755 21.5283 1.50943 21.6038C7.69811 21.6038 13.8113 21.6038 20 21.6038C20.3019 21.5283 20.6038 21.7547 20.6038 22.1321Z"
      fill="white"
    />
    <path
      d="M12 11.566C11.7736 11.566 11.6226 11.4906 11.4717 11.3396L7.39623 6.58491C7.16981 6.35849 7.16981 5.90566 7.4717 5.67925C7.54717 5.5283 7.77358 5.45283 7.92453 5.5283C8.07547 5.5283 8.22641 5.60378 8.37736 5.75472L12.5283 10.5094C12.7547 10.8113 12.7547 11.1887 12.4528 11.4151C12.3774 11.4906 12.2264 11.566 12 11.566Z"
      fill="white"
    />
    <path
      d="M18.717 8.62264C18.3396 8.62264 18.0377 8.32076 18.0377 7.9434L17.9623 1.60378C17.9623 1.22642 18.2641 0.92453 18.6415 0.92453C19.0189 0.92453 19.3208 1.22642 19.3208 1.60378L19.3962 7.9434C19.3962 8.09434 19.3208 8.32076 19.1698 8.39623C19.0943 8.54717 18.9434 8.62264 18.717 8.62264Z"
      fill="white"
    />
    <path
      d="M32.8302 26.283C32.7547 26.283 32.6038 26.283 32.5283 26.2075L26.9434 23.2642C26.7925 23.1887 26.6415 23.0377 26.6415 22.8868C26.566 22.7358 26.6415 22.5094 26.717 22.3585C26.7925 22.2075 26.9434 22.0566 27.0943 22.0566C27.2453 21.9811 27.4717 22.0566 27.6226 22.1321L33.2075 25.0755C33.3585 25.1509 33.5094 25.3019 33.5094 25.4528C33.5849 25.6038 33.5094 25.8302 33.434 25.9811C33.3585 26.2075 33.1321 26.283 32.8302 26.283Z"
      fill="white"
    />
    <path
      d="M28.2264 16.4717C28.0755 16.4717 27.9245 16.3962 27.8491 16.3208C27.6981 16.2453 27.6226 16.0189 27.6226 15.8679C27.6226 15.717 27.6226 15.4906 27.7736 15.4151C27.8491 15.2642 28.0755 15.1887 28.2264 15.1887L34.4906 14.434C34.6415 14.434 34.8679 14.434 35.0189 14.5849C35.1698 14.6604 35.2453 14.8868 35.2453 15.0377C35.3208 15.4151 35.0189 15.717 34.6415 15.7925L28.3774 16.5472C28.3019 16.4717 28.2264 16.4717 28.2264 16.4717Z"
      fill="white"
    />
    <path
      d="M23.6226 33C23.3208 33 23.0943 32.8491 23.0189 32.5472L20.9057 26.5849C20.8302 26.434 20.8302 26.2075 20.9057 26.0566C20.9811 25.9057 21.1321 25.7547 21.283 25.7547C21.434 25.6792 21.6604 25.6792 21.8113 25.7547C21.9623 25.8302 22.1132 25.9811 22.1132 26.1321L24.2264 32.0943C24.3019 32.2453 24.3019 32.4717 24.2264 32.6226C24.1509 32.7736 24 32.9245 23.8491 33C23.7736 32.9245 23.6981 33 23.6226 33Z"
      fill="white"
    />
    <path
      d="M24.7547 11.0377C24.6038 11.0377 24.4528 10.9623 24.3774 10.8868C24.0755 10.6604 24 10.2075 24.2264 9.98113L28.1509 5C28.2264 4.84906 28.4528 4.77359 28.6038 4.77359C28.7547 4.77359 28.9811 4.77359 29.0566 4.92453C29.2075 5 29.283 5.22642 29.283 5.37736C29.283 5.5283 29.283 5.75472 29.1321 5.90566L25.2075 10.8868C25.1321 10.9623 24.9811 11.0377 24.7547 11.0377Z"
      fill="white"
    />
    <path
      d="M12.2264 16.0189C12.9811 12.7736 16.0755 10.5094 19.6226 10.9623C22.566 11.3396 24.8302 13.6038 25.283 16.4717C25.8113 20.0189 23.5472 23.1132 20.3774 23.9434C20 24.0189 19.7736 24.4717 19.9245 24.8491C20.0755 25.2264 20.4528 25.3774 20.7547 25.3019C24.3019 24.3962 26.8679 21.1509 26.717 17.3019C26.6415 13.4528 23.0943 9.75472 19.2453 9.5283C15.1698 9.22642 11.7736 12.0189 10.8679 15.717C10.7925 16.0943 11.0189 16.5472 11.4717 16.6226C11.8491 16.6226 12.1509 16.3962 12.2264 16.0189Z"
      fill="white"
    />
  </svg>
);
