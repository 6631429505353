import React from "react";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";

const MenuDrawer = ({
  className,
  variant,
  classes,
  anchor,
  openState,
  onClose,
  children,
}) => {
  return (
    <Drawer
      className={className}
      variant={variant}
      classes={classes}
      anchor={anchor}
      open={openState}
      onClose={onClose}
    >
      {children}
    </Drawer>
  );
};

MenuDrawer.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  classes: PropTypes.object,
  anchor: PropTypes.string,
  openState: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
};

export default MenuDrawer;
