import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    mobile_card: {
      width: "100%",
    },
    mobile_divider: {
      border: `1px solid ${theme.palette.miscellaneous.grayDivider}`,
      margin: `${theme.spacing(2)}px 0px`,
    },
    image: {
      maxHeight: theme.spacing(31.25),
    },
    information: {
      width: "100%",
      display: "block",
    },
    information_title: {
      height: theme.spacing(6),
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      display: "block",
      "& span": {
        fontSize: "13px",
        fontWeight: 700,
        lineHeight: "16px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    secondary_story_top_section: {
      display: "flex",
      justifyContent: "space-between",
      height: "100%",
      flexDirection: "column",
    },
  };
});

export { useStyles };
