export const DIRECTORIES_BLOCK = "DirectoriesBlock";
export const REAL_ESTATE_BLOCK = "RealEstateBlock";
export const OBITUARIES_BLOCK = "ObituariesBlock";
export const EVENTS_BLOCK = "EventsBlock";
export const CLASSIFIED_BLOCK = "ClassifiedBlock";
export const BUSINESS_LISTING_BLOCK = "BusinessListingBlock";
export const CATEGORY_BLOCK = "CategoryBlock";
export const SECTION_BLOCK = "SectionBlock";
export const TITLE_BLOCK = "TitleBlock";
export const COLUMN_BLOCK = "ColumnBlock";
export const MILESTONES_BLOCK = "MilestonesBlock";
export const LEGAL_NOTICES_BLOCK = "LegalNoticesBlock";
export const GAMES_BLOCK = "GamesBlock";
export const AD_BLOCK = "AdBlock";
export const SUBSCRIPTION_BOX = "EmailSubscriptionBlock";
export const AD_PLACEHOLDER = "AdPlaceholder";

export const VIDEO_PLAYER_OPTIONS = {
  width: "100%",
  height: "200px",
  showPlayable: true,
};
