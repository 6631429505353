import { makeStyles } from "../../Theme/Theme";

const useStyles = makeStyles((theme) => ({
  dialog_title: {
    "&.MuiDialogTitle-root": {
      margin: 0,
      background: theme.palette.themeColor.primary,
    },
  },
  close_button: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    cursor: "pointer",
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1.5),
  },
  back_button: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    position: "absolute",
    "&.MuiIconButton-root": {
      height: theme.spacing(2),
      width: theme.spacing(2),
      position: "absolute",
      left: theme.spacing(2),
      top: theme.spacing(2),
    },
  },
}));

export { useStyles };
