import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  divider: {
    border: "none",
    height: "2px",
    backgroundColor: theme.palette.themeColor.primary,
    width: "15%",
    borderRadius: "8px",
    margin: "0px",
  },
  full_width_divider: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submenu_list_item: {
    flexDirection: "column",
    alignItems: "flex-start !important",
    gap: theme.spacing(1),
  },
  submenu_divider: {
    border: "none",
    height: "2px",
    backgroundColor: theme.palette.dark.gray,
    width: "50%",
  },
  submenu_item_gutters: {
    paddingRight: `${theme.spacing(4)}px !important`,
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingBottom: "0px !important",
  },
}));

export { useStyles };
