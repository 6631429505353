import React, { useState, useCallback } from "react";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import { useStyles } from "./FormContainer.style";
import { useFooterManagement } from "../../../context/FooterContext";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Button from "../../../common/Button/Button";
import { subscribeTo } from "../../../services/subscriber";
import clsx from "clsx";
import TextInput from "../../../common/TextInput/TextInput";
import { isValidEmail } from "../../../utils/helper";
import SignUpBottomText from "../../SignUpNewsletter/SignUpBottomText/SignUpBottomText";
import { loadThemeTexts } from "../../../services/townService";
import AlreadySubscribedMessage from "../../SignUpNewsletter/AlreadySubscribedMessage/AlreadySubscribedMessage";

const ACTION = "footerSignUp";
const LAYOUT_TWO = "layout_two";

const FormContainer = () => {
  const classes = useStyles();
  const { town, footerLayout } = useFooterManagement();
  const { type, name, slug } = town;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [inputError, setInputError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  const handleCommitAction = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const result = executeRecaptcha(ACTION);

    setToken(result);

    //Input validation
    if (!isValidEmail(subscriberEmail)) {
      setInputError(true);
    } else if (subscriberEmail && isValidEmail(subscriberEmail)) {
      setButtonDisabled(true);

      const subscribeResponse = await subscribeTo(
        type,
        slug,
        subscriberEmail,
        token,
        "footer"
      );

      if (subscribeResponse?.status === 200) {
        handleSuccess();
      }

      // status 204 means that email is already subscribed to town
      if (subscribeResponse?.status === 204) {
        setSuccessMessage(
          <AlreadySubscribedMessage
            slug={slug}
            email={subscriberEmail}
            linkColor={"white"}
          />
        );
      }
    }
  }, [executeRecaptcha, subscriberEmail]);

  const handleReCaptchaVerify = useCallback(
    (token) => {
      setToken(token);
    },
    [setToken]
  );

  const handleSuccess = async () => {
    const customizedTextResponse = await loadThemeTexts(slug);

    if (
      customizedTextResponse?.status === 200 &&
      customizedTextResponse?.data?.post_subscription_message
    ) {
      setSuccessMessage(
        <div
          className={classes.custom_success_message}
          dangerouslySetInnerHTML={{
            __html: customizedTextResponse?.data?.post_subscription_message,
          }}
        />
      );
    } else {
      setSuccessMessage(addContentName(labels.YOU_HAVE_SUCCESSFULLY_SIGNED_UP));
    }
  };

  const addContentName = (label) => {
    return label.replace("##contentName", name);
  };

  const ErrorMessage = () => {
    return (
      <Typography className={classes.error_message} level="medium_12_px" bold>
        {inputError ? labels.INPUT_ERROR : labels.SIGNUP_ERROR}
      </Typography>
    );
  };

  const formAtTop = () => {
    return footerLayout === LAYOUT_TWO;
  };

  return (
    <>
      <Typography
        color="white"
        bold
        className={clsx(
          classes.get_local_news,
          formAtTop() ? classes.label_at_top : ""
        )}
      >
        {labels.GET_LOCAL_NEWS_YOU_CAN_TRUST}
      </Typography>
      <div
        className={clsx(formAtTop() ? classes.email_container_centered : "")}
      >
        {!successMessage && (
          <div
            className={clsx(
              classes.email_and_button,
              formAtTop() ? classes.email_and_button_at_top : ""
            )}
          >
            <div className={clsx(classes.email)}>
              <TextInput
                placeholder="your@email.com"
                value={subscriberEmail}
                onChange={(event) => setSubscriberEmail(event.target.value)}
                className={classes.email}
              />
            </div>
            <div
              className={clsx(
                classes.signup_container,
                inputError ? classes.signup_with_extra_margin : ""
              )}
            >
              <Button
                level="simple_button"
                color="primary"
                variant="contained"
                onClick={handleCommitAction}
                disabled={buttonDisabled}
                className={classes.signup}
                id="newsletter-subscription-footer"
              >
                <Typography level="tiny" color="white" bold>
                  {labels.SIGN_UP}
                </Typography>
              </Button>
            </div>
          </div>
        )}
        {successMessage && (
          <Typography
            color="white"
            level="medium_13_px"
            className={classes.success_message}
          >
            {successMessage}
          </Typography>
        )}
        {inputError && <ErrorMessage />}
        <div className={classes.white_sign_up_footer}>
          <SignUpBottomText town={town} />
        </div>
      </div>
      <GoogleReCaptcha action={ACTION} onVerify={handleReCaptchaVerify} />
    </>
  );
};

export default FormContainer;
