import React from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./PasswordResetInstructionsContent.style";
import Link from "../../../common/Link/Link";
import { CONTACT_EMAIL } from "../logInConstants";
import { replaceInHtmlText } from "../../../utils/helper";

const PasswordResetInstructionsContent = ({ email, error, customMessage }) => {
  const classes = useStyles();
  const shouldDisplayError = () => {
    return error;
  };

  return (
    <>
      {shouldDisplayError() ? (
        <div className={classes.content}>
          <Typography level="t3_text_3" color="gray">
            {labels.ERROR_MESSAGE}
          </Typography>
        </div>
      ) : (
        <div className={classes.content}>
          <Typography level="h4" color="tapintoOrange" bold>
            {labels.THANK_YOU}
          </Typography>

          {customMessage ? (
            replaceInHtmlText(
              customMessage,
              [
                {
                  toReplace: ":email",
                  toReplaceWith: `<a href=mailto:${email}>${email}</a>`,
                },
              ],
              classes.custom_message
            )
          ) : (
            <Typography level="t3_text_3" color="gray">
              {labels.PASSWORD_RESET_INSTRUCTIONS_1 +
                email +
                labels.PASSWORD_RESET_INSTRUCTIONS_2}
              <Link
                children={CONTACT_EMAIL}
                level="t3_text_3"
                url={"mailto:" + CONTACT_EMAIL}
                color="blueLink"
              />
            </Typography>
          )}
        </div>
      )}
    </>
  );
};

PasswordResetInstructionsContent.propTypes = {
  email: PropTypes.string,
  error: PropTypes.bool,
  customMessage: PropTypes.string,
};

export default PasswordResetInstructionsContent;
