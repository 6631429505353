const legalNoticesLabels = {
  HEADER_INDEX_NAME: "LEGAL NOTICES",
  SUBMIT_LEGAL_NOTICE: "Submit a Legal Notice",
  LOAD_MORE_LEGAL_NOTICES: "See more legal notices",
  BACK_LINK: "BACK TO LEGAL NOTICES",
  SOCIAL_NETWORK_SHARE: "Share this Legal Notice",
  DESCRIPTION: "DESCRIPTION",
  PUBLISH: "Publication date: ",
  REPUBLISHED: "Republication date: ",
  SECOND_REPUBLISHED: "2nd republication date: ",
  ADDRESS: "Address: ",
  PHONE: "Phone: ",
  EMAIL: "Email: ",
  PDF_DOWNLOAD: "Download PDF",
  PRINT_LEGAL_NOTICE: "PRINT",
  SUBMIT_REQUEST_LEGAL_NOTICE: "Request Permission to Submit Legal Notices",
  REQUEST_PAY_BY_PO: "I would like to request authorization to pay by PO.",
  ADD_MORE_ENTITIES: "Add more entities",
  CANCEL_REQUEST: "Cancel",
  DRAG_AND_DROP_LOGO_ENTITY_INSTRUCTIONS:
    "Drag and drop a file here, or click Browse files to select from your computer",
  BROWSE_FILES: "BROWSE FILES",
  LEGAL_NOTICE_REQUEST_THANK_YOU: "Thank You!",
  OK: "OK",
  REQUEST_ACCESS: "Request Access",
  CHOOSE_A_LEGAL_NOTICE_ENTITY: "Choose a legal notice entity *",
  ADD_MORE_DATES: "+ Add more dates",
  SCHEDULE_PUBLICATION_DATE: "Schedule publication *",
  SUBMIT_A_LEGAL_NOTICE: "Submit a Legal Notice",
  EDIT_LEGAL_NOTICE: "Edit Legal Notice",
  LEGAL_NOTICE_NAME: "Legal notice name",
  LOGO_ENTITY_LABEL: "Upload Entity Logo (optional)",
  THANK_YOU_MESSAGE_FOR_CREATING_LEGAL_NOTICE_ENTITY:
    "You will get an email notification within the next 72 hours letting you know if your request was approved or denied. If you don´t receive an email , please contact us at:",
  ENTITY_LOGO_ALT_TEXT: " - Legal Notice Entity Logo",
};

export default legalNoticesLabels;
