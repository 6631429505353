import React from "react";
import PropTypes from "prop-types";

const HamburgerButton = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="54"
      height="34"
      viewBox="0 0 54 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.7773 4.24609H2.22268C1.00021 4.24609 0 3.34609 0 2.24609C0 1.14609 1.00021 0.246094 2.22268 0.246094H51.7773C52.9998 0.246094 54 1.14609 54 2.24609C54 3.34609 52.9998 4.24609 51.7773 4.24609Z"
        fill={fillColor}
      />
      <path
        d="M26.7947 19.2461H2.20532C0.992395 19.2461 0 18.1211 0 16.7461C0 15.3711 0.992395 14.2461 2.20532 14.2461H26.7947C28.0076 14.2461 29 15.3711 29 16.7461C29 18.1211 28.0076 19.2461 26.7947 19.2461Z"
        fill={fillColor}
      />
      <path
        d="M51.7773 33.2461H2.22268C1.00021 33.2461 0 32.3461 0 31.2461C0 30.1461 1.00021 29.2461 2.22268 29.2461H51.7773C52.9998 29.2461 54 30.1461 54 31.2461C54 32.3461 52.9998 33.2461 51.7773 33.2461Z"
        fill={fillColor}
      />
    </svg>
  );
};

HamburgerButton.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

HamburgerButton.defaultProps = {
  fillColor: "white",
};

export default HamburgerButton;
