import { isValidEmail } from "../../utils/helper";

export const contactRealtorFormReducer = (state, action) => {
  const newState = { ...state };

  newState.name = action.name || state.name;
  newState.email = action.email || state.email;
  newState.phone = action.phone;
  newState.message = action.message || state.message;

  return newState;
};

export const contactRealtorFormInitialState = {
  message: null,
  name: null,
  phone: null,
  email: null,
};

export const sendToAFriendFormReducer = (state, action) => {
  const newState = { ...state };

  newState.email = action.email || state.email;
  newState.message = action.message || state.message;

  return newState;
};

export const sendToAFriendFormInitialState = {
  message: null,
  email: null,
};

export const subscribeToEnews = (state, action) => {
  const newState = { ...state };

  if (isValidEmail(action.email)) {
    newState.email = action.email;
  } else if (isValidEmail(state.email)) {
    newState.email = state.email;
  }

  newState.selectedTowns = action.selectedTowns || state.selectedTowns;
  newState.recaptcha_token = action.recaptcha_token || state.recaptcha_token;
  newState.comes_from = action.comes_from || state.comes_from;
  newState.subscribed_town_slugs =
    action.subscribed_town_slugs || state.subscribed_town_slugs;

  return newState;
};

export const subscribeToEnewsInitialState = {
  selectedTowns: [],
  subscribed_town_slugs: [],
  email: null,
  recaptcha_token: null,
  frequency: "daily",
  comes_from: "subscribe-box",
};

export const resetPasswordFormReducer = (state, action) => {
  const newState = { ...state };

  if (action?.password?.length >= 8 || state?.password?.length >= 8) {
    newState.password = action.password || state.password;
  } else {
    newState.password = null;
  }

  if (
    action?.password_confirmation === state?.password ||
    state?.password_confirmation === state?.password
  ) {
    newState.password_confirmation =
      action.password_confirmation || state.password_confirmation;
  } else {
    newState.password_confirmation = null;
  }

  return newState;
};

export const resetPasswordInitialState = {
  password: null,
  password_confirmation: null,
};

export const contactBusinessFormReducer = (state, action) => {
  const newState = { ...state };

  newState.name = action.name || state.name;
  newState.phone = action.phone || state.phone;
  newState.email = action.email || state.email;
  newState.message = action.message || state.message;

  return newState;
};

export const contactBusinessFormInitialState = {
  name: null,
  phone: null,
  message: null,
  email: null,
};

export const editCmsPhotoFormReducer = (state, action) => {
  const newState = { ...state };

  newState.caption = action.caption || state.caption;
  newState.credits = action.credits || state.credits;

  return newState;
};

export const editCmsPhotoInitialState = {
  caption: null,
  credits: null,
};
