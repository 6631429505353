import {Box as MuiBox} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

const Box = ({ children, className, ...rest }) => {
  return (
    <MuiBox className={className} {...rest}>{children}</MuiBox>
  )
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Box