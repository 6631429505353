import React from "react";
import PropTypes from "prop-types";
import { Tooltip as MaterialTooltip } from "@material-ui/core";

const Tooltip = ({
  title,
  arrow,
  placement,
  disableHoverListener,
  disableFocusListener,
  contents,
  open,
  onClose,
  PopperProps,
}) => {

  return (
    <MaterialTooltip
      title={title}
      arrow={arrow}
      placement={placement}
      disableHoverListener={disableHoverListener}
      disableFocusListener={disableFocusListener}
      enterTouchDelay={50}
      leaveTouchDelay={5000}
      PopperProps={PopperProps}
      open={open}
      onClose={onClose}
    >
      {contents}
    </MaterialTooltip>
  );
};

Tooltip.propTypes = {
  title: PropTypes.node,
  arrow: PropTypes.bool,
  placement: PropTypes.oneOf([
    "bottom-end",
    "bottom-start",
    "bottom",
    "left-end",
    "left-start",
    "left",
    "right-end",
    "right-start",
    "right",
    "top-end",
    "top-start",
    "top",
  ]),
  disableHoverListener: PropTypes.bool,
  disableFocusListener: PropTypes.bool,
  contents: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  PopperProps: PropTypes.objectOf({
    disablePortal: PropTypes.bool,
  }),
};

Tooltip.defaultProps = {
  disableFocusListener: false,
  disableHoverListener: false,
};

export default Tooltip;
