import React from "react";
import PropTypes from "prop-types";

const VisibilityIcon = ({ className, fillColor, contrastColor }) => {
  return (
    <svg
      className={className}
      width="149"
      height="106"
      viewBox="0 0 149 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6669_19082)">
        <path
          d="M0 52.998C0 57.2356 23.3096 105.996 74.5 105.996C125.69 105.996 149 57.2356 149 52.998H0Z"
          fill={fillColor}
        />
        <path
          d="M0 52.9984C0 48.7608 23.3096 0 74.5 0C125.69 0 149 48.7608 149 52.9984H0Z"
          fill={fillColor}
        />
        <path
          d="M74.5 88.4325C93.2786 88.4325 108.502 72.5677 108.502 52.9975C108.502 33.4273 93.2786 17.5625 74.5 17.5625C55.7214 17.5625 40.4983 33.4273 40.4983 52.9975C40.4983 72.5677 55.7214 88.4325 74.5 88.4325Z"
          fill={contrastColor}
        />
        <path
          d="M74.5 73.9423C85.5991 73.9423 94.5967 64.5655 94.5967 52.9985C94.5967 41.4316 85.5991 32.0547 74.5 32.0547C63.4009 32.0547 54.4033 41.4316 54.4033 52.9985C54.4033 64.5655 63.4009 73.9423 74.5 73.9423Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_6669_19082">
          <rect width="149" height="106" fill={contrastColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

VisibilityIcon.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
  contrastColor: PropTypes.string,
};

VisibilityIcon.defaultProps = {
  fillColor: "black",
  contrastColor: "black",
};

export default VisibilityIcon;
