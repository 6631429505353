import React, { useEffect } from "react";
import clsx from "clsx";
import { useTown, withTownContextProvider } from "../../context/TownContext";
import PropTypes from "prop-types";
import Town from "../../pages/Town/Town";
import Masterhead from "../../components/Masterhead/Masterhead";
import { useStyles } from "./TownLayout.style";
import Footer from "../../components/Footer/Footer";

const TownLayout = ({ town, masterhead }) => {
  const classes = useStyles();
  const { townMutation } = useTown();

  useEffect(() => {
    if (town) {
      townMutation(town);
    }
    return () => {};
  }, [town]);

  if (town) {
    return (
      <>
        <div className={clsx(classes.head_container, classes.no_printing)}>
          <Masterhead {...masterhead} />
        </div>
        <Town />
        <Footer town={town} />
      </>
    );
  }
  return <></>;
};

TownLayout.propTypes = {
  town: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default withTownContextProvider(TownLayout);
