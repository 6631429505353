import { alpha } from "@material-ui/core";
import { makeStyles } from "../../../Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      color: theme.palette.themeColor.primary,
      fontWeight: 700,
      textTransform: "uppercase",
      paddingLeft: theme.spacing(1),
      fontSize: `${theme.spacing(2.5)}px !important`,
    },
    vertical_layout: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      lineHeight: 1,
    },
    caption_and_credits_container: {
      marginTop: theme.spacing(1),
    },
    media_container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: alpha(
        theme.palette.miscellaneous.backgroundImageGray,
        0.3
      ),
    },
    media_container_with_fill_background: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: (props) =>
        `linear-gradient(to bottom, rgba(255,255,255, 0.7), rgba(255,255,255, 0.7)), url(${props?.fillBackgroundImage}) round`,
    },
    carousel_swipers: {
      "& [class*='swiper']": {
        height: "100% !important",
        maxWidth: "100% !important",
      },
      "& .swiper-container": {
        [theme.breakpoints.down(760)]: {
          margin: 0,
        },
      },
    },
    carousel_pagination: {
      "& .swiper-pagination-bullet": {
        width: `${theme.spacing(1)}px !important`,
        height: `${theme.spacing(1)}px !important`,
      },
      "& .swiper-pagination": {
        bottom: "-1% !important",
        height: "fit-content !important",
      },
    },
    carousel_image: {
      "& [class*='image_container']": {
        maxHeight: theme.spacing(25),
        alignItems: "center !important",
        backgroundColor: alpha(
          theme.palette.miscellaneous.backgroundImageGray,
          0.3
        ),
        [theme.breakpoints.down(1260)]: {
          maxHeight: theme.spacing(20),
        },
        [theme.breakpoints.down(960)]: {
          maxHeight: theme.spacing(30),
        },
        [theme.breakpoints.down(760)]: {
          display: "block",
        },
      },
      "& img": {
        maxHeight: theme.spacing(25),
        [theme.breakpoints.down(1260)]: {
          maxHeight: theme.spacing(20),
        },
        [theme.breakpoints.down(960)]: {
          maxHeight: theme.spacing(30),
        },
      },
    },
    carousel_container: {
      maxHeight: `${theme.spacing(25)}px`,
      [theme.breakpoints.down(1260)]: {
        maxHeight: theme.spacing(20),
      },
      [theme.breakpoints.down(960)]: {
        maxHeight: theme.spacing(30),
      },
    },
    opacity_on_focus: {
      "&:hover": {
        opacity: "0.8",
      },
    },
  };
});

export { useStyles };
