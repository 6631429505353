import React from "react";
import PropTypes from "prop-types";
import labels from "../../../config/labels";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./PasswordResetConfirmationContent.style";
import Button from "../../../common/Button/Button";
import Spinner from "../../../common/Spinner/Spinner";

const PasswordResetConfirmationContent = ({
  handleGoBackToEnterPassword,
  handleResetPassword,
  loadingPasswordResetRequest,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.content}>
        <Typography level="t3_text_3" color="gray">
          {labels.PASSWORD_RESET_POPUP_MESSAGE}
        </Typography>
        <div className={classes.buttons_container}>
          <Button
            level="simple_button"
            color="primary"
            className={classes.cancel_button}
            onClick={handleGoBackToEnterPassword}
            disabled={loadingPasswordResetRequest}
          >
            <Typography color="tapintoOrange" level="t3_text_3" bold>
              {labels.CANCEL}
            </Typography>
          </Button>
          <Button
            level="simple_button"
            color="primary"
            className={classes.continue_button}
            onClick={handleResetPassword}
            disabled={loadingPasswordResetRequest}
          >
            <Typography color="white" level="t3_text_3" bold>
              {labels.CONTINUE}
            </Typography>
          </Button>
        </div>
      </div>
      {loadingPasswordResetRequest && (
        <Spinner
          withLoadingOverlay={true}
          loadingOverlayClassName={classes.login_loading_overlay}
        />
      )}
    </>
  );
};

PasswordResetConfirmationContent.propTypes = {
  handleGoBackToEnterPassword: PropTypes.func,
  handleResetPassword: PropTypes.func,
  loadingPasswordResetRequest: PropTypes.bool,
};

export default PasswordResetConfirmationContent;
