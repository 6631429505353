import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    information_container: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px 0px ${theme.spacing(
        2
      )}px`,
    },
    information: {
      width: "100%",
      display: "block",
      fontWeight: 600,
      paddingBottom: theme.spacing(1),
      fontSize: theme.spacing(2.25),
      textAlign: "center",
      [theme.breakpoints.up(768)]: {
        textAlign: (isFourCardsObituary) =>
          isFourCardsObituary ? "start" : "center",
      },
      "&:hover": {
        textDecoration: "underline",
      },
    },
    time: {
      fontSize: theme.spacing(1.75),
      paddingBottom: theme.spacing(2),
    },
    media_container: {
      [theme.breakpoints.up(768)]: {
        height: (isFourCardsObituary) =>
          isFourCardsObituary ? `${theme.spacing(12.5)}px` : "",
        width: (isFourCardsObituary) =>
          isFourCardsObituary ? `${theme.spacing(15.25)}px` : "",
      },
    },
    image: {
      maxHeight: theme.spacing(25),
      [theme.breakpoints.up(768)]: {
        maxHeight: (isFourCardsObituary) =>
          isFourCardsObituary
            ? `${theme.spacing(15)}px`
            : `${theme.spacing(25)}px`,
        height: (isFourCardsObituary) => (isFourCardsObituary ? "100%" : ""),
      },
    },
    obituary_card: {
      minWidth: "16vw",
      marginBottom: `${theme.spacing(2)}px !important`,
      [theme.breakpoints.down(1090)]: {
        minWidth: "25vw",
      },
      [theme.breakpoints.down(960)]: {
        minWidth: "100%",
        maxWidth: theme.spacing(30),
      },
    },
    obituary_card_length_4: {
      width: "45%",
      [theme.breakpoints.up(768)]: {
        display: "flex",
        justifyContent: "space-between",
      },
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    obituary_card_length_3: {
      width: "32%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    obituary_card_length_2: {
      width: "48%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
  };
});

export { useStyles };
