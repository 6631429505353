import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    body_container: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: `${theme.spacing(8.7)}px ${theme.spacing(9)}px`,
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      },
      [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
        flexDirection: "column-reverse",
        rowGap: `${theme.spacing(2)}`,
      },
    },
    left_container: {
      width: "50%",
      display: "flex",
      rowGap: theme.spacing(4),
      flexDirection: "column",
      [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
        width: "100%",
        rowGap: theme.spacing(3),
      },
    },
    right_container: {
      width: "45%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
        width: "100%",
      },
    },
    title_container: {
      width: "100%",
    },
    title: {
      textUnderlineOffset: `${theme.spacing(3)}px`,
      textDecorationThickness: "6px",
      textDecorationColor: theme.palette.themeColor.primary,
      fontSize: "48px !important",
      fontWeight: "500 !important",
      lineHeight: "59px",
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        fontSize: "32px !important",
      },
    },
    message: {
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "36px",
      fontFamily: "Montserrat, sans-serif",
      "& a": {
        color: theme.palette.themeColor.primary,
        textDecoration: "underline",
      },
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        fontSize: "18px",
      },
    },
    email: {
      height: "52px !important",
      "& .MuiFormControl-root": {
        height: "52px !important",
      },
      "& .MuiInputBase-root": {
        height: "52px !important",
        background: "rgba(217, 217, 217, 0.2)",
        "& input": {
          height: "52px !important",
        },
      },
    },
    signup_container: {
      "& button": {
        width: "100%",
        color: theme.palette.dark.black,
        backgroundColor: theme.palette.themeColor.primary,
        borderRadius: 0,
      },
    },
    signup_with_extra_margin: {
      marginTop: theme.spacing(7),
    },
    signup_text: {
      letterSpacing: "0.1em",
    },
    red_input_border: {
      marginTop: theme.spacing(2),
      width: "100%",
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "red",
        borderWidth: "2px",
      },
    },
    error_message: {
      fontSize: "1.2rem",
      color: "red",
    },
    success_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.themeColor.primary,
      height: 100,
      width: "100%",
      border: `3px solid ${theme.palette.themeColor.primary}`,
      padding: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
      textAlign: "center",
      "& .row .paragraph_2": {
        marginBottom: "unset",
      },
      [theme.breakpoints.down(601)]: {
        height: 150,
      },
      [theme.breakpoints.down(401)]: {
        height: 200,
      },
    },
    hidden: {
      display: "none",
    },
    full_width: {
      width: "100%",
    },
    button_label: {
      color: theme.palette.themeColor.primary_contrast,
      fontFamily: "Montserrat, sans-serif",
      fontSize: "20px",
    },
  };
});

export { useStyles };
