import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { cmsStyles, defaultStyles, useStyles } from "./Uploader.style";
import Button from "../Button/Button";
import clsx from "clsx";
import PropTypes, { oneOf, oneOfType } from "prop-types";
import labels from "../../config/labels";
import Spinner from "../Spinner/Spinner";
import Alert from "../Alert/Alert";

const DEFAULT_OPTIONS = {
  programmatic: true,
  disabled: false,
  multipleFiles: false,
  facebookPreview: true,
  allowDeletePhoto: true,
  maxFiles: 30,
  labels: {
    submitButton: labels.SAVE_PHOTO,
    choosePhotosButton: labels.CHOOSE_PHOTO,
    instructions: labels.DRAG_AND_DROP_FILES_INSTRUCTIONS,
  },
  placeholders: {
    previews: labels.PREVIEW_PHOTO_PLACEHOLDER,
  },
  alerts: {
    variant: "filled",
  },
  preExistingThumbsUrls: [],
  alerts: true,
  snackbars: false,
  bottomActions: true,
  customThumbnails: false,
};

const Uploader = ({
  customClasses,
  customStyles,
  options,
  handleSubmit,
  handleDeletePhoto,
  loading,
  statusMessage,
  handleError,
  files,
  addFiles,
  showPreviewPlaceholder = true,
  labelTitle,
  acceptedFiles,
  renderCustomThumbnail,
  variant,
}) => {
  const classes = useStyles();
  const allOptions = { ...DEFAULT_OPTIONS, ...options };
  const [thumbnails, setThumbnails] = useState(
    allOptions?.preExistingThumbsUrls?.length > 0
      ? allOptions.preExistingThumbsUrls
      : []
  );

  useEffect(() => {
    setThumbnails(allOptions?.preExistingThumbsUrls);
  }, [allOptions?.preExistingThumbsUrls]);

  const variantClasses = () => {
    const defaultClasses = {
      thumb: classes.thumb,
      thumb_inner: classes.thumb_inner,
      facebook_thumb: classes.facebook_thumb,
      img: classes.img,
      thumbs_container: classes.thumbs_container,
      form: classes.form,
      button: classes.button,
      button_label: classes.button_label,
      choose_photos_button: classes.choose_photos_button,
      delete_button: classes.delete_button,
      disabled_button: classes.disabled_button,
      submit_button: classes.submit_button,
      actions_container: classes.actions_container,
      alert_container: classes.alert_container,
      instructions: classes.instructions,
    };
    switch (variant) {
      case "cms":
        return {
          ...defaultClasses,
          thumbs_container: classes.cms_thumbs_container,
          button: classes.cms_button,
          button_label: classes.cms_button_label,
          instructions: classes.cms_instructions,
        };
      default:
        return defaultClasses;
    }
  };

  const variantStyles = () => {
    switch (variant) {
      case "cms":
        return { ...cmsStyles, ...customStyles };
      default:
        return { ...defaultStyles, ...customStyles };
    }
  };

  const validateSingleFile = (files) => {
    if (!allOptions?.multipleFiles && files.length > 1) {
      handleError(labels.SINGLE_PHOTO_VALIDATION_ERROR);
      return false;
    }
    return true;
  };

  function thumbnailUrlsForDroppedFiles(files) {
    return files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
  }

  const thumbnailsForPreviews = (files) => {
    return allOptions.multipleFiles
      ? thumbnails.concat(thumbnailUrlsForDroppedFiles(files))
      : thumbnailUrlsForDroppedFiles(files);
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    noKeyboard: allOptions?.programmatic,
    noClick: allOptions?.programmatic,
    disabled: allOptions?.disabled,
    useFsAccessApi: false,
    onError: handleError,
    maxFiles: allOptions?.multipleFiles ? allOptions.maxFiles : 1,
    accept: acceptedFiles,
    onDrop: (files, rejections) => {
      if (validateSingleFile(files)) {
        setThumbnails(thumbnailsForPreviews(files));
        addFiles(files);
      }
      // TODO: Validate file types
      rejections?.map((rejection) => {
        rejection?.errors?.map((error) => {
          if (error.code === "too-many-files") {
            handleError(labels.SINGLE_PHOTO_VALIDATION_ERROR);
            addFiles([]);
          }
        });
      });
    },
  });

  const style = useMemo(
    () => ({
      ...variantStyles().base_container,
      ...(isFocused ? variantStyles().focused_container : {}),
      ...(isDragAccept ? variantStyles().accept_container : {}),
      ...(isDragReject ? variantStyles().reject_container : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const thumbs = thumbnails.map((file, index) =>
    allOptions?.customThumbnails ? (
      <Fragment key={index}>{renderCustomThumbnail(file)}</Fragment>
    ) : (
      <div
        className={clsx(variantClasses().thumb, customClasses?.thumb)}
        key={index}
      >
        <div
          className={clsx(
            variantClasses().thumb_inner,
            customClasses?.thumb_inner
          )}
        >
          {allOptions?.facebookPreview ? (
            <div className={variantClasses().facebook_thumb}>
              <img
                src={file?.preview}
                className={clsx(variantClasses().img, customClasses?.img)}
              />
            </div>
          ) : (
            <img
              src={file?.preview}
              className={clsx(variantClasses().img, customClasses?.img)}
            />
          )}
        </div>
      </div>
    )
  );

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      thumbnails.forEach((file) => URL.revokeObjectURL(file?.preview));
    },
    [thumbnails]
  );

  return (
    <form
      onSubmit={(e) => handleSubmit(e, files)}
      className={clsx(variantClasses().form, customClasses?.form)}
    >
      {allOptions?.alerts && (
        <>
          {statusMessage?.error && (
            <div className={variantClasses().alert_container}>
              <Alert
                variant={allOptions?.alerts?.variant}
                severity="error"
                content={statusMessage?.error}
              />
            </div>
          )}

          {statusMessage?.success && (
            <div className={variantClasses().alert_container}>
              <Alert
                variant={allOptions?.alerts?.variant}
                severity="success"
                content={statusMessage?.success}
              />
            </div>
          )}
        </>
      )}

      {allOptions?.snackbars && (
        <>
          {statusMessage?.error && (
            <Snackbar
              message={statusMessage?.error}
              variant={allOptions?.alerts?.variant}
            />
          )}
        </>
      )}

      {loading && <Spinner withLoadingOverlay={true} />}

      {labelTitle}

      <div {...getRootProps({ style })}>
        <input
          {...getInputProps({
            onChange: (e) => {
              addFiles(e.target.files);
            },
          })}
        />
        <p className={variantClasses().instructions}>
          {allOptions?.labels?.instructions}
        </p>
        {allOptions?.programmatic && (
          <Button
            onClick={open}
            level="simple_button"
            color="primary"
            children={allOptions?.labels?.choosePhotosButton}
            classes={{
              root: clsx(
                variantClasses().button,
                variantClasses().button_label,
                variantClasses().choose_photos_button,
                customClasses?.choose_photos_button
              ),
            }}
          />
        )}
      </div>

      <div
        className={clsx(
          variantClasses().thumbs_container,
          customClasses?.thumbs_container
        )}
      >
        {thumbs.length > 0 ? (
          thumbs
        ) : (
          <>
            {variant !== "cms" && showPreviewPlaceholder && (
              <p>{allOptions?.placeholders?.previews}</p>
            )}
          </>
        )}
      </div>

      {allOptions?.bottomActions && (
        <div className={variantClasses().actions_container}>
          {allOptions.allowDeletePhoto && (
            <Button
              level="simple_button"
              color="primary"
              children={labels.REMOVE_PHOTO}
              disabled={thumbnails.length === 0}
              onClick={() => {
                handleDeletePhoto(), setThumbnails([]);
              }}
              classes={{
                root: clsx(
                  variantClasses().button,
                  variantClasses().button_label,
                  variantClasses().delete_button,
                  customClasses?.submit_button
                ),
                disabled: variantClasses().disabled_button,
              }}
            />
          )}

          <Button
            level="simple_button"
            color="primary"
            children={allOptions?.labels?.submitButton}
            type="submit"
            disabled={thumbnails.length === 0}
            classes={{
              root: clsx(
                variantClasses().button,
                variantClasses().button_label,
                variantClasses().submit_button,
                customClasses?.submit_button
              ),
              disabled: variantClasses().disabled_button,
            }}
          />
        </div>
      )}
    </form>
  );
};

Uploader.propTypes = {
  labelTitle: PropTypes.node,
  customClasses: PropTypes.shape({
    thumb: PropTypes.string,
    thumb_inner: PropTypes.string,
    img: PropTypes.string,
    choose_photos_button: PropTypes.string,
    submit_button: PropTypes.string,
    form: PropTypes.string,
    thumbs_container: PropTypes.string,
  }),
  customStyles: PropTypes.shape({
    base_container: PropTypes.object,
    focused_container: PropTypes.object,
    accept_container: PropTypes.object,
    reject_container: PropTypes.object,
  }),
  options: PropTypes.shape({
    programmatic: PropTypes.bool,
    disabled: PropTypes.bool,
    multipleFiles: PropTypes.bool,
    facebookPreview: PropTypes.bool,
    allowDeletePhoto: PropTypes.bool,
    preExistingThumbsUrls: PropTypes.arrayOf(
      PropTypes.shape({ thumbnail: PropTypes.string })
    ),
    labels: PropTypes.shape({
      submitButton: PropTypes.string,
      choosePhotosButton: PropTypes.string,
      instructions: PropTypes.string,
    }),
    placeholders: PropTypes.shape({
      previews: PropTypes.string,
    }),
    alerts: PropTypes.shape({
      variant: PropTypes.string,
    }),
  }),
  handleSubmit: PropTypes.func,
  handleDeletePhoto: PropTypes.func,
  loading: PropTypes.bool,
  statusMessage: PropTypes.shape({
    success: PropTypes.string,
    error: PropTypes.string,
  }),
  variant: oneOf(["default", "cms"]),
};

Uploader.defaultProps = {
  loading: false,
  customStyles: {},
  options: {},
  acceptedFiles: "image/*",
  variant: "default",
};

export default Uploader;
