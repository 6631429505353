import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    menuSize,
    palette,
    spacing,
    breakpoints: { down: below, values },
  } = theme;
  return {
    submenu_popover_paper: {
      backgroundColor: `${palette.dark.black} !important`,
      color: `${palette.miscellaneous.white} !important`,
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      left: "0px !important",
      zIndex: "94039 !important",
      "& .MuiList-root": {
        display: "flex",
      },
    },
    popover_root: {
      left: `${menuSize.default} !important`,
      zIndex: "94037 !important",
      "& div[aria-hidden='true']": {
        left: `${menuSize.default} !important`,
        zIndex: "94037 !important",
      },
      [below(values.xs)]: {
        left: `${menuSize.mobile} !important`,
        "& div[aria-hidden='true']": {
          left: `${menuSize.mobile} !important`,
        },
      },
    },
  };
});

export { useStyles };
