import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    logo_container: {
      textAlign: "center",
      fontSize: "50px",
      display: "flex",
    },
    tap_town: {
      fontFamily: "Verdana, Geneva, sans-serif",
      color: theme.palette.themeColor.secondary,
      fontWeight: 700,
    },
    into: {
      fontFamily: "Verdana, Geneva, sans-serif",
      color: theme.palette.themeColor.primary,
      fontWeight: 400,
    },
  };
});

export { useStyles };
