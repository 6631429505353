import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Card from "../../../../../../common/Card/Card";
import VerticalLayout from "../../../../../../common/Card/Layouts/Vertical/VerticalLayout";
import { useStyles } from "./TwoCards.style";
import ContentInfo from "../../../../Shared/ContentInfo/ContentInfo";
import SpecialCaseCard from "../../../../Shared/SpecialCaseCard/SpecialCaseCard";
import { specialCaseCard } from "../../../../../../utils/helpers/homepageHelper";
import { VIDEO_PLAYER_OPTIONS } from "../../../../../../utils/constants/homepageConstants";

const TwoCards = ({ contents, blockClass }) => {
  const classes = useStyles();

  return contents.map((content, index) => {
    if (specialCaseCard(blockClass)) {
      return (
        <Fragment key={"special-case-card-" + index}>
          <SpecialCaseCard content={content} blockClass={blockClass} options={{withShadow:false}} />
        </Fragment>
      );
    }

    return (
      <div className={classes.card_container} key={"two-cards-" + index}>
        <Card
          classes={{
            root: classes.content_card,
          }}
          withShadow={false}
          children={
            <VerticalLayout
              media={content.media_content[0]}
              information={
                <ContentInfo
                  content={content}
                  blockClass={blockClass}
                  asContentHeader={true}
                />
              }
              options={{
                caption: { visible: false },
                credits: { visible: false },
                media: {
                  clickable: true,
                  townContentUrl: content.url_content,
                  opacityOnFocus: true,
                },
                videoPlayer: VIDEO_PLAYER_OPTIONS,
              }}
              customClasses={{
                image: classes.image,
                media_container: classes.media_container,
                root: classes.root,
              }}
            />
          }
        />
      </div>
    );
  });
};

TwoCards.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.object).isRequired,
  blockClass: PropTypes.string.isRequired,
};

export default TwoCards;
