import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    image: {
      maxHeight: theme.spacing(21.25),
      [theme.breakpoints.down(768)]: {
        maxHeight: theme.spacing(31.25),
      },
    },
    media_container: {
      height: theme.spacing(21.25),
      maxHeight: theme.spacing(21.25),
      [theme.breakpoints.down(768)]: {
        height: theme.spacing(31.25),
        maxHeight: theme.spacing(31.25),
      },
    },
    event_info: {
      padding: 0,
    },
    event_milestone_directory_obituary_card: {
      flexGrow: 1,
      display: "flex",
      flexBasis: "min-content",
      flexDirection: "column",
      "& [class*='actions_container']": {
        justifyContent: "center",
      },
      "& [class*='content_header']": {
        "& span": { fontSize: `${theme.spacing(2.25)}` },
      },
    },
  };
});

export { useStyles };
