import { makeStyles } from "../Theme/Theme";

const useStyles = makeStyles((theme) => ({
  button_icon: {
    borderRadius: "0px !important",
    padding: "0px !important",
    marginLeft: `${theme.spacing(1)}px !important`,
    "& .MuiIconButton-label": {
      flexDirection: "column",
      rowGap: theme.spacing(0.7),
    },
  },
  hamburger_menu_icon: {
    width: theme.spacing(4),
    height: theme.spacing(2),
  },
}));

export { useStyles };
