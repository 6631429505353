import withConfig from "../utils/withConfig";
import restClient from "../utils/restClient";

const createEntity = async (entity, requestedByPo, userId) => {
  const response = await restClient.post(
    withConfig("CREATE_LEGAL_NOTICE_ENTITY"),
    { entity, user_id: userId, requested_pay_by_po: requestedByPo }
  );
  return response.data;
};

const uploadEntityLogo = async (entityId, file) => {
  const formData = new FormData();
  formData.append("logo_file", file);

  const response = await restClient.post(
    withConfig("UPLOAD_LEGAL_NOTICE_ENTITY_LOGO").replace(":id", entityId),
    formData
  );
  return response.data;
};

export const createLegalNoticieEntitiesFn = async ({ entities, requestedByPo, userId }) => {
  const createdEntities = await Promise.all(
    entities.map(async (entity) => {
      const entityData = await createEntity(entity, requestedByPo, userId);
      await uploadEntityLogo(entityData.entity.id, entity.logo_file);
      return entityData;
    })
  );

  return createdEntities;
};
