import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;
  return {
    mobile_search: {
      width: "100%",
      padding: spacing(2),
      transform: "translateY(0%) !important",
      opacity: "1 !important",
      display: "flex",
      gap: spacing(1),
      zIndex: "1 !important",
      height: "auto !important",
    },
    button: {
      borderRadius: "4px !important",
      padding: `0px !important`,
      backgroundColor: `${palette.themeColor.secondary} !important`,
      flex: 1,
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "16px",
        fontFamily: "Montserrat",
        color: palette.miscellaneous.white,
        fontWeight: 500,
      },
    },
    hidden: {
      transform: "translateY(-100%)",
      opacity: 0,
      transition: "transform 0.3s ease",
      zIndex: -1,
      height: 0,
    },
    textfield_search_icon: {
      width: spacing(1.5),
      height: spacing(1.5),
    },
  };
});

export { useStyles };
