import React, { useState } from "react";
import PropTypes from "prop-types";

const Home = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="64"
      height="67"
      viewBox="0 0 64 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64 33.741L32 0L19.2 13.4964H11.4286V21.6906L0 33.741H8.22857V67H21.4857V52.5396H42.5143V67H55.7714V33.741H64Z"
        fill={fillColor}
      />
      <path
        d="M64 33.741L32 0L19.2 13.4964H11.4286V21.6906L0 33.741H8.22857V67H21.4857V52.5396H42.5143V67H55.7714V33.741H64Z"
        fill={fillColor}
      />
    </svg>
  );
};

Home.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

Home.defaultProps = {
  fillColor: "white",
};

export default Home;
