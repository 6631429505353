import React from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@material-ui/core";

const LogoutFilled = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 17L8.41 15.59L5.83 13L16 13L16 11L5.83 11L8.41 8.42L7 7L2 12L7 17ZM20 19L12 19L12 21L20 21C21.1 21 22 20.1 22 19L22 5C22 3.9 21.1 3 20 3L12 3L12 5L20 5L20 19Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default LogoutFilled;
