import { alpha } from "@material-ui/core";
import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  weather_container: {
    "& .MuiButton-label": {
      gap: "10px",
      alignItems: "center",
    },
  },
  temperature_container: {
    width: "42px",
  },
  flex_container_column: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
  },
  weather_title_container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
    width: "280px",
    paddingBottom: "10px",
    backgroundColor: theme.palette.themeColor.primary,
    [theme.breakpoints.down(760)]: {
      width: "100%",
    },
  },
  flex_container_column_center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
  },
  flex_container_column_center_no_gap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  flex_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  tooltip_title_header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "10px",
    paddingTop: "10px",
  },
  weather_system_button: {
    color: "white !important",
    padding: "0 !important",
    fontSize: "24px !important",
  },
  weather_system_divider: {
    height: "25px",
    width: "1px",
    backgroundColor: "white",
    marginLeft: "8px",
    opacity: 0.5,
  },
  weather_system_opposite: {
    opacity: 0.5,
  },
  weather_title_content: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",
  },
  weather_title_content_footer: {
    backgroundColor: alpha(theme.palette.themeColor.primary, 0.5),
    display: "flex",
    justifyContent: "center",
    padding: "8px",
  },
  pop_up: {
    padding: "0px !important",
    "& .MuiPaper-root": {
      backgroundColor: "transparent !important",
    },
    "& .MuiPaper-rounded": {
      borderRadius: "0px !important",
      boxShadow: "none !important",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "100vw !important",
    },
    "& .MuiDialog-paper": {
      margin: "0px !important",
    },
  },
  pop_up_title: {
    backgroundColor: "transparent !important",
    "& svg": {
      right: "0px",
    },
  },
  pop_up_content: {
    backgroundColor: "white !important",
    padding: "0px !important",
    width: "95vw",
  },
  button_icon_label: {
    gap: "10px",
  },
}));

export default useStyles;
