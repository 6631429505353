import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./Footer.style";
import {
  useFooterManagement,
  withFooterContextProvider,
} from "../../context/FooterContext";
import TopSection from "./TopSection/TopSection";
import MiddleSection from "./MiddleSection/MiddleSection";
import BottomSection from "./BottomSection/BottomSection";
import { useTown, withTownContextProvider } from "../../context/TownContext";

const Footer = ({ town, options }) => {
  const { mastheadImage, setTown: setTownForTownContext } = useTown();
  const classes = useStyles(town);
  const { setTown: setTownForFooterContext, footerLayout, setOverallFooter, footer } =
    useFooterManagement();

  useEffect(async () => {
    if (town) {
      setTownForFooterContext(town);
      setTownForTownContext(town);
    } else {
      await setOverallFooter();
    }
  }, [town]);

  if (footerLayout === undefined) {
    return <>Loading footer...</>;
  }

  return (
    <div
      className={clsx(
        options.noMarginTop ? classes.no_margin_top : "",
        classes.container,
        classes.no_printing
      )}
    >
      <TopSection mastheadImage={mastheadImage} />
      <MiddleSection mastheadImage={mastheadImage} />
      <BottomSection
        withLogo={footer?.with_footer_logo}
        customCopyright={footer?.custom_copyright}
        logoImage={mastheadImage}
        isTownLicensed={town?.under_license_contract}
      />
    </div>
  );
};

Footer.propTypes = {
  town: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    twitter_url: PropTypes.string,
    facebook_url: PropTypes.string,
    instagram_url: PropTypes.string,
  }),
  options: PropTypes.shape({
    noMarginTop: PropTypes.bool,
  }),
};

Footer.defaultProps = {
  options: {
    noMarginTop: false,
  },
};

export default memo(
  withTownContextProvider(withFooterContextProvider(Footer))
);
