import { makeStyles } from "../Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    input: {
      width: "100%",
      backgroundColor: theme.palette.miscellaneous.white,
      padding: 0,
      "& .MuiInputBase-root": {
        height: theme.spacing(4),
        "& .MuiOutlinedInput-input": {
          paddingTop: "0",
          paddingBottom: "0",
          height: "100%",
        },
      },
    },
  };
});

export { useStyles };
