import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { values, up: above, down: below },
  } = theme;
  return {
    custom_button: {
      width: "100% !important",
      height: "fit-content !important",
      margin: "0px !important",
      textTransform: "uppercase !important",
      fontWeight: "500 !important",
      fontSize: "12px !important",
      height: "fit-content !important",
      padding: `${spacing(1)}px !important`,
      [below(values.lg)]: {
        fontSize: "10px !important",
        lineHeight: "12px !important",
      },
    },
    custom_buttons_container: {
      width: "100% !important",
      margin: "0px !important",
      padding: `${spacing(1)}px !important`,
      [above(values.lg)]: {
        padding: "0px !important",
      },
      [below(1160)]: {
        padding: "0px !important",
      },
    },
    grid_item: {
      alignItems: "center",
      display: "flex",
    },
  };
});

export { useStyles };
