import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      backgroundColor: theme.palette.miscellaneous.lightBlack,
      [theme.breakpoints.down(900)]: {
        backgroundColor: theme.palette.grayVariation.grayLightFooter,
      },
    },
    subscription_container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down(1150)]: {
        flexDirection: "column",
        paddingInline: "19%",
      },
      [theme.breakpoints.down(960)]: {
        flexDirection: "column",
        paddingInline: "16%",
        alignItems: "flex-start",
      },
    },
    logo_license_container: {
      display: "flex",
      justifyContent: "center",
    },
    logo_license: {
      maxWidth: theme.spacing(36.5),
    },
    logo_container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  };
});

export { useStyles };
