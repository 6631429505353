import React, { useState } from "react";
import PropTypes from "prop-types";

const Milestone = ({
  className,
  onHoverColor,
  offHoverColor,
  inactiveColor,
  disabled,
  fillColor,
}) => {
  const [iconColor, setIconColor] = useState(
    fillColor ? fillColor : !disabled ? offHoverColor : inactiveColor
  );
  return (
    <svg
      className={className}
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={
        !disabled
          ? () => setIconColor(onHoverColor)
          : () => setIconColor(inactiveColor)
      }
      onMouseLeave={
        !disabled
          ? () => setIconColor(offHoverColor)
          : () => setIconColor(inactiveColor)
      }
    >
      <circle cx="37.5" cy="37.5" r="32" stroke={iconColor} strokeWidth="3" />
      <circle cx="37.5" cy="37.5" r="36" stroke={iconColor} strokeWidth="3" />
      <path
        d="M21.8948 31.9696C21.8948 31.1411 22.5663 30.4696 23.3948 30.4696H51.9722C52.8006 30.4696 53.4722 31.1411 53.4722 31.9696V46.3243C53.4722 46.83 53.2233 47.266 52.8155 47.4775C50.866 48.4883 45.7104 50.6828 37.6835 50.6828C29.6565 50.6828 24.501 48.4883 22.5515 47.4775C22.1437 47.266 21.8948 46.83 21.8948 46.3243V31.9696Z"
        fill={iconColor}
      />
      <path
        d="M21.8948 31.9696C21.8948 31.1411 22.5663 30.4696 23.3948 30.4696H51.9722C52.8006 30.4696 53.4722 31.1411 53.4722 31.9696V46.3243C53.4722 46.83 53.2233 47.266 52.8155 47.4775C50.866 48.4883 45.7104 50.6828 37.6835 50.6828C29.6565 50.6828 24.501 48.4883 22.5515 47.4775C22.1437 47.266 21.8948 46.83 21.8948 46.3243V31.9696Z"
        stroke="white"
      />
      <path
        d="M21.8948 31.9696C21.8948 31.1411 22.5663 30.4696 23.3948 30.4696H51.9722C52.8006 30.4696 53.4722 31.1411 53.4722 31.9696V46.3243C53.4722 46.83 53.2233 47.266 52.8155 47.4775C50.866 48.4883 45.7104 50.6828 37.6835 50.6828C29.6565 50.6828 24.501 48.4883 22.5515 47.4775C22.1437 47.266 21.8948 46.83 21.8948 46.3243V31.9696Z"
        stroke="url(#paint0_linear_2769:1083)"
      />
      <path
        d="M11.9073 34.3956C10.6403 33.8965 10.6403 32.1035 11.9073 31.6044L36.4502 21.9367C36.8035 21.7975 37.1964 21.7975 37.5497 21.9367L62.0927 31.6044C63.3597 32.1035 63.3597 33.8965 62.0927 34.3956L37.5497 44.0633C37.1965 44.2025 36.8035 44.2025 36.4502 44.0633L11.9073 34.3956Z"
        fill={iconColor}
      />
      <path
        d="M11.9073 34.3956C10.6403 33.8965 10.6403 32.1035 11.9073 31.6044L36.4502 21.9367C36.8035 21.7975 37.1964 21.7975 37.5497 21.9367L62.0927 31.6044C63.3597 32.1035 63.3597 33.8965 62.0927 34.3956L37.5497 44.0633C37.1965 44.2025 36.8035 44.2025 36.4502 44.0633L11.9073 34.3956Z"
        stroke="white"
      />
      <path
        d="M11.9073 34.3956C10.6403 33.8965 10.6403 32.1035 11.9073 31.6044L36.4502 21.9367C36.8035 21.7975 37.1964 21.7975 37.5497 21.9367L62.0927 31.6044C63.3597 32.1035 63.3597 33.8965 62.0927 34.3956L37.5497 44.0633C37.1965 44.2025 36.8035 44.2025 36.4502 44.0633L11.9073 34.3956Z"
        stroke="url(#paint1_linear_2769:1083)"
      />
      <path d="M57 37L58 36.5V47H57V37Z" fill={iconColor} />
      <circle cx="57.5" cy="47.5" r="1.5" fill={iconColor} />
      <defs>
        <linearGradient
          id="paint0_linear_2769:1083"
          x1="37.6835"
          y1="29.9696"
          x2="37.6835"
          y2="51.1828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2769:1083"
          x1="22"
          y1="18"
          x2="52"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

Milestone.propTypes = {
  className: PropTypes.string,
  onHoverColor: PropTypes.string,
  offHoverColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  disabled: PropTypes.bool,
};

Milestone.defaultProps = {
  disabled: false,
  onHoverColor: "#f98c10",
  offHoverColor: "#7fc035",
  inactiveColor: "#e3e3e3",
};

export default Milestone;
