import { makeStyles } from "../Theme/Theme";

const useStyles = makeStyles((theme) => ({
  horizontal_divider: {
    border: "1px solid white",
    borderRadius: "8px",
    margin: "0px",
  },
}));

export { useStyles };
