import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  spinner: (spinnerColor) => ({
    position: "absolute",
    top: "40%",
    left: "45%",
    "& svg": {
      color: spinnerColor,
    },
  }),
  container: {
    width: "100%",
    height: "100%",
  },
  loading_overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 100,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.6)",
  },
  label_container: {
    top: "52%",
    position: "relative",
    width: "fit-content",
    margin: "auto",
    padding: "0px 16px",
    textAlign: "center",
  },
  fade_in_label: {
    transition: "opacity 1s ease",
  },
  fade_out_label: {
    opacity: 0,
    transition: "opacity 1s ease",
  },
});

export { useStyles };
