import React from "react";
import clsx from "clsx";
import Grid from "../../../../../common/Grid/Grid";
import Typography from "../../../../../common/Typography/Typography";
import labels from "../../../../../config/labels";
import { useStyles } from "./Header.style";
import { string } from "prop-types";

const Header = ({ variant, classes }) => {
  const defaultClasses = useStyles();
  const layoutClasses = {
    header_container: {
      root: defaultClasses[`header_container_${variant}`],
      ...classes?.header_container,
    },
    header: clsx(defaultClasses[`header_${variant}`], classes?.header),
  };

  return (
    ["layout_2", "layout_5"].includes(variant) && (
      <Grid item xs={12} classes={layoutClasses?.header_container}>
        <Typography level="paragraph_1" className={layoutClasses?.header}>
          {variant === "layout_2" ? labels.LATEST_NEWS : labels.TOP_LOCAL_NEWS}
        </Typography>
      </Grid>
    )
  );
};

Header.propTypes = {
  variant: string,
};

export default Header;
