import { useState, useEffect } from "react";
import { screenWidthIsLowerThan } from "./useMediaQuery";
import values from "../common/Theme/themes/breakpoints/values";

const MASTHEAD_HEIGHT = {
  mobile: 68,
  desktop: 176,
  desktopOverall: 176,
}

const getHeight = (isMobile, isOverall) => {
  if (isMobile) {
    return MASTHEAD_HEIGHT.mobile;
  } else {
    return isOverall ? MASTHEAD_HEIGHT.desktopOverall : MASTHEAD_HEIGHT.desktop;
  }
};

export const useMasthead = () => {
  const [sticky, setSticky] = useState(false);
  const [isOverall, setIsOverall] = useState(false);
  const isMobile = screenWidthIsLowerThan(values.sm);
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);

  const trackScroll = () => {
    if (typeof window === "undefined") {
      return;
    } else {
      const height = getHeight(isMobile, isOverall);
      setSticky(window.scrollY >= height);
    }
  };

  const handleOpenMenu = () => {
    setOpenHamburgerMenu(!openHamburgerMenu);
  };

  useEffect(() => {
    document.addEventListener("scroll", trackScroll);

    return () => {
      document.removeEventListener("scroll", trackScroll);
    };
  }, [isOverall, isMobile]);

  const stickyStyle = {
    transform: `translateY(${
      sticky
        ? "0px"
        : `-${
            getHeight(isMobile, isOverall)
          }px`
    })`,
  };

  return {
    stickyStyle,
    sticky,
    openHamburgerMenu,
    handleOpenMenu,
    setIsOverall,
  };
};
