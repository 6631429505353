import React from "react";
import PropTypes from "prop-types";

const WarningIcon = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="68"
      height="51"
      viewBox="0 0 68 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.2243 39.7092C38.4031 39.6195 38.5111 39.4318 38.4991 39.2322L36.7213 9.78544C36.7054 9.52148 36.4867 9.31557 36.2222 9.31557L31.7778 9.31557C31.5133 9.31557 31.2946 9.52148 31.2787 9.78544L29.5009 39.2322C29.4889 39.4318 29.5969 39.6195 29.7757 39.7092C30.761 40.2038 32.314 40.4756 33.8593 40.4984C35.4061 40.5213 37.0544 40.2964 38.2243 39.7092Z"
        fill={fillColor}
        stroke={fillColor}
        strokeLinejoin="round"
      />
      <ellipse
        cx="34"
        cy="3.12308"
        rx="3.11111"
        ry="3.12314"
        transform="rotate(-180 34 3.12308)"
        fill={fillColor}
      />
      <path
        d="M42.7032 7L64.3538 44.5C65.1236 45.8333 64.1614 47.5 62.6218 47.5H5.4641C3.92449 47.5 2.96225 45.8333 3.73205 44.5L25.3827 7"
        stroke={fillColor}
        strokeWidth="6"
        strokeLinecap="round"
      />
    </svg>
  );
};

WarningIcon.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

WarningIcon.defaultProps = {
  fillColor: "white",
};

export default WarningIcon;
