import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      textAlign: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  },
  custom_message: {
    fontSize: "16px",
    width: "100%",
    color: theme.palette.dark.gray,
    paddingTop: theme.spacing(2),
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
    "& a": {
      color: theme.palette.themeColor.primary,
      textDecoration: "underline",
    },
  },
}));

export { useStyles };
