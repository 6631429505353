import * as yup from "yup";

export const entitySchema = yup.object({
  entityName: yup.string().required(),
  address: yup.string().required(),
  phoneNumber: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, "Phone number must be a number"),
  email: yup.string().required().email(),
});