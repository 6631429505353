const values = {
  xs: 600,
  sm: 767,
  md: 991,
  l: 1280,
  lg: 1400,
  xl: 1920,
};

export default values;
