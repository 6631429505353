import React, { useState } from "react";
import PropTypes from "prop-types";

const PressRelease = ({
  className,
  onHoverColor,
  offHoverColor,
  inactiveColor,
  disabled,
  fillColor,
}) => {
  const [iconColor, setIconColor] = useState(
    fillColor ? fillColor : !disabled ? offHoverColor : inactiveColor
  );

  return (
    <svg
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={
        !disabled
          ? () => setIconColor(onHoverColor)
          : () => setIconColor(inactiveColor)
      }
      onMouseLeave={
        !disabled
          ? () => setIconColor(offHoverColor)
          : () => setIconColor(inactiveColor)
      }
    >
      <circle cx="37.5" cy="37.5" r="32" stroke={iconColor} strokeWidth="3" />
      <circle cx="37.5" cy="37.5" r="36" stroke={iconColor} strokeWidth="3" />
      <rect
        x="25.7333"
        y="19.6444"
        width="22.7778"
        height="5.46667"
        rx="2"
        fill={iconColor}
      />
      <rect
        x="25.7333"
        y="27.8445"
        width="22.7778"
        height="1.82222"
        rx="0.911111"
        fill={iconColor}
      />
      <rect
        x="25.7333"
        y="32.4"
        width="22.7778"
        height="1.82222"
        rx="0.911111"
        fill={iconColor}
      />
      <rect
        x="25.7333"
        y="36.9556"
        width="22.7778"
        height="1.82222"
        rx="0.911111"
        fill={iconColor}
      />
      <rect
        x="25.7333"
        y="41.5111"
        width="22.7778"
        height="1.82222"
        rx="0.911111"
        fill={iconColor}
      />
      <rect
        x="25.7333"
        y="46.0667"
        width="22.7778"
        height="1.82222"
        rx="0.911111"
        fill={iconColor}
      />
      <rect
        x="25.7333"
        y="50.6222"
        width="22.7778"
        height="1.82222"
        rx="0.911111"
        fill={iconColor}
      />
      <rect
        x="24"
        y="17"
        width="27.1556"
        height="39"
        rx="1"
        stroke={iconColor}
        strokeWidth="2"
      />
    </svg>
  );
};

PressRelease.propTypes = {
  className: PropTypes.string,
  onHoverColor: PropTypes.string,
  offHoverColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  disabled: PropTypes.bool,
};

PressRelease.defaultProps = {
  disabled: false,
  onHoverColor: "#f98c10",
  offHoverColor: "#7fc035",
  inactiveColor: "#e3e3e3",
};

export default PressRelease;
