import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;

  return {
    clickable_icon_container: {
      width: "min-content",
      "& h3": {
        lineHeight: "12px !important",
        margin: 0,
      },
    },
    contributables: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      padding: `${spacing(3)}px 0px`,
      columnGap: spacing(3),
      rowGap: spacing(2),
    },
    free_contributables: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      columnGap: spacing(3),
      rowGap: spacing(2),
    },
    top_border: {
      paddingTop: spacing(3),
      borderTop: `3px solid ${palette.themeColor.primary}`,
    },
  };
});

export { useStyles };
