const disclaimerLabels = {
  DISCLAIMER_ADVERTISEMENT_OR_FRONTEND_PRESS_RELEASE:
    "Editor's Note: This advertorial content is being published by TAPinto.net as a service for its marketing partners. For more information about how to market your business or nonprofit on TAPinto, please visit TAPintoMarketing.net or email contact@tapinto.net. The opinions expressed herein, if any, are the writer's alone, and do not reflect the opinions of TAPinto.net or anyone who works for TAPinto.net. TAPinto.net is not responsible for the accuracy of any of the information supplied by the writer.",

  DISCLAIMER_LETTERS_TO_THE_EDITOR:
    "Editor's Note: The opinions expressed herein are the writer's alone, and do not reflect the opinions of TAPinto.net or anyone who works for TAPinto.net. TAPinto.net is not responsible for the accuracy of any of the information supplied by the writer. Click here for TAPinto's Letters to the Editor policy. ",

  DISCLAIMER_CANDIDATE_STATEMENTS:
    "Editor's Note: The opinions expressed herein are the writer's alone, and do not reflect the opinions of TAPinto.net or anyone who works for TAPinto.net. TAPinto.net is not responsible for the accuracy of any of the information supplied by the writer.  This advertorial content is being published by TAPinto.net as a service for its marketing partners. Click here for TAPinto's Candidate Statement Policy. For more information about how to market your candidacy on TAPinto, please visit TAPintoMarketing.net or email contact@tapinto.net",

  DISCLAIMER_POLICE_BLOTTER:
    "Editor's Note: This article relies on information provided by law enforcement officials and the subjects are presumed innocent. To request removal of your name from a police report, click here.",

  DISCLAIMER_ADVERTISEMENT:
    "Editor's Note: This advertorial content is being published by TAPinto.net as a service for its marketing partners. For more information about how to market your business on TAPinto, please emailcontact@tapinto.net.",

  SUBMIT_LETTER_TO_EDITOR_HERE: "Submit a letter to the editor here",

  LETTER_TO_THE_EDITOR_POLICY:
    "Click here for TAPinto's Letters to the Editor policy.",

  CANDIDATE_STATEMENT_POLICY:
    "Click here for TAPinto's Candidate Statement Policy.",

  CANDIDATE_STATEMENT_DIY_POLICY:
    "I have read and understand the Candidate Statement Policy and by clicking Check Out, agree that my Candidate Statement abides by the Policy.",

  CANDIDATE_STATEMENT_POLICY_LINK_DIY: "Candidate Statement Policy",
};

export default disclaimerLabels;
