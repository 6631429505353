import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./AdCard.style";
import Card from "../../../common/Card/Card";
import Typography from "../../../common/Typography/Typography";
import Ad from "../../../components/Ad/Ad";
import labels from "../../../config/labels";

const AdCard = ({ adTargets, adUnit, adSize, options }) => {
  const classes = useStyles();

  return (
    <>
      <Card
        children={
          <>
            {options?.showAdText && (
              <div className={classes.text_container}>
                <Typography
                  level="t4_text_4"
                  className={classes.advertisement_text}
                >
                  {labels.ADVERTISEMENT}
                </Typography>
              </div>
            )}
            <Ad ad_unit={adUnit} size={adSize} targets={adTargets} />
          </>
        }
        withShadow={false}
        noRounded={true}
        classes={{
          root: options?.forTopStories
            ? classes.ad_card_for_top_stories
            : classes.ad_card,
        }}
      />
    </>
  );
};

AdCard.propTypes = {
  adTargets: PropTypes.oneOfType([
    PropTypes.shape({
      towns: PropTypes.arrayOf(PropTypes.string),
      sections: PropTypes.arrayOf(PropTypes.string),
      sponsor_display: PropTypes.arrayOf(PropTypes.string),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        towns: PropTypes.arrayOf(PropTypes.string),
        sections: PropTypes.arrayOf(PropTypes.string),
        sponsor_display: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  ]),
  adUnit: PropTypes.string,
  adSize: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.shape({
    showAdText: PropTypes.bool,
    forTopStories: PropTypes.bool,
  }),
};

export default AdCard;
