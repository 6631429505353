import { makeStyles } from "../Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    checkbox_container: {
      fontFamily: "Montserrat",
      "& .MuiIconButton-label": {
        color: (styleProps) => styleProps.checkboxColor,
        fontWeight: "bold",
      },
    },
    checkbox_disabled: {
      "& .MuiIconButton-label": {
        color: "rgba(0, 0, 0, 0.26) !important",
      },
    },
    disabled_label_container: {
      "& .MuiTypography-root": {
        opacity: 0.7,
      },
    },
  };
});

export { useStyles };
