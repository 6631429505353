import { makeStyles } from "../Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    chip: {
      backgroundColor: `rgba(127, 192, 53, 0.3) !important`,
      margin: theme.spacing(1),
      maxWidth: 250,
      borderRadius: 6,
      "& span": {
        color: `${theme.palette.dark.gray} !important`,
      },
    },
    paper_container: {
      "& li:hover": {
        backgroundColor: "rgba(127, 192, 53, 0.3)",
      },
      '& li.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: "rgba(127, 192, 53, 0.3)",
      },
      '& li.MuiAutocomplete-option[aria-selected="true"]:hover': {
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      },
    },
    autocomplete: {
      borderRadius: "4px !important",
      "& .MuiFormControl-root": {
        height: "100% !important",
      },
      "& .MuiInput-underline::after": {
        borderBottom: `2px ${theme.palette.themeColor.secondary} solid`,
      },
      "& .MuiAutocomplete-inputRoot": {
        paddingRight: `${theme.spacing(2)}px !important`,
        height: "100%",
      },
      "& input": {
        height: "100%",
      },
    },
  };
});

export { useStyles };
