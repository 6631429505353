import React from "react";
export const MostlyCloudyFlurries = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="32"
    viewBox="0 0 44 32"
    fill="none"
  >
    <path
      d="M23.7337 8.76711C23.5458 8.76711 23.4206 8.70448 23.2954 8.57924L19.8511 4.63403C19.6633 4.38354 19.6632 4.07043 19.9137 3.88257C20.039 3.75732 20.1642 3.75732 20.2895 3.75732C20.4147 3.75732 20.54 3.81994 20.6652 3.94519L24.1094 7.89039C24.2973 8.14088 24.2973 8.45399 24.0468 8.64186C23.9842 8.70448 23.8589 8.76711 23.7337 8.76711Z"
      fill="white"
    />
    <path
      d="M29.3071 6.32485C28.994 6.32485 28.7435 6.07436 28.7435 5.76125V0.563601C28.7435 0.250489 28.994 0 29.3071 0C29.6202 0 29.8707 0.250489 29.8707 0.563601L29.9333 5.82387C29.9333 5.94912 29.8707 6.13699 29.7455 6.19961C29.6202 6.26223 29.4323 6.32485 29.3071 6.32485Z"
      fill="white"
    />
    <path
      d="M41.0175 20.9785C40.9548 20.9785 40.8296 20.9785 40.767 20.9158L36.1329 18.4736C36.0077 18.4109 35.8824 18.2857 35.8824 18.1605C35.8198 18.0352 35.8824 17.8473 35.9451 17.7221C36.0077 17.5969 36.1329 17.4716 36.2582 17.4716C36.3834 17.409 36.5713 17.4716 36.6965 17.5342L41.3306 19.9765C41.4558 20.0391 41.5811 20.1644 41.5811 20.2896C41.6437 20.4149 41.5811 20.6027 41.5184 20.728C41.3932 20.9158 41.2053 20.9785 41.0175 20.9785Z"
      fill="white"
    />
    <path
      d="M37.1976 12.8376C37.0723 12.8376 36.9471 12.775 36.8845 12.7124C36.7592 12.6497 36.6966 12.4619 36.6966 12.3366C36.6966 12.2114 36.6966 12.0235 36.8218 11.9609C36.8845 11.8356 37.0723 11.773 37.1976 11.773L42.3953 11.1468C42.5205 11.1468 42.7084 11.1468 42.8336 11.272C42.9588 11.3347 43.0215 11.5225 43.0215 11.6478C43.0841 11.9609 42.8336 12.2114 42.5205 12.274L37.3228 12.9002C37.1976 12.8376 37.1976 12.8376 37.1976 12.8376Z"
      fill="white"
    />
    <path
      d="M33.3776 26.5519C33.1271 26.5519 32.9392 26.4266 32.8766 26.1761L31.1232 21.229C31.0606 21.1037 31.0606 20.9159 31.1232 20.7906C31.1858 20.6654 31.3111 20.5401 31.4363 20.5401C31.5616 20.4775 31.7494 20.4775 31.8747 20.5401C31.9999 20.6028 32.1251 20.728 32.1251 20.8532L33.8786 25.8004C33.9412 25.9256 33.9412 26.1135 33.8786 26.2388C33.816 26.364 33.6907 26.4893 33.5655 26.5519C33.5028 26.4893 33.4402 26.5519 33.3776 26.5519Z"
      fill="white"
    />
    <path
      d="M34.317 8.32879C34.1917 8.32879 34.0665 8.26617 34.0039 8.20355C33.7534 8.01568 33.6908 7.63995 33.8786 7.45208L37.135 3.31901C37.1976 3.19377 37.3855 3.13114 37.5107 3.13114C37.636 3.13114 37.8238 3.13114 37.8865 3.25639C38.0117 3.31901 38.0743 3.50688 38.0743 3.63212C38.0743 3.75737 38.0743 3.94523 37.9491 4.07048L34.7553 8.07831C34.6301 8.26617 34.4422 8.32879 34.317 8.32879Z"
      fill="white"
    />
    <path
      d="M29.3071 7.07631C25.8003 7.07631 22.857 9.83169 22.6692 13.3385C22.7944 13.3385 22.9196 13.3385 22.9823 13.3385C23.0449 13.3385 23.1701 13.3385 23.2328 13.3385C23.4206 13.3385 23.6085 13.4012 23.7964 13.4012C23.9842 10.5205 26.3639 8.26614 29.3071 8.26614C32.3756 8.26614 34.8179 10.7084 34.8179 13.7769C34.8179 16.8454 32.3756 19.2877 29.3071 19.2877H29.2445C29.6202 19.6008 29.9333 19.9765 30.1838 20.4149C33.5028 19.9765 36.0077 17.2211 36.0077 13.7769C35.9451 10.0822 32.9392 7.07631 29.3071 7.07631Z"
      fill="white"
    />
    <path
      d="M28.0548 18.5362C28.0548 18.411 28.0548 18.2857 28.0548 18.1605C28.1174 15.6556 26.1761 13.5264 23.6712 13.3386C22.6066 13.2759 21.6047 13.5264 20.7906 14.0274C20.3523 11.3346 17.8473 9.2681 14.9041 9.45597C12.1487 9.58121 9.95694 11.6477 9.58121 14.2153C9.08023 14.0274 8.57925 13.9648 8.01565 13.9648C5.38551 13.9648 3.25637 16.0939 3.25637 18.7241C3.25637 18.7867 3.25637 18.8493 3.25637 18.9119C1.9413 19.2877 0 20.0391 0 22.7945C0 24.7358 1.56555 26.4266 3.69471 26.6145C3.88258 26.6145 4.00783 26.5519 4.13308 26.4266L4.57142 25.8004C4.63404 25.7378 4.57143 25.6125 4.44619 25.6125H4.25831C2.50488 25.6125 1.12719 24.3601 1.12719 22.7945C1.12719 20.9785 2.12915 20.3523 4.00782 19.9139L4.44619 19.726V19.0372C4.44619 18.9119 4.38356 18.8493 4.38356 18.7241C4.38356 16.7202 6.01173 15.092 8.01565 15.092C8.64187 15.092 9.20548 15.2798 9.76908 15.593L10.5832 16.0939V15.1546C10.5205 12.775 12.4618 10.771 14.9041 10.6458H15.1546C17.5969 10.6458 19.5382 12.4618 19.6634 14.8415C19.6634 14.9041 19.6634 15.0294 19.6634 15.2172L19.6008 16.6575L20.6027 15.6556C21.3542 14.9041 22.2936 14.5284 23.2955 14.591C25.1116 14.6536 26.6771 16.0939 26.8023 17.91C26.865 18.2857 26.8024 18.5988 26.7397 18.9746L26.6145 19.5382L27.1781 19.6634C28.6184 19.9139 29.6203 21.0411 29.683 22.3562C29.7456 23.6712 28.8689 25.1115 27.5538 25.6125C27.2407 25.6751 26.865 25.8004 26.5519 25.8004H26.364C26.2388 25.8004 26.1761 25.9256 26.2387 25.9883L26.6771 26.6145C26.8024 26.7397 26.9276 26.865 27.1155 26.8023C27.3659 26.8023 27.6791 26.6771 27.9296 26.6145C29.6204 25.9883 30.8728 24.1722 30.8102 22.2935C30.9354 20.4149 29.7456 19.0372 28.0548 18.5362Z"
      fill="white"
    />
    <path
      d="M9.26807 23.1076C9.26807 23.7339 8.7671 24.2348 8.14087 24.2348C7.51465 24.2348 7.01368 23.7339 7.01368 23.1076C7.01368 22.4814 7.51465 21.9804 8.14087 21.9804C8.7671 21.9804 9.26807 22.4814 9.26807 23.1076Z"
      fill="white"
    />
    <path
      d="M15.9686 25.9257C15.9686 26.8024 15.2798 27.4912 14.403 27.4912C13.5263 27.4912 12.8375 26.8024 12.8375 25.9257C12.8375 25.049 13.5263 24.3601 14.403 24.3601C15.2798 24.4227 15.9686 25.1116 15.9686 25.9257Z"
      fill="white"
    />
    <path
      d="M20.7279 24.4853C20.7279 25.1116 20.2269 25.5499 19.6633 25.5499C19.0371 25.5499 18.5987 25.0489 18.5987 24.4853C18.5987 23.8591 19.0997 23.4208 19.6633 23.4208C20.2269 23.4208 20.7279 23.9217 20.7279 24.4853Z"
      fill="white"
    />
    <path
      d="M15.5303 30.9354C15.5303 31.5617 15.0293 32 14.4657 32C13.8395 32 13.4011 31.499 13.4011 30.9354C13.4011 30.3092 13.9021 29.8709 14.4657 29.8709C15.0293 29.8082 15.5303 30.3092 15.5303 30.9354Z"
      fill="white"
    />
    <path
      d="M10.3326 28.5558C10.3326 29.182 9.83163 29.6204 9.26803 29.6204C8.64181 29.6204 8.20346 29.1194 8.20346 28.5558C8.20346 27.9296 8.70443 27.4912 9.26803 27.4912C9.89425 27.4912 10.3326 27.9922 10.3326 28.5558Z"
      fill="white"
    />
    <path
      d="M20.6026 30.9355C20.6026 31.4364 20.1643 31.8748 19.6633 31.8748C19.1623 31.8748 18.724 31.4364 18.724 30.9355C18.724 30.4345 19.1623 29.9961 19.6633 29.9961C20.1643 29.9335 20.6026 30.3719 20.6026 30.9355Z"
      fill="white"
    />
    <path
      d="M24.1723 26.4266C24.1723 26.9276 23.7339 27.366 23.2329 27.366C22.732 27.366 22.2936 26.9276 22.2936 26.4266C22.2936 25.9257 22.732 25.4873 23.2329 25.4873C23.7339 25.4873 24.1723 25.9257 24.1723 26.4266Z"
      fill="white"
    />
  </svg>
);
