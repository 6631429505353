import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    autocomplete_container: {
      width: "100%",
      border: "2px solid #D8D8D8",
      borderRadius: 2,
      "& input": {
        opacity: 0,
      },
      "& input:focus": {
        opacity: 0.8,
        backgroundColor: "inherit",
        border: "none",
        boxShadow: "none",
      },
      "& .MuiInput-underline:before": {
        content: "none",
      },
      "& .MuiInputBase-root": {
        paddingRight: "0 !important",
      },
    },
  };
});

export { useStyles };
