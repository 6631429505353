import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ExitIntentPopup.style";
import PopUpDialog from "../../common/Dialog/PopUpDialog";
import { useExitIntent } from "use-exit-intent";
import PopUpContent from "./PopUpContent";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import withConfig from "../../utils/withConfig";
import cookies from "../../utils/cookies";

const TOWNS_SUBSCRIBED_BY_POP_UP = "townsSubscribedByPopUp";

const ExitIntentPopup = ({
  subscribe_to_content,
  placement,
  photo_url,
  is_license,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const { registerHandler } = useExitIntent({
    desktop: {
      triggerOnIdle: false,
      useBeforeUnload: false,
      triggerOnMouseLeave: true,
      delayInSecondsToTrigger: 1,
    },
    mobile: {
      triggerOnIdle: true,
      delayInSecondsToTrigger: 20,
    },
  });

  const handlePopUpOpen = () => {
    const existingTowns = cookies.get(TOWNS_SUBSCRIBED_BY_POP_UP).split(",");

    if (existingTowns.length === 1 && existingTowns[0] === "") {
      setIsOpen(true);
      manageCookies();
    } else {
      setIsOpen(false);
    }
  };

  registerHandler({
    id: "openModal",
    handler: () => handlePopUpOpen(),
  });

  const handleCloseExitIntentPopUp = () => {
    setIsOpen(false);
    manageCookies();
  };

  const handleSubscription = (subscribed) => {
    if (subscribed) {
      manageCookies();
    }
  };

  const manageCookies = () => {
    const existingTownsAllTogether = cookies.get(TOWNS_SUBSCRIBED_BY_POP_UP);
    const existingTowns = existingTownsAllTogether.split(",");
    const existingCookie = existingTowns.find(
      (town) => town === subscribe_to_content.slug
    );

    if (existingCookie === undefined) {
      const townsTosave =
        existingTownsAllTogether === ""
          ? subscribe_to_content.slug
          : `${existingTownsAllTogether},${subscribe_to_content.slug}`;

      cookies.save(TOWNS_SUBSCRIBED_BY_POP_UP, townsTosave);
    }
  };

  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={withConfig("RECAPTCHA_KEY")}
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
    >
      <PopUpDialog
        title=""
        content={
          <PopUpContent
            subscribeToContent={subscribe_to_content}
            placement={placement}
            onSubscription={handleSubscription}
            photoUrl={photo_url}
            isLicense={is_license}
          />
        }
        openState={isOpen}
        onClose={handleCloseExitIntentPopUp}
        className={classes.exit_intent_pop_up}
        titleClass={classes.title_class}
        closeButtonColor="#454545"
        disableCloseOnBackDropClick={true}
      />
    </GoogleReCaptchaProvider>
  );
};

ExitIntentPopup.propTypes = {
  subscribe_to_content: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    id: PropTypes.number,
  }),
  placement: PropTypes.string,
  under_license_contract: PropTypes.bool,
  photo_url: PropTypes.string,
};

export default ExitIntentPopup;
