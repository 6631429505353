import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      width: "50%",
      height: theme.spacing(3),

      "& .MuiButton-root": {
        background: "linear-gradient(#a66114 3%, 20%, #f98c10)",
        borderRadius: 20,
        borderBottomRightRadius: 3,
      },

      "&.MuiButtonBase-root": {
        marginTop: theme.spacing(0.5),
        textTransform: "none",
      },
      "&.MuiButton-root": {
        borderRadius: 20,
        borderBottomRightRadius: 3,
      },
      "&.MuiButton-text": {
        fontSize: "15px",
        fontWeight: 700,
        fontFamily: "Montserrat",
        lineHeight: "18px",
      },
      "&.MuiButton-textPrimary": {
        color: theme.palette.miscellaneous.white,
      },
    },
    button_disabled: {
      "&.MuiButton-root": {
        background: "linear-gradient(#777777 3%, 20%, #e3e3e3)",
      },
    },

    orange_button: {
      "&.MuiButton-root": {
        background: theme.palette.themeColor.primary,
      },
    },

    green_button: {
      "&.MuiButton-root": {
        background: "linear-gradient(#518219 3%, 20%, #7fc035)",
      },
    },
    custom_button: {
      "&.MuiButton-root": {
        background: (props) => `${props.backgroundColor}`,
        color: (props) => `${props.labelColor}`,
      },
      "&.MuiButton-root:hover": {
        background: (props) => `${props.backgroundColor}`,
      },
    },
    common: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    mont_light: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 200,
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    mont_italic: {
      fontFamily: "Montserrat",
      fontStyle: "italic",
      fontWeight: 200,
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    mont_medium: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },

    mont_bold: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    h1: {
      fontWeight: 800,
      fontSize: "48px",
      lineHeight: "1.1",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "38px",
      lineHeight: "1.1",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "28px",
      lineHeight: "1.1",
    },
    h4: {
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "1.45",
    },
    body: {
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "1.45",
    },
    large: {
      fontWeight: "normal",
      fontSize: "60px",
      lineHeight: "1.45",
    },
    small: {
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "1.45",
    },
    tiny: {
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "1.6",
    },
    underlined: {
      textDecoration: "underline",
    },
    font_size_9_px: {
      fontSize: "9px",
    },
    font_size_10_px: {
      fontSize: "10px !important",
    },
    font_size_11_px: {
      fontSize: "11px",
    },
    font_size_12_px: {
      fontSize: "12px",
    },
    font_size_16_px: {
      fontSize: "16px",
    },
    font_size_15_px: {
      fontSize: "15px",
    },
    font_size_17_px: {
      fontSize: "17px",
    },
    font_size_26_px: {
      fontSize: "26px",
    },
    font_size_28_px: {
      fontSize: "28px",
    },
    font_size_35_px: {
      fontSize: "35px",
    },
  };
});

export { useStyles };
