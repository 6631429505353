import React from "react";
import PropTypes, { bool, object, string } from "prop-types";
import RealEstateCard from "../../ContentCard/RealEstateCard/RealEstateCard";
import EventCard from "../../ContentCard/EventCard/EventCard";
import ObituaryCard from "../../ContentCard/ObituaryCard/ObituaryCard";
import CategorySectionOrColumnCard from "./CategorySectionOrColumnCard/CategorySectionOrColumnCard";
import GamesCard from "../../Games/GamesCard/GamesCard";
import {
  BUSINESS_LISTING_BLOCK,
  CATEGORY_BLOCK,
  COLUMN_BLOCK,
  EVENTS_BLOCK,
  OBITUARIES_BLOCK,
  REAL_ESTATE_BLOCK,
  SECTION_BLOCK,
  CLASSIFIED_BLOCK,
  TITLE_BLOCK,
  MILESTONES_BLOCK,
  LEGAL_NOTICES_BLOCK,
  GAMES_BLOCK,
} from "../../../utils/constants/homepageConstants";
import BlockTitle from "../../Town/Shared/BlockTitle/BlockTitle";
import SpecialCaseCard from "../../Town/Shared/SpecialCaseCard/SpecialCaseCard";
import Divider from "../../../common/Divider/Divider";

const ContentCard = ({
  content,
  blockClass,
  useSectionContext,
  asSidebarItems,
}) => {
  switch (blockClass) {
    case EVENTS_BLOCK:
      return (
        <EventCard
          content={content}
          useSectionContext={useSectionContext}
          options={{ withShadow: false, rightBlock: true }}
        />
      );
    case OBITUARIES_BLOCK:
      return <ObituaryCard content={content} options={{ withShadow: false }} />;
    case CLASSIFIED_BLOCK:
    case MILESTONES_BLOCK:
    case LEGAL_NOTICES_BLOCK:
    case BUSINESS_LISTING_BLOCK:
      return (
        <SpecialCaseCard
          content={content}
          blockClass={blockClass}
          useSectionContext={useSectionContext}
          options={{ withShadow: false }}
        />
      );
    case REAL_ESTATE_BLOCK:
      return (
        <RealEstateCard content={content} options={{ withShadow: false }} />
      );
    case CATEGORY_BLOCK:
    case SECTION_BLOCK:
    case COLUMN_BLOCK:
      return (
        <CategorySectionOrColumnCard
          content={content}
          blockClass={blockClass}
          asSidebarItems={asSidebarItems}
          options={{ withShadow: false }}
        />
      );
    case TITLE_BLOCK:
      return <BlockTitle title={content.title} />;
    case GAMES_BLOCK:
      return <GamesCard content={[content]} cardType={"sidebar"} />;
    default:
      return <></>;
  }
};

ContentCard.propTypes = {
  content: object.isRequired,
  blockClass: string.isRequired,
  asSidebarItems: bool,
};

export default ContentCard;
