import React, { useEffect, useState } from "react";
import { bool, func, number, object, oneOfType } from "prop-types";
import labels from "../../../config/labels";
import { useStyles } from "./SubmitContentModal.style";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import { fetchSubmitContentItems } from "../../../services/masthead";
import ModalContents from "./ModalContents/ModalContents";

const SubmitContentModal = ({ handleClose, openState, town, legalNoticePermissions, currentUserId }) => {
  const classes = useStyles();
  const [actions, setActions] = useState([]);
  const [contributables, setContributables] = useState([]);
  const primaryColor =
    JSON.parse(localStorage.getItem("townTheme"))?.primary_color ?? "#f98c10";
  const secondaryColor =
    JSON.parse(localStorage.getItem("townTheme"))?.secondary_color ?? "#f98c10";

  useEffect(() => {
    const loadSubmitContentItems = async () => {
      const response = await fetchSubmitContentItems(town?.id);

      if (response.status === 200) {
        setContributables(response?.data?.contributables);
        if (town?.id !== 266 || town?.id !== "halston-media") {
          setActions(response?.data?.actions);
        }
      }
    };

    if (contributables.length === 0) {
      loadSubmitContentItems();
    }

    return () => {
      setContributables([]), setActions([]);
    };
  }, [town?.id]);

  return handleClose || openState ? (
    <PopUpDialog
      onClose={handleClose}
      openState={openState}
      title={labels.SUBMIT_CONTENT}
      content={
        <ModalContents
          contributables={contributables}
          currentUserId={currentUserId}
          legalNoticePermissions={legalNoticePermissions}
          actions={actions}
          theme={{ primaryColor: primaryColor, secondaryColor: secondaryColor }}
        />
      }
      titleClass={classes.title}
      titleTypographyClass={classes.title_typography}
      closeButtonClass={classes.close_button}
      className={classes.submit_content_dialog}
    />
  ) : (
    <ModalContents
      contributables={contributables}
      actions={actions}
      theme={{ primaryColor: primaryColor, secondaryColor: secondaryColor }}
    />
  );
};

SubmitContentModal.propTypes = {
  handleClose: func,
  openState: bool,
  townId: oneOfType([object, number]),
};

export default SubmitContentModal;
