import React from "react";
import PropTypes from "prop-types";

const ClickHere = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.9978 24.1711L18.541 18.7647L24.607 35L28.0934 27.3509L34.9978 24.1711Z"
        stroke={fillColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.7157 14.8647C27.7157 7.75971 21.959 2 14.8579 2C7.75666 2 2 7.75971 2 14.8647C2 21.9696 7.75666 27.7293 14.8579 27.7293"
        stroke={fillColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4208 14.8647C22.4208 10.6853 19.0346 7.29724 14.8574 7.29724C10.6802 7.29724 7.29395 10.6853 7.29395 14.8647C7.29395 19.0441 10.6802 22.4321 14.8574 22.4321"
        stroke={fillColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ClickHere.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};

ClickHere.defaultProps = {
  fillColor: "#F98C10",
};

export default ClickHere;
