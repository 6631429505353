import React, { Fragment } from "react";
import { useStyles } from "./CustomButtons.style";
import { redirectToPage } from "../../../utils/helper";
import { useTownTheme } from "../../../context/TownThemeContext";
import Button from "../../../common/Button/Button";
import { bool } from "prop-types";

const TARGET_BLANK = "_blank";

const CustomButtons = () => {
  const classes = useStyles();
  const { secondBlockButtons } = useTownTheme();

  return (
    <div className={classes.buttons}>
      {secondBlockButtons?.map((button, index) => (
        <Fragment key={`custom-button-${index}`}>
          <Button
            level="simple_button"
            color="primary"
            onClick={() => redirectToPage(button.action, TARGET_BLANK)}
            children={button.label}
            classes={{
              root: classes.button,
              label: classes.button_label,
            }}
            style={{
              border: `${button.border_color} 1px solid`,
              color: button.label_color,
            }}
            ariaLabel={`navbar button ${button.label}`}
            dataCy="navbar-button"
          />
        </Fragment>
      ))}
    </div>
  );
};

CustomButtons.propTypes = {
  mobile: bool,
};

CustomButtons.defaultProps = {
  mobile: false,
};

export default CustomButtons;
