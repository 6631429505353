import React, { useRef } from "react";
import PropTypes from "prop-types";
import theme from "../Theme/themes/default";
import clsx from "clsx";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
import { useStyles } from "./Carousel.style";
import Item from "./Item";
import labels from "../../config/labels";
import ChevronRightCircle from "../Icons/ChevronRightCircle";
import ChevronLeftCircle from "../Icons/ChevronLeftCircle";

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay]);

const DELAY_MILISECONDS = 7000;

const Carousel = ({
  items,
  withPagination,
  onImageClick,
  videoPlayerProps,
  showArrows,
  withAutoplay,
}) => {
  const classes = useStyles();

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const getPagination = () => {
    if (items.length > 1 && items.length < 11) {
      return { clickable: true };
    }
    return false;
  };

  const getAutoplay = () => {
    if (items.length > 1 && !items[0].isVideo) {
      return { delay: DELAY_MILISECONDS };
    }
    return false;
  };

  const printPhotoCreditsWithLabel = (credits) => {
    if (credits) {
      return `${labels.PHOTO_CREDIT} ${credits}`;
    } else {
      return "";
    }
  };

  return (
    <>
      <Swiper
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
        }}
        className={classes.swiper}
        pagination={withPagination ? getPagination() : false}
        {...(withAutoplay && { autoplay: getAutoplay() })}
        loop={items.length > 1}
      >
        {items.length > 1 && showArrows && (
          <div
            ref={prevRef}
            className={clsx(classes.navigator_button, classes.previous)}
          >
            <ChevronLeftCircle fillColor={theme.palette.themeColor.secondary} />
          </div>
        )}

        {items &&
          items.map((item, i) => (
            <SwiperSlide key={`swiper-slide-${i}`}>
              <Item
                key={i}
                contentUrl={item.contentUrl}
                title={item.title}
                caption={item.caption}
                credits={printPhotoCreditsWithLabel(item.credits)}
                alt_text={item.alt_text}
                isVideo={item.isVideo}
                onImageClick={onImageClick}
                videoPlayerProps={videoPlayerProps}
                documentUrl={item?.documentUrl}
              />
            </SwiperSlide>
          ))}

        {items.length > 1 && showArrows && (
          <div
            ref={nextRef}
            className={clsx(classes.navigator_button, classes.next)}
          >
            <ChevronRightCircle
              fillColor={theme.palette.themeColor.secondary}
            />
          </div>
        )}
      </Swiper>
    </>
  );
};

Carousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      contentUrl: PropTypes.string.isRequired,
      caption: PropTypes.string,
      credits: PropTypes.string,
      alt_text: PropTypes.string,
      title: PropTypes.string,
      isVideo: PropTypes.bool,
    }).isRequired
  ).isRequired,
  withPagination: PropTypes.bool,
  onImageClick: PropTypes.func,
  videoPlayerProps: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  showArrows: PropTypes.bool,
  withAutoplay: PropTypes.bool,
};

Carousel.defaultProps = {
  withPagination: false,
  showArrows: true,
  withAutoplay: true,
};

export default Carousel;
