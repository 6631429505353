import React, { useEffect, useState } from "react";
import restClient from "../../../utils/restClient";
import withConfig from "../../../utils/withConfig";
import { useStyles } from "./SignUpBottomText.styles";
import DefaultBottomText from "../DefaultBottomText/DefaultBottomText";
import clsx from "clsx";
import Disclaimer from "../../SubscriptionBox/Disclaimer/Disclaimer";

const SignUpBottomText = ({ town, withDisclaimer }) => {
  const classes = useStyles();
  const [footer, setFooter] = useState();

  const footerNotPresent = () => {
    return footer === undefined || footer === null || footer === "";
  };

  useEffect(() => {
    const loadBottomText = async () => {
      const response = await restClient.get(
        withConfig("FETCH_SIGN_UP_BOTTOM_TEXT").replace(":id", town?.id)
      );
      if (response?.status === 200) {
        setFooter(response?.data?.footer);
      }
    };

    if (town) {
      loadBottomText();
    }
  }, [town]);

  return (
    <>
      {footer && (
        <div className={clsx(classes.row, classes.singup_footer)}>
          <div
            dangerouslySetInnerHTML={{
              __html: footer,
            }}
          />
        </div>
      )}
      {footerNotPresent() && withDisclaimer && <Disclaimer />}
      {footerNotPresent() && !withDisclaimer && <DefaultBottomText />}
    </>
  );
};

SignUpBottomText.defaultProps = {
  withDisclaimer: false,
};

export default SignUpBottomText;
