import React, { memo } from "react";
import BubbledButton from "../../../../common/Button/BubbledButton";
import { useStyles } from "./CustomButtons.style";
import LoginButton from "../DefaultButtons/LoginButton/LoginButton";
import { useTownTheme } from "../../../../context/TownThemeContext";
import SubmitContentButton from "../DefaultButtons/SubmitContentButton/SubmitContentButton";
import NewsletterSignupButton from "../DefaultButtons/NewsletterSignupButton/NewsletterSignupButton";
import { redirectToPage } from "../../../../utils/helper";
import Grid from "../../../../common/Grid/Grid";

const CustomButtons = ({ townsWithClusters }) => {
  const classes = useStyles();
  const { firstBlockButtons, signUpPath } = useTownTheme();

  const itemColumns = (index) => {
    if (firstBlockButtons?.length % 2 === 0) {
      return 6;
    } else {
      if (index === firstBlockButtons?.length - 1) {
        return 12;
      }
      return 6;
    }
  };

  const CustomButton = ({ button }) => {
    switch (button.action) {
      case "default_login":
        return (
          <LoginButton
            button={button}
            townsWithClusters={townsWithClusters}
            className={classes.custom_button}
          />
        );
      case "default_submit":
        return (
          <SubmitContentButton
            button={button}
            className={classes.custom_button}
          />
        );
      case "default_newsletter_signup":
        return (
          <NewsletterSignupButton
            button={button}
            className={classes.custom_button}
            signUpPath={signUpPath}
          />
        );

      default:
        return (
          <BubbledButton
            level="custom_button"
            backgroundColor={button.background_color}
            labelColor={button.label_color}
            className={classes.custom_button}
            onClick={() => redirectToPage(button.action, "_blank")}
          >
            {button.label}
          </BubbledButton>
        );
    }
  };

  return (
    <Grid container spacing={1} className={classes.custom_buttons_container}>
      {firstBlockButtons?.length > 0 &&
        firstBlockButtons.map((button, index) => (
          <Grid
            item
            xs={itemColumns(index)}
            className={classes.grid_item}
            key={"custom-button-" + index}
          >
            <CustomButton button={button} />
          </Grid>
        ))}
    </Grid>
  );
};

export default memo(CustomButtons);
