import React from "react";
export const Cold = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="32"
      viewBox="0 0 11 32"
      fill="none"
    >
      <path
        d="M7.27843 22.4V0H2.88627V22.4C1.12941 23.2784 0 25.0353 0 26.9804C0 29.7412 2.25882 32 5.08235 32C7.84314 32 10.1647 29.7412 10.1647 26.9176C10.102 25.0353 9.03529 23.2784 7.27843 22.4ZM3.57647 0.690196H6.58823V21.9608H3.57647V0.690196Z"
        fill="white"
      />
    </svg>
  );
};
