import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Card as MuiCard } from "@material-ui/core";
import { useStyles } from "./Card.style";

const Card = ({ children, classes, raised, withShadow, noRounded }) => {
  const customClasses = useStyles();
  return (
    <MuiCard
      classes={{
        root: clsx(
          customClasses.card,
          classes?.root && classes.root,
          !withShadow && customClasses.no_shadow,
          noRounded && customClasses.no_rounded
        ),
      }}
      raised={raised}
    >
      {children}
    </MuiCard>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  raised: PropTypes.bool,
  withShadow: PropTypes.bool,
};

Card.defaultProps = {
  withShadow: true,
  noRounded: false,
};

export default Card;
