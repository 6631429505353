import React from "react";
import BubbledButton from "../../../../../common/Button/BubbledButton";
import { redirectToPage } from "../../../../../utils/helper";

const NewsletterSignupButton = ({ button, className, signUpPath }) => {
  return (
    <BubbledButton
      level="custom_button"
      backgroundColor={button.background_color}
      labelColor={button.label_color}
      className={className}
      onClick={() => redirectToPage(signUpPath, "_top")}
    >
      {button.label}
    </BubbledButton>
  );
};

export default NewsletterSignupButton;
