/*eslint sort-keys: "error"*/

const obituaryLabels = {
  IN_MEMORIAM: "In Memoriam",
  SEARCH_BY_DATE_OF_PASSING: "Search by Date of Passing",
  SEARCH_BY_NAME: "Search by Name",
  SHARING_TITLE: "Obituary: ",
  MAIN_OBITUARY_IMAGE_ALT: "main-obituary-image",
  ALL_OBITUARIES: "All Obituaries",
  RETURN_TO_INDEX_PAGE: "Return to Index Page",
  SEARCH_RESULTS: "Search Results",
  NO_RESULTS: "No results were found",
  REQUEST_OBITUARY_ROLE:
    "You do not currently have access to submit obituaries. To request access click ",
  FUNERAL_HOME_NAME: "Funeral Home Name",
  FUNERAL_HOME_ADDRESS: "Funeral Home Address",
  FUNERAL_HOME_PHONE: "Funeral Home Phone",
  PROVIDE_INFORMATION: "Please provide the following information",
  REQUEST_OBITUARY_CONTRIBUTION_ACCESS: "Request obituary contribution access",
  ALREADY_REQUESTED_ACCESS:
    "Thank you for submitting your request. You will receive a response via email within 24 hours. If you do not receive a response, please email us at ",
};
export default obituaryLabels;
