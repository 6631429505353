import React from "react";

const TapintoLogoSmall = () => {
  return (
    <svg
      width="216"
      height="32"
      viewBox="0 0 216 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.7998 9.35272H50.8547V25.5811H45.9451V9.35272H40V5.46114H56.7998V9.35272Z"
        fill="#81BF40"
      />
      <path
        d="M76.8221 25.5811H71.7463L70.4294 21.5139H63.372L62.0551 25.5811H57.1072L64.1391 5.46114H69.7902L76.8221 25.5811ZM69.2404 17.825L66.9007 10.6094L64.561 17.825H69.2404Z"
        fill="#81BF40"
      />
      <path
        d="M94.7475 11.812C94.7475 12.7128 94.5983 13.5956 94.3 14.4604C94.0017 15.3162 93.5755 16.0369 93.0215 16.6224C92.2629 17.4151 91.4148 18.0142 90.4772 18.4195C89.5482 18.8249 88.389 19.0276 86.9996 19.0276H83.944V25.5811H79.0344V5.46114H87.1147C88.325 5.46114 89.3436 5.57375 90.1704 5.79895C91.0057 6.01515 91.743 6.34395 92.3822 6.78536C93.1493 7.31685 93.7332 7.99697 94.1338 8.82573C94.5429 9.6545 94.7475 10.6499 94.7475 11.812ZM89.6717 11.9336C89.6717 11.3661 89.5269 10.8796 89.2371 10.4742C88.9473 10.0599 88.6106 9.7716 88.227 9.60945C87.7156 9.39326 87.217 9.27615 86.7311 9.25813C86.2453 9.23111 85.5975 9.2176 84.7878 9.2176H83.944V15.2441H85.3503C86.1856 15.2441 86.8718 15.1901 87.4088 15.082C87.9543 14.9739 88.4103 14.7577 88.7768 14.4334C89.0922 14.1451 89.318 13.8028 89.4544 13.4064C89.5993 13.0011 89.6717 12.5101 89.6717 11.9336Z"
        fill="#81BF40"
      />
      <path
        d="M100.233 7.96094H97.5224V5.32602H100.233V7.96094ZM100.079 25.5811H97.6758V10.4878H100.079V25.5811Z"
        fill="#F78C2A"
      />
      <path
        d="M116.253 25.5811H113.85V16.9872C113.85 16.2936 113.811 15.645 113.735 15.0414C113.658 14.4289 113.517 13.9514 113.313 13.6091C113.1 13.2308 112.793 12.9515 112.392 12.7714C111.992 12.5822 111.472 12.4876 110.832 12.4876C110.176 12.4876 109.49 12.6588 108.774 13.0011C108.058 13.3434 107.372 13.7803 106.716 14.3118V25.5811H104.312V10.4878H106.716V12.1633C107.466 11.5057 108.241 10.9922 109.042 10.6229C109.844 10.2535 110.666 10.0689 111.51 10.0689C113.053 10.0689 114.229 10.5598 115.039 11.5417C115.848 12.5236 116.253 13.9379 116.253 15.7846V25.5811Z"
        fill="#F78C2A"
      />
      <path
        d="M127.773 25.446C127.322 25.5721 126.827 25.6757 126.29 25.7568C125.762 25.8379 125.289 25.8784 124.871 25.8784C123.414 25.8784 122.306 25.464 121.547 24.6353C120.788 23.8065 120.409 22.4778 120.409 20.6491V12.6227H118.785V10.4878H120.409V6.15028H122.813V10.4878H127.773V12.6227H122.813V19.5005C122.813 20.2933 122.83 20.9148 122.864 21.3653C122.898 21.8067 123.017 22.221 123.222 22.6084C123.409 22.9687 123.665 23.2345 123.989 23.4056C124.321 23.5678 124.824 23.6489 125.498 23.6489C125.89 23.6489 126.299 23.5903 126.725 23.4732C127.151 23.3471 127.458 23.2435 127.646 23.1624H127.773V25.446Z"
        fill="#F78C2A"
      />
      <path
        d="M142.336 18.0412C142.336 20.5005 141.74 22.4417 140.546 23.8651C139.353 25.2884 137.755 26 135.752 26C133.732 26 132.125 25.2884 130.932 23.8651C129.747 22.4417 129.155 20.5005 129.155 18.0412C129.155 15.5819 129.747 13.6407 130.932 12.2174C132.125 10.785 133.732 10.0689 135.752 10.0689C137.755 10.0689 139.353 10.785 140.546 12.2174C141.74 13.6407 142.336 15.5819 142.336 18.0412ZM139.856 18.0412C139.856 16.0864 139.494 14.6361 138.769 13.6902C138.045 12.7353 137.039 12.2579 135.752 12.2579C134.448 12.2579 133.433 12.7353 132.709 13.6902C131.993 14.6361 131.635 16.0864 131.635 18.0412C131.635 19.9329 131.997 21.3698 132.722 22.3517C133.446 23.3246 134.456 23.811 135.752 23.811C137.03 23.811 138.032 23.3291 138.756 22.3652C139.489 21.3923 139.856 19.951 139.856 18.0412Z"
        fill="#F78C2A"
      />
      <path
        d="M144.974 26C144.533 26 144.153 25.8512 143.833 25.5535C143.524 25.2447 143.37 24.8533 143.37 24.3792C143.37 23.905 143.524 23.5246 143.833 23.238C144.153 22.9403 144.533 22.7914 144.974 22.7914C145.426 22.7914 145.807 22.9403 146.115 23.238C146.424 23.5246 146.579 23.905 146.579 24.3792C146.579 24.8533 146.424 25.2447 146.115 25.5535C145.807 25.8512 145.426 26 144.974 26Z"
        fill="white"
      />
      <path
        d="M153.735 16.8373C154.441 16.8373 155.07 16.9806 155.621 17.2673C156.183 17.543 156.624 17.973 156.944 18.5574C157.264 19.1307 157.424 19.8695 157.424 20.7736V25.8677H154.844V21.1706C154.844 20.4539 154.684 19.9246 154.364 19.5828C154.055 19.241 153.614 19.0701 153.041 19.0701C152.633 19.0701 152.263 19.1583 151.933 19.3347C151.613 19.5001 151.359 19.7592 151.172 20.1121C150.995 20.4649 150.907 20.917 150.907 21.4683V25.8677H148.327V16.9696H150.791V19.434L150.328 18.6897C150.648 18.0943 151.106 17.6367 151.701 17.3169C152.296 16.9972 152.975 16.8373 153.735 16.8373Z"
        fill="white"
      />
      <path
        d="M164.243 26C163.229 26 162.335 25.8015 161.564 25.4046C160.803 25.0077 160.213 24.4674 159.794 23.7838C159.375 23.0891 159.165 22.3007 159.165 21.4187C159.165 20.5255 159.369 19.7372 159.777 19.0536C160.196 18.3589 160.764 17.8186 161.481 17.4327C162.198 17.0358 163.008 16.8373 163.912 16.8373C164.783 16.8373 165.566 17.0247 166.261 17.3996C166.966 17.7635 167.523 18.2927 167.931 18.9874C168.339 19.671 168.543 20.4925 168.543 21.4517C168.543 21.551 168.538 21.6667 168.527 21.7991C168.516 21.9203 168.505 22.0361 168.494 22.1464H161.266V20.6413H167.137L166.145 21.0879C166.145 20.6248 166.051 20.2223 165.864 19.8805C165.676 19.5387 165.417 19.2741 165.086 19.0866C164.756 18.8882 164.37 18.7889 163.929 18.7889C163.488 18.7889 163.096 18.8882 162.754 19.0866C162.424 19.2741 162.165 19.5442 161.977 19.897C161.79 20.2389 161.696 20.6468 161.696 21.121V21.5179C161.696 22.003 161.801 22.4331 162.01 22.8079C162.231 23.1718 162.534 23.453 162.92 23.6514C163.317 23.8389 163.78 23.9326 164.309 23.9326C164.783 23.9326 165.197 23.8609 165.55 23.7176C165.913 23.5743 166.244 23.3593 166.542 23.0726L167.915 24.5611C167.507 25.0242 166.994 25.3825 166.377 25.6362C165.759 25.8787 165.048 26 164.243 26Z"
        fill="white"
      />
      <path
        d="M173.9 26C172.852 26 172.036 25.7354 171.452 25.2061C170.867 24.6659 170.575 23.8665 170.575 22.8079V15.0014H173.155V22.7749C173.155 23.1498 173.254 23.442 173.453 23.6514C173.651 23.8499 173.922 23.9492 174.263 23.9492C174.671 23.9492 175.019 23.8389 175.305 23.6184L176 25.4377C175.735 25.6251 175.416 25.7685 175.041 25.8677C174.677 25.9559 174.296 26 173.9 26ZM169.202 19.1528V17.1681H175.372V19.1528H169.202Z"
        fill="white"
      />
    </svg>
  );
};

export default TapintoLogoSmall;
