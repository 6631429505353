import React, { useState } from "react";
import PropTypes from "prop-types";

const LettersToTheEditor = ({
  className,
  onHoverColor,
  offHoverColor,
  inactiveColor,
  disabled,
  fillColor,
}) => {
  const [iconColor, setIconColor] = useState(
    fillColor ? fillColor : !disabled ? offHoverColor : inactiveColor
  );
  return (
    <svg
      className={className}
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={
        !disabled
          ? () => setIconColor(onHoverColor)
          : () => setIconColor(inactiveColor)
      }
      onMouseLeave={
        !disabled
          ? () => setIconColor(offHoverColor)
          : () => setIconColor(inactiveColor)
      }
    >
      <path
        d="M58.1264 27.93V47.8919C58.1264 48.773 57.9303 49.5984 57.5853 50.3144L42.8043 37.4178L57.2071 24.8513C57.7814 25.6953 58.1264 26.7662 58.1264 27.93Z"
        fill={iconColor}
      />
      <path
        d="M31.3221 37.4178L16.5411 50.3144C16.1961 49.5983 16 48.7709 16 47.8919V27.93C16 26.7662 16.345 25.6953 16.9193 24.8513L31.3221 37.4178Z"
        fill={iconColor}
      />
      <path
        d="M55.4383 23.326L41.0461 35.8842L39.2897 37.4174L38.1147 38.4429C37.5596 38.9257 36.5528 38.9257 35.9977 38.4429L34.8227 37.4174L33.0663 35.8842L18.6741 23.326C19.1381 23.1156 19.6424 23 20.1695 23H53.9446C54.4682 23.0021 54.9743 23.1156 55.4383 23.326Z"
        fill={iconColor}
      />
      <path
        d="M50.3541 52H23.5113C21.6636 52 20.804 49.7089 22.1958 48.4936L32.1061 39.8393C32.776 39.2543 33.7517 39.1819 34.5006 39.6617L36.157 40.7229C36.4553 40.914 36.8021 41.0156 37.1563 41.0156C37.5359 41.0156 37.9064 40.899 38.2175 40.6815L39.5402 39.7571C40.2994 39.2265 41.3236 39.2866 42.0155 39.9023L51.6837 48.5059C53.0571 49.7281 52.1926 52 50.3541 52Z"
        fill={iconColor}
      />
      <circle cx="37.5" cy="37.5" r="32" stroke={iconColor} strokeWidth="3" />
      <circle cx="37.5" cy="37.5" r="36" stroke={iconColor} strokeWidth="3" />
    </svg>
  );
};

LettersToTheEditor.propTypes = {
  className: PropTypes.string,
  onHoverColor: PropTypes.string,
  offHoverColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  disabled: PropTypes.bool,
};

LettersToTheEditor.defaultProps = {
  disabled: false,
  onHoverColor: "#f98c10",
  offHoverColor: "#7fc035",
  inactiveColor: "#e3e3e3",
};

export default LettersToTheEditor;
