import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useStyles } from "./BlockTitle.style";
import Link from "../../../../common/Link/Link";
import ArrowForwardIosIcon from "../../../../common/Icons/ArrowForwardIos";
import Typography from "../../../../common/Typography/Typography";

const BlockTitle = ({ title, townSlug, contentType, options }) => {
  const classes = useStyles();

  const buildUrl = () => {
    if (contentType === "directories") {
      return townSlug ? `/${townSlug}/${contentType}` : `/${contentType}`;
    }
    if (contentType.includes("columns/")) {
      return townSlug
        ? `/towns/${townSlug}/${contentType}/articles`
        : `/${contentType}/articles`;
    }
    return townSlug ? `/towns/${townSlug}/${contentType}` : `/${contentType}`;
  };

  return townSlug && contentType ? (
    <Link
      level={"medium_20_px"}
      bold={!options?.forRightSidebar}
      className={clsx(
        classes.block_title_link,
        options?.forRightSidebar && [
          classes.primary_color_font,
          options?.isLowerCased && classes.lowercase_font,
        ]
      )}
      children={
        options?.withEndIcon ? (
          <>
            {title + " "} <ArrowForwardIosIcon className={classes.icon} />
          </>
        ) : (
          title
        )
      }
      url={buildUrl()}
      target="_top"
      underlined={options?.underlined}
    />
  ) : (
    <Typography
      level={options?.forRightSidebar ? "medium_20_px" : "t5_text_5"}
      bold
      className={clsx(
        classes.block_title,
        options?.forRightSidebar && [
          classes.primary_color_font,
          classes.lowercase_font,
        ]
      )}
      children={
        options?.withEndIcon ? (
          <>
            {title + " "} <ArrowForwardIosIcon className={classes.icon} />
          </>
        ) : (
          title
        )
      }
    />
  );
};

BlockTitle.propTypes = {
  title: PropTypes.string.isRequired,
  townSlug: PropTypes.string,
  contentType: PropTypes.string,
  options: PropTypes.shape({
    withEndIcon: PropTypes.bool,
    forRightSidebar: PropTypes.bool,
    isLowerCased: PropTypes.bool,
    underlined: PropTypes.bool,
  }),
};

BlockTitle.defaultProps = {
  options: {
    withEndIcon: false,
    forRightSidebar: false,
    isLowerCased: false,
    underlined: true
  },
};

export default BlockTitle;
