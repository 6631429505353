import React from "react";
import PropTypes from "prop-types";

const CloseCircle = ({ className, fillColor, onClick }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width="50"
      height="49"
      viewBox="0 0 50 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      title="Close"
    >
      <path
        d="M31.3174 14.7L24.9719 21.0455L18.6264 14.7L15.1719 18.1545L21.5174 24.5L15.1719 30.8455L18.6264 34.3L24.9719 27.9545L31.3174 34.3L34.7719 30.8455L28.4264 24.5L34.7719 18.1545L31.3174 14.7ZM24.9719 0C11.4234 0 0.471924 10.9515 0.471924 24.5C0.471924 38.0485 11.4234 49 24.9719 49C38.5204 49 49.4719 38.0485 49.4719 24.5C49.4719 10.9515 38.5204 0 24.9719 0ZM24.9719 44.1C14.1674 44.1 5.37192 35.3045 5.37192 24.5C5.37192 13.6955 14.1674 4.9 24.9719 4.9C35.7764 4.9 44.5719 13.6955 44.5719 24.5C44.5719 35.3045 35.7764 44.1 24.9719 44.1Z"
        fill={fillColor}
      />
    </svg>
  );
};

CloseCircle.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
  onClick: PropTypes.func,
};

CloseCircle.defaultProps = {
  fillColor: "white",
  onClick: () => "",
};

export default CloseCircle;
