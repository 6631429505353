import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values },
  } = theme;
  return {
    container: {
      display: "flex",
      alignItems: "center",
      columnGap: spacing(2),
      height: spacing(10),
      width: "fit-content",
      [below(values.md)]: {
        width: "100%",
        height: "100%",
        flexWrap: "wrap",
        paddingRight: spacing(2),
      },
    },
    image_container: {
      height: "100%",
      [below(values.md)]: {
        flex: 1,
      },
    },
    image: {
      maxHeight: "100%",
      [below(values.md)]: {
        maxHeight: spacing(10),
      },
    },
    info_container: {
      rowGap: spacing(1),
      display: "flex",
      flexDirection: "column",
      [below(values.md)]: {
        width: "50%",
        rowGap: 0,
        flex: 2,
      },
    },
    name_container: {
      display: "flex",
      flexDirection: "column",
      "& span": {
        padding: 0,
      },
      [`@media print`]: {
        fontSize: "10px !important",
      },
    },
    label: {
      width: "100%",
      paddingBottom: spacing(1),
    },
    fit_content: {
      width: "fit-content",
      [below(values.md)]: {
        width: "fit-content",
      },
    },
    contact_link: {
      textUnderlineOffset: `${spacing(0.6)}px`,
    },
  };
});

export { useStyles };
