/*eslint sort-keys: "error"*/

const realEstateLabels = {
  REAL_ESTATES_FINDER: "Find your dream home, with us by your side",
  REAL_ESTATES_FILTER_TOWN: "Select a Town",
  REAL_ESTATES_FILTER_PRICE: "Select a Price Range",
  REAL_ESTATES_FILTER_BEDROOMS: "Select Number of Bedrooms",
  REAL_ESTATES_FILTER_BATHROOMS: "Select Number of Bathrooms",
  REAL_ESTATES_FILTER_KEYWORDS: "Key words",
  REAL_ESTATES_FILTER_RESET: "Reset filters",
  REAL_ESTATES_FILTER_APPLY: "Apply",
  POST_YOUR_LISTING: "+ Post Your Listing",
  SEE_MORE_LISTINGS: "See more Listings",
  BACK_TO_REAL_ESTATES: "Back to Real Estate Listings",
  CONTACT_REALTOR: "Contact Realtor",
  SEND_TO_A_FRIEND: "Send to a friend",
  FOR_RENT: "For Rent",
  FOR_SALE: "For Sale",
  REAL_ESTATE_ERROR_EMAIL: "Email should have an '@' and at least one dot",
  REAL_ESTATE_ERROR_PHONE:
    "Phone number should contain country code (+001999999999)",
  REAL_ESTATE_ERROR_MESSAGE:
    "Please enter your questions or comments about the property",
  SEND_EMAIL: "Send email",
  YOUR_FRIENDS_EMAIL: "Your friend's email",
  TOWN: "Town",
  FILTER_TITLE: "Filters",
  TOWN_PLACE_HOLDER: "Choose a location",
  EMAIL_SENT: "Your email has been sent!",
  SENT_REALTOR_EMAIL_MESSAGE: "Thank you for your interest.",
  SENT_FRIEND_EMAIL_MESSAGE: "We hope you found what you were looking for.",
  GO_BACK_TO_PROPERTY: "Go back to property",
  RECENTLY_ADDED_IN: "Recently added in ",
  FILTERS: "Filters",
  NO_RESULTS_TITLE: "Oops!",
  NO_RESULTS_SUBTITLE: "No results found for your search",
  REAL_ESTATE_SUCCESSFULLY_REMOVED: "Real Estate Successfully Removed",
  REALTOR: "Realtor",
  CALL: "Call",
  SEND_A_MESSAGE: "Send a message",
  REAL_ESTATE_BED: "bed",
  REAL_ESTATE_BATH: "bath",
};
export default realEstateLabels;
