import React, { useState } from "react";
import PropTypes from "prop-types";

const Column = ({
  className,
  onHoverColor,
  offHoverColor,
  inactiveColor,
  disabled,
  fillColor,
}) => {
  const [iconColor, setIconColor] = useState(
    fillColor ? fillColor : !disabled ? offHoverColor : inactiveColor
  );
  return (
    <svg
      className={className}
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={
        !disabled
          ? () => setIconColor(onHoverColor)
          : () => setIconColor(inactiveColor)
      }
      onMouseLeave={
        !disabled
          ? () => setIconColor(offHoverColor)
          : () => setIconColor(inactiveColor)
      }
    >
      <rect
        x="21.3877"
        y="17"
        width="13.5819"
        height="41.2832"
        rx="5"
        stroke={iconColor}
        strokeWidth="2"
      />
      <rect
        x="39.5667"
        y="17"
        width="13.5819"
        height="41.2832"
        rx="5"
        stroke={iconColor}
        strokeWidth="2"
      />
      <path
        d="M47.6445 51.8386V53.6443H45.8388V54.6274H47.6445V56.4331L48.6276 56.4331V54.6274H50.4333V53.6443H48.6276V51.8386L47.6445 51.8386ZM44.6501 50.6499C42.7223 52.5776 42.7223 55.6941 44.6501 57.6219C46.5778 59.5496 49.6943 59.5496 51.6221 57.6219C53.5498 55.6941 53.5498 52.5776 51.6221 50.6499C49.6943 48.7221 46.5778 48.7221 44.6501 50.6499ZM50.9249 56.9247C49.3875 58.462 46.8846 58.462 45.3473 56.9247C43.8099 55.3873 43.8099 52.8844 45.3473 51.3471C46.8846 49.8097 49.3875 49.8097 50.9249 51.3471C52.4622 52.8844 52.4622 55.3873 50.9249 56.9247Z"
        fill={iconColor}
      />
      <circle cx="37.5" cy="37.5" r="32" stroke={iconColor} strokeWidth="3" />
      <circle cx="37.5" cy="37.5" r="36" stroke={iconColor} strokeWidth="3" />
    </svg>
  );
};

Column.propTypes = {
  className: PropTypes.string,
  onHoverColor: PropTypes.string,
  offHoverColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  disabled: PropTypes.bool,
};

Column.defaultProps = {
  disabled: false,
  onHoverColor: "#f98c10",
  offHoverColor: "#7fc035",
  inactiveColor: "#e3e3e3",
};

export default Column;
