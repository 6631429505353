/*eslint sort-keys: "error"*/

const eventLabels = {
  CALENDAR_OF_EVENTS: "Calendar of Events",
  EVENT_CATEGORIES: "Event Categories",
  ADD_TO_CALENDAR: "Add to Calendar",
  EVENTS: "Events ",
  VIEW_IN_GOOGLE_MAPS: "View in Google Maps",
  EM_DASH: " — ",
  UPCOMING_EVENTS: "UPCOMING EVENTS",
  ONLINE: "Online",
  OUR_WEBSITE: "Our Website",
  FIND_US_ON: "Find us on ",
  FACEBOOK: "Facebook",
  TWITTER: "X",
  INSTAGRAM: "Instagram",
  DASH_SEPARATOR: " / ",
  VIRTUAL_EVENT: "Virtual Event",
  ALL_DAY_EVENT: "All Day",
};
export default eventLabels;
