import React from "react";
import PropTypes from "prop-types";
import VerticalLayout from "../../../../common/Card/Layouts/Vertical/VerticalLayout";
import HorizontalDivider from "../../../../common/HorizontalDivider/HorizontalDivider";
import { useStyles } from "./MobileCard.style";
import Link from "../../../../common/Link/Link";
import { isBlock } from "../../../../utils/helpers/homepageHelper";
import {
  EVENTS_BLOCK,
  OBITUARIES_BLOCK,
} from "../../../../utils/constants/homepageConstants";
import { useTown } from "../../../../context/TownContext";
import { parseEventMedia } from "../../../../utils/helpers/mediaHelper";

const MobileCard = ({ item, options, blockClass, withFillImageBackground }) => {
  const showBottomDivider = options?.withBottomDivider;
  const classes = useStyles(showBottomDivider);
  const { town } = useTown();
  const { eventMedia, eventUrl } = parseEventMedia({
    event: item,
    town: town,
  });
  const townContentUrl =
    blockClass && isBlock({ blockClass: blockClass, type: EVENTS_BLOCK })
      ? eventUrl
      : !item.firstSection ? item.url_content : item.articleUrl;

  const EventBlockContentInfo = () => {
    return (
      <div>
        <Link
          level="paragraph_2"
          children={item.title}
          url={townContentUrl}
          className={classes.information_title}
        />
        <Link
          level="t4_text_4"
          children={item.starts}
          url={townContentUrl}
          className={classes.information}
        />
        <Link
          level="t4_text_4"
          children={item.start_time + " - " + item.end_time}
          url={townContentUrl}
          className={classes.information}
        />
      </div>
    );
  };

  const ObituaryBlockContentInfo = () => {
    return (
      <div>
        <Link
          level="paragraph_2"
          children={item.name}
          url={townContentUrl}
          className={classes.information_title}
        />
        <Link
          level="t4_text_4"
          children={item.date_of_birth + " - " + item.date_of_death}
          url={townContentUrl}
          className={classes.information}
        />
      </div>
    );
  };

  const Information = () => {
    switch (blockClass) {
      case EVENTS_BLOCK:
        return <EventBlockContentInfo />;
      case OBITUARIES_BLOCK:
        return <ObituaryBlockContentInfo />;

      default:
        return (
          <>
            <Link
              level="h2_header_2_mobile"
              children={item?.secction_for_front_end?.name}
              url={item?.secction_for_front_end?.path}
              className={classes.section_title}
            />
            <Link
              level="paragraph_2"
              children={item.name}
              url={townContentUrl}
              className={classes.information_title}
            />
          </>
        );
    }
  };

  return (
    <>
      <VerticalLayout
        media={
          isBlock({ blockClass: blockClass, type: EVENTS_BLOCK })
            ? eventMedia
            : item.media_content[0]
        }
        information={<Information />}
        options={{
          media: {
            clickable: true,
            townContentUrl: townContentUrl,
            withFillImageBackground,
          },
          videoPlayer: { showPlayable: false },
        }}
        customClasses={{
          root: classes.mobile_card,
          image: classes.image,
          top_section: classes.secondary_story_top_section,
          media_container: classes.media_container,
        }}
      />

      {showBottomDivider && (
        <HorizontalDivider className={classes.mobile_divider} />
      )}
    </>
  );
};

MobileCard.propTypes = {
  item: PropTypes.object.isRequired,
  options: PropTypes.shape({
    withBottomDivider: PropTypes.bool,
    withFillImageBackground: PropTypes.bool,
  }),
  blockClass: PropTypes.string,
};

MobileCard.defaultProps = {
  options: { withBottomDivider: false, withFillImageBackground: false },
};

export default MobileCard;
