import React from "react";
import { object, string } from "prop-types";
import PressRelease from "../../../../common/Icons/PressRelease";
import CalendarEventsListing from "../../../../common/Icons/CalendarEventsListing";
import CandidateStatements from "../../../../common/Icons/CandidateStatements";
import RealEstateListing from "../../../../common/Icons/RealEstateListing";
import Classified from "../../../../common/Icons/Classified";
import BusinessDirectoryListing from "../../../../common/Icons/BusinessDirectoryListing";
import Column from "../../../../common/Icons/Column";
import Obituary from "../../../../common/Icons/Obituary";
import LettersToTheEditor from "../../../../common/Icons/LettersToTheEditor";
import Milestone from "../../../../common/Icons/Milestone";
import LegalNotice from "../../../../common/Icons/LegalNotice";

const Icon = ({ iconProps, type }) => {
  switch (type) {
    case "press_release":
      return <PressRelease {...iconProps} />;
    case "event":
      return <CalendarEventsListing {...iconProps} />;
    case "candidate_statement":
      return <CandidateStatements {...iconProps} />;
    case "real_estate_listing":
      return <RealEstateListing {...iconProps} />;
    case "classified":
      return <Classified {...iconProps} />;
    case "directory":
      return <BusinessDirectoryListing {...iconProps} />;
    case "column":
      return <Column {...iconProps} />;
    case "obituary":
      return <Obituary {...iconProps} />;
    case "letter_to_editor":
      return <LettersToTheEditor {...iconProps} />;
    case "milestone":
      return <Milestone {...iconProps} />;
    case "legal_notice":
      return <LegalNotice {...iconProps} />;

    default:
      return <></>;
  }
};

Icon.propTypes = {
  iconProps: object,
  type: string,
};

export default Icon;
