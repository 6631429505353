import { useState } from "react";
import { createLegalNoticieEntitiesFn } from "../services/legalNoticeEntities";

export const useLegalNoticeEntities = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const createLegalNoticeEntities = async (entities, requestedByPo, { onSuccess }) => {
    setLoading(true);
    try {
      await createLegalNoticieEntitiesFn({ entities, requestedByPo, userId });
    } catch (error) {
      setError(error?.message || "An error occurred");
    } finally {
      setLoading(false);
      onSuccess?.();
    }
  }

  return {
    loading,
    error,
    createLegalNoticeEntities,
  }
}