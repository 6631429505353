import React, { Fragment, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./SavedDraftsModal.style";
import PopUpDialog from "../../../common/Dialog/PopUpDialog";
import labels from "../../../config/labels";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import { fetchDraftsForUser } from "../../../services/drafts";
import Spinner from "../../../common/Spinner/Spinner";
import {
  snackbarInitialState,
  snackbarReducer,
} from "../../../reducers/snackbars/snackbarsReducers";
import Snackbar from "../../../common/Snackbar/Snackbar";
import Draft from "./Draft/Draft";
import Card from "../../../common/Card/Card";

const SavedDraftsModal = ({ currentUserId, handleClose, openState }) => {
  const classes = useStyles();
  const [userDrafts, setUserDrafts] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [snackbarProps, setSnackbarProps] = useReducer(snackbarReducer, {
    ...snackbarInitialState,
    autoHideDuration: 2000,
  });

  const fetchUserDrafts = async () => {
    setDataLoading(true);
    const response = await fetchDraftsForUser(currentUserId);

    if (response && response?.status === 200) {
      setUserDrafts(response?.data?.drafts);
    }
    setDataLoading(false);
  };

  useEffect(() => {
    if (openState) {
      fetchUserDrafts();
    }
  }, [openState]);

  const Drafts = () => {
    return (
      <div className={classes.drafts_modal_content}>
        <Snackbar
          onClose={() => setSnackbarProps(snackbarInitialState)}
          open={snackbarProps.open}
          message={snackbarProps.message}
          variant={snackbarProps.variant}
        />
        {dataLoading && <Spinner withLoadingOverlay={true} />}
        {userDrafts.length > 0 ? (
          userDrafts?.map((draft, index) => (
            <Fragment key={index}>
              <Card
                children={
                  <Draft
                    draft={draft}
                    setDataLoading={setDataLoading}
                    setSnackbarProps={setSnackbarProps}
                    fetchUserDrafts={fetchUserDrafts}
                  />
                }
                classes={{ root: classes.draft_card }}
              />
            </Fragment>
          ))
        ) : (
          <Typography level="paragraph_2" color="gray" bold>
            {labels.NO_DRAFTS_LEFT}
          </Typography>
        )}
      </div>
    );
  };

  return (
    <PopUpDialog
      className={classes.saved_drafts_dialog}
      onClose={handleClose}
      openState={openState}
      title={labels.MY_DRAFTS}
      content={<Drafts />}
      titleClass={classes.title}
      titleTypographyClass={classes.title_typography}
      closeButtonClass={classes.close_button}
    />
  );
};

SavedDraftsModal.propTypes = {
  currentUserId: PropTypes.number,
  handleClose: PropTypes.func,
  openState: PropTypes.bool,
};

export default SavedDraftsModal;
