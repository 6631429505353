import { makeStyles } from "../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      backgroundColor: `${theme.palette.themeColor.secondary} !important`,
      width: "60%",
    },
    exit_intent_pop_up: {
      "& .MuiDialog-paper": {
        minWidth: "80%",
        border: `1px solid ${theme.palette.dark.black}`,
      },
    },
    title_class: {
      backgroundColor: theme.palette.miscellaneous.white,
    },
  };
});

export { useStyles };
