import React from "react";
import clsx from "clsx";
import Typography from "../../../common/Typography/Typography";
import { useStyles } from "./Header.style";
import labels from "../../../config/labels";
import Link from "../../../common/Link/Link";
import { topLocalNewstUrl } from "../../../utils/helper";

const Header = ({ town, section }) => {
  const classes = useStyles();

  const Title = ({ link }) => {
    const label = () => {
      if (section) {
        return section.name;
      } else if (town) {
        return town?.options?.local_news_header;
      }
      return labels.LOCAL_NEWS_FROM_AROUND_THE_TAP_INTO_NETWORK;
    };

    return link ? (
      label()
    ) : (
      <Typography
        level="h2_header_2"
        bold
        className={clsx(classes.header_text, !town && classes.capitalized_text)}
      >
        {label()}
      </Typography>
    );
  };

  return (
    <div className={classes.header_container}>
      {town && section === undefined ? (
        <Link
          level="t5_text_5"
          className={classes.card_title}
          children={<Title link={true} />}
          url={topLocalNewstUrl({
            townSlug: town.slug,
          })}
          bold
          underlined
        />
      ) : (
        <Title link={false} />
      )}
    </div>
  );
};

export default Header;
