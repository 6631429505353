import React from "react";
import PropTypes from "prop-types";

const Download = ({ width, height, fillColor }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={"0 0 12 19"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0747 8.392C11.0997 8.36701 11.0997 8.32649 11.0747 8.30149L10.2628 7.48956C10.2378 7.46458 10.1973 7.46457 10.1723 7.48952L6.74922 10.9065C6.70889 10.9468 6.64 10.9182 6.64 10.8612V0.730749C6.64 0.695402 6.61135 0.666748 6.576 0.666748H5.424C5.38866 0.666748 5.36 0.695402 5.36 0.730748V10.8608C5.36 10.9179 5.29101 10.9464 5.25071 10.9061L1.83406 7.48328C1.80907 7.45825 1.76852 7.45823 1.74351 7.48324L0.92526 8.30149C0.900266 8.32649 0.900266 8.36701 0.92526 8.392L5.95475 13.4215C5.97974 13.4465 6.02027 13.4465 6.04526 13.4215L11.0747 8.392Z"
        fill={fillColor}
      />
      <path
        d="M0.880005 17.4507C0.880005 17.4154 0.908659 17.3867 0.944005 17.3867H11.056C11.0914 17.3867 11.12 17.4154 11.12 17.4507V18.6027C11.12 18.6381 11.0914 18.6667 11.056 18.6667H0.944005C0.908659 18.6667 0.880005 18.6381 0.880005 18.6027V17.4507Z"
        fill={fillColor}
      />
    </svg>
  );
};

Download.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fillColor: PropTypes.string,
};

Download.defaultProps = {
  fillColor: "#444B54",
  width: 24,
  height: 24,
};

export default Download;
