import { townContentUrl } from "../helper";

export const mediaUrlForCardLayout = (options, media) => {
  if (media.isVideo) {
    return options?.videoPlayer?.showPlayable === false
      ? media.contentThumbUrl
      : media.contentUrl;
  }

  switch (options?.media?.version) {
    case "carousel":
      return media.contentCarouselUrl;
    case "thumb":
      return media.contentThumbUrl;
    default:
      return media.contentUrl;
  }
};

export const parseEventMedia = ({ event, town }) => {
  const eventMedia = {
    contentUrl: event.video_url ? event.video_url : event.photo_url,
    contentThumbUrl: event.video_thumbnail_url
      ? event.video_thumbnail_url
      : event.photo_url,
    alt_text: event.photo_alt_text,
    isVideo: event.video_url ? true : false,
  };

  const eventUrl = townContentUrl({
    townSlug: town?.slug,
    typeOfContent: "events",
    contentSlug: event.slug,
  });

  return { eventMedia: eventMedia, eventUrl: eventUrl };
};

export const removeCaptionsAndCredits = (mediaContent) => {
  return mediaContent.map((item) => {
    return { ...item, caption: "", credits: "" };
  });
};

export const determineOrientation = (width, height) => {
  if (width > height) {
    return "landscape";
  } else if (width < height) {
    return "portrait";
  } else {
    return "square";
  }
};
