import withConfig from "../utils/withConfig";
import restClient from "../utils/restClient";
import { getWindowWidth } from "../hooks/useMediaQuery";

export const fetchHamburgerMenuItems = async (payload) => {
  try {
    const response = await restClient.get(
      withConfig("GET_HAMBURGER_MENU_ITEMS"),
      payload
    );

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchMastheadInfo = async (payload) => {
  try {
    const response = await restClient.get(withConfig("MASTHEAD_INFO"), payload);

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const parseSubmenuItemsForDesktop = (item) => {
  let divideBy = 5;
  const originalItems = [...item.submenu_items];
  let parsedItems = [];

  if (item.submenu_items?.length > 25) {
    divideBy = 8;
  }
  if (getWindowWidth() < 1300) {
    divideBy = 15;
  }
  while (originalItems?.length > 0) {
    parsedItems.push(originalItems.splice(0, divideBy));
  }
  return parsedItems;
};

export const itemHasSubmenu = (item) => {
  return item.submenu_items?.length > 0;
};

export const isSelectedMenuItem = (selectedItemId, item) => {
  return selectedItemId === item.id;
};
