import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    "& span": {
      textAlign: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.down(960)]: {
        lineHeight: "36px",
      },
    },
  },
  title_for_mobile: {
    [theme.breakpoints.down(960)]: {
      width: "60%",
    },
  },
  here_link: {
    display: "inline-block",
    textDecoration: "underline",
    cursor: "pointer",
    color: theme.palette.miscellaneous.blueLink,
  },
  custom_message: {
    paddingTop: theme.spacing(2),
    color: theme.palette.dark.gray,
    fontFamily: "Montserrat, sans-serif",
  },
  custom_link: {
    paddingLeft: theme.spacing(0.5),
    "& span": {
      color: theme.palette.themeColor.primary,
    },
  },
}));

export { useStyles };
