import React from "react";
import PropTypes from "prop-types";
import { Chip as MaterialChip } from "@material-ui/core";
import { useStyles } from "./Chip.style";
import clsx from "clsx";

const Chip = ({ onClick, onDelete, label, className, deleteIcon, props }) => {
  const classes = useStyles();

  return (
    <>
      <MaterialChip
        {...props}
        label={label}
        onClick={onClick}
        onDelete={onDelete}
        deleteIcon={deleteIcon}
        className={clsx(classes.custom_chip, className)}
      />
    </>
  );
};

Chip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  deleteIcon: PropTypes.node,
  className: PropTypes.string,
};

export default Chip;
