import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values },
  } = theme;

  return {
    // Layout 2
    header_layout_2: {
      fontSize: "32px !important",
      letterSpacing: "1.6px",
      fontFamily: "'Bebas Neue', sans-serif",
      fontWeight: "400 !important",
      lineHeight: "normal !important",
      [below(values.sm)]: {
        fontSize: "24px !important",
      },
    },

    // Layout 5
    header_container_layout_5: {
      backgroundColor: "#E3E3E3",
      textTransform: "uppercase",
      textAlign: "center",
      padding: `${spacing(1)}px 0px`,
    },
    header_layout_5: {
      fontSize: "18px !important",
      fontWeight: "700 !important",
      lineHeight: "24px !important",
    },
  };
});

export { useStyles };
