import React from "react";
import PropTypes from "prop-types";
import { AlertTitle, Alert as MuiAlert } from "@material-ui/lab";

const Alert = ({ title, content, severity, variant }) => {
  return (
    <MuiAlert severity={severity} variant={variant}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {content}
    </MuiAlert>
  );
};

Alert.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  severity: PropTypes.oneOf(["error", "info", "success", "warning"]),
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
};

Alert.defaultProps = {
  variant: "standard",
};

export default Alert;
