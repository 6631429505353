import React, { useState } from "react";

const LegalNotice = ({
  className,
  onHoverColor,
  offHoverColor,
  inactiveColor,
  disabled,
  fillColor,
}) => {
  const [iconColor, setIconColor] = useState(
    fillColor ? fillColor : !disabled ? offHoverColor : inactiveColor
  );
  return (
    <svg
      className={className}
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={
        !disabled
          ? () => setIconColor(onHoverColor)
          : () => setIconColor(inactiveColor)
      }
      onMouseLeave={
        !disabled
          ? () => setIconColor(offHoverColor)
          : () => setIconColor(inactiveColor)
      }
    >
      <circle cx="39" cy="39" r="33.34" stroke={iconColor} stroke-width="3" />
      <circle cx="39" cy="39" r="37.5" stroke={iconColor} stroke-width="3" />
      <path
        d="M45.0046 54.7917H41.7204V25.48H36.1592V54.7917H32.8749V58.8641H24.2484V61.3601H36.1592H41.7204H53.6312V58.8641H45.0046V54.7917Z"
        fill={iconColor}
      />
      <path
        d="M55.8645 22.2997H21.5773L13.52 39.0273C13.9579 43.0997 17.3735 46.2526 21.5335 46.2526C28.6712 46.2526 29.5908 39.0273 29.5908 39.0273L23.2413 25.8029H54.2005L47.8072 39.0273C48.2451 43.0997 51.6607 46.2526 55.8207 46.2526C63.0021 46.2526 63.8779 39.0273 63.8779 39.0273L55.8645 22.2997ZM21.5335 44.1507C19.3002 44.1507 17.3735 42.9684 16.3225 41.1292H26.7007C25.6935 42.9246 23.7668 44.1507 21.5335 44.1507ZM15.8847 39.0273L21.5773 27.1604L27.3137 39.0273H15.8847ZM55.8645 44.1507C53.6312 44.1507 51.7045 42.9684 50.6535 41.1292H61.0316C60.0245 42.9246 58.0539 44.1507 55.8645 44.1507ZM50.1718 39.0273L55.8645 27.1604L61.6009 39.0273H50.1718Z"
        fill={iconColor}
      />
      <path
        d="M41.2387 19.8476L43.472 15.2059L41.2387 11.44H36.7722L34.5389 15.2059L36.7722 19.8476H41.2387Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default LegalNotice;