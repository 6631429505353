import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./Ad.style";
import { useGAMSlot } from "../../hooks/useGAMSlot";
import clsx from "clsx";

const Ad = ({ ad_unit, size, targets, element_id }) => {
  const classes = useStyles();
  const elementId = element_id !== undefined ? element_id : ad_unit;

  useEffect(() => {
    if (targets) {
      useGAMSlot(ad_unit, size, elementId, targets);
    }
  }, [targets]);

  const bordersForAds = () => {
    const adSizes = {
      "300x250": classes.ad_for_bullseye,
      "300x100": classes.ad_for_standard,
      "728x90": classes.ad_for_desktop_leaderboard,
      "320x100": classes.ad_for_mobile_leaderboard,
    };

    const adSizeKey = `${size[0]}x${size[1]}`;
    return adSizes[adSizeKey] || "";
  };

  return (
    <div className={classes.ads_container}>
      <div className={clsx(classes.ad, bordersForAds())} id={elementId} />
    </div>
  );
};

Ad.propTypes = {
  ad_unit: PropTypes.string,
  size: PropTypes.arrayOf(PropTypes.number),
  targets: PropTypes.oneOfType([
    PropTypes.shape({
      towns: PropTypes.arrayOf(PropTypes.string),
      sections: PropTypes.arrayOf(PropTypes.string),
      sponsor_display: PropTypes.arrayOf(PropTypes.string),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        towns: PropTypes.arrayOf(PropTypes.string),
        sections: PropTypes.arrayOf(PropTypes.string),
        sponsor_display: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  ]),
  element_id: PropTypes.string,
};

export default Ad;
