import React from "react";
import PropTypes, { string } from "prop-types";

const SquareRssFeed = ({ className, fillColor }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="white" />
      <path
        d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM7.23 18.72C6.75 18.72 6.3 18.54 5.97 18.18C5.64 17.85 5.43 17.37 5.43 16.89C5.43 15.9 6.24 15.09 7.23 15.09C8.22 15.09 9.03 15.9 9.03 16.89C9.03 17.91 8.22 18.72 7.23 18.72ZM11.91 18.45C11.67 18.6 11.37 18.6 11.1 18.45C10.83 18.3 10.71 18.03 10.71 17.76C10.71 15.24 8.7 13.23 6.21 13.23C5.76 13.23 5.43 12.87 5.43 12.45C5.43 12 5.79 11.67 6.21 11.67C9.57 11.67 12.3 14.43 12.3 17.79C12.3 18.03 12.15 18.3 11.91 18.45ZM14.43 18.54C13.98 18.54 13.65 18.18 13.65 17.76C13.65 13.62 10.32 10.26 6.21 10.26C5.91 10.26 5.67 10.11 5.52 9.87C5.37 9.63 5.37 9.33 5.52 9.06C5.67 8.82 5.94 8.67 6.21 8.67C11.19 8.67 15.24 12.75 15.24 17.76C15.24 18.18 14.88 18.54 14.43 18.54ZM18.21 18.45C17.97 18.6 17.67 18.6 17.4 18.45C17.13 18.3 17.01 18.03 17.01 17.76C17.01 11.73 12.18 6.87 6.21 6.87C5.91 6.87 5.67 6.72 5.52 6.48C5.37 6.24 5.37 5.94 5.52 5.67C5.67 5.4 5.94 5.28 6.21 5.28C13.05 5.28 18.6 10.86 18.6 17.76C18.6 18.03 18.45 18.3 18.21 18.45Z"
        fill={fillColor}
      />
    </svg>
  );
};

SquareRssFeed.propTypes = {
  className: string,
  fillColor: string,
};

SquareRssFeed.defaultProps = {
  fillColor: "#F98C10",
};

export default SquareRssFeed;
