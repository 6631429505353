import React from "react";
import labels from "../../../config/labels";
import { useStyles } from "./PreviewBanner.style";

const PreviewBanner = ({}) => {
  const classes = useStyles();

  return (
    <div className={classes.banner_container}>
      <span className={classes.bold_text}>{labels.PREVIEW_BANNER}</span>
      <span>{labels.PREVIEW_REMINDER}</span>
    </div>
  );
};

export default PreviewBanner;
